import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApproversByRecord } from '../services/getApproversByRecord.service';

export const loadApproversByRecord = createAsyncThunk(
  'approvals/load-approvers-by-record',
  async (recordId) => {
    const response = await getApproversByRecord(recordId);
    return response;
  }
);
