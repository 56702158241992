import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { postGetUsers } from '../services/getUsers.service';

export const loadReactionUsers = createAsyncThunk(
  'users/loadUsers',
  async (userIds) => {
    const uniqueUserIds = uniq(userIds);

    const response = await postGetUsers(uniqueUserIds);
    return response;
  }
);
