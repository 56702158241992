import i18n from 'i18next';
import moment from 'moment';

const keyTranslationPairs = {
  status: 'record.status',
  clockInAt: 'record.clocked_in',
  clockOutAt: 'record.clocked_out',
  billedClockInAt: 'record.billed_in',
  billedClockOutAt: 'record.billed_out',
  description: 'calendar.description',
  assignment: 'expense.assignment',
  checked: 'calendar.checked',
  approved: 'calendar.approved',
  type: 'calendar.type',
  project: 'common.project',
  resource: 'resource.resource',
  created: 'common.created_at',
};

const typeTranslations = {
  WORK: 'record.type.work',
  BREAK: 'record.type.break',
  BREAK_COFFEE: 'record.type.coffee_break',
  BREAK_MEAL: 'record.type.meal_break',
  SHIFT: 'record.type.shift',
  ABSENCE: 'record.type.absence',
  BENEFIT: 'record.type.benefit',
  RIDE: 'record.type.ride',
  ITEM: 'record.type.item',
  NEGATIVE: 'record.type.negative',
};

const valueTranslations = {
  IN: 'record.clocked_in',
  OUT: 'record.clocked_out',
  checked: 'calendar.checked',
  unchecked: 'calendar.unchecked',
  approved: 'calendar.approved',
  unapproved: 'calendar.unapproved',
};

export const formatRecordChanges = (
  recordChanges,
  assignments,
  projects,
  resources,
  isAdmin
) => {
  const dateFormat = 'DD.MM.yyyy HH.mm';

  return recordChanges.map((change) => {
    const changeList = change.changes
      .filter((attribute) => {
        // temporary solution for not showing billed hours to employees.
        // Billed hours should be parsed in API by user permissions
        const firstIndexOfColon = attribute.indexOf(':');
        const key = attribute.substring(0, firstIndexOfColon).trim();

        const keyIsBilled = key.includes('billed');
        const keyIsAssignments = key === 'assignments';

        if (keyIsBilled) return !keyIsBilled || isAdmin;
        if (keyIsAssignments) return false;
        return true;
      })
      .map((changedAttribute) => {
        const firstIndexOfColon = changedAttribute.indexOf(':');

        const key = changedAttribute.substring(0, firstIndexOfColon).trim();
        const values = changedAttribute.substring(firstIndexOfColon + 1);

        const [oldValue, newValue] = values.split('->');

        let oldValueTrimmed = oldValue.trim();
        let newValueTrimmed = newValue.trim();

        if (key === 'checked') {
          const oldValueTranslationKey =
            oldValueTrimmed === 'true' ? 'checked' : 'unchecked';
          const newValueTranslationKey =
            newValueTrimmed === 'true' ? 'checked' : 'unchecked';

          oldValueTrimmed = valueTranslations[oldValueTranslationKey]
            ? i18n.t(valueTranslations[oldValueTranslationKey])
            : oldValueTranslationKey;
          newValueTrimmed = valueTranslations[newValueTranslationKey]
            ? i18n.t(valueTranslations[newValueTranslationKey])
            : newValueTranslationKey;
        }
        if (key === 'approved') {
          const oldValueTranslationKey =
            oldValueTrimmed === 'true' ? 'approved' : 'unapproved';
          const newValueTranslationKey =
            newValueTrimmed === 'true' ? 'approved' : 'unapproved';

          oldValueTrimmed = valueTranslations[oldValueTranslationKey]
            ? i18n.t(valueTranslations[oldValueTranslationKey])
            : oldValueTranslationKey;
          newValueTrimmed = valueTranslations[newValueTranslationKey]
            ? i18n.t(valueTranslations[newValueTranslationKey])
            : newValueTranslationKey;
        }

        if (key === 'assignment') {
          if (assignments && assignments[oldValueTrimmed]) {
            const number = assignments[oldValueTrimmed].number
              ? assignments[oldValueTrimmed].number
              : '';
            const name = assignments[oldValueTrimmed].name
              ? assignments[oldValueTrimmed].name
              : '';

            oldValueTrimmed = `${number}. ${name}`;
          }
          if (assignments && assignments[newValueTrimmed]) {
            const number = assignments[newValueTrimmed].number
              ? assignments[newValueTrimmed].number
              : '';
            const name = assignments[newValueTrimmed].name
              ? assignments[newValueTrimmed].name
              : '';
            newValueTrimmed = `${number}. ${name}`;
          }
        }
        if (key === 'project') {
          oldValueTrimmed =
            projects && projects[oldValueTrimmed]
              ? projects[oldValueTrimmed].name
              : '';
          newValueTrimmed =
            projects && projects[newValueTrimmed]
              ? projects[newValueTrimmed].name
              : '';
        }
        if (key === 'resource') {
          if (resources && resources[oldValueTrimmed]) {
            const firstName = resources[oldValueTrimmed].firstName;
            const lastName = resources[oldValueTrimmed].lastName;
            const employer =
              resources[oldValueTrimmed].employer &&
              resources[oldValueTrimmed].employer.name
                ? resources[oldValueTrimmed].employer.name
                : '';
            oldValueTrimmed = `${employer} / ${firstName} ${lastName}`;
          }
          if (resources && resources[newValueTrimmed]) {
            const firstName = resources[newValueTrimmed].firstName;
            const lastName = resources[newValueTrimmed].lastName;
            const employer =
              resources[newValueTrimmed].employer &&
              resources[newValueTrimmed].employer.name
                ? resources[newValueTrimmed].employer.name
                : '';

            newValueTrimmed = `${employer} / ${firstName} ${lastName}`;
          }
        }
        if (
          key.includes('clock') ||
          key.includes('Clock') ||
          key.includes('created')
        ) {
          oldValueTrimmed = oldValueTrimmed
            ? moment(oldValueTrimmed).format(dateFormat)
            : '';
          newValueTrimmed = newValueTrimmed
            ? moment(newValueTrimmed).format(dateFormat)
            : '';
        }

        if (key === 'type') {
          oldValueTrimmed = typeTranslations[oldValueTrimmed]
            ? i18n.t(typeTranslations[oldValueTrimmed])
            : oldValueTrimmed;
          newValueTrimmed = typeTranslations[newValueTrimmed]
            ? i18n.t(typeTranslations[newValueTrimmed])
            : newValueTrimmed;
        }

        if (key === 'status') {
          oldValueTrimmed = valueTranslations[oldValueTrimmed]
            ? i18n.t(valueTranslations[oldValueTrimmed])
            : oldValueTrimmed;
          newValueTrimmed = valueTranslations[newValueTrimmed]
            ? i18n.t(valueTranslations[newValueTrimmed])
            : newValueTrimmed;
        }

        const translationKey = keyTranslationPairs[key.trim()];

        return {
          key: translationKey ? i18n.t(translationKey) : key.trim(),
          oldValue: oldValueTrimmed,
          newValue: newValueTrimmed,
        };
      });

    const formatedChange = {
      user: change.user,
      timeStamp: moment(change.timeStamp).format(dateFormat),
      changeList: changeList,
    };

    return formatedChange;
  });
};
