import { createAsyncThunk } from '@reduxjs/toolkit';
import { setApproveRejectInvoice } from '../services/setApproveRejectInvoice';

export const patchApproveRejectInvoice = createAsyncThunk(
  'invoices/patchApproveRejectInvoice',
  async ({ invoiceId, approvalStatus, comment }, { getState, dispatch }) => {
    const projectId = getState().projects.selectedProjectId;

    return await setApproveRejectInvoice(
      projectId,
      invoiceId,
      approvalStatus,
      comment
    );
  }
);
