import React, { useEffect } from 'react';
import useStyles from './RecordDialogLayoutStyles';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { mdiCoffee, mdiSilverwareForkKnife, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { setBreakRecordEditAttributes } from 'store/records/actions/records.actions';

export function BreaksTimePicker({
  breakRecord,
  disabled,
  parentClockIn,
  parentClockOut,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const dateFormat = 'YYYY-MM-DDTHH:mm';

  const formattedClockInAt = moment(breakRecord.clockInAt).format(dateFormat);

  const formattedClockOutAt = moment(breakRecord.clockOutAt).format(dateFormat);

  const breakClockInBeforeParentClockIn = formattedClockInAt < parentClockIn;
  const breakClockOutAfterParentClockOut =
    formattedClockOutAt > parentClockOut && breakRecord.status !== 'IN';

  const breakType = breakRecord.type;

  const differenceInMinutes = moment(breakRecord.clockOutAt)
    .startOf('minute')
    .diff(moment(breakRecord.clockInAt).startOf('minute'), 'minutes');

  const onDateChange = (value, attributeName) => {
    const utcDate = moment(value).toISOString();
    dispatch(
      setBreakRecordEditAttributes({
        id: breakRecord.id,
        [attributeName]: utcDate,
      })
    );
  };

  const handleDelete = () => {
    const utcDate = moment().toISOString();
    dispatch(
      setBreakRecordEditAttributes({
        id: breakRecord.id,
        deletedAt: utcDate,
      })
    );
  };

  const handleClockInGreaterThanClockout = (clockInAt, clockOutAt) => {
    const clockIn = moment(clockInAt).toISOString();
    const clockOut = moment(clockOutAt).toISOString();

    if (clockIn > clockOut) {
      dispatch(
        setBreakRecordEditAttributes({
          id: breakRecord.id,
          clockInAt: clockIn,
          clockOutAt: clockIn,
        })
      );
    }
  };

  return (
    <>
      <Grid item xs={3} className={classes.breakIconItem}>
        {breakType === 'BREAK_COFFEE' ? (
          <Icon path={mdiCoffee} size={1} className={classes.icon} />
        ) : null}
        {breakType === 'BREAK_MEAL' ? (
          <Icon
            path={mdiSilverwareForkKnife}
            size={1}
            className={classes.icon}
          />
        ) : null}

        <p className={classes.breakTimeDiff}>{`${differenceInMinutes} min`}</p>
      </Grid>
      <Grid item xs={4} className={classes.dateItem}>
        <TextField
          id={`break-clock-in-${breakRecord.id}`}
          InputLabelProps={{ shrink: true, className: classes.breakTextInput }}
          InputProps={{
            className: `${classes.breakTextInput} ${
              breakClockInBeforeParentClockIn ? classes.clockedInputWarning : ''
            }`,
          }}
          className={classes.breakTextInput}
          fullWidth
          label={t('calendar.start_date')}
          variant="outlined"
          size="small"
          type="datetime-local"
          value={formattedClockInAt}
          onChange={(event) => onDateChange(event.target.value, 'clockInAt')}
          onBlur={(event) =>
            handleClockInGreaterThanClockout(
              event.target.value,
              breakRecord.clockOutAt
            )
          }
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4} className={classes.dateItem}>
        {breakRecord.clockOutAt ? (
          <TextField
            id={`break-clock-out-${breakRecord.id}`}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
              className: classes.breakTextInput,
            }}
            InputProps={{
              className: `${classes.breakTextInput} ${
                breakClockOutAfterParentClockOut
                  ? classes.clockedInputWarning
                  : ''
              }`,
            }}
            fullWidth
            className={classes.breakTextInput}
            label={t('calendar.end_date')}
            variant="outlined"
            size="small"
            type="datetime-local"
            value={formattedClockOutAt}
            onChange={(event) => onDateChange(event.target.value, 'clockOutAt')}
            onBlur={(event) =>
              handleClockInGreaterThanClockout(
                breakRecord.clockInAt,
                event.target.value
              )
            }
          />
        ) : (
          <Typography className={classes.clockedText}>
            {t('calendar.clocked_in')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={1} className={classes.centerItems}>
        <IconButton disabled={disabled} onClick={() => handleDelete()}>
          <Icon
            color="#b0340a"
            path={mdiDelete}
            size={1}
            className={classes.icon}
          />
        </IconButton>
      </Grid>

      {breakClockInBeforeParentClockIn || breakClockOutAfterParentClockOut ? (
        <Grid item xs={12} className={classes.centerItems}>
          <FormHelperText className={classes.warningText}>
            {t('calendar.break_is_beyond_record_timeframe')}
          </FormHelperText>
        </Grid>
      ) : null}
    </>
  );
}
