import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadAllUserProjectsPermissions } from 'scenes/Permissions/store/thunks/loadAllUserProjectsPermissions.thunk';

import { clearSelectedRecords } from 'store/records/actions/records.actions';
import {
  setPersonalWorkCalendarMode,
  setSelectionMode,
} from '../../actions/demoActions';
import { loadPersonalWorkCalendarData } from './loadPersonalWorkCalendarData.thunk';
import { loadAllowancesOfCurrentUserEmployers } from 'store/employerAllowances/thunks/loadAllowancesOfCurrentUserEmployers.thunk';

export const initMyLixaniWorkCalendar = createAsyncThunk(
  'workCalendar/initMyLixaniWorkCalendar',
  async (_, { dispatch, getState }) => {
    dispatch(clearSelectedRecords());
    dispatch(setSelectionMode(false));
    dispatch(setPersonalWorkCalendarMode(true));
    dispatch(loadAllowancesOfCurrentUserEmployers());
    // dispatch(loadProjectAndSubProjectBulletins());

    await dispatch(loadAllUserProjectsPermissions());

    await dispatch(loadPersonalWorkCalendarData());
  }
);
