import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectProjectAndChildProjectEditableRecords,
  selectWorkCalendarFilteredRecords,
} from 'scenes/WorkDemo/store/selectors/workSelectorsDemo';
import { selectSelectableRecordsIds } from '../selectors/records.selectors';

export const setAllFilteredRecordsToEdit = createAsyncThunk(
  'records/setAllFilteredRecordsToEdit',
  async (_, { getState }) => {
    const state = getState();
    const filteredRecords = selectWorkCalendarFilteredRecords(state);
    const editableRecords = selectProjectAndChildProjectEditableRecords(
      getState()
    );
    const selectableRecords = selectSelectableRecordsIds(getState());
    const records = filteredRecords.filter(
      (record) =>
        editableRecords.includes(record.id) &&
        selectableRecords.includes(record.id)
    );

    return records;
  }
);
