import { createSelector } from '@reduxjs/toolkit';
import { EDIT_PERMISSIONS_OF_PROJECT } from 'scenes/Permissions/constants/permissions';
import { returnDefaultPermissionsByEmploymentType } from 'scenes/Permissions/utils/returnDefaultPermissionsByEmploymentType';
import {
  selectProjects,
  selectSelectedProject,
  selectSelectedProjectId,
  selectProjectsFolder,
  selectProjectsArray,
} from 'store/projects/selectors/projectsSelectors';
import {
  selectIsUserProjectResourcesLoaded,
  selectSelectedProjectCurrentUserResources,
} from 'store/resources/selectors/resources.selectors';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';

import { uniqBy } from 'lodash';
import { selectUserProfileEmployerBusinessIds } from 'scenes/UserProfile/store/selectors/user-profile.selectors';

const selectProjectPermissionsState = (state) => state.projectPermissions;

export const selectUserProjectPermissions = createSelector(
  [selectProjectPermissionsState],
  (state) => state.userProjectPermissionsByProjectId
);

const selectIsProjectPermissionsLoading = createSelector(
  [selectProjectPermissionsState],
  (state) => state.loading
);

export const selectUserAdminPermissions = createSelector(
  [selectProjectPermissionsState],
  (state) => state.userAdminPermissions
);

export const selectIsBusinessAdminOrOwnerOfSelectedProject = createSelector(
  [selectLoggedInUserId, selectUserAdminPermissions, selectSelectedProject],
  (userId, adminPermissions, project) =>
    (userId && userId === project.mirrorUser) ||
    adminPermissions[project.business_id]
      ? true
      : false
);
const selectIsEmployerProject = createSelector(
  [selectSelectedProject],
  (project) =>
    project.businessTypeEnum && project.businessTypeEnum === 'EMPLOYER'
      ? true
      : false
);

export const selectUserAdminBusinessIds = createSelector(
  [selectUserAdminPermissions],
  (adminPermissions) => Object.keys(adminPermissions)
);

export const selectResourceDefaultPermissions = createSelector(
  [selectSelectedProjectCurrentUserResources],
  (selectedProjectUserResources) => {
    const resourceDefaultPermissions = returnDefaultPermissionsByEmploymentType(
      selectedProjectUserResources
    );
    return resourceDefaultPermissions;
  }
);

export const selectIsCurrentUserEmployeeInProject = createSelector(
  [selectSelectedProjectCurrentUserResources],
  (currentUserResources) => {
    const hasEmployeeResource = currentUserResources.filter(
      (resource) =>
        !resource.employmentType || resource.employmentType === 'EMPLOYEE'
    )[0];

    return hasEmployeeResource ? true : false;
  }
);

export const getNearestUserProjectPermissions = (
  projects,
  userPermissions,
  selectedProjectId
) => {
  //If permissions are not set in current project, they are inherited from nearest parent project. Parent projects are looped until permissions are found.
  const selectedProjectPermissions = userPermissions[selectedProjectId];

  const selectedProject = projects[selectedProjectId];

  const getParentProjectPermissions = (projectId) => {
    const currentProject = projects[projectId];

    const parentProjectPermissions =
      currentProject && currentProject.parent
        ? userPermissions[currentProject.parent]
        : {};

    return parentProjectPermissions
      ? parentProjectPermissions
      : // if not found, call itself again, this time with the parent of current project in loop
        getParentProjectPermissions(currentProject.parent);
  };

  if (selectedProjectPermissions) {
    return selectedProjectPermissions;
  } else if (selectedProject && selectedProject.parent) {
    return getParentProjectPermissions(selectedProjectId);
  } else {
    return {};
  }
};

export const selectSelectedFolderUserPermissions = createSelector(
  [
    selectProjectsFolder,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
  ],
  (
    selectedFolder,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions
  ) => {
    const definedUserPermissions = getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      selectedFolder.projectId
    );

    const resourcePermissionsWithDefinedPermissions = {
      ...resourceDefaultPermissions,
      ...definedUserPermissions,
    };

    return resourcePermissionsWithDefinedPermissions;
  }
);
export const selectSelectedProjectUserPermissions = createSelector(
  [
    selectSelectedProjectId,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
  ],
  (
    selectedProjectId,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions
  ) => {
    const definedUserPermissions = getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      selectedProjectId
    );

    const resourcePermissionsWithDefinedPermissions = {
      ...resourceDefaultPermissions,
      ...definedUserPermissions,
    };

    return resourcePermissionsWithDefinedPermissions;
  }
);

export const selectCanAccessPermissionsManagement = createSelector(
  [
    selectSelectedProjectUserPermissions,
    selectIsBusinessAdminOrOwnerOfSelectedProject,
  ],
  (userPermissions, isOwner) =>
    isOwner || userPermissions[EDIT_PERMISSIONS_OF_PROJECT] === true
);

export const selectSelectedProjectUserPermissionsArray = createSelector(
  [selectSelectedProjectUserPermissions],
  (permissions) => (permissions ? Object.keys(permissions) : [])
);

export const selectIsPersonalPermissionsLoaded = createSelector(
  [selectProjectPermissionsState],
  (state) => state.isPersonalPermissionsLoaded
);

export const selectIsPermissionsDataLoaded = createSelector(
  [selectIsPersonalPermissionsLoaded, selectIsUserProjectResourcesLoaded],
  (isPermissionsLoaded, isResourcesLoaded) =>
    isPermissionsLoaded && isResourcesLoaded
);

export const selectIsAdminInSelectedProject = createSelector(
  [selectSelectedProject, selectUserAdminPermissions],
  (selectedProject, userAdminPermissions) =>
    userAdminPermissions[selectedProject.business_id] ? true : false
);

//multiple use permission selector, in case of not owner, the permission's true value is checked from user permissions.
export const selectIsRequiredPermission = createSelector(
  [
    selectIsBusinessAdminOrOwnerOfSelectedProject,
    selectSelectedProjectUserPermissions,
    (_, args) => args,
  ],
  (isOwner, permissions, args) => isOwner || permissions[args] === true
);

export const selectEmployersWithPermissionToAdjustUserEmployerSettings =
  createSelector(
    [selectProjectsArray, selectUserProfileEmployerBusinessIds],
    (projects, adminBusinessIds) => {
      const userAdminEmployerProjects = projects.filter(
        (project) =>
          project.businessTypeEnum === 'EMPLOYER' &&
          adminBusinessIds.includes(project.business_id)
      );

      const uniqueBusinessIdEmployers = uniqBy(
        userAdminEmployerProjects,
        'business_id'
      );

      return uniqueBusinessIdEmployers;
    }
  );
