import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const getInvoiceSortingRules = async (projectId) => {
  const apiPath = `invoice/${projectId}/invoice-sorting-rules/`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    //url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
};
