import { createAsyncThunk } from '@reduxjs/toolkit';
import { getParentAndSubProjects } from '../services/getParentAndSubProjects';
import {
  selectSelectedProjectId,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';

export const loadParentAndSubProjects = createAsyncThunk(
  'expenseTracking/loadParentAndSubProjects',
  async (params, { getState, dispatch }) => {
    const project = selectSelectedProject(getState());
    const projectId = project?.id;

    if (!projectId) {
      return [];
    }

    const businessId = project?.business_id;
    if (!businessId) {
      console.log('No business id found, returning only project');
      return [project];
    }

    const response = await getParentAndSubProjects(projectId);

    if (!response) {
      return [];
    }

    return response;
  }
);
