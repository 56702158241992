import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminsOfBusiness } from 'scenes/Permissions/services/getAdminsOfBusiness.service';
import { getResourcesOfBusiness } from '../../services/getResourcesOfBusiness.service';


export const onSearchBusinessClick = createAsyncThunk(
  'settings/onSearchBusinessClick',
  async (businessId) => {
    const resources
      = await getResourcesOfBusiness(businessId)
      .then((res) => res.data)
      .catch((e) => console.error(e));

    const admins = await getAdminsOfBusiness(businessId);

    return {resources,admins}
  }
);
