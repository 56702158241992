import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setDeleteFormTemplateFromAllEmployerProjects from 'scenes/ProjectForms/utils/setDeleteFormTemplateFromAllEmployerProjects';

export const deleteFormTemplateFromAllEmployerProjects = createAsyncThunk(
  'projectForm/deleteFormTemplateFromAllEmployerProjects',
  async (templateData, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const deletedFormTemplate = await setDeleteFormTemplateFromAllEmployerProjects(templateData, params);
    return { deletedFormTemplate };
  }
);
