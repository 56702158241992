import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateProjectBankFolder } from 'scenes/ProjectBank/services/postCreateProjectBankFolder.service';

export const createProjectBankFolder = createAsyncThunk(
  'projectBank/createProjectBankFolder',
  async (filePermission) => {
    const folderFilePermission = await postCreateProjectBankFolder(
      filePermission
    );

    return folderFilePermission;
  }
);
