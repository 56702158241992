import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadReactionUsers } from './thunks/loadReactionUsers';

const usersAdapter = createEntityAdapter();

const filepermissionReactionUsersSlice = createSlice({
  name: 'filepermissionReactionUsers',
  initialState: {
    users: usersAdapter.getInitialState(),
    loading: true,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadReactionUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadReactionUsers.fulfilled, (state, action) => {
        const users = action.payload;
        usersAdapter.addMany(state.users, users);
        state.loading = false;
      })
      .addCase(loadReactionUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export default filepermissionReactionUsersSlice.reducer;
