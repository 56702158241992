import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from 'mdi-material-ui/Close';
import Refresher from 'components/Refresher/Refresher';
import InTime from 'components/InTime/InTime';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.component.dialogPaperFullscreen,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 320,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paper: {
    backgroundColor: 'initial',
    border: `1px solid ${theme.palette.borderColor}`,
    boxSizing: 'border-box',
    margin: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeGrid: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  closeButton: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'hidden',
    },
  },
  dateContainer: {
    marginBottom: theme.spacing(1),
  },
  datetime: {
    ...theme.typography.fontPantonLight,
    color: theme.palette.text.tertiary,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  todayGrid: {
    textAlign: 'right',
  },
  today: {
    ...theme.typography.fontPantonLight,
    color: theme.palette.text.caption,
    fontSize: '0.65rem',
  },
  inTime: {
    ...theme.typography.fontPantonSemiBold,
    color: theme.palette.text.secondary,
    fontSize: '2.5rem',
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    paddingBottom: theme.spacing(3),
  },
}));

function TimerContent(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Translation ns={['common']}>
      {(t) => (
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={2}>
            <div className={classes.primary}>
              <Grid
                container
                alignItems="center"
                className={classes.dateContainer}
              >
                <Grid item xs></Grid>
                <Grid item>
                  <Typography className={classes.datetime} variant="inherit">
                    <Refresher
                      render={() =>
                        moment().format('HH:mm:ss, dd Do MMMM YYYY')
                      }
                    />
                  </Typography>
                </Grid>
                <Grid item xs className={classes.closeGrid}>
                  <ButtonBase
                    className={classes.closeButton}
                    onClick={props.onClose}
                  >
                    <CloseIcon />
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs className={classes.todayGrid}>
                  <Typography
                    align="right"
                    variant="caption"
                    className={classes.today}
                  >
                    {t('datetime.today')}
                  </Typography>
                </Grid>
                <Grid item className={classes.inTimeGrid}>
                  <Typography className={classes.inTime} variant="inherit">
                    <InTime full={true} />
                  </Typography>
                </Grid>
                <Grid item xs className={classes.collapseButtonGrid}></Grid>
              </Grid>
            </div>
          </Paper>
          <div className={classes.content}>{children}</div>
        </div>
      )}
    </Translation>
  );
}

TimerContent.defaultProps = {
  classes: {},
  children: [],
  onClose: () => null,
};

TimerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TimerContent;
