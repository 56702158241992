import { RECORD_STATUS } from 'LixaniAPI/enums';
import moment from 'moment';
import { sortBy } from 'lodash';
const getTimeDifferenceFromStartOfTheDay = (date, currentDate) => {
  const currentDateStart = moment(currentDate).startOf('day');
  const dateLocal = moment(date);

  const clockInDifferenceFromStartOfTheDay = dateLocal.diff(
    currentDateStart,
    'hours',
    true
  );

  return clockInDifferenceFromStartOfTheDay;
};

export const getRecordTimeLineItems = (records, currentDate) => {
  return sortBy(
    records.map((record) => {
      const clockInDifferenceFromStartOfTheDay =
        getTimeDifferenceFromStartOfTheDay(record.clockInAt, currentDate);
      const clockOutDifferenceFromStartOfTheDay =
        getTimeDifferenceFromStartOfTheDay(record.clockOutAt, currentDate);

      const start = (clockInDifferenceFromStartOfTheDay / 24) * 100;
      const end = (clockOutDifferenceFromStartOfTheDay / 24) * 100;

      const isClockedIn = RECORD_STATUS.IN === record.status;

      return {
        start: start,
        end: end,
        isClockedIn: isClockedIn,
      };
    }),
    'start'
  );
};
