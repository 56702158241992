import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchSaveProjectRoleModifications } from 'scenes/Permissions/services/roles/patchSaveProjectRoleModifications.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectAssignedDefaultRolesModifications,
  selectProjectRolePermissionModifications,
} from '../../selectors/permissionsSelectors';

export const saveProjectRoleModifications = createAsyncThunk(
  'permissions/saveProjectRoleModifications',
  async (_, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const projectRoleModifications = selectProjectRolePermissionModifications(
      getState()
    );

    const projectAssignedDefaultRoleModifications =
      selectAssignedDefaultRolesModifications(getState());

    const response = await patchSaveProjectRoleModifications(
      projectId,
      Object.values(projectRoleModifications),
      Object.values(projectAssignedDefaultRoleModifications)
    );

    return response;
  }
);
