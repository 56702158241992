import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserSingleProjectPermissions } from 'scenes/Permissions/services/getProjectPermissions.service';

export const loadUserSingleProjectPermissions = createAsyncThunk(
  'permissions/loadUserSingleProjectPermissions',
  async (projectId) => {
    const projectPermissions = await getUserSingleProjectPermissions(projectId);

    return projectPermissions;
  },
  {
    condition: (projectId, { getState }) => {
      const { projectPermissions } = getState();

      const existingUserProjectPermissions =
        projectPermissions.userProjectPermissionsByProjectId[projectId];

      if (existingUserProjectPermissions) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);
