import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminInitialDataByProjectsOfBusinessInTimeFrame } from 'scenes/WorkDemo/services/getAdminInitialDataByProjectsOfBusinessInTimeFrame';
import { getAdminInitialDataByResourcesOfEmployerInTimeframe } from 'scenes/WorkDemo/services/getAdminInitialDataByResourcesOfEmployerInTimeframe';
import {
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { uniq } from 'lodash';
import { loadAssignmentsForProjects } from 'store/assignments/thunks/loadAssignmentsForProjects';
import { loadRecordAllowancesOfEmployerInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfEmployerInTimeframe.thunk';
import { loadRecordAllowancesOfProjectInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfProjectInTimeframe.thunk';

export const loadAdminInitialDataInTimeFrame = createAsyncThunk(
  'workCalendar/loadAdminInitialDataByTimeFrame',
  async (_, { getState, dispatch }) => {
    const { workSearchParams_demo } = getState();

    const selectedProjectId = selectSelectedProjectId(getState());
    const selectedProject = selectSelectedProject(getState());

    const fromDate = workSearchParams_demo.fromDate;
    const toDate = workSearchParams_demo.toDate;

    const searchingInEmployerProject =
      selectedProject.businessTypeEnum === 'EMPLOYER';

    if (searchingInEmployerProject) {
      dispatch(loadRecordAllowancesOfEmployerInTimeframe({ fromDate, toDate }));

      const getAdminInitialDataByResourcesOfEmployerInTimeframePromise =
        getAdminInitialDataByResourcesOfEmployerInTimeframe(
          selectedProjectId,
          fromDate,
          toDate
        );
      const getAdminInitialDataByProjectsOfBusinessInTimeFramePromise =
        getAdminInitialDataByProjectsOfBusinessInTimeFrame(
          selectedProjectId,
          fromDate,
          toDate
        );
      const [externalData, ownProjectsData] = await Promise.all([
        getAdminInitialDataByResourcesOfEmployerInTimeframePromise,
        getAdminInitialDataByProjectsOfBusinessInTimeFramePromise,
      ]);

      const allData = {
        resources: [...externalData.resources, ...ownProjectsData.resources],
        employers: [...externalData.employers, ...ownProjectsData.employers],
        records: [...externalData.records, ...ownProjectsData.records],
        projects: [...externalData.projects, ...ownProjectsData.projects],
        filePermissions: [
          ...externalData.filePermissions,
          ...ownProjectsData.filePermissions,
        ],
      };

      const recordsProjectIds = uniq(
        allData.records
          .filter((record) => record.assignment)
          .map((record) => record.project)
      );

      dispatch(loadAssignmentsForProjects(recordsProjectIds));

      return allData;
    } else {
      dispatch(loadRecordAllowancesOfProjectInTimeframe({ fromDate, toDate }));
      const { resources, employers, records, projects, filePermissions } =
        await getAdminInitialDataByProjectsOfBusinessInTimeFrame(
          selectedProjectId,
          fromDate,
          toDate
        );

      const recordsProjectIds = uniq(
        records
          .filter((record) => record.assignment)
          .map((record) => record.project)
      );

      dispatch(loadAssignmentsForProjects(recordsProjectIds));

      return {
        resources,
        employers,
        records,
        projects,
        filePermissions,
      };
    }
  }
);
