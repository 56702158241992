import moment from 'moment';

export const createReport = (reportData, settings, generationParams) => {
  const { days, daysTypes, resources, workHourType } = reportData;
  const { isRecordBasedReductions, paidCoffeeBreakMinutes } = generationParams;

  const paidCoffeeBreakLengthValueInHours = paidCoffeeBreakMinutes
    ? Number(paidCoffeeBreakMinutes) / 60
    : 0;

  let weeks = {};

  Object.keys(days).map(function (day) {
    const week1 = moment(day).format('YYYY[W]WW');
    const week2 = moment(day).add(1, 'weeks').format('YYYY[W]WW');
    const weekKey = `${week1}-${week2}`;
    if (
      !Object.keys(weeks).some(function (k) {
        return ~k.indexOf(week1);
      })
    ) {
      weeks[weekKey] = {};
    }
  });

  Object.keys(days).map(function (day) {
    const dayWeek = moment(day).format('YYYY[W]WW');
    Object.keys(weeks).map(function (week) {
      if (week.includes(dayWeek)) {
        if (!(day in weeks[week])) weeks[week][day] = days[day];
      }
    });
  });

  const mainTableHeader = (_days) => {
    let titles3 = [];

    if (settings.filetype === 'PDF') {
      titles3 = titles3.map((x) =>
        x ? { text: x, style: 'tableHeader' } : ''
      );
    }

    let totalHours = 0;
    Object.keys(_days).map(function (key) {
      const d = moment(key);

      Object.keys(_days[key]).map((resource) => {
        totalHours += _days[key][resource].hours;
      });

      if (settings.filetype === 'excel') {
        titles3.push(`${d.date()}.${d.month() + 1}.`);
      } else {
        titles3.push(
          `${d.date()}.${d.month() + 1}._ISOWEEKDAY-${d.isoWeekday()}`
        );
      }
    });

    if (totalHours > 0) {
      return titles3;
    } else {
      return [];
    }
  };

  let userTotalWorkAmountsByUserId = {};

  const mainTableRows = (_days) => {
    let rows = [];
    Object.keys(resources).map((resourceKey) => {
      let workdays = 0;
      let totalsum = 0;
      let totalSumWithRecordBasedReductions = 0;
      let autoReducedSum = 0;

      const resourceData = resources[resourceKey];
      let resourceName = `${resourceData.resource.firstName} ${resourceData.resource.lastName}`;
      if (settings.lastNameFirst) {
        resourceName = `${resourceData.resource.lastName} ${resourceData.resource.firstName}`;
      }
      let columns = [resourceName, '', '', ''];

      Object.keys(_days).map(function (dayKey) {
        if (
          Object.keys(daysTypes[dayKey].WORK).length ||
          Object.keys(daysTypes[dayKey].PROJECTWORK).length
        ) {
          const workTypeDataForResourceInDay =
            daysTypes[dayKey]['WORK'][resourceKey];

          const projectworkTypeDataForResourceInDay =
            daysTypes[dayKey]['PROJECTWORK'][resourceKey];

          const userHasWorkOrProjectWorkThisDay =
            workTypeDataForResourceInDay || projectworkTypeDataForResourceInDay
              ? true
              : false;

          if (userHasWorkOrProjectWorkThisDay) {
            let daySum;

            const workTypeValue = workTypeDataForResourceInDay
              ? workTypeDataForResourceInDay.value
              : 0;
            const projectWorkTypeValue = projectworkTypeDataForResourceInDay
              ? projectworkTypeDataForResourceInDay.value
              : 0;

            const workTypeReduction = workTypeDataForResourceInDay
              ? workTypeDataForResourceInDay.reduction
              : 0;
            const projectWorkTypeReduction = projectworkTypeDataForResourceInDay
              ? projectworkTypeDataForResourceInDay.reduction
              : 0;

            if (workHourType === 'ALL') {
              workdays += 1;
              const totalWork = workTypeValue + projectWorkTypeValue;
              const totalReductions =
                workTypeReduction + projectWorkTypeReduction;

              totalsum += totalWork;
              daySum = totalWork;
              autoReducedSum += totalWork - totalReductions;
            } else {
              if (workHourType === 'WORK') {
                totalsum += workTypeValue;
                daySum = workTypeValue;
                autoReducedSum += workTypeValue - workTypeReduction;

                if (workTypeValue > 0) {
                  workdays += 1;
                }
              } else {
                totalsum += projectWorkTypeValue;
                daySum = projectWorkTypeValue;
                autoReducedSum +=
                  projectWorkTypeValue - projectWorkTypeReduction;
                if (projectWorkTypeValue > 0) {
                  workdays += 1;
                }
              }
            }

            const existingMealBreakForResourceInDay =
              daysTypes[dayKey]['BREAK_MEAL'][resourceKey];

            const existingBreakForResourceInDay =
              daysTypes[dayKey]['BREAK'][resourceKey];

            const existingCoffeeBreakForResourceInDay =
              daysTypes[dayKey]['BREAK_COFFEE'][resourceKey];

            const calculatedDayReductions = calculateBreakRecordTotalReductions(
              existingBreakForResourceInDay,
              existingMealBreakForResourceInDay,
              existingCoffeeBreakForResourceInDay,
              paidCoffeeBreakLengthValueInHours
            );

            totalSumWithRecordBasedReductions +=
              daySum - calculatedDayReductions;

            const daySumValue = Number(daySum) > 0.01 ? daySum.toFixed(2) : '';

            columns.push(daySumValue);
          } else {
            columns.push('');
          }
        } else {
          columns.push('');
        }
      });

      if (
        totalsum > 0 ||
        Object.keys(resourceData.additionalSalaries.length > 0)
      ) {
        const finalReducedSum = !isRecordBasedReductions
          ? autoReducedSum.toFixed(2)
          : totalSumWithRecordBasedReductions.toFixed(2);

        const userSalaryType = resourceData.resource.baseSalaryType;

        const salaryUnitPrice =
          userSalaryType === 'MONTHLY'
            ? resourceData.resource.salaryMonth
            : resourceData.resource.salaryHour;

        const userHourlySalary = resourceData.resource.salaryHour || 0;
        const userMonthlySalary = resourceData.resource.salaryMonth || 0;

        const monthSalaryFrequency = resourceData.resource.monthSalaryFrequency;

        columns[1] = workdays;
        columns[2] = totalsum.toFixed(2);
        columns[3] = finalReducedSum;

        rows.push({
          userId: resourceKey,
          cells: columns,
          baseSalaryType: userSalaryType,
          salaryUnitPrice,
        });

        const foundUserTotals = userTotalWorkAmountsByUserId[resourceKey];

        if (foundUserTotals) {
          foundUserTotals.workTotalHours += Number(totalsum);
          foundUserTotals.workReducedHours += Number(finalReducedSum);
        } else {
          userTotalWorkAmountsByUserId[resourceKey] = {
            workTotalHours: Number(totalsum),
            workReducedHours: Number(finalReducedSum),
            userId: resourceKey,
            baseSalaryType: userSalaryType,
            unitPrice:
              userSalaryType === 'MONTHLY'
                ? userMonthlySalary
                : userHourlySalary,
            monthSalaryFrequency,
          };
        }
      }
    });
    return rows;
  };

  let dataRows = [];
  let headerRows = [];
  Object.keys(weeks).map(function (week) {
    const _days = weeks[week];
    dataRows.push(mainTableRows(_days));
    headerRows.push(mainTableHeader(_days));
  });

  return { dataRows, headerRows, userTotalWorkAmountsByUserId };
};

const calculateBreakRecordTotalReductions = (
  breakRecordData,
  mealBreakRecordData,
  coffeeBreakRecordData,
  paidCoffeeBreakHours
) => {
  let totalReductions = 0;

  if (breakRecordData) {
    totalReductions += breakRecordData.value;
  }

  if (mealBreakRecordData) {
    totalReductions = mealBreakRecordData.value;
  }

  if (
    coffeeBreakRecordData &&
    coffeeBreakRecordData.value > paidCoffeeBreakHours
  ) {
    const coffeeBreakLengthValueWithPaidPortionReduced =
      coffeeBreakRecordData.value - paidCoffeeBreakHours;

    totalReductions += coffeeBreakLengthValueWithPaidPortionReduced;
  }
  return totalReductions;
};
