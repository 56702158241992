import { createSignedRequest } from 'utils/createSignedRequest';
import Config from 'Config';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addProjectToMergeFrom = createAsyncThunk(
  'projects/addProjectToMergeFrom',
  async (keyOfProjectToMergeFrom, { getState, rejectWithValue }) => {
    const state = getState();

    const selectedProjectId = state?.root?.project?.id;

    if (!selectedProjectId) {
      throw new Error('No project selected');
    }

    if (!keyOfProjectToMergeFrom) {
      throw new Error('Project is required in addProjectToMerge thunk');
    }

    const apiPath = `projects/${selectedProjectId}/add-project-to-merge-from`;

    const request = {
      //url: `http://localhost:3001/${apiPath}`,
      url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
      host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
      path: apiPath,
      method: 'PATCH',
      region: Config.AWS_REGION,
      service: 'execute-api',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ keyOfProjectToMergeFrom }), // aws4 looks for body; axios for data
      data: { keyOfProjectToMergeFrom },
    };
    const signedRequest = await createSignedRequest(request);

    let response;
    try {
      response = await axios(signedRequest);
    } catch (e) {
      return rejectWithValue({ errorMessage: e.response.data.message });
    }

    return response.data;
  }
);
