import { createSelector } from '@reduxjs/toolkit';
import {
  selectSelectedProject,
  selectSelectedProjectId,
  selectSelectedProjectParent,
} from 'store/projects/selectors/projectsSelectors';
import { selectProjectsThatUserHasResourceToOrIsCreatorOfWithoutJoinedData } from 'store/projects/selectors/userProjects.selectors';
import { selectSelectedProjectCurrentUserResources } from 'store/resources/selectors/resources.selectors';

const selectBreadcrumbsState = (state) => state.breadcrumbs;

export const selectBreadcrumbs = createSelector(
  [selectBreadcrumbsState],
  (state) => state.path
);

export const selectSelectedEmployer = createSelector(
  [selectBreadcrumbsState],
  (state) => state.selectedEmployer
);

export const selectProjectsCrumbRoute = createSelector(
  [
    selectSelectedProject,
    selectSelectedProjectCurrentUserResources,
    selectSelectedProjectParent,
    selectProjectsThatUserHasResourceToOrIsCreatorOfWithoutJoinedData,
    selectSelectedEmployer,
    selectSelectedProjectId,
  ],
  (
    selectedProject,
    resources,
    parent,
    projects,
    activeEmployerId,
    selectedProjectId
  ) => {
    const employersIds = resources.map((resource) => resource.employer);

    let parentKey = [];
    if (parent.key) {
      // delete employer from parent projects key
      parentKey = parent.key.split('/');
      parentKey.splice(0, 2);
    }

    const projectPathKeys = [...parentKey];

    const projectPath = projectPathKeys.map((key) => {
      const project = projects.find((project) => project.projectKey === key);
      return { key: project.projectKey, name: project.name };
    });

    const navigationActiveEmployer = projects.find(
      (project) => project.id === activeEmployerId
    );

    const activeEmployerData = navigationActiveEmployer
      ? {
          key: navigationActiveEmployer.projectKey,
          name: navigationActiveEmployer.name,
        }
      : undefined;

    let employers = projects
      .filter(
        (project) =>
          employersIds.includes(project.id) &&
          selectedProject.projectKey !== project.projectKey
      )
      .map((project) => ({
        key: project.projectKey,
        name: project.name,
      }));

    if (employers.length > 1) {
      employers = [employers.shift()];
    }

    return {
      employers:
        activeEmployerData && selectedProject.businessTypeEnum === 'NORMAL'
          ? [activeEmployerData]
          : employers,
      projectPath: projectPath,
    };
  }
);
