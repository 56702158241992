import Config from 'Config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSignedRequest } from 'utils/createSignedRequest';
import axios from 'axios';
export const getLixaniUsageDataOfBusinesses = createAsyncThunk(
  'settings/getLixaniUsageDataOfBusinesses',
  async () => {
    const apiPath = `businesses/usage-data`;
    let request = undefined;
    if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'prod') {
      request = {
        url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
        host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
        path: apiPath,
        method: 'GET',
        region: Config.AWS_REGION,
        service: 'execute-api',
      };
    } else {
      request = {
        url: `https://api.lixani.com/${apiPath}`,
        host: 'api.lixani.com',
        path: apiPath,
        method: 'GET',
        region: Config.AWS_REGION,
        service: 'execute-api',
      };
    }
    const signedRequest = await createSignedRequest(request);

    const response = await axios(signedRequest);

    return response.data;
  }
);
