import { createSlice } from '@reduxjs/toolkit';
import { closeProjectResourcesView } from 'scenes/ProjectResources/store/actions/project-resources.actions';
import { arrayToMap } from 'utils/arrayToMap';
import { loadUserData } from './thunks/loadUserData.thunk';
import { setUserBusinessConfigurations } from './thunks/setUserBusinessConfigurations.thunk';

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    loading: false,
    user: {},
    userPersonalResource: {},
    userEmployerBusinessIds: [],
    userEmployeeConfigurations: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadUserData.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.userPersonalResource = action.payload.userPersonalResource;
        state.userEmployerBusinessIds = action.payload.userEmployerBusinessIds;
        state.userEmployeeConfigurations = arrayToMap(
          action.payload.userEmployeeConfigurations,
          'businessId'
        );

        state.loading = false;
      })
      .addCase(loadUserData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(setUserBusinessConfigurations.fulfilled, (state, action) => {
        state.userEmployeeConfigurations[action.payload.businessId] =
          action.payload;
      })
      .addCase(closeProjectResourcesView, (state, action) => {
        state.user = {};
      });
  },
});
