import {
  RECORDS_APPROVE,
  RECORDS_CHECK_BILLED_TIMES,
  RECORDS_CHECK_CLOCKED_TIMES,
} from 'scenes/Permissions/constants/permissions';
import { getNearestUserProjectPermissions } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

export const checkPermissionsToCheckRecordBilledInTimes = (
  record,
  userProjectPermissions,
  projects,
  resourceDefaultPermissions,
  IsAdminOfSelectedRecordProject,
  IsAdminOfSelectedRecordResource
) => {
  const nearestUserProjectPermissionsForRecord =
    getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      record.project
    );

  const defaultPermissionsWithNearestProjectPermissions = {
    ...resourceDefaultPermissions,
    ...nearestUserProjectPermissionsForRecord,
  };

  const isPermissionToCheckBilledTimes =
    defaultPermissionsWithNearestProjectPermissions[RECORDS_CHECK_BILLED_TIMES];

  return (
    IsAdminOfSelectedRecordProject || isPermissionToCheckBilledTimes
    //IsAdminOfSelectedRecordResource
  );
};
