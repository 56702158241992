import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimelineTasksService } from 'scenes/Timelines/services/getTimelineTasks.service';

export const getTimelineTasks = createAsyncThunk(
  'timelines/getTimelineTasks',
  async ({ projectId, timelineId }, { rejectWithValue }) => {
    try {
      const timelineTasks = await getTimelineTasksService(
        projectId,
        timelineId
      );
      return timelineTasks.tasks;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
