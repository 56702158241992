import dayjs from 'dayjs';

function dayjsDiffHoursSimple(a, b, ignoreSeconds = false) {
  let mA = dayjs(a);
  let mB = dayjs(b);
  if (ignoreSeconds) {
    mA = mA.second(0).millisecond(0);
    mB = mB.second(0).millisecond(0);
  }
  const duration = mB.unix() - mA.unix();
  const hours = duration / 3600;
  return hours;
}

export default dayjsDiffHoursSimple;
