import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectBankFolderContents } from 'scenes/ProjectBank/services/getProjectBankFolderContents.service';
import { loadUsersAndPersonalResourcesByUserIds } from 'store/projectUsers/thunks/loadUsersAndPersonalResourcesByUserIds';
import { uniq } from 'lodash';

export const loadProjectBankFolderContents = createAsyncThunk(
  'projectBank/loadProjectBankFolderContents',
  async (folderId, { dispatch }) => {
    const folderContentFilePermissions = await getProjectBankFolderContents(
      folderId
    );

    if (
      folderContentFilePermissions &&
      folderContentFilePermissions.length > 0
    ) {
      const uniqueUserIds = uniq(
        folderContentFilePermissions.map((folder) => folder.user)
      );
      dispatch(loadUsersAndPersonalResourcesByUserIds(uniqueUserIds));
    }

    return folderContentFilePermissions;
  }
);
