import { createAsyncThunk } from '@reduxjs/toolkit';
import { setRoleOfUser } from 'scenes/Permissions/services/roles/setRoleOfUser.service';

export const setRoleOfUserThunk = createAsyncThunk(
  'permissionsManagement/setRoleOfUserThunk',
  async ({ userId, projectId, permissions, roleId }) => {
    const response = await setRoleOfUser({
      userId,
      projectId,
      permissions,
      roleId,
    });

    return response;
  }
);
