import { createAsyncThunk } from '@reduxjs/toolkit';
import { postGetUsersAndUserResources } from '../services/getUsersAndUserResources.service';

export const loadUsersAndPersonalResourcesByUserIds = createAsyncThunk(
  'users/loadUsersAndPersonalResources',
  async (userIds) => {
    const response = await postGetUsersAndUserResources(userIds);
    return response;
  }
);
