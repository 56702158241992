import Config from 'Config';

import workSearchParams from './states/workSearchParams';

const initialState = {
  projects: [],
  assignments: [],
  taxNumberMissing: false,
  user: {},
  profileImageSrc: Config.UNKNOWN_PROFILE_URL,
  cachedIdCards: [],
  workSearchParams: workSearchParams,
  project: {},
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PROJECTS':
      return {
        ...state,
        projects: action.projects,
      };
    case 'SET_TAXNUMBER_MISSING':
      return {
        ...state,
        taxNumberMissing: action.missing,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_USERSETTING':
      return {
        ...state,
        userSetting: action.userSetting,
      };
    case 'SET_PROFILE_IMAGE_SRC':
      return {
        ...state,
        profileImageSrc: action.src,
      };
    case 'RESET_WORKSEARCHPARAMS':
      return {
        ...state,
        workSearchParams: initialState.workSearchParams,
      };
    case 'SET_WORKSEARCHPARAMS':
      return {
        ...state,
        workSearchParams: action.workSearchParams,
      };
    case 'ADD_IDCARD':
      return {
        ...state,
        cachedIdCards: [...state.cachedIdCards, action.payload],
      };
    case 'UPDATE_STORE':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'SET_PROJECT':
      return {
        ...state,
        project: action.project,
      };
    default:
      return state;
  }
};

export default rootReducer;
