import { createAsyncThunk } from '@reduxjs/toolkit';
import updateUserSetting from '../services/updateUserSetting.service';
import { setUserSetting } from 'store/actions/setUserSetting';
import { selectUserSettings } from '../selectors.js/user.selectors';
import { changeLanguage } from 'utils/changeLanguage';

export const updateUserSettings = createAsyncThunk(
  'user/updateUserSettings',
  async (userSettings, { dispatch, getState }) => {
    const state = getState();

    const currentUserSetting = selectUserSettings(state);

    const userSetting = await updateUserSetting(userSettings);
    dispatch(setUserSetting(userSetting));

    if (currentUserSetting.locale !== userSetting.locale) {
      changeLanguage(userSetting.locale);
    }

    return userSetting;
  }
);
