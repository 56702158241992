import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectFilePermissions } from 'scenes/ProjectGallery/services/getProjectFilePermissions.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadGalleryImages = createAsyncThunk(
  'projectGallery/loadGalleryImages',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projectFilePermissions = await getProjectFilePermissions(
      selectedProjectId
    );

    return projectFilePermissions;
  }
);
