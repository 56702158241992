import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectInvoiceTables } from '../services/getProjectInvoiceTables';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectSaleInvoiceTables = createAsyncThunk(
  'invoices/loadProjectSaleInvoiceTables',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projectInvoiceTables = await getProjectInvoiceTables(
      selectedProjectId
    );

    return projectInvoiceTables;
  }
);
