import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';

export const openCreateRecordDialog = createAsyncThunk(
  'demo/openCreateRecordDialog',
  async (projectId, { getState, dispatch }) => {
    const state = getState();

    const selectedProjectId = projectId
      ? projectId
      : selectSelectedProjectId(state);

    dispatch(loadResourcesAndResourceEmployersOfProject(selectedProjectId));
    dispatch(loadAssignmentsForProject(selectedProjectId));

    const recordInitialData = {
      project: selectedProjectId,
      clockInAt: moment().toISOString(),
      clockOutAt: moment().toISOString(),
      billedClockInAt: moment().toISOString(),
      billedClockOutAt: moment().toISOString(),
      status: 'OUT',
      type: 'WORK',
    };

    return recordInitialData;
  }
);
