import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { loadInitialBulletinData } from './store/thunks/loadInitialBulletinData';
import * as bulletinActions from './store/actions/bulletinActions';
import { createBulletin } from './store/thunks/createBulletin';
import { loadImageSelectorData } from './store/thunks/loadImageSelectorData';
import { editBulletin } from './store/thunks/editBulletin';
import { deleteBulletin } from './store/thunks/deleteBulletin';
import { loadBulletinSendData } from './store/thunks/loadBulletinSendData';
import { sendBulletin } from './store/thunks/sendBulletin';
import { createManualResource } from './store/thunks/createManualResource';
import { loadProjectAndSubProjectBulletins } from './store/thunks/loadProjectAndSubProjectBulletins';
import { loadAttachments } from './store/thunks/loadAttachments';
import { deleteAttachment } from './store/thunks/deleteAttachment';

const resourcesAdapter = createEntityAdapter();
const manualResourcesAdapter = createEntityAdapter();
const bulletinsAdapter = createEntityAdapter();
const projectAdapter = createEntityAdapter();
const filePermissionsAdapter = createEntityAdapter();
const employerProjectAdapter = createEntityAdapter();
const defaultFilePermissionsAdapter = createEntityAdapter();
const attachmentsAdapter = createEntityAdapter();

const resetState = {
  resources: createEntityAdapter().getInitialState(),
  manualResources: createEntityAdapter().getInitialState(),
  bulletins: createEntityAdapter().getInitialState(),
  project: createEntityAdapter().getInitialState(),
  filePermissions: createEntityAdapter().getInitialState(),
  employerProject: createEntityAdapter().getInitialState(),
  defaultFilePermissions: createEntityAdapter().getInitialState(),
  attachments: createEntityAdapter().getInitialState(),
  filepermissionUrls: [],
};

const bulletinBoardSlice = createSlice({
  name: 'bulletinBoard',
  initialState: {
    resources: resourcesAdapter.getInitialState(),
    manualResources: manualResourcesAdapter.getInitialState(),
    bulletins: bulletinsAdapter.getInitialState(),
    project: projectAdapter.getInitialState(),
    filePermissions: filePermissionsAdapter.getInitialState(),
    employerProject: employerProjectAdapter.getInitialState(),
    defaultFilePermissions: defaultFilePermissionsAdapter.getInitialState(),
    attachments: attachmentsAdapter.getInitialState(),
    filepermissionUrls: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadInitialBulletinData.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadInitialBulletinData.fulfilled, (state, action) => {
        state.loading = false;
        bulletinsAdapter.setMany(state.bulletins, action.payload.bulletins);
        projectAdapter.setMany(state.project, action.payload.project);
        const filterOutNulls = action.payload.filePermissions.filter(
          (fp) => fp !== null && fp !== undefined && fp.id
        );
        filePermissionsAdapter.setMany(state.filePermissions, filterOutNulls);
        employerProjectAdapter.setMany(
          state.employerProject,
          action.payload.employerProject
        );
        defaultFilePermissionsAdapter.setMany(
          state.defaultFilePermissions,
          action.payload.defaultFilePermissions
        );
      })
      .addCase(loadInitialBulletinData.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(loadAttachments.pending, (state) => ({
        ...state,
        loadingAttachments: true,
      }))
      .addCase(loadAttachments.fulfilled, (state, action) => {
        state.loadingAttachments = false;
        attachmentsAdapter.setMany(state.attachments, action.payload);
      })
      .addCase(loadAttachments.rejected, (state, action) => ({
        ...state,
        loadingAttachments: false,
        loadingAttachmentsError: action.error,
      }))
      .addCase(deleteAttachment.pending, (state) => ({
        ...state,
        deleteAttachmentLoading: true,
      }))
      .addCase(deleteAttachment.fulfilled, (state, action) => {
        state.deleteAttachmentLoading = false;
        attachmentsAdapter.removeOne(
          state.attachments,
          action.payload.deletedAttachment.id
        );
      })
      .addCase(deleteAttachment.rejected, (state, action) => ({
        ...state,
        deleteAttachmentLoading: false,
        deleteAttachmentError: action.error,
      }))
      .addCase(bulletinActions.addAttachments, (state, action) => {
        attachmentsAdapter.addMany(state.attachments, action.payload);
      })
      .addCase(loadProjectAndSubProjectBulletins.fulfilled, (state, action) => {
        bulletinsAdapter.setMany(state.bulletins, action.payload);
      })
      .addCase(bulletinActions.openBulletinDialog, (state, action) =>
        action.payload
          ? {
            ...state,
            bulletins: {
              ...state.bulletins,
              bulletinDialogData: action.payload,
            },
          }
          : {
            ...state,
            bulletins: {
              ...state.bulletins,
              bulletinDialogData: {},
            },
          }
      )
      .addCase(bulletinActions.closeBulletinDialog, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          bulletinDialogData: action.payload,
        },
      }))
      .addCase(bulletinActions.resetBulletinData, (state, action) => ({
        ...state,
        ...resetState,
      }))
      .addCase(bulletinActions.openCreateBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          createBulletin: true,
          bulletinDialogData: null,
        },
      }))
      .addCase(bulletinActions.closeCreateBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          createBulletin: false,
        },
      }))
      .addCase(createBulletin.pending, (state) => ({
        ...state,
        createBulletinLoading: true,
      }))
      .addCase(createBulletin.fulfilled, (state, action) => {
        state.createBulletinLoading = false;
        const bulletins = [
          action.payload.createdBulletin,
          ...selectAllInsideSlice(state),
        ];
        bulletinsAdapter.setAll(state.bulletins, bulletins);
        state.bulletins.bulletinDialogData = action.payload.createdBulletin;
        state.lastOpenedBulletinId = action.payload.createdBulletin.id;
      })
      .addCase(createBulletin.rejected, (state, action) => ({
        ...state,
        createBulletinLoading: false,
        createBulletinError: action.error,
      }))
      .addCase(loadImageSelectorData.pending, (state) => ({
        ...state,
        loadingFilePermissions: true,
      }))
      .addCase(loadImageSelectorData.fulfilled, (state, action) => {
        state.loadingFilePermissions = false;
        filePermissionsAdapter.addMany(
          state.filePermissions,
          action.payload.filePermissions
        );
      })
      .addCase(loadImageSelectorData.rejected, (state, action) => ({
        ...state,
        loadingFilePermissions: false,
        error: action.error,
      }))
      .addCase(bulletinActions.openImageSelector, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          imageSelector: true,
        },
      }))
      .addCase(bulletinActions.closeImageSelector, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          imageSelector: false,
        },
      }))
      .addCase(bulletinActions.openEditBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          editBulletin: true,
          editableBulletin: action.payload,
        },
      }))
      .addCase(bulletinActions.closeEditBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          editBulletin: false,
          editableBulletin: null,
        },
      }))
      .addCase(editBulletin.pending, (state) => ({
        ...state,
        editBulletinLoading: true,
      }))
      .addCase(editBulletin.fulfilled, (state, action) => {
        state.editBulletinLoading = false;
        bulletinsAdapter.upsertOne(
          state.bulletins,
          action.payload.editedBulletin
        );
        state.bulletins = {
          ...state.bulletins,
          bulletinDialogData: action.payload.editedBulletin,
        };
        state.lastOpenedBulletinId = action.payload.editedBulletin.id;
      })
      .addCase(editBulletin.rejected, (state, action) => ({
        ...state,
        editBulletinLoading: false,
        editBulletinError: action.error,
      }))
      .addCase(bulletinActions.openDeleteBulletinDialog, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          deleteBulletinDialog: true,
          deleteBulletinData: action.payload,
        },
      }))
      .addCase(bulletinActions.closeDeleteBulletinDialog, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          deleteBulletinDialog: false,
          deleteBulletinData: null,
        },
      }))
      .addCase(deleteBulletin.pending, (state) => ({
        ...state,
        deleteBulletinLoading: true,
      }))
      .addCase(deleteBulletin.fulfilled, (state, action) => {
        state.deleteBulletinLoading = false;
        bulletinsAdapter.removeOne(
          state.bulletins,
          action.payload.deletedBulletin.id
        );
      })
      .addCase(deleteBulletin.rejected, (state, action) => ({
        ...state,
        deleteBulletinLoading: false,
        deleteBulletinError: action.error,
      }))
      .addCase(loadBulletinSendData.pending, (state) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          loadingBulletinSendData: true,
        },
      }))
      .addCase(loadBulletinSendData.fulfilled, (state, action) => {
        state.bulletins.loadingBulletinSendData = false;
        resourcesAdapter.setMany(state.resources, action.payload.resources);
        manualResourcesAdapter.setMany(
          state.manualResources,
          action.payload.manualResources
        );
      })
      .addCase(loadBulletinSendData.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(bulletinActions.openSendBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          sendBulletinDialog: true,
          sendBulletinData: action.payload,
        },
      }))
      .addCase(bulletinActions.closeSendBulletin, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          sendBulletinDialog: false,
          sendBulletinData: null,
        },
      }))
      .addCase(sendBulletin.pending, (state) => ({
        ...state,
        sendBulletinLoading: true,
      }))
      .addCase(sendBulletin.fulfilled, (state, action) => {
        state.sendBulletinLoading = false;
        state.loadingPdf = false;
        state.sendBulletinError = action.payload.response.error;
      })
      .addCase(sendBulletin.rejected, (state, action) => {
        state.sendBulletinLoading = false;
        state.loadingPdf = false;
        state.sendBulletinError = action.payload.response.error;
      })
      .addCase(bulletinActions.addFilepermissionUrl, (state, action) => {
        state.filepermissionUrls = [
          ...state.filepermissionUrls,
          action.payload,
        ];
      })
      .addCase(bulletinActions.resetFilepermissionUrls, (state) => {
        state.filepermissionUrls = [];
      })
      .addCase(bulletinActions.setLoadingPdf, (state, action) => {
        state.loadingPdf = action.payload;
      })
      .addCase(bulletinActions.openAddEmailDialog, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          addEmailDialog: true,
        },
      }))
      .addCase(bulletinActions.closeAddEmailDialog, (state, action) => ({
        ...state,
        bulletins: {
          ...state.bulletins,
          addEmailDialog: false,
        },
      }))
      .addCase(createManualResource.pending, (state) => ({
        ...state,
        createManualResourceLoading: true,
      }))
      .addCase(createManualResource.fulfilled, (state, action) => {
        state.createManualResourceLoading = false;
        manualResourcesAdapter.addOne(
          state.manualResources,
          action.payload.createdManualResource
        );
      })
      .addCase(createManualResource.rejected, (state, action) => ({
        ...state,
        createManualResourceLoading: false,
        createManualResourceError: action.error,
      }))
      .addCase(bulletinActions.setBulletinImageUrls, (state, action) => ({
        ...state,
        imageUrls: action.payload,
      }))
      .addCase(bulletinActions.setLastOpenedBulletinId, (state, action) => ({
        ...state,
        lastOpenedBulletinId: action.payload,
      }))
      .addCase(bulletinActions.setOpenAttachmentDeleteDialog, (state, action) => ({
        ...state,
        openAttachmentDeleteDialog: action.payload,
      }))
  },
});

export const {
  selectAll: selectAllResources,
  selectEntities: selectResourceEntities,
  selectById: selectResourceById,
  selectIds: selectResourceIds,
} = resourcesAdapter.getSelectors((state) => state.bulletinBoard.resources);

export const { selectAll: selectAllManualResources } =
  manualResourcesAdapter.getSelectors(
    (state) => state.bulletinBoard.manualResources
  );

export const {
  selectAll: selectAllBulletins,
  selectEntities: selectBulletinEntities,
  selectById: selectBulletinById,
  selectIds: selectBulletinIds,
} = bulletinsAdapter.getSelectors((state) => state.bulletinBoard.bulletins);

// This is to avoid undefined error in the usage differences between slice and selectors
export const { selectAll: selectAllInsideSlice } =
  bulletinsAdapter.getSelectors((state) => state.bulletins);

export const { selectAll: selectAllDefaultFilePermissions } =
  defaultFilePermissionsAdapter.getSelectors(
    (state) => state.bulletinBoard.defaultFilePermissions
  );

export default bulletinBoardSlice.reducer;
