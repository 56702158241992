import { getNearestUserProjectPermissions } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

/**
 * This function checks users permission in given project.
 *
 *
 * @param {string} projectId
 * @param {string} userId
 * @param {string} userProjectPermissions
 * @param {string} projects
 * @param {string} resourceDefaultPermissions
 * @param {string} userAdminBusinessIds
 * @param {string} permissionName
 * @param {boolean} projectOwnerCheck [optionalParam] - if provided gives permission to project owner
 * @returns {boolean} true/false
 */

export const checkUserPermissionInProject = (
  projectId,
  userId,
  userProjectPermissions,
  projects,
  resourceDefaultPermissions,
  userAdminBusinessIds,
  permissionName,
  projectOwnerCheck
) => {
  const nearestUserProjectPermissions = getNearestUserProjectPermissions(
    projects,
    userProjectPermissions,
    projectId
  );

  const defaultPermissionsWithNearestProjectPermissions = {
    ...resourceDefaultPermissions,
    ...nearestUserProjectPermissions,
  };

  const isPermission =
    defaultPermissionsWithNearestProjectPermissions[permissionName];

  const projectToCheck = projects[projectId];

  const isProjectOwner = projectOwnerCheck
    ? projectToCheck.mirrorUser === userId
    : false;

  const isAdmin =
    projectToCheck && projectToCheck.business_id
      ? userAdminBusinessIds.includes(projectToCheck.business_id)
      : false;

  //console.log(`isPermission to ${permissionName}: `, isPermission);
  //console.log('isAdmin: ', isAdmin);
  //console.log('isProjectOwner: ', isProjectOwner);

  return isPermission || isAdmin || isProjectOwner;
};
