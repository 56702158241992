export const findResourcePermissionsFromParentProjects = (
  parentProjectId,
  projects,
  allUsersPermissions,
  resource
) => {
  const foundParentPermissions = allUsersPermissions.find(
    (userPermissions) =>
      userPermissions.userId === resource.user &&
      userPermissions.projectId === parentProjectId
  );

  const currentProject = projects[parentProjectId];

  const findFromNextParent = (projectId) => {
    const currentProject = projects[projectId];

    const foundPermissions = allUsersPermissions.find(
      (userPermissions) =>
        userPermissions.userId === resource.user &&
        userPermissions.projectId === parentProjectId
    );

    if (foundPermissions) {
      return {
        ...foundPermissions,
        inheritedProject: currentProject.name,
        isProjectOwner: currentProject.mirrorUser === resource.user,
      };
    } else if (foundParentPermissions) {
      return {
        ...foundParentPermissions,
        inheritedProject: currentProject.name,
        isProjectOwner: currentProject.mirrorUser === resource.user,
      };
    } else if (currentProject && currentProject.parent) {
      return findFromNextParent(currentProject.parent);
    } else {
      return {
        isProjectOwner: currentProject
          ? currentProject.mirrorUser === resource.user
          : false,
      };
    }
  };

  if (foundParentPermissions) {
    return {
      ...foundParentPermissions,
      inheritedProject: currentProject.name,
      isProjectOwner: currentProject.mirrorUser === resource.user,
    };
  } else if (currentProject && currentProject.parent) {
    return findFromNextParent(currentProject.parent);
  } else {
    return {
      isProjectOwner: currentProject
        ? currentProject.mirrorUser === resource.user
        : false,
    };
  }
};
