import projectItem from 'LixaniAPI/projectListItem';

const item = `
	__typename
	id
	createdAt
	updatedAt
	deletedAt
	name
	parent {
		__typename
		id
		name
		number
		parent {
			__typename
			id
			name
			number
			parent {
				__typename
				id
				name
				number
			}
		}
	}
	project {
		${projectItem}
	}
	businessId
	importId
	invoiceKeywords
	originalBudget {
		
		value
		comment
	}
	updatedBudget {
		
		value
		comment
	}
	transfer {
		value
		comment
	}
	talseComment
	index
	number
	copyOrigin
	unit
	unitAmountGoal
	unitPrice
	completion
	group
	additionalCosts {
		id
		value
		comment
	}
`;

const itemWithBudget = `
	__typename
	id
	createdAt
	updatedAt
	deletedAt
	name
	parent {
		__typename
		id
		name
		number
		parent {
			__typename
			id
			name
			number
			parent {
				__typename
				id
				name
				number
			}
		}
	}
	project {
		${projectItem}
	}
	businessId
	importId
	invoiceKeywords
	budget
	originalBudget {
		value
		comment
	}
	updatedBudget {
		value
		comment
	}
	transfer {
		value
		comment
	}
	talseComment
	index
	number
	copyOrigin
	unit
	unitAmountGoal
	completion
	group
	additionalCosts {
		id
		value
		comment
	}
`;

export default item;
export { itemWithBudget };
