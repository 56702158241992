import React, { Component, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Translation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import ClockInIcon from 'mdi-material-ui/LoginVariant';
import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectClockInPendingProjectIds } from 'store/records/selectors/records.selectors';

const styles = (theme) => {
  return {
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      color: theme.palette.text.tertiary,
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.5),
    },
    recordIn: {
      backgroundColor: theme.palette.primary.main,
    },
    recordOut: {
      backgroundColor: theme.palette.background.default,
    },
    project: {
      display: 'flex',
      flexDirection: 'column',
    },
    projectName: {
      justifyContent: 'flex-start',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    projectKey: {
      fontSize: '0.87rem',
    },
    times: {
      display: 'flex',
      fontSize: '0.87rem',
      marginBottom: theme.spacing(1),
      '& span': {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
      },
    },
    icon: {
      height: 18,
      marginRight: theme.spacing(1),
      width: 18,
    },
    actions: {},
    actionBtn: {
      height: 40,
      marginLeft: -10,
      width: 40,
    },
  };
};

function ProjectItem(props) {
  const { classes, project, loading } = props;

  const clockInPendingProjectIds = useSelector(selectClockInPendingProjectIds);
  const clockInLoading = clockInPendingProjectIds.includes(project.id);

  const rootClass = clsx(classes.root);
  const handleClick = () => {
    if (!clockInLoading) {
      props.onClickClockIn();
    }
  };

  return (
    <Translation ns={['common']}>
      {(t) => (
        <Paper elevation={1} square={true} className={rootClass}>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={10}>
              {project && (
                <div className={classes.project}>
                  {project.name && (
                    <ButtonBase
                      className={classes.projectName}
                      component={
                        `/project/${project.projectKey || project.id}`
                          ? Link
                          : React.forwardRef((props, ref) => (
                              <ButtonBase {...props} ref={ref} />
                            ))
                      }
                      to={`/project/${project.projectKey || project.id}`}
                    >
                      {<div>{project.name}</div>}
                    </ButtonBase>
                  )}
                  {project.projectKey && (
                    <span className={classes.projectKey}>
                      #{project.projectKey}
                    </span>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item>
                  <IconButton
                    className={classes.actionBtn}
                    onClick={handleClick}
                  >
                    {!clockInLoading ? (
                      <ClockInIcon className={classes.actionIcon} />
                    ) : (
                      <ButtonLoading />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Translation>
  );
}

export default withStyles(styles)(ProjectItem);
