import gql from 'graphql-tag';

import items from 'LixaniAPI/assignmentListItems';
import { itemsWithBudget } from 'LixaniAPI/assignmentListItems';

const query = gql`
query assignmentList($limit: Int, $nextToken: String, $project: [ID]) {
  assignmentList(
    limit: $limit,
    nextToken: $nextToken,
    filter: {
      project: $project
    }
  ) {
		${items}
  }
}`;

const queryWithBudget = gql`
query assignmentList($limit: Int, $nextToken: String, $project: [ID]) {
  assignmentList(
    limit: $limit,
    nextToken: $nextToken,
    filter: {
      project: $project
    }
  ) {
		${itemsWithBudget}
  }
}`;

const variables = { limit: 500 };

const assignmentsWithBudget = { query: queryWithBudget, variables: variables };

export default { query: query, variables: variables };
export { assignmentsWithBudget };
