import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

const selectEmployerAllowancesState = (state) => state.employerAllowances;

export const selectEmployerAllowanceDrafts = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.employerAllowanceDrafts.entities
);
export const selectEmployerAllowanceDraftsIds = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.employerAllowanceDrafts.ids
);
export const selectEmployerAllowanceDraftsArray = createSelector(
  [selectEmployerAllowanceDrafts],
  (drafts) => Object.values(drafts)
);

export const selectIdsOfEmployerAllowanceDraftsMissingRequiredAttributes =
  createSelector([selectEmployerAllowanceDraftsArray], (drafts) => {
    const IdsOfDraftsMissingAttributes = drafts
      .filter(
        (draft) =>
          // required attributes
          (!draft.salaryType || !draft.unit) && !draft.deletedAt
      )
      .map((draft) => draft.id);

    return IdsOfDraftsMissingAttributes;
  });
export const selectIsEmployerAllowanceDraftsMissingRequiredAttributes =
  createSelector(
    [selectIdsOfEmployerAllowanceDraftsMissingRequiredAttributes],
    (ids) => {
      return ids.length > 0;
    }
  );

export const selectIEmployerAllowanceDrafts = createSelector(
  [selectEmployerAllowanceDraftsArray],
  (drafts) => drafts.length > 0
);

export const selectEmployerAllowances = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.employerAllowances.entities
);
export const selectEmployerAllowancesIds = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.employerAllowances.ids
);

export const selectEmployerAllowancesArray = createSelector(
  [selectEmployerAllowances],
  (employerAllowances) => Object.values(employerAllowances)
);
export const selectEmployerAllowancesBySelectedProjectBusinessId =
  createSelector(
    [selectEmployerAllowances, selectSelectedProject],
    (employerAllowances, selectedProject) => {
      let employerAllowancesToFilter = { ...employerAllowances };
      for (const key in employerAllowancesToFilter) {
        if (
          employerAllowancesToFilter[key].businessId !==
          selectedProject.business_id
        ) {
          delete employerAllowancesToFilter[key];
        }
      }

      return employerAllowancesToFilter;
    }
  );

export const selectEmployerAllowancesWithMergedDrafts = createSelector(
  [
    selectEmployerAllowancesBySelectedProjectBusinessId,
    selectEmployerAllowanceDrafts,
  ],
  (allowances, drafts) => {
    return { ...allowances, ...drafts };
  }
);

export const selectEmployerAllowancesWithDraftsArray = createSelector(
  [selectEmployerAllowancesWithMergedDrafts],
  (AllowancesWithMergedDrafts) => {
    return Object.values(AllowancesWithMergedDrafts).filter(
      (employerAllowance) => !employerAllowance.deletedAt
    );
  }
);

export const selectIsEmployerAllowancesSavePending = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.savePending
);
export const selectIsEmployerAllowancesFetchPending = createSelector(
  [selectEmployerAllowancesState],
  (state) => state.loading
);
