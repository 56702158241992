import { createSelector } from '@reduxjs/toolkit';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import dayjs from 'dayjs';
import moment from 'moment';
import { selectRecordsArray } from 'store/records/selectors/records-base.selectors';
import { selectLoggedInUserResourcesArray } from 'store/resources/selectors/resources.selectors';

export const selectPersonalRecords = createSelector(
  [selectRecordsArray, selectLoggedInUserResourcesArray],
  (records, currentUserResources) => {
    const currentUserResourceIds = currentUserResources.map(
      (resource) => resource.id
    );

    const userResourceFilteredRecords = records.filter(
      (record) =>
        currentUserResourceIds.includes(record.resource) && !record.deletedAt
    );

    return userResourceFilteredRecords;
  }
);

export const selectPersonalRecordsFromToday = createSelector(
  [selectPersonalRecords],
  (records) => {
    const startOfTheDayUTC = moment().startOf('day').toISOString();
    const endOfTheDayUTC = moment().endOf('day').toISOString();

    return records.filter((record) => {
      const recordClockedInToday =
        record.clockInAt > startOfTheDayUTC &&
        record.clockInAt < endOfTheDayUTC;
      return recordClockedInToday;
    });
  }
);

export const selectPersonalTodayClockedInRecords = createSelector(
  [selectPersonalRecords],
  (records) => {
    const fromDate = dayjs().startOf('day').utcOffset(0).format();
    const toDate = dayjs().endOf('day').utcOffset(0).format();
    const recordsClockedInToday = records.filter(
      (record) =>
        record.type === RECORD_TYPE.WORK &&
        record.clockInAt >= fromDate &&
        record.clockInAt <= toDate
    );
    return recordsClockedInToday ? recordsClockedInToday : [];
  }
);

export const selectPersonalWorkAndBreakRecordsFromToday = createSelector(
  [selectPersonalRecordsFromToday],
  (records) => {
    const workRecordTypes = [RECORD_TYPE.WORK, RECORD_TYPE.PROJECTWORK];
    const breakRecordTypes = [
      RECORD_TYPE.BREAK,
      RECORD_TYPE.BREAK_COFFEE,
      RECORD_TYPE.BREAK_MEAL,
    ];
    return records.filter(
      (record) =>
        workRecordTypes.includes(record.type) ||
        breakRecordTypes.includes(record.type)
    );
  }
);
