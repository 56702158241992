import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import patchDisapproveRecord from '../services/disapproveRecord.service';

export const disapproveRecord = createAsyncThunk(
  'records/disapproveRecord',
  async (approvalSortKey, { getState }) => {
    const selectedRecord = selectSelectedRecord(getState());

    const recordProjectId = selectedRecord.project;

    const { record, approvalSK } = await patchDisapproveRecord(
      recordProjectId,
      selectedRecord.id,
      approvalSortKey
    );

    return { record, approvalSK };
  }
);
