import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getFormTemplatesForProject from 'scenes/ProjectForms/utils/getFormTemplatesForProject';

export const loadFormTemplatesForProject = createAsyncThunk(
  'projectForm/loadFormTemplatesForProject',
  async (project_id, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const formTemplates = await getFormTemplatesForProject(project_id, params);
    return { formTemplates };
  }
);
