import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateProject } from '../services/postCreateProject.service';

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (projectData) => {
    const projectCreationData = await postCreateProject(projectData);

    return projectCreationData;
  }
);
