import { createSelector } from '@reduxjs/toolkit';

const selectNotificationsState = (state) => state.notifications;

export const selectIsLoadingNotificationSettings = createSelector(
  [selectNotificationsState],
  (state) => state.isLoadingNotificationSettings
);

export const selectBusinessNotificationSettings = createSelector(
  [selectNotificationsState],
  (state) => state.businessNotificationSettings.entities
);

export const selectModifiedNotificationSettings = createSelector(
  [selectNotificationsState],
  (state) => state.modifiedNotificationSettings
);
