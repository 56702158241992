import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { patchReportDraftAsDeleted } from '../services/patchReportDraftAsDeleted.service';

export const deleteReportDraft = createAsyncThunk(
  'reports/deleteReportDraft',
  async (reportDraftId, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const reportDraftUpdatedAsDeleted = await patchReportDraftAsDeleted(
      selectedProjectId,
      reportDraftId
    );

    return reportDraftUpdatedAsDeleted;
  }
);
