import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import { graphql, compose } from 'react-apollo';
import Dialog from 'components/DialogWrapper/DialogWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LogoutIcon from 'mdi-material-ui/LogoutVariant';
import AssignmentsSelect from '../RecordDialog/AssignmentsSelect';
import Button from 'components/Button/Button';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import TextInput from 'components/TextInput/TextInput';
import momentDiffTime from 'utils/momentDiffTime';
import assignmentQuList from 'LixaniAPI/assignmentQuList';
import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import { Grid, Typography } from '@material-ui/core';

import Icon from '@mdi/react';
import {
  mdiMapMarkerAlertOutline,
  mdiLogoutVariant,
  mdiProgressWrench,
} from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    ...theme.component.dialogPaper,
    [theme.breakpoints.down('xs')]: {
      ...theme.component.dialogPaperFullscreen,
    },
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  dialogActions: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  textInput: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

function AssignmentAmountDialogLayout(props) {
  

  const { assignment, assignmentUnitAmount, setAssignmentUnitAmount } = props;

  const classes = useStyles();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  
  return (
    <Translation ns={['common']}>
      {(t) => (
        <Dialog
          aria-labelledby="clock-out-dialog"
          classes={{ paper: classes.dialogPaper }}
          onBackdropClick={props.onClose}
          fullScreen={!upSm}
          fullWidth
          maxWidth="xs"
          onClose={props.onClose}
          open={true}
        >
          <DialogHeader
            icon={
              <Icon
                path={mdiProgressWrench}
                size={1}
                className={classes.icon}
              />
            }
            onClose={props.onClose}
            title={t('common.work_amount')}
          />
          <DialogContent className={classes.dialogContent}>
            <ListItem disableGutters dense divider={assignment?.unitAmountGoal}>
              <ListItemText
                primary={
                  t('common.work_amount_explanation') + ': ' + assignment?.name
                }
                secondary={
                  <TextInput
                    className={classes.textInput}
                    defaultValue={assignmentUnitAmount}
                    adornment={assignment?.unit}
                    fullWidth
                    onChange={(e) => setAssignmentUnitAmount(e.target.value)}
                    //label={assignment?.unit}
                  />
                }
              />
            </ListItem>

            {assignment?.unitAmountGoal && (
              <ListItem disableGutters dense>
                <ListItemText
                  primary={'Työtä yhteensä'}
                  secondary={`${assignment?.unitAmountGoal} ${assignment?.unit}`}
                />
              </ListItem>
            )}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.dialogActions}>
            <Button onClick={() => props.onClose()} color="light">
              {t('common.confirm_amount')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Translation>
  );
}

AssignmentAmountDialogLayout.defaultProps = {
  onClose: () => null,
};

AssignmentAmountDialogLayout.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AssignmentAmountDialogLayout;
