import { createAction } from '@reduxjs/toolkit';

export const selectRecord = createAction('records/selectRecord');

export const setRecordToEdit = createAction('records/setRecordToEdit');

export const removeRecordToEdit = createAction('records/removeRecordToEdit');

export const clearSelectedRecords = createAction(
  'records/clearSelectedRecords'
);

export const setSelectedRecordsEditDialogOpen = createAction(
  'records/setSelectedRecordsEditDialogOpen'
);

export const clearEditRecordsError = createAction(
  'records/clearEditRecordsError'
);

export const setEditRecordsAttributes = createAction(
  'records/setEditRecordsAttributes'
);
export const setClockOutRecordAttributes = createAction(
  'records/setClockOutRecordAttributes'
);

export const setLoadedResourcesProjectId = createAction(
  'records/setLoadedResourcesProjectId'
);

export const closeCreateRecordDialog = createAction(
  'records/closeCreateRecordDialog'
);

export const setCreateRecordDialogAttributes = createAction(
  'records/setCreateRecordDialogAttributes'
);

export const closeEditRecordDialog = createAction(
  'records/closeEditRecordDialog'
);

export const setEditRecordDialogAttributes = createAction(
  'records/setEditRecordDialogAttributes'
);

export const openLocationMustDialog = createAction(
  'records/openLocationMustDialog'
);
export const closeLocationMustDialog = createAction(
  'records/closeLocationMustDialog'
);
export const setBreakRecordEditAttributes = createAction(
  'records/setBreakRecordEditAttributes'
);

export const closeClockOutRecordDialog = createAction(
  'records/closeClockOutRecordDialog'
);
export const setAllowancesCreateParams = createAction(
  'records/setAllowancesCreateParams'
);
export const removeAllowancesCreateParams = createAction(
  'records/removeAllowancesCreateParams'
);
