import React from 'react';
import { browserDetect } from 'utils/helpers';

const Input = ({
  htmlFor,
  children,
  onUploadImages,
  inputKey,
  clockOut,
  disabled,
}) => {
  const browser = browserDetect();
  console.log('children', children);
  console.log('browser', browser);

  const accept = clockOut
    ? 'image/*, image/heic, image/heif'
    : 'image/*, video/*, image/heic, image/heif';

  if (browser === 'Google Chrome') {
    if (clockOut) {
      return (
        <React.Fragment>
          <input
            // accept="image/*, capture=camera"
            id={htmlFor}
            onChange={onUploadImages}
            style={{ display: 'none' }}
            type="file"
            multiple="multiple"
            disabled={disabled}

            // alt="Add image"
          />
          {children}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <input
          id={htmlFor}
          onChange={onUploadImages}
          style={{ display: 'none' }}
          type="file"
          multiple="multiple"
          disabled={disabled}
        />

        {children}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <input
        key={inputKey || ''}
        accept={accept}
        id={htmlFor}
        onChange={onUploadImages}
        style={{ display: 'none' }}
        type="file"
        multiple="multiple"
        alt="Add image"
        disabled={disabled}
      />

      {children}
    </React.Fragment>
  );
};

export default Input;
