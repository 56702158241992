import { createAsyncThunk } from '@reduxjs/toolkit';

import postRecordsApprovalGroup from '../services/postRecordsApprovalGroup.service';
import { selectSelectedRecordsIds } from 'store/records/selectors/records.selectors';
import { selectSendToApprovalDialogData } from '../selectors/approvals.selectors';

export const createRecordsApprovalGroup = createAsyncThunk(
  'records/approvalGroup',
  async (_, { getState }) => {
    const recordIds = selectSelectedRecordsIds(getState());
    const dialogData = selectSendToApprovalDialogData(getState());

    const { projectId, employerId, approvers } = dialogData;

    const response = await postRecordsApprovalGroup(
      approvers,
      recordIds,
      projectId,
      employerId
    );
    // response contains records, approvers, approvalGroup
    return response;
  }
);
