import { createSlice } from '@reduxjs/toolkit';

import { reactToFilepermission } from './thunks/reactToFilepermission';

export const filepermissionsSlice = createSlice({
  name: 'filepermissions',
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(reactToFilepermission.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(reactToFilepermission.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(reactToFilepermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
