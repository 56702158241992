import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { selectSelectedRecordChangeHistoryWithFilledData } from 'store/records/selectors/records.selectors';
import moment from 'moment';

export function NewHistoryList() {
  const historyObjectsWithFilledData = useSelector(
    selectSelectedRecordChangeHistoryWithFilledData
  );

  const { t } = useTranslation();

  const keyTranslationPairs = {
    status: 'record.status',
    clockInAt: 'record.clocked_in',
    clockOutAt: 'record.clocked_out',
    billedClockInAt: 'record.billed_in',
    billedClockOutAt: 'record.billed_out',
    description: 'calendar.description',
    assignment: 'expense.assignment',
    checked: 'calendar.checked',
    approved: 'calendar.approved',
    type: 'calendar.type',
    project: 'common.project',
    resource: 'resource.resource',
    created: 'common.created_at',
    billedInAtChecked: 'record.billed_in',
    billedOutAtChecked: 'record.billed_out',
    clockedInAtChecked: 'record.clocked_in',
    clockedOutAtChecked: 'record.clocked_out',
  };

  const valueTranslations = {
    IN: 'record.clocked_in',
    OUT: 'record.clocked_out',
    checked: 'calendar.checked',
    unchecked: 'calendar.unchecked',
    approved: 'calendar.approved',
    unapproved: 'calendar.unapproved',
    WORK: 'record.type.work',
    BREAK: 'record.type.break',
    BREAK_COFFEE: 'record.type.coffee_break',
    BREAK_MEAL: 'record.type.meal_break',
    SHIFT: 'record.type.shift',
    ABSENCE: 'record.type.absence',
    BENEFIT: 'record.type.benefit',
    RIDE: 'record.type.ride',
    ITEM: 'record.type.item',
    NEGATIVE: 'record.type.negative',
  };

  const dateFormat = 'DD.MM.yyyy HH.mm';

  return historyObjectsWithFilledData.map((historyObject, index) => {
    return (
      <Grid key={index} item xs={12}>
        <Typography>{`${moment(historyObject.timestamp).format(dateFormat)} - ${
          historyObject.firstName
        } ${historyObject.lastName}`}</Typography>
        {Object.keys(historyObject.attributes).map((attributeKey, index) => {
          const oldValue = historyObject.attributes[attributeKey].old;
          const newValue = historyObject.attributes[attributeKey].new;
          return keyTranslationPairs[attributeKey] ? (
            <Typography key={index} paragraph={true} variant="caption">{`${t(
              keyTranslationPairs[attributeKey]
            )}: ${
              valueTranslations[oldValue]
                ? t(valueTranslations[oldValue])
                : oldValue
                ? oldValue
                : ''
            } -> ${
              valueTranslations[newValue]
                ? t(valueTranslations[newValue])
                : newValue
                ? newValue
                : ''
            }`}</Typography>
          ) : null;
        })}
      </Grid>
    );
  });
}
