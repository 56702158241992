import { makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import { getRecordsFirstAndLastClockedTimes } from './utils/getRecordsFirstAndLastClockedTimes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textItem: {
    padding: '0px',
    margin: '0px',
  },
}));

export function RecordsTimelineDates({ currentDate, recordsFromToday }) {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation();
  const workRecords = recordsFromToday.filter(
    (record) =>
      record.type === RECORD_TYPE.WORK ||
      record.type === RECORD_TYPE.PROJECTWORK
  );

  const [recordsTimeFrame, setRecordsTimeframe] = useState(
    getRecordsFirstAndLastClockedTimes(workRecords)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRecordsTimeframe(getRecordsFirstAndLastClockedTimes(workRecords));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const startTimeFormated = `${moment(recordsTimeFrame.clockIn).format('LT')}`;
  const endTimeFormated = `${moment(recordsTimeFrame.clockOut).format('LT')}`;

  return (
    <div className={classes.flexRow}>
      <div>
        <p className={classes.textItem}>{t('datetime.start')}</p>
        <p className={classes.textItem}> {startTimeFormated}</p>
      </div>
      <div>
        <p className={classes.textItem}>{t('datetime.end')}</p>
        <p className={classes.textItem}>{endTimeFormated}</p>
      </div>
    </div>
  );
}
