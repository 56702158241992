import { createAsyncThunk } from '@reduxjs/toolkit';
import getResourcesOfProject from '../services/getResourcesOfProject';
import getCredentials from '../../../utils/getCredentials';
export const loadResourcesOfProject = createAsyncThunk(
  'common/loadResourcesOfProject',
  async (projectId) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const response = await getResourcesOfProject(projectId, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });
    return response;
  }
);
