import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';
import Config from 'Config';

export const startProjectMergeFrom = createAsyncThunk(
  'projects/startProjectMergeFrom',
  async (keyOfProjectToMergeFrom, { getState, rejectWithValue }) => {
    const state = getState();

    const selectedProjectKey = state?.root?.project?.projectKey;

    if (!selectedProjectKey) {
      throw new Error('No project selected');
    }

    if (!keyOfProjectToMergeFrom) {
      throw new Error('Project key to merge from is required');
    }
    const apiPath = `projects/merge/start-merge`;

    const request = {
      //url: `http://localhost:3001/${apiPath}`,
      url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
      host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
      path: apiPath,
      method: 'POST',
      region: Config.AWS_REGION,
      service: 'execute-api',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        keyOfProjectToMergeFrom,
        keyOfProjectToMergeInto: selectedProjectKey,
      }), // aws4 looks for body; axios for data
      data: {
        keyOfProjectToMergeFrom,
        keyOfProjectToMergeInto: selectedProjectKey,
      },
    };
    const signedRequest = await createSignedRequest(request);

    let response;
    try {
      response = await axios(signedRequest);
    } catch (e) {
      return rejectWithValue({ errorMessage: e.response.data.message });
    }

    return response.data;
  }
);
