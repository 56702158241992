import React from 'react';
import preval from 'preval.macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from './components/AppBar';
import { LayoutContent } from 'lixani-lib-ui';
import { useSelector } from 'react-redux';
import { selectLatestProjectsClockedInTo } from 'store/projects/selectors/projectsRecords.selectors';
import RecordDialogContainer from 'scenes/WorkDemo/components/RecordDialog/RecordDialogContainer';
import {
  selectIsClockedInRecordsLoading,
  selectPersonalClockedInRecords,
} from 'store/records/selectors/records.selectors';
import { AddUserDialogProvider } from 'components/AddUserDialog/AddUserDialog';

const buildTime = preval`
	const moment = require('moment')
	module.exports = moment(new Date()).format('DDMMYY-HHmm');
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    zIndex: 1,
  },
  footer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function Layout(props) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const lastProjects = useSelector(selectLatestProjectsClockedInTo);

  const inRecords = useSelector(selectPersonalClockedInRecords);

  const inRecordsLoading = useSelector(selectIsClockedInRecordsLoading);

  const { children, client } = props;
  return (
    <div className={classes.root}>
      <RecordDialogContainer />
      <AppBar
        client={client}
        inRecords={inRecords}
        inRecordsLoading={inRecordsLoading}
        lastProjects={lastProjects}
      />
      <LayoutContent
        marginTop={70}
        footerClass={classes.footer}
        footerText={t('footer.powered_by') + ' (v. ' + buildTime + ')'}
        layoutStyle={{ backgroundColor: 'initial' }}
        bgStyle={{
          minHeight: `calc(100vh - ${48}px)`,
        }}
      >
        {/*<div style={{"maxWidth":1024,"margin":"auto"}}>*/}
        {children}
        {/*</div>*/}
      </LayoutContent>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  client: PropTypes.object.isRequired,
};

const LayoutWithApollo = withApollo(Layout);

export default LayoutWithApollo;
