import TextInput from 'components/TextInput/TextInput';
import React from 'react';

export const AllowancesUnitCount = ({ unitCount, setUnitCount, t, unit }) => {
  return (
    <TextInput
      fullWidth
      label={t('report.unit_count')}
      value={unitCount}
      onChange={(event) => setUnitCount(event.target.value)}
      adornment={unit}
      style={{ marginRight: '5px', maxWidth: '200px' }}
      type="number"
    />
  );
};
