import { createAsyncThunk } from '@reduxjs/toolkit';
import getResourcesAndResourceEmployersOfProject from '../services/getResourcesAndResourceEmployersOfProject';
import { setLoadedResourcesProjectId } from 'store/records/actions/records.actions';
import { selectLoadedResourcesProjectIds } from '../selectors/resources.selectors';

export const loadResourcesAndResourceEmployersOfProject = createAsyncThunk(
  'resources/loadResourcesAndResourceEmployersOfProject',
  async (projectId, { dispatch }) => {
    dispatch(setLoadedResourcesProjectId(projectId));
    const response = await getResourcesAndResourceEmployersOfProject(projectId);
    return response;
  },
  {
    condition: (projectId, { getState }) => {
      const state = getState();
      const loadedProjectIds = selectLoadedResourcesProjectIds(state);

      const isProjectsResourcesAlreadyFetched =
        loadedProjectIds.includes(projectId);

      if (isProjectsResourcesAlreadyFetched) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);
