import { createAsyncThunk } from '@reduxjs/toolkit';

import { getInteractionsByProject } from '../services/get-interactions-by-project.service';

export const loadInteractionsByProject = createAsyncThunk(
  'interactions/loadInteractionsByProject',
  async (projectId) => {
    const interactions = await getInteractionsByProject(projectId);
    return interactions;
  }
);
