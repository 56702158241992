import gql from 'graphql-tag';

import item from 'LixaniAPI/filePermissionListItem';

export default gql`
mutation FilePermission($id: ID!,
								$parent: ID,
								$project: ID,
								$record: ID,
								$assignment: ID,
								$name: String,
								$description: String,
								$file: S3ObjectInput,
								$key: String,
								$parentKey: String,
								$path: String,
								$parentPath: String,
								$type: FilePermissionType!,
								$uploadSource: String,
								$businessId: String,
							) {
	filePermissionCreate(input: {
		id: $id
		parent: $parent
		project: $project
		businessId: $businessId
		record: $record
		assignment: $assignment
		name: $name
		description: $description
		file: $file
		key: $key
		parentKey: $parentKey
		path: $path
		parentPath: $parentPath
		type: $type
		uploadSource: $uploadSource
	}) {
		${item}
	}
}`;
