import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import { setUpdateResources } from '../services/setUpdateResources';

export const updateResources = createAsyncThunk(
  'expenseTracking/updateResources',
  async (data, { getState }) => {
    const updatedEmployer = await setUpdateResources(data);
    console.log('updatedEmployer', updatedEmployer);
    return updatedEmployer;
  }
);
