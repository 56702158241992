import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getEmployeeUsersOfEmployer } from '../services/getEmployeeResourcesOfEmployer';

export const loadEmployeeUsersOfEmployer = createAsyncThunk(
  'projectResources/loadEmployeeUsersOfEmployer',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await getEmployeeUsersOfEmployer(selectedProjectId);
    return response;
  }
);
