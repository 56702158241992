import { createAsyncThunk } from '@reduxjs/toolkit';
import updateProjectService from '../services/update.project.service';
import { selectSelectedProjectId } from '../selectors/projectsSelectors';

/**
 * Update project thunk
 * @param {object} projectData - the project data to be added or updated to project
 */
export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (projectData, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    if (!selectedProjectId) {
      throw new Error('No project selected');
    }

    if (!projectData) {
      throw new Error('Project is required in updateProject thunk');
    }

    if (projectData.id !== selectedProjectId) {
      throw new Error('Project id mismatch');
    }

    const updatedProject = await updateProjectService(selectedProjectId, {
      project: { ...projectData },
    });

    return updatedProject;
  }
);
