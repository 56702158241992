import { createSelector } from '@reduxjs/toolkit';

const selectRecordAllowancesState = (state) => state.recordAllowances;

export const selectRecordAllowancesEntities = createSelector(
  [selectRecordAllowancesState],
  (state) => state.recordAllowances.entities
);

export const selectRecordAllowancesArray = createSelector(
  [selectRecordAllowancesEntities],
  (recordAllowances) => Object.values(recordAllowances)
);
