import { createAsyncThunk } from '@reduxjs/toolkit';
import postClockOutRecord from '../services/clockOutRecord.service';
import { selectedClockOutRecordWithUpdatedAttributes } from '../selectors/records.selectors';
import { selectUserCoordinates } from 'store/user/selectors.js/user.selectors';
import { RECORD_STATUS } from 'LixaniAPI/enums';
import {
  isSetAllowanceParams,
  selectRecordAllowanceTypeAllowancesToCreate,
} from '../selectors/records-base.selectors';

export const clockOutRecord = createAsyncThunk(
  'records/clockOutRecord',
  async (_, { getState }) => {
    const record = selectedClockOutRecordWithUpdatedAttributes(getState());

    const now = new Date().toISOString();

    const coordinates = selectUserCoordinates(getState());

    const isAllowancesSet = isSetAllowanceParams(getState());

    const recordAllowancesToCreate =
      selectRecordAllowanceTypeAllowancesToCreate(getState());

    const clockOut = {
      id: record.id,
      description: record.description,
      assignment: record.assignment,
      assignments: record.assignments,
      type: record.type,
      assignmentUnitAmount: record.assignmentUnitAmount,
      clockOutAt: now,
      status: RECORD_STATUS.OUT,
      latOut: coordinates ? coordinates.latitude : undefined,
      longOut: coordinates ? coordinates.longitude : undefined,
      updatedAt: now,
    };

    const recordWithDefaultBilledClockOut = {
      ...clockOut,
      billedClockOutAt: clockOut.clockOutAt,
    };

    const recordAllowancesToCreateWithoutAllowanceType =
      recordAllowancesToCreate.map((allowance) => {
        const { allowanceType, ...allowanceFields } = allowance;
        return allowanceFields;
      });

    const response = await postClockOutRecord(
      recordWithDefaultBilledClockOut,
      isAllowancesSet,
      recordAllowancesToCreateWithoutAllowanceType
    );

    return response;
  }
);
