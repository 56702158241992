import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchFilePermissionAsDeletedAt } from 'scenes/ProjectBank/services/patchFilePermissionAsDeletedAt.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const setAsDeletedProjectBankFilePermission = createAsyncThunk(
  'projectBank/deleteProjectBankFilePermission',
  async (filePermissionId, {getState}) => {
    const projectId = selectSelectedProjectId(getState());
    const updatedFilePermission = await patchFilePermissionAsDeletedAt(
      filePermissionId,
      projectId
    );

    return updatedFilePermission;
  }
);
