import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadResourceTree } from './thunks/loadResourceTree';
import { loadParentAndSubProjects } from './thunks/loadParentAndSubProjects';
import { updateResources } from './thunks/updateResources';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadActiveAssignmentsForProject } from 'store/assignments/thunks/loadActiveAssignmentsForProject';
import {
  addFilter,
  clearExpensesTrackingData,
  getProjectAssignmentTree,
  removeFilter,
  resetFilters,
  setActiveFilters,
  setAllProjectAssignmentsLoaded,
  setAllProjectAssignmentsLoadedAmount,
  setFilters,
  updateAssignmentsFromGraphqlMutate,
} from './actions/expenses-tracking.actions';
import { loadActiveAssignmentsForBusiness } from 'store/assignments/thunks/loadActiveAssignmentsForBusiness';
import { loadCompanyInvoiceTotals } from './thunks/loadCompanyInvoiceTotals';

const resourceTreeAdapter = createEntityAdapter();
const parentAndSubProjectsAdapter = createEntityAdapter();
const projectAssignmentsAdapter = createEntityAdapter();
const companyTotalsAdapter = createEntityAdapter();
const resetState = {
  resourceTree: resourceTreeAdapter.getInitialState(),
  parentAndSubProjects: parentAndSubProjectsAdapter.getInitialState(),
  loadingResourceTree: false,
  projectAssignments: projectAssignmentsAdapter.getInitialState(),
  companyInvoiceTotals: companyTotalsAdapter.getInitialState(),
  loadingCompanyInvoiceTotals: false,
  allProjectAssignmentsLoaded: false,
  allProjectAssignmentsLoadedAmount: 0,
  activeFilters: [],
};

export const expenseTrackingSlice = createSlice({
  name: 'expenseTracking',
  initialState: {
    resourceTree: resourceTreeAdapter.getInitialState(),
    parentAndSubProjects: parentAndSubProjectsAdapter.getInitialState(),
    loadingResourceTree: false,
    projectAssignments: projectAssignmentsAdapter.getInitialState(),
    companyInvoiceTotals: companyTotalsAdapter.getInitialState(),
    loadingCompanyInvoiceTotals: false,
    allProjectAssignmentsLoaded: false,
    allProjectAssignmentsLoadedAmount: 0,
    activeFilters: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadResourceTree.pending, (state) => ({
        ...state,
        loadingResourceTree: true,
      }))
      .addCase(loadResourceTree.fulfilled, (state, action) => {
        resourceTreeAdapter.setAll(state.resourceTree, action.payload);
        state.loadingResourceTree = false;
      })
      .addCase(loadResourceTree.rejected, (state, action) => ({
        ...state,
        loadingResourceTree: false,
        error: action.error,
      }))
      .addCase(loadCompanyInvoiceTotals.fulfilled, (state, action) => {
        state.companyInvoiceTotals = action.payload;
        state.loadingCompanyInvoiceTotals = false;
      })
      .addCase(loadCompanyInvoiceTotals.pending, (state) => ({
        ...state,
        loadingCompanyInvoiceTotals: true,
      }))
      .addCase(loadCompanyInvoiceTotals.rejected, (state, action) => ({
        ...state,
        error: action.error,
        loadingCompanyInvoiceTotals: false,
      }))

      .addCase(loadParentAndSubProjects.pending, (state) => ({
        ...state,
        loadingParentAndSubProjects: true,
      }))
      .addCase(loadParentAndSubProjects.fulfilled, (state, action) => {
        parentAndSubProjectsAdapter.setAll(
          state.parentAndSubProjects,
          action.payload
        );
        state.loadingParentAndSubProjects = false;
      })
      .addCase(loadParentAndSubProjects.rejected, (state, action) => ({
        ...state,
        loadingParentAndSubProjects: false,
        error: action.error,
      }))
      .addCase(updateResources.pending, (state) => ({
        ...state,
        loadingUpdateResources: true,
      }))
      .addCase(updateResources.fulfilled, (state, action) => {
        resourceTreeAdapter.upsertOne(state.resourceTree, action.payload);
        state.loadingUpdateResources = false;
      })
      .addCase(updateResources.rejected, (state, action) => ({
        ...state,
        loadingUpdateResources: false,
        error: action.error,
      }))
      .addCase(loadActiveAssignmentsForBusiness.pending, (state) => ({
        ...state,
        loadingProjectAssignments: true,
      }))
      .addCase(loadActiveAssignmentsForBusiness.fulfilled, (state, action) => {
        projectAssignmentsAdapter.setMany(
          state.projectAssignments,
          action.payload
        );
        state.loadingProjectAssignments = false;
      })
      .addCase(loadActiveAssignmentsForBusiness.rejected, (state, action) => ({
        ...state,
        loadingProjectAssignments: false,
        error: action.error,
      }))
      .addCase(getProjectAssignmentTree, (state, action) => ({
        ...state,
        projectAssignmentTree: action.payload,
      }))
      .addCase(setAllProjectAssignmentsLoaded, (state, action) => ({
        ...state,
        allProjectAssignmentsLoaded: action.payload,
      }))
      .addCase(setAllProjectAssignmentsLoadedAmount, (state, action) => ({
        ...state,
        allProjectAssignmentsLoadedAmount: action.payload,
      }))
      .addCase(clearExpensesTrackingData, () => resetState)
      .addCase(updateAssignmentsFromGraphqlMutate, (state, action) => {
        console.log('updateAssignmentsFromGraphqlMutate', action);
        if (action.payload.length) {
          projectAssignmentsAdapter.upsertMany(
            state.projectAssignments,
            action.payload
          );
        } else {
          projectAssignmentsAdapter.upsertOne(
            state.projectAssignments,
            action.payload
          );
        }
      })
      .addCase(setFilters, (state, action) => ({
        ...state,
        activeFilters: action.payload,
      }))
      .addCase(addFilter, (state, action) => ({
        ...state,
        activeFilters: [...state.activeFilters, action.payload],
      }))
      .addCase(removeFilter, (state, action) => ({
        ...state,
        activeFilters: state.activeFilters.filter(
          (filter) => filter !== action.payload
        ),
      }))
      .addCase(resetFilters, (state) => ({
        ...state,
        activeFilters: [],
      }));
  },
});
