import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getProject from 'scenes/ProjectForms/utils/getProject';

export const getMasterParentProject = createAsyncThunk(
    'projectForm/getMasterParentProject',
    async (projectId, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getProjectPromises = [];

        getProjectPromises.push(getProject(projectId, params));

        const getProjectPromisesSettled = await Promise.allSettled(
            getProjectPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const masterParent = [].concat(...getProjectPromisesSettled);

        return {
            masterParent,
        };
    }
);