import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUser } from '../services/getUser.service';
import { setUser } from 'store/actions/setUser';

export const loadUser = createAsyncThunk(
  'user/loadUser',
  async (_, { dispatch }) => {
    const user = await getUser();
    dispatch(setUser(user));
    return user;
  }
);
