import { createAsyncThunk } from '@reduxjs/toolkit';
import postEmployerForUser from '../services/addEmployerForUser.service';

export const addEmployerForUser = createAsyncThunk(
  'employers/addEmployerForUser',
  async (businessToJoin) => {
    const employerJoinData = await postEmployerForUser(businessToJoin);

    return employerJoinData;
  }
);
