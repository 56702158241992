import { createAction } from '@reduxjs/toolkit';

export const setFilePermissions = createAction(
  'imageSelector/setFilePermissions'
);
export const setLoading = createAction(
  'imageSelector/setLoading'
);

export const resetState = createAction(
  'imageSelector/resetState'
);