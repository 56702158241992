import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { patchPermissionsOfUsers } from '../../services/patchPermissionsOfUsers';
import { selectUserPermissionModifications } from '../selectors/permissionsSelectors';

export const updatePermissionsOfUsers = createAsyncThunk(
  'permissionsManagement/updatePermissionsOfUsers',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());
    const userPermissionModifications = selectUserPermissionModifications(
      getState()
    );

    const response = await patchPermissionsOfUsers(
      selectedProjectId,
      Object.values(userPermissionModifications)
    );

    return response;
  }
);

export const massUpdatePermissionsOfUsers = createAsyncThunk(
  'permissionsManagement/massUpdatePermissionsOfUsers',
  async (roleId, { getState }) => {
    const {
      permissions: { selectedUsersToUpdatePermissions },
    } = getState();

    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const usersPermissions = selectedUsersToUpdatePermissions.map((user) => ({
      userId: user,
      roleId,
      projectId: selectedProjectId,
    }));

    const projectIds = uniq(usersPermissions.map((p) => p.projectId));

    const response = await patchPermissionsOfUsers(
      usersPermissions,
      projectIds
    );

    return response;
  }
);
