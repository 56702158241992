import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getLixaniUsageDataOfBusinesses } from '../thunks/getLixaniUsageDataOfBusinesses.thunk';
import {
  closeAddEmployerDialog,
  openAddEmployerDialog,
  openBusinessesLixaniUsageDataDialog,
} from '../actions/settingsActions';
import { addEmployerForUser } from 'store/projects/thunks/addEmployerForUser.thunk';

const businessesLixaniUsageDataAdapter = createEntityAdapter({
  selectId: (usageDataOfBusiness) => usageDataOfBusiness.businessId,
});
export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    businessesLixaniUsageData:
      businessesLixaniUsageDataAdapter.getInitialState(),
    isAddEmployerDialogOpen: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLixaniUsageDataOfBusinesses.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getLixaniUsageDataOfBusinesses.fulfilled, (state, action) => {
        console.log(action);
        businessesLixaniUsageDataAdapter.setMany(
          state.businessesLixaniUsageData,
          Object.values(action.payload)
        );
        state.loading = false;
      })
      .addCase(getLixaniUsageDataOfBusinesses.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(openBusinessesLixaniUsageDataDialog, (state) => ({
        ...state,
        businessesLixaniUsageDataDialogOpen: true,
      }))
      .addCase(openAddEmployerDialog, (state, action) => {
        state.isAddEmployerDialogOpen = true;
      })
      .addCase(closeAddEmployerDialog, (state, action) => {
        state.isAddEmployerDialogOpen = false;
      })
      .addCase(addEmployerForUser.fulfilled, (state, action) => {
        state.isAddEmployerDialogOpen = false;
      })
      .addCase(addEmployerForUser.rejected, (state, action) => {
        state.isAddEmployerDialogOpen = false;
      });
  },
});

export const {
  selectAll: selectAllBusinessesLixaniUsageData,
  selectEntities: selectBusinessesLixaniUsageDataEntities,
  selectById: selectBusinessesLixaniUsageDataById,
  selectIds: selectBusinessesLixaniUsageDataIds,
} = businessesLixaniUsageDataAdapter.getSelectors(
  (state) => state.settings.businessesLixaniUsageData
);
