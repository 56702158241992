import { createAsyncThunk } from '@reduxjs/toolkit';
import { createNewTimelineService } from 'scenes/Timelines/services/createNewTimeline.service';

export const createNewTimeline = createAsyncThunk(
  'timelines/createNewTimeline',
  async ({ projectId, timelineDetails }, { rejectWithValue }) => {
    try {
      const timeline = await createNewTimelineService(
        projectId,
        timelineDetails
      );
      return timeline;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
