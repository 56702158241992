import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProjectAssignments } from '../services/getProjectAssignments';

export const loadAssignmentsForProject = createAsyncThunk(
  'assignments/loadAssignmentsForProject',
  async (projectId) => {
    const projectAssignments = await getProjectAssignments(projectId);
    return projectAssignments;
  }
);
