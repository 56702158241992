import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-breadcrumbs';
import { Route } from 'react-router-dom';
import ProjectCrumbRoute from './projectCrumbRoute';
import { useSelector } from 'react-redux';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { useTranslation } from 'react-i18next';

function CrumbRoute(props) {
  const { component: Component, includeSearch, render, ...restProps } = props;

  //const selectedRoute = useSelector(selectProjectsCrumbRoute);

  const { t } = useTranslation('common');

  const selectedProject = useSelector(selectSelectedProject);

  const projectName = selectedProject.id
    ? selectedProject.name
    : t('navigation.projects');

  const title = props.title ? props.title : projectName;

  return (
    <>
      {props.crumbpath ? <ProjectCrumbRoute></ProjectCrumbRoute> : null}
      <Route
        {...restProps}
        key={props.computedMatch?.url}
        render={(routeProps) => (
          <>
            {/* {!!props.crumbpath &&
            props.crumbpath.map(
              (item) =>
                !!item.name && (
                  <Breadcrumb
                    key={item.projectKey}
                    data={{
                      title: item.name,
                      pathname: item.projectKey,
                    }}
                  ></Breadcrumb>
                )
            )} */}

            {/*!!props.parentKey &&
      <Breadcrumb
      data={{
        title: props.parent,
        pathname: props.parentKey
      }}
      >
      </Breadcrumb>
    */}

            <Breadcrumb
              data={{
                title: title,
                pathname: routeProps.match.url,
                search: includeSearch ? routeProps.location.search : null,
                state: {
                  historyProject: routeProps?.location?.pathname || null,
                },
              }}
            >
              {Component ? (
                <Component {...routeProps} />
              ) : render ? (
                render(routeProps)
              ) : null}
            </Breadcrumb>
          </>
        )}
      />{' '}
    </>
  );
}

CrumbRoute.defaultProps = {
  includeSearch: false,
};

CrumbRoute.propTypes = {
  component: PropTypes.object,
  includeSearch: PropTypes.bool,
  render: PropTypes.func,
  title: PropTypes.string,
  parent: PropTypes.string,
  parentKey: PropTypes.string,
};

export default CrumbRoute;
