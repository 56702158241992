import {
  RECORDS_APPROVE,
  RECORDS_CHECK_CLOCKED_TIMES,
} from 'scenes/Permissions/constants/permissions';
import { getNearestUserProjectPermissions } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

export const checkPermissionsToCheckRecordClockedInTimes = (
  record,
  userProjectPermissions,
  projects,
  resourceDefaultPermissions,
  IsAdminOfSelectedRecordProject,
  IsAdminOfSelectedRecordResource
) => {
  const nearestUserProjectPermissionsForRecord =
    getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      record.project
    );

  const defaultPermissionsWithNearestProjectPermissions = {
    ...resourceDefaultPermissions,
    ...nearestUserProjectPermissionsForRecord,
  };

  const isPermissionToCheckClockedTimes =
    defaultPermissionsWithNearestProjectPermissions[
      RECORDS_CHECK_CLOCKED_TIMES
    ];

  return (
    IsAdminOfSelectedRecordProject ||
    isPermissionToCheckClockedTimes ||
    IsAdminOfSelectedRecordResource
  );
};
