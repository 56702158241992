import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from 'react-breadcrumbs';

import MenuIcon from 'mdi-material-ui/Menu';

import NavItems from './NavItems/NavItems';
import TimerButton from './TimerButton/TimerButton';
import { LogoNegaPng } from 'lixani-lib-data';
import { selectTaxNumberMissing } from 'store/projects/selectors/userProjects.selectors';
import { selectIsExpiringCurrentUserCards } from 'store/cards/selectors/user-cards.selectors';

const toolbarMinHeight = 48;

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    ...theme.typography.fontPantonLight,
    ...theme.component.breadcrumbs,
    boxSizing: 'border-box',
    fontSize: '0.8rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    width: '100%',
    zIndex: 9999,
  },
  root: {
    zIndex: theme.zIndex.drawer + 1,
    /* Fixes profile page - needs a better fix */
    backgroundColor:
      theme.component.appbarToolbarRoot.backgroundColor + ' !important',
  },
  toolbar: {
    ...theme.appbarToolbarRoot,
    justifyContent: 'space-between',
    minHeight: toolbarMinHeight,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(1),
    },
  },
  logoButton: {
    padding: 0,
  },
  logo: {
    height: 25,
  },
  logoContainer: {
    flex: 1,
  },
  menuButton: {
    color: 'inherit',
    height: 42,
    padding: 0,
    width: 42,
  },
  sideNavPaper: {
    backgroundColor: theme.palette.background.darkPaper,
    minWidth: 240,
  },
}));

function AppBar(props) {
  const { client, inRecords, inRecordsLoading, lastProjects } = props;
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);

  const taxNumberMissing = useSelector(selectTaxNumberMissing);

  const isExpiringUserCards = useSelector(selectIsExpiringCurrentUserCards);

  return (
    <MuiAppBar className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <Button className={classes.logoButton} component={Link} to={'/'}>
            <img
              className={classes.logo}
              alt={'Lixani logo'}
              src={`data:image/png;base64,${LogoNegaPng}`}
              height={'25px'}
              width={'136px'}
            />
          </Button>
        </div>
        <TimerButton
          inRecords={inRecords}
          inRecordsLoading={inRecordsLoading}
          lastProjects={lastProjects}
        />
        <IconButton
          className={classes.menuButton}
          onClick={() => setNavOpen(true)}
        >
          <Badge
            color="secondary"
            invisible={!taxNumberMissing && !isExpiringUserCards}
            variant="dot"
            overlap="rectangular"
          >
            <MenuIcon />
          </Badge>
        </IconButton>
        <Drawer
          anchor="right"
          classes={{ paper: classes.sideNavPaper }}
          className={classes.sideNav}
          onClose={() => setNavOpen(false)}
          open={navOpen}
        >
          <NavItems onCloseMenu={() => setNavOpen(false)} client={client} />
        </Drawer>
      </Toolbar>
      <Breadcrumbs className={classes.breadcrumbs} />
    </MuiAppBar>
  );
}

AppBar.propTypes = {
  client: PropTypes.object.isRequired,
  inRecords: PropTypes.array.isRequired,
  inRecordsLoading: PropTypes.bool.isRequired,
  lastProjects: PropTypes.array.isRequired,
};

export default AppBar;
