import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import getCredentials from '../../../../../utils/getCredentials';
import { getAdminsOfBusiness } from '../../../services/getAdminsOfBusiness.service';

export const loadAdminsOfBusiness = createAsyncThunk(
  'permissionsManagement/loadAdminsOfBusiness',
  async (_, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const businessId = selectedProject?.business_id;

    const response = await getAdminsOfBusiness(businessId, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    return response;
  }
);
