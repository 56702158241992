import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import Dialog from 'components/DialogWrapper/DialogWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import CalendarPlusIcon from 'mdi-material-ui/CalendarPlus';

import AssignmentsSelect from './AssignmentsSelect';
import Button from 'components/Button/Button';
import ButtonSwitch from 'components/ButtonSwitch/ButtonSwitch';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import InTime from 'components/InTime/InTime';
import TextInput from 'components/TextInput/TextInput';
import useStyles from './RecordDialogLayoutStyles';

import { RECORD_STATUS } from 'LixaniAPI/enums';

import sortByName from 'utils/sortByName';
import ComboBox from 'scenes/ExpenseTracking/components/ComboBox';

import { createRecord } from '../../../../store/records/thunks/createRecord';

import {
  selectCreateRecordDialogData,
  selectIsAdminOfCreatingRecordProject,
  selectIsDialogResourcesLoading,
} from 'store/records/selectors/records.selectors';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { selectProjectResources } from 'store/resources/selectors/resources.selectors';
import { selectProjectAssignments } from 'store/assignments/selectors/assignment.selectors';
import { TypeChange } from './typeChange';
import {
  closeCreateRecordDialog,
  setCreateRecordDialogAttributes,
} from 'store/records/actions/records.actions';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import { TextField } from '@material-ui/core';
import { selectCreateRecordProjectOptions } from 'store/projects/selectors/recordProjectOptions.selectors';
import OpenAddUserDialogButton from 'components/AddUserDialog/OpenAddUserDialogButton';
import { selectIsAddNewUserDialogOpen } from 'store/projectUsers/selectors/projectUsers.selectors';
import { AddUserDialog } from 'components/AddUserDialog/AddUserDialog';

function CreateRecordDialog({ open }) {
  const dispatch = useDispatch();

  const dialogData = useSelector(selectCreateRecordDialogData);
  let {
    project,
    clockInAt,
    clockOutAt,
    billedClockInAt,
    billedClockOutAt,
    status,
    type,
    description,
    resource,
    assignment,
    targetCountry,
    assignmentUnitAmount,
  } = dialogData;

  const classes = useStyles();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { t } = useTranslation('common');

  const [unitAmountDialogOpen, setUnitAmountDialogOpen] = useState(false);

  const setAssignmentUnitAmount = (value) => {
    setAttributes({ assignmentUnitAmount: value });
  };
  const setAssignmentUnitAmountDialogOpen = (value) => {
    setUnitAmountDialogOpen(value);
  };

  const checkedOut = status === 'OUT' ? true : false;

  const projectOptions = useSelector(selectCreateRecordProjectOptions);

  const isAdminOnSelectedProject = useSelector(
    selectIsAdminOfCreatingRecordProject
  );

  const isResourcesLoading = useSelector(selectIsDialogResourcesLoading);
  const projectAssignments = useSelector((state) =>
    selectProjectAssignments(state, project)
  );

  const projectResources = useSelector((state) =>
    selectProjectResources(state, project)
  );

  const isAddNewUserDialogOpen = useSelector(selectIsAddNewUserDialogOpen);

  const handleTypeChange = (value) => {
    setAttributes({ type: value });
  };

  const handleSave = () => {
    console.log(dialogData);
    dispatch(createRecord());
  };

  const closeDialog = () => {
    dispatch(closeCreateRecordDialog());
  };

  const setAttributes = (attributeObject) => {
    console.log(attributeObject);
    dispatch(setCreateRecordDialogAttributes(attributeObject));
  };

  const debounceDescriptionSearch = useCallback(
    debounce(setDescriptionSearchText, 500),
    []
  );

  function setDescriptionSearchText(value) {
    console.log(value);
    setAttributes({ description: value });
  }

  const loggedInUserId = useSelector(selectLoggedInUserId);
  const selectedEmployer = useSelector(selectSelectedEmployer);

  useEffect(() => {
    const loggedInUsersResourceBySelectedEmployer = projectResources.find(
      (resource) =>
        resource.user === loggedInUserId &&
        resource.employer &&
        resource.employer.id &&
        resource.employer.id === selectedEmployer
    );

    if (loggedInUsersResourceBySelectedEmployer) {
      setAttributes({ resource: loggedInUsersResourceBySelectedEmployer.id });
    }
  }, []);

  const handleClockInGreaterThanClockout = (clockIn, clockOut) => {
    if (clockIn > clockOut) {
      setAttributes({ clockInAt: clockIn, clockOutAt: clockIn });
    }
  };

  const handleBilledClockInGreaterThanClockout = (clockIn, clockOut) => {
    if (clockIn > clockOut) {
      setAttributes({ billedClockInAt: clockIn, billedClockOutAt: clockIn });
    }
  };

  const handleProjectChange = (project) => {
    setAttributes({
      project: project,
      resource: undefined,
      assignment: undefined,
    });
    dispatch(loadResourcesAndResourceEmployersOfProject(project));
    dispatch(loadAssignmentsForProject(project));
  };

  const handleAssignmentChange = (assignmentId) => {
    // handling assignment deselect on click if assignment already selected
    if (assignmentId !== assignment) {
      setAttributes({
        assignment: assignmentId,
      });
    } else {
      setAttributes({
        assignment: undefined,
      });
    }
  };

  const clockedInGreaterThanClockedOut = clockInAt > clockOutAt;

  const dateFormat = 'YYYY-MM-DDTHH:mm';

  const formattedClockInAt = moment(clockInAt).format(dateFormat);

  const formattedClockOutAt = moment(clockOutAt).format(dateFormat);

  const formattedBilledClockInAt = moment(billedClockInAt).format(dateFormat);

  const formattedBilledClockOutAt = moment(billedClockOutAt).format(dateFormat);

  const formatDateOutput = (date) => {
    return moment(date).toISOString();
  };

  return (
    <>
      <Dialog
        aria-labelledby="create-record-dialog"
        classes={{ paper: classes.dialogPaper }}
        disableBackdropClick
        fullScreen={!upSm}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={closeDialog}
      >
        <DialogHeader
          icon={<CalendarPlusIcon />}
          onClose={closeDialog}
          title={t('calendar.new_record')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography className={classes.inTime}>
                IN{' '}
                <InTime
                  clockInAt={moment(clockInAt)}
                  clockOutAt={moment(clockOutAt)}
                  status={checkedOut ? RECORD_STATUS.OUT : RECORD_STATUS.IN}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.clockedItem}>
              <ButtonSwitch
                checked={!checkedOut}
                fullWidth
                on={t('record.clocked_in')}
                off={t('record.clocked_out')}
                onChange={() =>
                  setAttributes({ status: checkedOut ? 'IN' : 'OUT' })
                }
              />
            </Grid>

            {/*LEIMATTU AIKA*/}
            <Grid item xs={12}>
              <label className="mylabel">{t('calendar.clocked_time')}</label>
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: classes.textInput }}
                fullWidth
                shrink={true}
                label={t('calendar.start_date')}
                variant="outlined"
                size="small"
                type="datetime-local"
                value={formattedClockInAt}
                onChange={(event) =>
                  setAttributes({
                    clockInAt: formatDateOutput(event.target.value),
                    billedClockInAt: formatDateOutput(event.target.value),
                  })
                }
                onBlur={(event) =>
                  handleClockInGreaterThanClockout(
                    formatDateOutput(event.target.value),
                    formatDateOutput(clockOutAt)
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={clsx(classes.dateItem, checkedOut && classes.hidden)}
            >
              <Typography className={classes.clockedText}>
                {t('calendar.clocked_in')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className={clsx(classes.dateItem, !checkedOut && classes.hidden)}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: classes.textInput }}
                fullWidth
                shrink={true}
                label={t('calendar.end_date')}
                variant="outlined"
                size="small"
                type="datetime-local"
                value={formattedClockOutAt}
                onChange={(event) =>
                  setAttributes({
                    clockOutAt: formatDateOutput(event.target.value),
                    billedClockOutAt: formatDateOutput(event.target.value),
                  })
                }
                onBlur={(event) =>
                  handleClockInGreaterThanClockout(
                    formatDateOutput(clockInAt),
                    formatDateOutput(event.target.value)
                  )
                }
              />
            </Grid>
            {/*LASKUTETTAVA AIKA*/}
            {isAdminOnSelectedProject ? (
              <>
                <Grid item xs={12}>
                  <label className="mylabel">{t('calendar.billed_time')}</label>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: classes.textInput }}
                    fullWidth
                    shrink={true}
                    label={t('calendar.start_date')}
                    variant="outlined"
                    size="small"
                    type="datetime-local"
                    value={formattedBilledClockInAt}
                    onChange={(event) =>
                      setAttributes({
                        billedClockInAt: formatDateOutput(event.target.value),
                      })
                    }
                    onBlur={(event) =>
                      handleBilledClockInGreaterThanClockout(
                        formatDateOutput(event.target.value),
                        formatDateOutput(billedClockOutAt)
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(
                    classes.dateItem,
                    checkedOut && classes.hidden
                  )}
                >
                  <Typography className={classes.clockedText}>
                    {t('calendar.clocked_in')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(!checkedOut && classes.hidden)}
                >
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: classes.textInput }}
                    fullWidth
                    shrink={true}
                    label={t('calendar.end_date')}
                    variant="outlined"
                    size="small"
                    type="datetime-local"
                    value={formattedBilledClockOutAt}
                    onChange={(event) =>
                      setAttributes({
                        billedClockOutAt: formatDateOutput(event.target.value),
                      })
                    }
                    onBlur={(event) =>
                      handleBilledClockInGreaterThanClockout(
                        formatDateOutput(billedClockInAt),
                        formatDateOutput(event.target.value)
                      )
                    }
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} className={classes.gridItem}>
              <ComboBox
                items={projectOptions}
                selected={project}
                onChange={(e) => handleProjectChange(e)}
                label={t('project.project')}
                emptyOption={false}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TypeChange
                handleChange={handleTypeChange}
                selectedType={type}
              ></TypeChange>
            </Grid>

            {type === 'RIDE' && (
              <Grid item xs={12} className={classes.gridItem}>
                <CountrySelect
                  changeCountry={(country) =>
                    setAttributes({ targetCountry: country })
                  }
                  label={t('record.country')}
                  propsCountry={targetCountry}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <ComboBox
                items={projectResources
                  .sort((a, b) => sortByName(a, b, 'lastName'))
                  .map((item) => {
                    if (item.employer?.name) {
                      return {
                        ...item,
                        name: `${item.employer.name} / ${item.lastName} ${item.firstName}`,
                      };
                    } else {
                      return {
                        ...item,
                        name: `MyLixani (Ei työnantajaa) / ${item.lastName} ${item.firstName}`,
                      };
                    }
                  })}
                selected={resource}
                onChange={(value) => setAttributes({ resource: value })}
                label={t('resource.resource')}
                emptyOption={false}
                disabled={isResourcesLoading}
                loading={isResourcesLoading}
              />
            </Grid>
            {isAdminOnSelectedProject ? (
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <OpenAddUserDialogButton />
              </Grid>
            ) : null}

            <Grid item xs={12} className={classes.gridItem}>
              <TextInput
                fullWidth
                id="description"
                onChange={(e) => debounceDescriptionSearch(e.target.value)}
                label={t('calendar.description')}
                multiline
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth>
                <AssignmentsSelect
                  a1={
                    projectAssignments && projectAssignments[0]
                      ? projectAssignments[0]
                      : undefined
                  }
                  selectedAssignment={assignment}
                  assignments={projectAssignments}
                  update={handleAssignmentChange}
                  unitAmountDialogOpen={unitAmountDialogOpen}
                  setDialogOpen={setAssignmentUnitAmountDialogOpen}
                  setAssignmentUnitAmount={setAssignmentUnitAmount}
                  unitAmount={assignmentUnitAmount}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.actionBtn}
            onClick={closeDialog}
            outline
            color="primary"
          >
            {t('common.cancel')}
          </Button>
          <Button
            className={classes.actionBtn}
            onClick={handleSave}
            color="primary"
            disabled={clockedInGreaterThanClockedOut || !resource}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
      {isAddNewUserDialogOpen ? <AddUserDialog /> : null}
    </>
  );
}

export default CreateRecordDialog;
