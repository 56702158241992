import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setCreateManualResource from 'scenes/BulletinBoard/utils/setCreateManualResource';
export const createManualResource = createAsyncThunk(
  'bulletinBoard/createManualResource',
  async (manualResource, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdManualResource = await setCreateManualResource(
      manualResource,
      params
    );

    return { createdManualResource };
  }
);
