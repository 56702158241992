import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadUserManualContacts } from './thunks/loadUserManualContacts.thunk';

const manualContactsAdapter = createEntityAdapter();

export const manualContactsSlice = createSlice({
  name: 'manualContacts',
  initialState: {
    manualContacts: manualContactsAdapter.getInitialState(),
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUserManualContacts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadUserManualContacts.fulfilled, (state, action) => {
        state.manualContacts = manualContactsAdapter.setMany(
          state.manualContacts,
          action.payload
        );
      });
  },
});
