import { createTheme } from '@material-ui/core/styles';
import { baseTheme, pantonFonts } from './common';

const defaultTextColor = 'rgba(255,255,255,0.87)';
const defaultBackgroundColor = '#023140';
const backgroundGradient = 'linear-gradient(0deg, #012531, #023140)';
const pantonCommon = {
  color: defaultTextColor,
  fontSize: '1rem',
  lineHeight: 1,
};
const textColors = {
  primary: 'rgba(255,255,255,1)',
  secondary: 'rgba(255,255,255,0.87)',
  tertiary: 'rgba(255,255,255,0.75)',
  caption: 'rgba(255,255,255,0.54)',
  disabled: 'rgba(255,255,255,0.38)',
  hint: 'rgba(255,255,255,0.38)',
};
const textBlackColors = {
  primary: 'rgba(0,0,0,1)',
  secondary: 'rgba(0,0,0,0.87)',
  tertiary: 'rgba(0,0,0,0.75)',
  caption: 'rgba(0,0,0,0.54)',
  disabled: 'rgba(0,0,0,0.38)',
  hint: 'rgba(0,0,0,0.38)',
};
const scheme = {
  main: '#023140',
  darker: '#012531',
  dark: '#021F28',
  lighter: '#034961',
  light: '#036484',
  lightest: '#047195',
};

const darkTheme = createTheme({
  name: 'dark',
  typography: {
    fontFamily: pantonFonts.panton,
    fontPanton: {
      ...pantonCommon,
      fontFamily: pantonFonts.panton,
    },
    fontPantonLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonLight,
    },
    fontPantonExtraLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonExtraLight,
    },
    fontPantonSemiBold: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonSemiBold,
    },
    h4: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    h5: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    body1: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    body2: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
      fontSize: '0.875rem',
    },
    caption: {
      color: textColors.caption,
      fontFamily: pantonFonts.pantonLight,
    },
  },
  palette: {
    borderColor: scheme.light,
    type: 'dark',
    text: {
      ...textColors,
    },
    textBlack: {
      ...textBlackColors,
    },
    textWhite: {
      ...textColors,
    },
    textInvert: {
      ...textBlackColors,
    },
    loading: {
      primary: scheme.lightest,
      secondary: scheme.lightest,
    },
    primary: {
      main: scheme.main,
      light: scheme.light,
      dark: scheme.dark,
      gradient: scheme.main,
      alt_main: scheme.lighter,
    },
    danger: {
      main: '#b0340a',
      light: '#e96538',
      dark: '#7a0000',
    },
    trash: {
      main: 'rgba(176, 52, 10,0.5)',
      light: '#e96538',
      dark: '#7a0000',
    },
    buttonPrimary: scheme.lighter,
    buttonSecondary: scheme.lightest,
    buttonMain: scheme.main,
    buttonDark: scheme.dark,
    buttonDarker: scheme.darker,
    buttonLighter: scheme.lighter,
    buttonLight: scheme.light,
    buttonLightest: scheme.lightest,
    buttonHoverText: textColors.main,
  },
  component: {
    appbarToolbarRoot: {
      backgroundColor: scheme.lightest,
    },
    border: `1px solid ${scheme.lighter}`,
    borderMust: `1px solid ${scheme.lighter}`,
    borderDotted: `1px dotted ${scheme.light}`,
    breadcrumbs: {
      background: scheme.light,
      color: textColors.primary,
    },
    buttonSwitchColorInitial: scheme.light,
    dashboardButtonColorDefault: {
      backgroundColor: scheme.lighter,
    },
    dashboardButtonColorSecondary: {
      backgroundColor: scheme.light,
    },
    dashboardButtonBackdrop: {
      borderRadius: 4,
    },
    dashboardButtonPaper: {
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: baseTheme.spacing(1.5),
      minHeight: 100,
      width: '100%',
    },
    dashboardButtonIcon: {
      color: 'rgba(255,255,255,0.7)',
      fontSize: '2.5rem',
      lineHeight: '2.5rem',
      textAlign: 'center',
    },
    dashboardButtonPrimary: {
      fontFamily: pantonFonts.pantonLight,
    },
    dashboardButtonSecondary: {
      color: textColors.tertiary,
      minHeight: 22,
    },
    dashboardClockInListPaper: {
      backgroundColor: 'initial',
      border: `1px solid ${scheme.lighter}`,
    },
    dashboardClockInCollapsed: {
      backgroundColor: scheme.lighter,
      borderRadius: '2px',
    },
    dashboardPaper: {
      backgroundColor: 'initial',
      border: `1px solid ${scheme.lighter}`,
      boxSizing: 'border-box',
    },
    dialogPaper: {
      background: defaultBackgroundColor,
      position: 'initial',
      overflow: 'initial',
    },
    dialogPaperDark: {
      background: scheme.dark,
      position: 'initial',
      overflow: 'initial',
    },
    dialogPaperFullscreen: {
      background: backgroundGradient,
    },
    dialogHeader: {
      background: scheme.light,
    },
    dialogHeaderCloseButton: {
      borderLeft: `1px solid ${scheme.lighter}`,
      padding: baseTheme.spacing(1),
    },
    drawerPaper: {
      background: 'none',
    },
    drawerPaperMobile: {
      background: backgroundGradient,
    },
    drawerButton: {
      background: scheme.light,
      color: textColors.secondary,
    },
    imagePlaceholder: '/images/image_placeholder_white.png',
    videoPlaceholder: '/images/video_placeholder_white.png',
    infoContent: {
      background: 'none',
    },
    navIcon: {
      color: textColors.tertiary,
    },
    navTab: {
      backgroundColor: scheme.dark,
    },
    navTabTitle: {},
    navTabActive: {
      backgroundColor: scheme.main,
    },
    projectClockButtonPaper: {
      backgroundColor: scheme.buttonLight,
    },
    projectsListButton: {
      backgroundColor: scheme.lighter,
      color: textColors.primary,
      fontFamily: pantonFonts.pantonLight,
    },
    projectsListButtonFirst: {
      color: textColors.tertiary,
      fontFamily: pantonFonts.panton,
    },
    projectsListButtonMiddle: {
      marginLeft: baseTheme.spacing(0.25),
    },
    projectsListButtonLast: {
      marginLeft: baseTheme.spacing(0.25),
    },
    projectsListButtonTitle: {
      fontFamily: pantonFonts.panton,
    },
    record: {
      background: 'rgba(125,125,125,0.5)',
      color: textColors.secondary,
    },
    recordBackface: {
      backgroundColor: 'rgb(255,255,255)',
    },
    recordWork: {
      background: 'rgba(125,125,125,0.5)',
    },
    recordProjectwork: {
      background: 'rgba(80,170,165,0.5)',
    },
    recordWorkActive: {
      background: 'hsla(120, 75%, 70%,0.5)',
    },
    recordBreak: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordBreak_coffee: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordBreak_meal: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordShift: {
      background: 'hsla(30, 50%, 70%,0.5)',
    },
    recordAbsence: {
      background: 'hsla(210, 50%, 70%,0.5)',
    },
    recordBenefit: {
      background: 'hsla(90, 50%, 70%,0.5)',
    },
    recordRide: {
      background: 'hsla(180, 50%, 70%,0.5)',
    },
    recordNegative: {
      background: 'hsla(240, 50%, 70%,0.5)',
    },
    recordItem: {
      background: 'hsla(60, 50%, 70%,0.5)',
    },
    recordApproved: {
      background: 'hsla(137.4,99%,38.8%, 0.7)',
    },
    recordAbsence_sick_leave: {
      background: 'hsla(0,77.3%,91.4%, 0.5)',
    },
    recordAbsence_vacation_paid: {
      background: 'hsla(210, 50%, 70%,0.5)',
    },
    recordApprovePending: {
      background: 'hsla(60, 100%, 55%, 0.7)',
    },
    sideBadge: {
      border: `2px solid ${scheme.lightest}`,
    },
    snackBar: {
      background: scheme.main,
    },
    workCalendarWeekPaper: {
      backgroundColor: '#424242',
    },
    workflowActionsViewtypeBar: {
      //backgroundColor: scheme.dark,
      borderBottom: `1px solid ${scheme.lighter}`,
    },
    workflowActionsViewTypeButton: {
      fontFamily: pantonFonts.panton,
      padding: baseTheme.spacing(2),
      //backgroundColor: scheme.dark,
    },
    workflowActionsViewTypeButtonActive: {
      fontFamily: pantonFonts.panton,
      padding: baseTheme.spacing(2),
      backgroundColor: scheme.lighter,
    },
    workflowButton: {
      padding: baseTheme.spacing(1.5),
      marginRight: baseTheme.spacing(1),
      [baseTheme.breakpoints.down('xs')]: {
        padding: baseTheme.spacing(1),
        marginRight: 0,
      },
    },
    invoicePrimary: {
      backgroundColor: scheme.lightest,
    },
    invoiceSecondary: {
      backgroundColor: scheme.lighter,
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#ccc',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#ccc',
        },
        '&$disabled': {
          color: 'darkgray',
          opacity: 0.7,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#fff',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          // backgroundColor: "#fff"
          backgroundColor: '#00ae2c',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          background: backgroundGradient,
          color: defaultTextColor,
        },
        body: {
          background: 'none',
          backgroundColor: 'initial',
          color: defaultTextColor,
          fontWeight: 'initial',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: scheme.lightest,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0',
      },
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#4A90E2',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#4A90E2',
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#4A90E2',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.87rem',
        minHeight: baseTheme.spacing(4),
      },
    },
  },
});

export default darkTheme;
