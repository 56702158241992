import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_ADDITIONAL_SALARY_TYPES } from 'LixaniAPI/enums';
import i18next from 'i18next';
import { groupBy } from 'lodash-es';
import { selectEmployerAllowances } from 'store/employerAllowances/selectors/employerAllowances.selectors';
import { selectRecordAllowancesArray } from './record-allowances-base.selectors';
import { selectSelectedRecordId } from 'store/records/selectors/records-base.selectors';

const selectRecordAllowancesWithEmployerAllowancesJoinedData = createSelector(
  [selectRecordAllowancesArray, selectEmployerAllowances],
  (recordAllowances, employerAllowances) => {
    const recordAllowancesWithEmployerAllowanceData = recordAllowances.map(
      (recordAllowance) => {
        const foundEmployerAllowance =
          employerAllowances[recordAllowance.allowanceId];

        if (foundEmployerAllowance) {
          return {
            ...recordAllowance,
            name: i18next.t(
              DEFAULT_ADDITIONAL_SALARY_TYPES[foundEmployerAllowance.salaryType]
                .salaryName
            ),
            unit: foundEmployerAllowance.unit,
          };
        }
      }
    );

    return recordAllowancesWithEmployerAllowanceData;
  }
);

export const selectRecordAllowancesGroupedByRecordId = createSelector(
  [selectRecordAllowancesWithEmployerAllowancesJoinedData],
  (recordAllowances) => groupBy(recordAllowances, 'recordId')
);

export const selectRecordAllowancesOfSelectedRecord = createSelector(
  [selectSelectedRecordId, selectRecordAllowancesGroupedByRecordId],
  (selectedRecordId, recordAllowances) => recordAllowances[selectedRecordId]
);
