import { createAsyncThunk } from '@reduxjs/toolkit';
import getFormNotes from 'scenes/ProjectForms/utils/getFormNotes';
import getCredentials from '../../utils/getCredentials';

export const getNotes = createAsyncThunk(
    'projectForm/getNotes',
    async (form_id, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getFormNotesPromises = [];

        getFormNotesPromises.push(getFormNotes(form_id, params));


        const getFormNotesPromisesSettled = await Promise.allSettled(
            getFormNotesPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const formNotes = [].concat(...getFormNotesPromisesSettled);

        return {
            formNotes,
        };
    }
);