import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const setDeleteInvoiceSortRule = async (
  invoiceSortRuleId,
) => {
  const apiPath = `invoice/invoice-sort-rules/${invoiceSortRuleId}/delete`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'DELETE',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  console.log('request', request);
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);
  console.log('response', response)
  return response.data;
};
