import { createSelector } from '@reduxjs/toolkit';
import { uniqWith, orderBy } from 'lodash';
import { selectWorkCalendarRecordsArray } from './records-base.selectors';
import {
  selectWorkCalendarProjectsArray,
  selectWorkCalendarResourcesArrayWithJoinedData,
} from 'scenes/WorkDemo/store/selectors/workSelectorsDemo';

import { uniq } from 'lodash';

const selectRecordsState = (state) => state.records;

export const selectSelectedRecords = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecords.entities
);

export const selectSelectedRecordsArray = createSelector(
  [selectSelectedRecords],
  (records) =>
    Object.values(records).map((record) => ({
      ...record,
    }))
);

export const selectSelectedRecordsEditAttributes = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecordsEditAttributes
);

export const selectSelectedRecordsIds = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecords.ids
);

export const selectSelectedWorkCalendarsRecordsProjectIds = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithoutJoinedData = allRecords.filter((record) =>
      recordIds.includes(record.id)
    );

    return uniq(
      selectedRecordsWithoutJoinedData.map((record) => record.project)
    );
  }
);

export const isSelectedRecordsWithGroupCode = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithGroupCode = allRecords.filter(
      (record) => recordIds.includes(record.id) && record.groupCode
    );
    return selectedRecordsWithGroupCode.length > 0;
  }
);

export const isSelectedRecordsWithApproved = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithApproved = allRecords.filter(
      (record) => recordIds.includes(record.id) && record.approved
    );
    return selectedRecordsWithApproved.length > 0;
  }
);
export const isSelectedRecordsWithTypeAbsence = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const absenceRecordTypes = ['ABSENCE_SICK_LEAVE'];
    const absenceRecords = allRecords.filter(
      (record) =>
        recordIds.includes(record.id) &&
        absenceRecordTypes.includes(record.type)
    );
    return absenceRecords.length > 0;
  }
);

export const isSelectedRecordsWithStatusIN = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithStatusIn = allRecords.filter(
      (record) => recordIds.includes(record.id) && record.status === 'IN'
    );
    return selectedRecordsWithStatusIn.length > 0;
  }
);

export const isSelectedRecordsFromMultipleProjects = createSelector(
  [selectSelectedWorkCalendarsRecordsProjectIds],
  (projectsIds) => {
    return projectsIds.length > 1;
  }
);

export const selectRecordsEditPending = createSelector(
  [selectRecordsState],
  (state) => state.recordsEditPending
);

export const selectRecordsEditError = createSelector(
  [selectRecordsState],
  (state) => state.recordsEditError
);

export const selectRecordsEditErrorMessage = createSelector(
  [selectRecordsState],
  (state) => state.recordsEditErrorMessage
);

export const selectSelectedRecordsProjects = createSelector(
  [
    selectSelectedRecordsIds,
    selectWorkCalendarRecordsArray,
    selectWorkCalendarProjectsArray,
  ],
  (recordIds, allRecords, projects) => {
    const selectedRecordsWithoutJoinedData = allRecords.filter((record) =>
      recordIds.includes(record.id)
    );

    const selectedRecordsProjectIds = selectedRecordsWithoutJoinedData.map(
      (record) => record.project
    );

    const selectedRecordsProjects = projects.filter((project) =>
      selectedRecordsProjectIds.includes(project.id)
    );

    return selectedRecordsProjects ? selectedRecordsProjects : [];
  }
);

export const selectSelectedRecordsDescriptions = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithoutJoinedData = allRecords.filter((record) =>
      recordIds.includes(record.id)
    );

    const selectedRecordsDescriptions = orderBy(
      uniqWith(
        selectedRecordsWithoutJoinedData
          .filter((record) => record.description)
          .map((record) => record.description),
        (a, b) => a.toLowerCase() === b.toLowerCase()
      ),
      [(description) => description.toLowerCase()],
      ['asc']
    );

    return selectedRecordsDescriptions ? selectedRecordsDescriptions : [];
  }
);

export const selectSelectedRecordsResourcesIds = createSelector(
  [selectSelectedRecordsIds, selectWorkCalendarRecordsArray],
  (recordIds, allRecords) => {
    const selectedRecordsWithoutJoinedData = allRecords.filter((record) =>
      recordIds.includes(record.id)
    );

    const selectedRecordsResourcesIds = selectedRecordsWithoutJoinedData.map(
      (record) => record.resource
    );

    return selectedRecordsResourcesIds ? selectedRecordsResourcesIds : [];
  }
);

export const selectSelectedRecordsEditDialogOpen = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecordsEditDialogOpen
);

export const selectSelectedRecordsResources = createSelector(
  [
    selectSelectedRecordsResourcesIds,
    selectWorkCalendarResourcesArrayWithJoinedData,
  ],
  (selectedRecordsResourceIds, resources) => {
    const selectedRecordsResources = resources.filter((resource) =>
      selectedRecordsResourceIds.includes(resource.id)
    );

    const uniqueResources = uniqWith(
      selectedRecordsResources,
      (a, b) => a.user === b.user && a.employer.id === b.employer.id
    );

    const uniqueAndSortedResources = orderBy(
      uniqueResources,
      'employer.name',
      'desc'
    );

    return uniqueAndSortedResources ? uniqueAndSortedResources : [];
  }
);
