export default function returnProjectIdAndItsChildProjectIds(
  parentIds,
  allProjects
) {
  /**
   * @param {string[]} parentIds Array of project ids
   * @param {Object[]} allProjects Array of project objects
   *
   * finds direct children of given projectId parameters. Then loops until no more children are found. returns all child project ids and original parentIds param.
   */
  let children = Object.values(allProjects)
    .filter((project) => parentIds.includes(project.parent))
    .map((project) => project.id);
  if (children.length > 0) {
    const nextChildren = returnProjectIdAndItsChildProjectIds(
      children,
      allProjects
    );
    return [...parentIds, ...nextChildren];
  }
  return [...parentIds, ...children];
}
