import React from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { selectSelectedRecordApprovers } from 'store/approvals/selectors/approvals.selectors';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  approverText: {
    opacity: 0.5,
  },
}));

export function ApproverList() {
  const approvers = useSelector(selectSelectedRecordApprovers);
  const dateFormat = 'DD.MM.yyyy HH.mm';
  const classes = useStyles();

  return approvers
    ? approvers.map((approver) => {
        return (
          <Grid key={approver.SK} item xs={12}>
            <Typography className={classes.approverText}>
              {`${`${approver.firstName} ${approver.lastName}`} - ${
                approver.approvedAt
                  ? `Hyväksytty: ${moment(approver.approvedAt).format(
                      dateFormat
                    )} `
                  : `Lähetetty: ${moment(approver.createdAt).format(
                      dateFormat
                    )}`
              }`}
            </Typography>
          </Grid>
        );
      })
    : null;
}
