import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectRecord } from '../actions/records.actions';
import { selectSelectedRecord } from '../selectors/records.selectors';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadApproversByRecord } from 'store/approvals/thunks/loadApproversByRecord';
import { getRecordsBreakRecords } from './getRecordsBreakRecords.thunk';

export const openEditRecordDialog = createAsyncThunk(
  'records/openEditRecordDialog',
  async (recordId, { dispatch, getState, rejectWithValue }) => {
    dispatch(selectRecord(recordId));

    let record = selectSelectedRecord(getState());

    if (!record) {
      rejectWithValue('record_not_found');
    }

    if (!record.billedClockInAt) {
      record = {
        ...record,
        billedClockInAt: record.clockInAt,
      };
    }
    if (!record.billedClockOutAt) {
      record = {
        ...record,
        billedClockOutAt: record.clockOutAt,
      };
    }

    const editableAttributes = {
      billedClockInAt: record.billedClockInAt,
      billedClockOutAt: record.billedClockOutAt,
      clockInAt: record.clockInAt,
      clockOutAt: record.clockOutAt,
      status: record.status,
      project: record.project,
      description: record.description,
      id: record.id,
      assignment: record.assignment,
      resource: record.resource,
      type: record.type,
      latIn: record.latIn,
      latOut: record.latOut,
      longIn: record.longIn,
      longOut: record.longOut,
      assignmentUnitAmount: record.assignmentUnitAmount,
    };

    dispatch(loadResourcesAndResourceEmployersOfProject(record.project));
    dispatch(loadAssignmentsForProject(record.project));
    dispatch(loadApproversByRecord(record.id));
    dispatch(
      getRecordsBreakRecords({
        projectId: record.project,
        parentRecordId: record.id,
      })
    );

    return editableAttributes;
  }
);
