import React from 'react';
import { imported } from 'react-imported-component';

import Loading from 'components/Loading/Loading';

const loadComponent = (component) => {
  if (!component) return;
  return imported(component, {
    LoadingComponent: () => <Loading />,
  });
};

export default loadComponent;
