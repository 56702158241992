import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEmployeeConfigurationsInBusiness } from '../services/getEmployeeConfigurationsInBusiness.service';
import { selectSelectedEmployersBusinessIds } from 'scenes/WorkDemo/store/selectors/workSearchParamsSelectors';
import {
  selectProjectsArray,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';

export const loadEmployeeConfigurationsInBusiness = createAsyncThunk(
  'users/loadEmployeeConfigurationsInBusiness',
  async (_, { getState }) => {
    const workCalendarSelectedBusinessIds = selectSelectedEmployersBusinessIds(
      getState()
    );

    const activeEmployerId = selectSelectedEmployer(getState());

    const projects = selectProjectsArray(getState());

    const selectedProject = selectSelectedProject(getState());

    const navigationActiveEmployer = projects.find(
      (project) => project.id === activeEmployerId
    );

    let businessId;

    if (workCalendarSelectedBusinessIds[0]) {
      businessId = workCalendarSelectedBusinessIds[0];
    } else if (navigationActiveEmployer) {
      businessId = navigationActiveEmployer.business_id;
    } else {
      businessId = selectedProject.business_id;
    }

    const employeeConfigurations = await getEmployeeConfigurationsInBusiness(
      businessId
    );

    return employeeConfigurations;
  }
);
