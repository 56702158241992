import { createAction } from '@reduxjs/toolkit';

export const setSelectedProjectResourceUserId = createAction(
  'projectResources/setSelectedProjectResourceUserId'
);

export const closeProjectResourcesView = createAction(
  'projectResources/closeProjectResourcesView'
);

export const setProjectResourcesEmployeeSearchString = createAction(
  'projectResources/setProjectResourcesEmployeeSearchString'
);
