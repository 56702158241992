import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Button from 'components/Button/Button';
import Icon from '@mdi/react';
import {
  mdiAlertOctagon,
  mdiHome,
  mdiRefresh,
  mdiRobotDeadOutline,
} from '@mdi/js';
import Typography from '@material-ui/core/Typography';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Icon path={mdiRobotDeadOutline} size={3} color="white" />
          <Typography variant="body1" style={{ color: 'white' }}>
            Something went wrong
          </Typography>
          <br />

          <button
            onClick={() => window.location.reload()}
            style={{
              padding: 10,
              border: 'none',
              background: 'none',
              borderBottom: '2px solid #fff',
              font: 'inherit',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 150,
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Icon
                path={mdiRefresh}
                size={1}
                color="white"
                style={{ marginRight: 5 }}
              />
              <Typography style={{ color: 'white' }}>Refresh</Typography>
            </div>
          </button>

          <br />

          <button
            onClick={() => window.location.assign('/')}
            style={{
              padding: 10,
              border: 'none',
              background: 'none',
              borderBottom: '2px solid #fff',
              font: 'inherit',
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 150,
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Icon
                path={mdiHome}
                size={1}
                color="white"
                style={{ marginRight: 5 }}
              />
              <Typography style={{ color: 'white' }}>To Home</Typography>
            </div>
          </button>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]),
};

export default ErrorBoundary;
