import { createSelector } from '@reduxjs/toolkit';

const selectNotificationsState = (state) => state.notifications;

export const selectNotificationsEntities = createSelector(
  [selectNotificationsState],
  (state) => state.notifications.entities
);

export const selectNotificationsArray = createSelector(
  [selectNotificationsEntities],
  (notificationEntities) => Object.values(notificationEntities)
);

export const selectBusinessNotificationsArray = createSelector(
  [selectNotificationsArray],
  (notifications) =>
    notifications.filter((notification) =>
      notification.notificationTarget.includes('BUSINESS_ID')
    )
);
