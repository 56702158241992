export const COMPETENCY_CARD_TYPES = {
  WORKSITE_CARD: { name: 'worksite_card', type: 'WORKSITE_CARD' },
  ELECTRICAL_SAFETY_CARD: {
    name: 'electrical_safety_card',
    type: 'ELECTRICAL_SAFETY_CARD',
  },
  OCCUPATIONAL_SAFETY_CARD: {
    name: 'occupational_safety_card',
    type: 'OCCUPATIONAL_SAFETY_CARD',
  },
  FORKLIFT_CARD: { name: 'forklift_card', type: 'FORKLIFT_CARD' },
  POWERED_ACCESS_LICENCE: {
    name: 'powered_access_licence',
    type: 'POWERED_ACCESS_LICENCE',
  },
  CRANE_CARD: { name: 'crane_card', type: 'CRANE_CARD' },
  HOT_WORK_SAFETY_CARD: {
    name: 'hot_work_safety_card',
    type: 'HOT_WORK_SAFETY_CARD',
  },
  ATEX_CARD: { name: 'atex_card', type: 'ATEX_CARD' },
  WATER_WORK_CARD: { name: 'water_work_card', type: 'WATER_WORK_CARD' },
  HYGIENE_PASSPORT: { name: 'hygiene_passport', type: 'HYGIENE_PASSPORT' },
  EMERGENCY_FIRST_AID_CARD: {
    name: 'emergency_first_aid_card',
    type: 'EMERGENCY_FIRST_AID_CARD',
  },
  FIRST_AID_EA_1_CARD: { name: 'first_aid_ea_1', type: 'FIRST_AID_EA_1_CARD' },
  FIRST_AID_EA_2_CARD: { name: 'first_aid_ea_2', type: 'FIRST_AID_EA_2_CARD' },
  ELECTICAL_WORK_EMERGENCY_FIRST_AID_CARD: {
    name: 'electrical_work_emergency_first_aid_card',
    type: 'ELECTICAL_WORK_EMERGENCY_FIRST_AID_CARD',
  },
  ROOF_HOT_WORK_SAFETY_CARD: {
    name: 'roof_hot_work_safety_card',
    type: 'ROOF_HOT_WORK_SAFETY_CARD',
  },
  PRESSURE_EQUIPMENT_OPERATION_SUPERVISOR_TRAINING: {
    name: 'pressure_equipment_operation_supervisor_training',
    type: 'PRESSURE_EQUIPMENT_OPERATION_SUPERVISOR_TRAINING',
  },
  WORK_INVOLVING_A_RISK_OF_FALLING: {
    name: 'work_involving_a_risk_of_falling',
    type: 'WORK_INVOLVING_A_RISK_OF_FALLING',
  },
  PROTECTIVE_EQUIPMENT_AND_THEIR_USE: {
    type: 'PROTECTIVE_EQUIPMENT_AND_THEIR_USE',
    name: 'protective_equipment_and_their_use',
  },
  TRACK_WORK_TRAINING: {
    type: 'TRACK_WORK_TRAINING',
    name: 'track_work_training',
  },
};
