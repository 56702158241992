import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getTimelineTasks } from './thunks/getTimelineTasks';
import { saveTimelineTasks } from './thunks/saveTimelineTasks';
import { getTimelines } from './thunks/getTimelines';
import { createNewTimeline } from './thunks/createNewTimeline';
import { updateTimeline } from './thunks/updateTimeline';
import { setTimelineAsDeleted } from './thunks/setTimelineAsDeleted';
import { updateTimelineSettings } from './thunks/updateTimelineSettings';

const tasksAdapter = createEntityAdapter();
const newTasksAdapter = createEntityAdapter();

const initialState = {
  timelines: [],
  selectedTimelineId: null,
  tasks: tasksAdapter.getInitialState(),
  newTasks: newTasksAdapter.getInitialState(),
  taskModifications: {},
  loadingTimelines: false,
  loadingTasks: false,
  linkedTasks: [],
  masterParentProjectLogo: undefined,
};

export const timelineSlice = createSlice({
  name: 'timelines',
  initialState,
  reducers: {
    addNewTask: (state, action) => {
      newTasksAdapter.addOne(state.newTasks, {
        ...action.payload,
        isVisible: true,
      });
    },
    modifyTask: (state, action) => {
      const { id, changes } = action.payload;

      if (state.tasks.ids.includes(id)) {
        state.taskModifications[id] = {
          ...(state.taskModifications[id] || {}),
          ...changes,
        };
      } else if (state.newTasks.ids.includes(id)) {
        newTasksAdapter.updateOne(state.newTasks, { id, changes });
      } else {
        console.error('Task not found for modification:', id);
      }
    },
    modifyMultipleTasksAtOnce: (state, action) => {
      action.payload.forEach((taskUpdate) => {
        const { id, changes } = taskUpdate;
        if (state.tasks.ids.includes(id)) {
          state.taskModifications[id] = {
            ...(state.taskModifications[id] || {}),
            ...changes,
          };
        } else if (state.newTasks.ids.includes(id)) {
          newTasksAdapter.updateOne(state.newTasks, { id, changes });
        } else {
          console.error('Task not found for modification:', id);
        }
      });
    },
    setAsDeleted: (state, action) => {
      const setAsDeletedRecursive = (taskId) => {
        if (state.newTasks.entities[taskId]) {
          newTasksAdapter.removeOne(state.newTasks, taskId);
        } else if (state.tasks.entities[taskId]) {
          state.taskModifications[taskId] = {
            ...(state.taskModifications[taskId] || {}),
            deletedAt: new Date().toISOString(),
          };
        }
        const childTasksInTasks = Object.values(state.tasks.entities).filter(
          (task) => task.parentId === taskId
        );
        const childTasksInNewTasks = Object.values(
          state.newTasks.entities
        ).filter((task) => task.parentId === taskId);

        const combinedChildTasks = [
          ...childTasksInTasks,
          ...childTasksInNewTasks,
        ];

        combinedChildTasks.forEach((childTask) =>
          setAsDeletedRecursive(childTask.id)
        );
      };

      setAsDeletedRecursive(action.payload);
    },

    clearModificationsAndNewTasks: (state) => {
      state.taskModifications = {};
      state.newTasks = newTasksAdapter.getInitialState();
    },
    setSelectedTimelineId: (state, action) => {
      state.selectedTimelineId = action.payload;
    },
    clearSelectedTimelineId: (state) => {
      state.selectedTimelineId = null;
    },
    addTaskToLinkedTasks: (state, action) => {
      state.linkedTasks.push(action.payload);
    },
    removeTaskFromLinkedTasks: (state, action) => {
      state.linkedTasks = state.linkedTasks.filter(
        (task) => task !== action.payload
      );
    },
    setMasterParentProjectLogo: (state, action) => {
      state.masterParentProjectLogo = action.payload;
    },
    toggleTaskVisibility: (state, action) => {
      const { taskId, isVisible } = action.payload;
      state.taskModifications[taskId] = {
        ...(state.taskModifications[taskId] || {}),
        isVisible: isVisible,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimelineTasks.pending, (state) => {
        state.loadingTasks = true;
      })
      .addCase(getTimelineTasks.fulfilled, (state, action) => {
        const visibleTasks = action.payload.map((task) => ({
          ...task,
          isVisible: true,
        }));
        tasksAdapter.setMany(state.tasks, visibleTasks);
        state.loadingTasks = false;
      })

      .addCase(getTimelineTasks.rejected, (state) => {
        state.loadingTasks = false;
      })
      .addCase(createNewTimeline.fulfilled, (state, action) => {
        state.timelines.push(action.payload);
      })
      .addCase(saveTimelineTasks.fulfilled, (state, action) => {
        const visibleTasks = action.payload.map((task) => ({
          ...task,
          isVisible: true,
        }));
        tasksAdapter.setMany(state.tasks, visibleTasks);

        state.newTasks = newTasksAdapter.getInitialState();
        state.taskModifications = {};
      })
      .addCase(saveTimelineTasks.rejected, (state, action) => {
        state.newTasks = newTasksAdapter.getInitialState();
        state.taskModifications = {};
      })
      .addCase(getTimelines.pending, (state) => {
        state.loadingTimelines = true;
      })
      .addCase(getTimelines.fulfilled, (state, action) => {
        state.loadingTimelines = false;
        state.timelines = action.payload;
      })
      .addCase(getTimelines.rejected, (state, action) => {
        state.loadingTimelines = false;
        console.error('Failed to fetch timelines:', action.payload);
      })
      .addCase(updateTimeline.fulfilled, (state, action) => {
        const index = state.timelines.findIndex(
          (timeline) => timeline.id === action.payload.id
        );
        if (index !== -1) {
          state.timelines[index] = {
            ...state.timelines[index],
            ...action.payload,
          };
        }
        state.loadingTimelines = false;
      })
      .addCase(updateTimelineSettings.fulfilled, (state, action) => {
        const index = state.timelines.findIndex(
          (timeline) => timeline.id === action.payload.id
        );
        if (index !== -1) {
          state.timelines[index] = {
            ...state.timelines[index],
            isAllTasksLinked: action.payload.isAllTasksLinked,
          };
        }
        state.loadingTimelines = false;
      })
      .addCase(setTimelineAsDeleted.fulfilled, (state, action) => {
        state.selectedTimelineId = null;

        state.timelines = state.timelines.filter(
          (timeline) => timeline.id !== action.payload.id
        );
      });
  },
});

export const {
  modifyTask,
  modifyMultipleTasksAtOnce,
  addNewTask,
  clearModificationsAndNewTasks,
  setSelectedTimelineId,
  clearSelectedTimelineId,
  setAsDeleted,
  addTaskToLinkedTasks,
  removeTaskFromLinkedTasks,
  setMasterParentProjectLogo,
  toggleTaskVisibility,
} = timelineSlice.actions;
export default timelineSlice.reducer;
