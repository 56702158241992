import { initSettings } from './initSettings';
import { fetchReportDataFromRecords } from './fetchReportDataFromRecordIds.util';
import { processData } from './processData.util';

const reports = require('./reports');

export const generateReportFromRecords = async (
  records,
  resources,
  assignments,
  users,
  generationParams,
  projects,
  selectedProjectId
) => {
  const project = projects.find((project) => project.id === selectedProjectId);

  const settings = initSettings(generationParams);

  let reportData = await fetchReportDataFromRecords(
    project,
    records,
    resources,
    projects,
    assignments,
    users,
    generationParams,
    settings
  );

  reportData = processData(reportData, settings);

  const tableRows = await reports[
    generationParams.selectedReportType
  ].createReport(reportData, settings, generationParams);

  const resp = {
    processedData: {
      reportType: reportData.reportType,
      resources: reportData.resources,
      fromDate: reportData.fromDate,
      toDate: reportData.toDate,
      dataRows: tableRows.dataRows,
      headerRows: tableRows.headerRows,
      userTotalWorkAmountsByUserId: tableRows.userTotalWorkAmountsByUserId,
    },
  };

  return resp;
};
