import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveAssignmentsForBusiness } from '../services/getActiveAssignmentsForBusiness';
import { getActiveProjectAssignments } from '../services/getActiveProjectAssignments';
import {
  selectSelectedProjectId,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import {
  setAllProjectAssignmentsLoaded,
  setAllProjectAssignmentsLoadedAmount,
} from 'scenes/ExpenseTracking/store/actions/expenses-tracking.actions';

export const loadActiveAssignmentsForBusiness = createAsyncThunk(
  'invoices/loadActiveAssignmentsForBusiness',
  async (params, { getState, dispatch }) => {
    const state = getState();


    const selectedProject = selectSelectedProject(state);
    const businessId = selectedProject?.business_id;

    let assignmentsByBusiness = [];

    let response;

    if (businessId) {
      do {
        response = await getActiveAssignmentsForBusiness(
          businessId,
          response?.LastEvaluatedKey
        );
        assignmentsByBusiness.push(...response.Items);

        dispatch(
          setAllProjectAssignmentsLoadedAmount(assignmentsByBusiness.length)
        );
      } while (response.LastEvaluatedKey);
    } else {
      console.log('No business id found, fetching project by project');
      const projects = params.projects;
      if (!projects) {
        console.log('No projects found');
        return;
      }
      for await (const project of projects) {
        const projectId = project.id;
        const response = await getActiveProjectAssignments(projectId);

        assignmentsByBusiness.push(...response);
        dispatch(
          setAllProjectAssignmentsLoadedAmount(assignmentsByBusiness.length)
        );
      }
    }

    dispatch(setAllProjectAssignmentsLoaded(true));

    return assignmentsByBusiness;
  }
);
