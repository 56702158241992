import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRecordAllowancesOfCurrentUserInTimeframe } from '../services/getRecordAllowancesOfCurrentUserInTimeframe.service';

export const loadRecordAllowancesOfCurrentUserInTimeframe = createAsyncThunk(
  'recordAllowances/loadRecordAllowancesOfCurrentUserInTimeframe',
  async (timeframe) => {
    const response = await getRecordAllowancesOfCurrentUserInTimeframe(
      timeframe
    );
    return response;
  }
);
