import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUserCards } from '../services/getCurrentUserCards.service';

export const loadCurrentUserCards = createAsyncThunk(
  'cards/loadCurrentUserCards',
  async () => {
    const response = await getCurrentUserCards();

    return response;
  }
);
