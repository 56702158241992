import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCreateRecordDialogAttributes } from 'store/records/actions/records.actions';
import { putCreateOrUpdateUser } from '../services/putCreateOrUpdateUser.service';

export const createOrUpdateUser = createAsyncThunk(
  'user/createOrUpdateUser',
  async (
    {
      userAttributes,
      projectKeyOfProjectToJoin,
      businessIdOfEmployerToJoinWith,
    },
    { rejectWithValue, dispatch }
  ) => {
    console.log(
      userAttributes,
      projectKeyOfProjectToJoin,
      businessIdOfEmployerToJoinWith
    );

    try {
      const { resource, employer } = await putCreateOrUpdateUser(
        userAttributes,
        projectKeyOfProjectToJoin,
        businessIdOfEmployerToJoinWith
      );
      dispatch(setCreateRecordDialogAttributes({ resource: resource.id }));

      return { resource, employer };
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
