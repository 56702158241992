Object.defineProperty(exports, '__esModule', {
  value: true,
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _SvgIcon = require('@material-ui/core/SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = function (props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', {
      d: 'M17.3,13.8c-0.7,0-1.3,0.2-1.9,0.6L12,11c0.5-0.6,0.8-1.4,0.9-2.2h2.4c0.3,1.2,1.4,2,2.6,2c1.5,0,2.7-1.2,2.7-2.8c0-1.5-1.2-2.8-2.7-2.8c-1.3,0-2.3,0.8-2.6,2h-2.4C12.6,4.8,10.5,3,8,3C5.3,3,3,5.3,3,8c0,2.5,1.8,4.6,4.2,4.9v2.4c-1.1,0.3-2,1.4-2,2.7c0,1.5,1.2,2.7,2.8,2.7c1.5,0,2.8-1.2,2.8-2.7c0-1.3-0.8-2.3-2-2.7v-2.4c0.8-0.1,1.6-0.4,2.2-0.9l3.4,3.4c-0.4,0.5-0.6,1.2-0.6,1.9c0,1.9,1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5S19.2,13.8,17.3,13.8zM18,6.8c0.7,0,1.2,0.6,1.2,1.3c0,0.7-0.6,1.3-1.2,1.3c-0.7,0-1.3-0.6-1.3-1.3C16.8,7.3,17.3,6.8,18,6.8z M9.3,18c0,0.7-0.6,1.2-1.3,1.2S6.8,18.7,6.8,18c0-0.7,0.6-1.3,1.3-1.3S9.3,17.3,9.3,18z M8,11c-1.6,0-3-1.3-3-3c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3C11,9.7,9.7,11,8,11z M17.3,19.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C19.2,18.3,18.4,19.2,17.3,19.2z',
    })
  );
};
