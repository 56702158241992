import { createSelector } from '@reduxjs/toolkit';
import { TAXNUMBER_STATUS } from 'LixaniAPI/enums';
import { selectProjectsThatUserHasResourceToOrIsCreatorOf } from 'store/projects/selectors/userProjects.selectors';

const selectRootState = (state) => state.root;

export const selectSelectedProject = createSelector(
  [selectRootState],
  (state) => state.project
);

export const selectCurrentUser = createSelector(
  [selectRootState],
  (state) => state.user
);
