import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const updateTimelineSettingsService = async (
  projectId,
  timelineId,
  { isAllTasksLinked }
) => {
  const host = process.env.REACT_APP_WORK_CALENDAR_API_URL;
  const apiPath = `timelines/project/${projectId}/timeline/${timelineId}/update-timeline-settings`;
  const request = {
    url: `https://${host}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    method: 'PATCH',
    host: host,
    path: apiPath,
    body: JSON.stringify({
      isAllTasksLinked,
    }),
    data: { isAllTasksLinked },
    headers: {
      'Content-Type': 'application/json',
    },
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);
  const response = await axios(signedRequest);
  return response.data;
};
