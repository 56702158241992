import { createSelector } from '@reduxjs/toolkit';

const selectCardsState = (state) => state.cards;

export const selectCards = createSelector(
  [selectCardsState],
  (state) => state.cards.entities
);

export const selectCardsArray = createSelector([selectCards], (cards) =>
  Object.values(cards)
);

export const selectCardsArrayWithoutDeleted = createSelector(
  [selectCardsArray],
  (cards) => cards.filter((card) => !card.deletedAt)
);

export const selectIsAddCardDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isAddCardDialogOpen
);

export const selectIsAddCardForOtherUserDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isAddCardForOtherUserDialogOpen
);

export const selectUserIdToAddCardFor = createSelector(
  [selectCardsState],
  (state) => state.userIdToAddCardFor
);

export const selectIsCardsLoading = createSelector(
  [selectCardsState],
  (state) => state.loading
);

export const selectIsAddingCard = createSelector(
  [selectCardsState],
  (state) => state.isAddingCard
);

export const selectExpiringEmployeeCards = createSelector(
  [selectCardsState],
  (state) => state.expiringEmployeeCards.entities
);

export const selectExpiringEmployeeCardsArray = createSelector(
  [selectExpiringEmployeeCards],
  (cards) => Object.values(cards)
);

export const selectCardIdToDelete = createSelector(
  [selectCardsState],
  (state) => state.cardIdToDelete
);

export const selectIsDeletingCard = createSelector(
  [selectCardsState],
  (state) => state.isDeletingCard
);

export const selectIsDeleteCardDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isDeleteCardDialogOpen
);

export const selectSelectedCardToDelete = createSelector(
  [selectCards, selectCardIdToDelete],
  (cards, selectedCardId) => cards[selectedCardId]
);
