import { createSelector } from '@reduxjs/toolkit';

import { orderBy } from 'lodash-es';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

const selectReportsState = (state) => state.reports;

export const selectGeneratedReport = createSelector(
  [selectReportsState],
  (state) => state.generatedReport
);

export const selectReportGeneratedFromMode = createSelector(
  [selectReportsState],
  (state) => state.reportGeneratedFromMode
);

export const selectReportProcessedData = createSelector(
  [selectReportsState],
  (state) => state.reportProcessedData
);

export const selectIsReportSendDialogOpen = createSelector(
  [selectReportsState],
  (state) => state.isReportSendDialogOpen
);

export const selectSalaryRowsRecipientEmails = createSelector(
  [selectReportsState],
  (state) => state.reportRecipientEmails
);

export const selectIsSendingSalaryData = createSelector(
  [selectReportsState],
  (state) => state.isSendingSalaryData
);

export const selectIsReportLoading = createSelector(
  [selectReportsState],
  (state) => state.loading
);

export const selectWeekIndexedEmployeeNamesToAdd = createSelector(
  [selectReportsState],
  (state) => state.weekIndexedEmployeeNamesToAdd
);
export const selectWeekIndexedDayWorkHoursOfEmployeeToAdd = createSelector(
  [selectReportsState],
  (state) => state.weekIndexedDayWorkHoursOfEmployeeToAdd
);

export const selectIsReportDraftsDialogOpen = createSelector(
  [selectReportsState],
  (state) => state.isReportDraftsDialogOpen
);

export const selectIsRowCommentDialogOpen = createSelector(
  [selectReportsState],
  (state) => state.isRowCommentDialogOpen
);

export const selectIsReportsHistoryOpen = createSelector(
  [selectReportsState],
  (state) => state.isReportsHistoryOpen
);

export const selectSavedReportsEntities = createSelector(
  [selectReportsState],
  (state) => state.savedReports.entities
);

export const selectReportUserRowTotals = createSelector(
  [selectReportsState],
  (state) => state.reportUserRowTotals
);

export const selectReportBasicData = createSelector(
  [selectReportsState],
  (state) => state.reportBasicData
);

export const selectIsRecordBasedReductionsActive = createSelector(
  [selectReportsState],
  (state) => state.isRecordBasedReductionsActive
);

export const selectSavedReportsArray = createSelector(
  [selectSavedReportsEntities],
  (entities) => Object.values(entities)
);

export const selectSavedReportsArrayWithoutDeleted = createSelector(
  [selectSavedReportsArray],
  (reports) => reports.filter((report) => !report.deletedAt)
);

export const selectSelectedProjectSavedReports = createSelector(
  [selectSavedReportsArrayWithoutDeleted, selectSelectedProjectId],
  (savedReports, selectedProjectId) => {
    const selectedProjectReports = savedReports.filter(
      (report) => report.projectId === selectedProjectId
    );

    return selectedProjectReports;
  }
);

export const selectSelectedProjectSavedSentReports = createSelector(
  [selectSelectedProjectSavedReports],
  (savedReports) => {
    const sentReports = savedReports.filter(
      (report) => report.state === 'SENT'
    );

    const orderedReportsByCreatedAt = orderBy(
      sentReports,
      [(report) => report.createdAt],
      ['desc']
    );
    return orderedReportsByCreatedAt;
  }
);

export const selectSelectedProjectSavedReportDrafts = createSelector(
  [selectSelectedProjectSavedReports],
  (savedReports) => {
    const reportDrafts = savedReports.filter(
      (report) => report.state === 'DRAFT'
    );

    const orderedReportsByCreatedAt = orderBy(
      reportDrafts,
      [(report) => report.createdAt],
      ['desc']
    );

    return orderedReportsByCreatedAt;
  }
);

export const selectIsAdditionalSalaryTypeDialogOpen = createSelector(
  [selectReportsState],
  (state) => state.isAdditionalSalaryRowDialogOpen
);

export const selectSelectedRowCommentData = createSelector(
  [selectReportsState],
  (state) => state.selectedRowCommentData
);

export const selectRowComments = createSelector(
  [selectReportsState],
  (state) => state.rowComments
);

export const selectSelectedReportDraftId = createSelector(
  [selectReportsState],
  (state) => state.selectedReportDraftId
);

export const selectSelectedReportDraft = createSelector(
  [selectSavedReportsEntities, selectSelectedReportDraftId],
  (reports, selectedDraftId) => reports[selectedDraftId]
);

export const selectSelectedRowComment = createSelector(
  [selectRowComments, selectSelectedRowCommentData],
  (rowComments, rowCommentData) => {
    const rowCommentId = rowCommentData.salaryType
      ? `${rowCommentData.userId}-${rowCommentData.salaryType}`
      : rowCommentData.userId;
    return rowComments[rowCommentId];
  }
);

export const selectAdditionalUserSalaryRows = createSelector(
  [selectReportsState],
  (state) => state.additionalUserSalaryRows
);

export const selectAdditionalUserSalaryRowDialogData = createSelector(
  [selectReportsState],
  (state) => state.additionalUserSalaryRowDialogData
);

export const selectCurrentOpenHistoryReportIndex = createSelector(
  [selectReportsState],
  (state) => state.currentOpenHistoryReportIndex
);

export const selectCurrentSelectedSavedReportData = createSelector(
  [selectSelectedProjectSavedSentReports, selectCurrentOpenHistoryReportIndex],
  (reports, currentOpenIndex) => ({
    report: reports[currentOpenIndex],
    isPreviousReport: reports[currentOpenIndex + 1] ? true : false,
    isNextReport: reports[currentOpenIndex - 1] ? true : false,
  })
);

export const selectReportPreviewWeeklyPeriodTableData = createSelector(
  [selectReportsState],
  (state) => state.tableDataByWeekPeriod
);

export const selectIsConfirmReportDraftDeleteDialogOpen = createSelector(
  [selectReportsState],
  (state) => state.isConfirmReportDraftDeleteDialogOpen
);

export const selectIsDeletingReportDraft = createSelector(
  [selectReportsState],
  (state) => state.isDeletingReportDraft
);
