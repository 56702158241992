import aws4 from 'aws4';

export const createSignedRequest = async (
  request,
  accessKeyId,
  secretAccessKey,
  sessionToken
) => {
  const signer = new aws4.RequestSigner(request, {
    accessKeyId,
    secretAccessKey,
    sessionToken,
  });
  const signedRequest = signer.sign();
  signedRequest.headers.Accept = '*/*';

  delete signedRequest.headers['Host'];
  delete signedRequest.headers['Content-Length'];

  return signedRequest;
};
