/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from 'components/DialogWrapper/DialogWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Divider from '@material-ui/core/Divider';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MapIcon from 'mdi-material-ui/MapClockOutline';

import Button from 'components/Button/Button';
import DialogHeader from 'components/DialogHeader/DialogHeader';

import useStyles from './MapDialogLayoutStyles';

import { update_store } from 'store/actions/update_store';

import mapMarkerIn from './components/map-marker-account.svg';
import mapMarkerOut from './components/map-marker-account-outline.svg';
import mapMarkerProject from './components/map-marker.svg';
import mapMarkerHome from './components/home-circle.svg';

import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Map from './components/Map';

import uuidv4 from 'uuid/v4';

import Icon from '@mdi/react';
import {
  mdiMap
} from '@mdi/js';

function MapDialogLayout(props) {
  const { projects, records, resources, onClose, mapType, addresses } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('common');
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  /**
   * getCoordsFromAddress turns address Strings into lat/lng coordinates
   * @param {String} street1 
   * @param {String} street2 
   * @param {String} postcode 
   * @param {String} city 
   */
  const getCoordsFromAddress = async (street1, street2, postcode, city) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${street1}%20${street2}%20${postcode}%20${city}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    ).then((res) => res.json());
    return response.results[0].geometry.location.lat
  };

  /**
   * setMarkers turns all given records, projects, and (soon) addresses into markers on the map
   * @returns 
   */
  const setMarkers = () => {
    let markers = [];
    if (records) {
      records.forEach((record) => {
        record.latIn &&
          markers.push({
            id: uuidv4(),
            desc: `${record.resource.firstName} ${record.resource.lastName} ${t(
              'location.logged_in'
            )} ${moment(record.clockInAt).format('MMMM Do YYYY, h:mm:ss')}`,
            position: {
              lat: Number(record.latIn),
              lng: Number(record.longIn),
            },
            icon: {
              url: mapMarkerIn,
              anchor: new window.google.maps.Point(30, 72),
              scaledSize: new window.google.maps.Size(60, 85),
            },
          });
        record.latOut &&
          markers.push({
            id: uuidv4(),
            desc: `${record.resource.firstName} ${record.resource.lastName} ${t(
              'location.logged_out'
            )} ${moment(record.clockOutAt).format('MMMM Do YYYY, h:mm:ss')}`,
            position: {
              lat: Number(record.latOut),
              lng: Number(record.longOut),
            },
            icon: {
              url: mapMarkerOut,
              anchor: new window.google.maps.Point(30, 72),
              scaledSize: new window.google.maps.Size(60, 85),
            },
          });
      });
    }
    if (projects) {
      projects.forEach((project) => {
        project.latitude &&
          markers.push({
            id: project.id,
            desc: project.projectKey + ' - ' + project.name,
            address: `${project.street1 || ''} ${project.street2 || ''} ${
              project.postcode || ''
            } ${project.city || ''}`,
            position: {
              lat: Number(project.latitude),
              lng: Number(project.longitude),
            },
            icon: {
              url: mapMarkerProject,
              anchor: new window.google.maps.Point(30, 72),
              scaledSize: new window.google.maps.Size(60, 85),
            },
          });
      });
    }
    if (resources) {
      resources.forEach((resource) => {
        resource.latitude &&
          markers.push({
            id: resource.id,
            desc: `${resource.firstName} ${resource.lastName} - Koti`,
            address: `${resource.street1 || ''} ${resource.street2 || ''} ${resource.postcode || ''
              } ${resource.city || ''}`,
            position: {
              lat: Number(resource.latitude),
              lng: Number(resource.longitude),
            },
            icon: {
              url: mapMarkerHome,
              anchor: new window.google.maps.Point(30, 30),
              scaledSize: new window.google.maps.Size(60, 60),
            },
          });
      });
    }
    console.log("markers", markers)
    return markers;
  };

  /**
   * setCircles turns all given projects into (soon) varying sized circles on the map,
   * which can (later) be used to detect whether user logged in/out inside the circle or not
   * @returns 
   */
  const setCircles = () => {
    let circles = [];
    if (projects) {
      projects.forEach((project) => {
        project.latitude &&
          circles.push({
            id: project.id,
            desc: project.projectKey + ' - ' + project.name,
            options: {
              strokeColor: '#2596be',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#2596be',
              fillOpacity: 0.35,
              clickable: false,
              draggable: true,
              editable: false,
              visible: true,
              radius: 100,
              zIndex: 1,
            },
            center: {
              lat: Number(project.latitude),
              lng: Number(project.longitude),
            },
          });
      });
    }
    return circles;
  };

  console.log('projects', projects);
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Dialog
      aria-labelledby="contact-edit-dialog"
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
      fullScreen={!upSm}
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={onClose}
    >
      <DialogHeader
        icon={
          <Icon
            path={mdiMap}
            size={1}
            className={classes.icon}
          />
        }
        onClose={onClose}
        title={t('location.map')}
      />

      <DialogContent className={classes.dialogContent}>
        {isLoaded && <Map markers={setMarkers()} circles={setCircles()} mapType={mapType} />}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.actionBtn}
          onClick={onClose}
          outline
          color="primary"
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MapDialogLayout.defaultProps = {};

MapDialogLayout.propTypes = {
  onClose: PropTypes.func.isRequired,
  records: PropTypes.array,
  projects: PropTypes.array,
  addresses: PropTypes.array,
  mapType: PropTypes.string,
};

export default withApollo(MapDialogLayout);
