import { createAsyncThunk } from '@reduxjs/toolkit';
import postRecord from '../services/postRecord';
import getCredentials from '../../../scenes/WorkDemo/utils/getCredentials';
import { selectCreateRecordDialogData } from '../selectors/records.selectors';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
export const createRecord = createAsyncThunk(
  'recordDialog/createRecord',
  async (_, { getState }) => {
    const mirrorUser = selectLoggedInUserId(getState());

    let recordData = selectCreateRecordDialogData(getState());

    recordData = { ...recordData, mirrorUser };

    if (recordData.status === 'IN') {
      recordData = {
        ...recordData,
        clockOutAt: undefined,
        billedClockOutAt: undefined,
      };
    }

    const response = await postRecord(recordData);
    return { response };
  }
);
