import { createAsyncThunk } from '@reduxjs/toolkit';
import getTranslation from '../services/getTranslation.service';
import { selectUserSettings } from 'store/user/selectors.js/user.selectors';

export const loadTranslation = createAsyncThunk(
  'translations/loadTranslation',
  async (
    { targetId, textToTranslate, sourceLanguageCode, targetLanguageCode },
    { getState }
  ) => {
    const state = getState();

    const userSettings = selectUserSettings(state);

    const userLocale = userSettings.locale;

    const languageCode = targetLanguageCode ? targetLanguageCode : userLocale;

    const translations = state.translations.translationsByLanguageCode;

    if (translations[languageCode] && translations[languageCode][targetId]) {
      console.log('translation already fetched');
      return {
        targetId,
        translatedText: translations[languageCode][targetId],
        targetLanguageCode: languageCode,
      };
    }

    const response = await getTranslation({
      targetId,
      textToTranslate,
      sourceLanguageCode,
      targetLanguageCode: languageCode,
    });

    return response;
  }
);
