import React from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Badge from '@material-ui/core/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AccountIcon from 'mdi-material-ui/AccountOutline';
import BankIcon from 'mdi-material-ui/BankOutline';
import CalendarCheckIcon from 'mdi-material-ui/CalendarCheck';
import CalendarIcon from 'mdi-material-ui/Calendar';
import HomeIcon from 'mdi-material-ui/HomeOutline';
import InfoIcon from 'mdi-material-ui/InformationOutline';
import LinkIcon from 'mdi-material-ui/LinkVariant';
import LogoutIcon from 'mdi-material-ui/ExitToApp';
import ProjectIcon from 'components/LightIcons/Project2Light';
//import EmailIcon from 'mdi-material-ui/EmailOutline';
//import PlusIcon from 'mdi-material-ui/Plus';
//import ProjectBankIcon from 'components/LightIcons/ProjectLight';
import SettingsIcon from 'mdi-material-ui/Cog';
//import UserSettingsIcon from 'mdi-material-ui/AccountMultipleOutline';

import NavItemsProfileImage from './NavItemsProfileImage';
import { selectTaxNumberMissing } from 'store/projects/selectors/userProjects.selectors';
import { selectIsExpiringCurrentUserCards } from 'store/cards/selectors/user-cards.selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 240,
  },
  profileButton: {
    background: theme.palette.primary.dark,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    width: '100%',
    maxWidth: 240,
  },
  profileName: {
    alignItems: 'center',
    display: 'inline-flex',
    flex: 1,
    flexDirection: 'column',
  },
  profileNameText: {
    color: `${theme.palette.textWhite.secondary} !important`,
  },
  profilePhoneText: {
    color: `${theme.palette.textWhite.secondary} !important`,
  },
  navIcon: {
    ...theme.component.navIcon,
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  navItem: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    background: theme.palette.primary.darker,
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '&.active': {
      background: theme.palette.primary.light,
    },
  },
  navText: {
    ...theme.typography.fontPanton,
    fontSize: '0.87rem',
  },
}));

function NavItems(props) {
  const { onCloseMenu, client } = props;
  const classes = useStyles();

  const user = useSelector((state) => state.root.user);
  const resource = user ? user.resource : {};

  const logOutWithResetCache = (client) => {
    console.log('Logging out. Resetting apollo client');
    localStorage.clear();
    localStorage.removeItem('persist:root');

    client.resetStore();

    onCloseMenu();
  };

  const taxNumberMissing = useSelector(selectTaxNumberMissing);

  const isExpiringUserCards = useSelector(selectIsExpiringCurrentUserCards);

  return (
    <Translation ns={['common']}>
      {(t) => (
        <div className={classes.root}>
          <ButtonBase
            className={classes.profileButton}
            component={NavLink}
            to="/settings/1"
            exact
          >
            <NavItemsProfileImage
              fileObject={resource ? resource.picture : null}
              resource={resource}
              user={user}
            />
            <span className={classes.profileName}>
              <Typography variant="body2" className={classes.profileNameText}>
                {resource.firstName
                  ? `${resource.firstName} ${resource.lastName}`
                  : ''}
              </Typography>
              <Typography
                variant="caption"
                className={classes.profilePhoneText}
              >
                {resource.phone ? resource.phone : ''}
              </Typography>
            </span>
          </ButtonBase>
          <List className={classes.list} dense disablePadding>
            <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              exact
              to="/"
            >
              <ListItemIcon className={classes.navIcon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary="MyLixani"
              />
            </ListItem>
            {/* <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              exact
              to="/settings/1"
              isActive={() => false}
            >
              <ListItemIcon className={classes.navIcon}>
                <Badge
                  color="secondary"
                  invisible={!taxNumberMissing}
                  variant="dot"
                >
                  <AccountIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.profile')}
              />
            </ListItem> */}
            {/*
          <ListItem
            button
            onClick={onCloseMenu}
            className={classes.navItem}
            component={NavLink}
            disableGutters
            to="/email"
          >

            <ListItemIcon className={classes.navIcon}>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.navText,
              }}
              primary={t('navigation.email')}
            />
          </ListItem>
          */}

            <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/projects"
            >
              <ListItemIcon className={classes.navIcon}>
                <ProjectIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.projects')}
              />
            </ListItem>

            <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/project/join"
            >
              <ListItemIcon className={classes.navIcon}>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('dashboard.join_project')}
              />
            </ListItem>

            {/* <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/calendar"
            >
              <ListItemIcon className={classes.navIcon}>
                <CalendarIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.calendar')}
              />
            </ListItem> */}

            {/*
          <ListItem
            button
            onClick={onCloseMenu}
            className={classes.navItem}
            component={NavLink}
            disableGutters
            to="/usercontrol"
          >
            <ListItemIcon className={classes.navIcon}>
              <UserSettingsIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.navText,
              }}
              primary={t('navigation.user_control')}
            />
          </ListItem>
          */}
            {/*
          <ListItem
            button
            onClick={onCloseMenu}
            className={classes.navItem}
            component={NavLink}
            disableGutters
            to="/projectbank"
          >
            <ListItemIcon className={classes.navIcon}>
              <ProjectBankIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.navText,
              }}
              primary={t('navigation.project_bank')}
            />
          </ListItem>
          */}
            {/*
          <ListItem
            button
            onClick={onCloseMenu}
            className={classes.navItem}
            component={NavLink}
            disableGutters
            to="/addapp"
          >
            <ListItemIcon className={classes.navIcon}>
              <PlusIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.navText,
              }}
              primary={t('navigation.add_app')}
            />
          </ListItem>
          */}
            {/* <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/bank"
            >
              <ListItemIcon className={classes.navIcon}>
                <BankIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.project_bank')}
              />
            </ListItem> */}
            <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/settings"
            >
              <ListItemIcon className={classes.navIcon}>
                <Badge
                  color="secondary"
                  overlap="rectangular"
                  invisible={!taxNumberMissing && !isExpiringUserCards}
                  variant="dot"
                >
                  <SettingsIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.settings')}
              />
            </ListItem>
            <ListItem
              button
              onClick={onCloseMenu}
              className={classes.navItem}
              component={NavLink}
              disableGutters
              to="/info"
            >
              <ListItemIcon className={classes.navIcon}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('dashboard.info')}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => logOutWithResetCache(client)}
              className={classes.navItem}
              component={'a'}
              disableGutters
              href={Config.SSO_LOGOUT_URL + encodeURIComponent(window.location)}
            >
              <ListItemIcon className={classes.navIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.navText,
                }}
                primary={t('navigation.logout')}
              />
            </ListItem>
          </List>
        </div>
      )}
    </Translation>
  );
}

NavItems.propTypes = {
  client: PropTypes.object.isRequired,
  location: PropTypes.object,
  onCloseMenu: PropTypes.func,
  user: PropTypes.object,
};

export default NavItems;
