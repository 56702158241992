import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTimelineAsDeletedService } from 'scenes/Timelines/services/setTimelineAsDeleted.service';

import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const setTimelineAsDeleted = createAsyncThunk(
  'timelines/setTimelineAsDeleted',
  async (timelineId, { getState, rejectWithValue }) => {
    const state = getState();
    const projectId = selectSelectedProjectId(state);
    try {
      const response = await setTimelineAsDeletedService(projectId, timelineId);
      return response;
    } catch (error) {
      console.error('Failed to update timeline:', error);
      return rejectWithValue(error.response.data);
    }
  }
);
