import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/selectors/root.selectors';
import { patchProcountorDimension } from '../services/patchProcountorDimension.service';

export const saveProcountorDimension = createAsyncThunk(
  'project/saveProcountorDimension',
  async (dimensionId, { getState }) => {
    const state = getState();
    const selectedProject = selectSelectedProject(state);

    const response = await patchProcountorDimension(
      selectedProject.id,
      dimensionId
    );
    return { ...response, projectId: selectedProject.id };
  }
);
