import { createAsyncThunk } from '@reduxjs/toolkit';
import { createProjectRole } from 'scenes/Permissions/services/roles/postCreateProjectRole.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const createProjectRoleThunk = createAsyncThunk(
  'permissionsManagement/createProjectRole',
  async ({ roleName, permissions, createSettings }, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const role = {
      projectId,
      roleName,
      permissions,
    };

    const response = await createProjectRole(role, createSettings);

    return response;
  }
);
