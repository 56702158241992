import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedRecord } from 'store/records/selectors/records.selectors';
import { calculateRecordsTotalTimesInSeconds } from './utils/calculateRecordsTotalTimesInSeconds';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import { formatTimeFromSeconds } from './utils/formatTimeFromSeconds';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectPersonalRecordsFromToday } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';
const useStyles = makeStyles((theme) => ({}));

export function WorkTimes() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const recordsFromToday = useSelector(selectPersonalRecordsFromToday);

  const selectedRecord = useSelector(selectSelectedRecord);

  const workRecords = recordsFromToday.filter(
    (record) =>
      record.type === RECORD_TYPE.WORK ||
      record.type === RECORD_TYPE.PROJECTWORK
  );

  const breakRecords = recordsFromToday.filter((record) =>
    [
      RECORD_TYPE.BREAK,
      RECORD_TYPE.BREAK_COFFEE,
      RECORD_TYPE.BREAK_MEAL,
    ].includes(record.type)
  );

  const [workTimeInSeconds, setWorkTimeInSeconds] = useState(
    calculateRecordsTotalTimesInSeconds(workRecords)
  );

  const [breakTimesInSeconds, setBreakTimesInSeconds] = useState(
    calculateRecordsTotalTimesInSeconds(breakRecords)
  );

  const paidBreakTime = (breakRecords, projectBreakRules) => {
    // calculate projects paid break time
    // prework before we get employer/project specific rules

    // add here the real limits
    const limitsByTypes = {
      [RECORD_TYPE.BREAK_COFFEE]: 12,
      [RECORD_TYPE.BREAK_MEAL]: 30,
      [RECORD_TYPE.BREAK]: 5,
    };

    let totalPaidTimeInMinutes = 0;

    breakRecords.forEach((breakRecord) => {
      const limitByType = limitsByTypes[breakRecord.type];

      const clockIn = moment(breakRecord.clockInAt).startOf('minute');
      const clockOut = moment(breakRecord.clockOutAt).startOf('minute');

      const diff = clockOut.diff(clockIn, 'minutes');

      if (diff > limitByType) {
        totalPaidTimeInMinutes += limitByType;
      } else {
        totalPaidTimeInMinutes += diff;
      }
    });
    return totalPaidTimeInMinutes;
  };

  const selectedRecordBreaks = breakRecords.filter(
    (breakRecord) => breakRecord.parentRecordId === selectedRecord.id
  );

  const [selectedRecordWorkTimeInSeconds, setSelectedRecordTimeInSeconds] =
    useState(calculateRecordsTotalTimesInSeconds([selectedRecord]));
  const [selectedRecordBreakTimeInSeconds, setSelectedBreakTimeInSeconds] =
    useState(calculateRecordsTotalTimesInSeconds(selectedRecordBreaks));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const workSeconds = calculateRecordsTotalTimesInSeconds(workRecords);
      const breakSeconds = calculateRecordsTotalTimesInSeconds(breakRecords);

      const selectedRecordWorkSeconds = calculateRecordsTotalTimesInSeconds([
        selectedRecord,
      ]);

      const selectedRecordBreakSeconds =
        calculateRecordsTotalTimesInSeconds(selectedRecordBreaks);

      setWorkTimeInSeconds(workSeconds);
      setBreakTimesInSeconds(breakSeconds);
      setSelectedRecordTimeInSeconds(selectedRecordWorkSeconds);
      setSelectedBreakTimeInSeconds(selectedRecordBreakSeconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight="bold">
          {t('common.work_time')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('record.clocked_time')}:`}
          </Grid>
          <Grid item xs={4}>
            {formatTimeFromSeconds(selectedRecordWorkTimeInSeconds)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('calendar.breaks')}:`}
          </Grid>
          <Grid item xs={4}>
            {formatTimeFromSeconds(selectedRecordBreakTimeInSeconds)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('record.clocked_time_total')}:`}
          </Grid>
          <Grid item xs={4}>
            {formatTimeFromSeconds(workTimeInSeconds)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('record.break_time_total')}:`}
          </Grid>
          <Grid item xs={4}>
            {formatTimeFromSeconds(breakTimesInSeconds)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
