import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApproversByGroupCode } from '../services/getApproversByGroupCode.service';

export const loadApproversByGroupCode = createAsyncThunk(
  'approvals/load-approvers-by-group-code',
  async (groupCode) => {
    const response = await getApproversByGroupCode(groupCode);
    return response;
  }
);
