import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectRecordsFromToday } from '../services/getProjectRecordsFromToday.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectRecordsFromToday = createAsyncThunk(
  'records/loadProjectRecordsFromToday',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const records = await getProjectRecordsFromToday(selectedProjectId);

    return records;
  }
);
