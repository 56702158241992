import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setProjectFormTemplate from 'scenes/ProjectForms/utils/setProjectFormTemplate';

export const createFormTemplate = createAsyncThunk(
  'projectForm/createFormTemplate',
  async (projectForm, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdFormTemplate = await setProjectFormTemplate(projectForm, params);
    return { createdFormTemplate };
  }
);
