import { createAsyncThunk } from '@reduxjs/toolkit';
import returnProjectIdAndItsChildProjectIds from 'scenes/WorkDemo/utils/returnProjectIdAndItsChildProjectIds';
import { createSignedRequest } from '../../utils/createSignedRequest';
import axios from 'axios';
import getCredentials from '../../utils/getCredentials';
import { createRequestGetInitialWorkCalendarDataForProject } from 'scenes/WorkDemo/utils/requests/createRequestGetInitialWorkCalendarDataForProject';
import {
  selectProjects,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { loadUsersAndPersonalResources } from 'store/projectUsers/thunks/loadUsersAndPersonalResources';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { getAdminWorkCalendarDataFromExternalProject } from 'scenes/WorkDemo/services/getAdminWorkCalendarDataFromExternalProject.service';
import { loadRecordAllowancesOfCurrentUserInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfCurrentUserInTimeframe.thunk';
import { loadRecordAllowancesOfProjectInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfProjectInTimeframe.thunk';

export const loadInitialCalendarData = createAsyncThunk(
  'workCalendar/loadInitialCalendarData',
  async (_, { getState, dispatch }) => {
    const { workSearchParams_demo } = getState();

    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projects = selectProjects(state);

    const projectsArray = Object.values(projects);

    const userAdminBusinessIds = selectUserAdminBusinessIds(getState());

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    let adminSearchData = {
      resources: [],
      records: [],
      filePermissions: [],
      employers: [],
    };

    const { fromDate, toDate } = workSearchParams_demo;

    if (selectedProjectId) {
      if (userAdminBusinessIds && userAdminBusinessIds.length > 0) {
        try {
          adminSearchData = await getAdminWorkCalendarDataFromExternalProject(
            selectedProjectId,
            fromDate,
            toDate
          );
          dispatch(
            loadRecordAllowancesOfProjectInTimeframe({ fromDate, toDate })
          );
        } catch (error) {
          console.log('Error occured with admin search');
        }
      } else {
        // normal employee search for own allowances
        // to optimize data amounts in the future this could be project-date search, where other than user's own record allowances are filtered out

        dispatch(
          loadRecordAllowancesOfCurrentUserInTimeframe({ fromDate, toDate })
        );
      }

      const selectedProject = projects[selectedProjectId];

      let projectsToSearch = [];

      if (selectedProject.businessTypeEnum === 'EMPLOYER') {
        const projectsOfBusiness = projectsArray.filter(
          (project) => project.business_id === selectedProject.business_id
        );

        const projectIds = projectsOfBusiness.map((project) => project.id);

        projectsToSearch = projectIds;
      } else {
        projectsToSearch = returnProjectIdAndItsChildProjectIds(
          [selectedProjectId],
          projectsArray
        );
      }

      const requests = await Promise.all(
        projectsToSearch.map((projectId) => {
          const request = createRequestGetInitialWorkCalendarDataForProject(
            projectId,
            fromDate,
            toDate
          );
          return createSignedRequest(
            request,
            accessKeyId,
            secretAccessKey,
            sessionToken
          );
        })
      );

      const projectAndChildProjects = projectsArray.filter(
        (project) =>
          project && project.id && projectsToSearch.includes(project.id)
      );

      const data = await Promise.all(
        requests.map((request) => axios(request).then((res) => res.data))
      );

      const resources = data
        .map((projectData) => projectData.resources)
        .flat()
        .filter((resource) => resource && resource.id);

      const resourcesIncludingAdminSearchResources = [
        ...resources,
        ...adminSearchData.resources,
      ];

      if (
        resourcesIncludingAdminSearchResources &&
        resourcesIncludingAdminSearchResources.length > 0
      ) {
        dispatch(
          loadUsersAndPersonalResources(resourcesIncludingAdminSearchResources)
        );
      }

      const filePermissions = data
        .map((projectData) => projectData.filePermissions)
        .flat()
        .filter((filePermission) => filePermission && filePermission.id);

      const assignments = data
        .map((projectData) => projectData.assignments)
        .flat()
        .filter((a) => a && a.id);

      const employers = data
        .map((projectData) => projectData.employers)
        .flat()
        .filter((employer) => employer && employer.id);

      const records = data
        .map((projectData) => projectData.records)
        .flat()
        .filter((record) => record && record.id);

      const recordsIncludingAdminSearchRecords = [
        ...records,
        ...adminSearchData.records,
      ];

      const employersIncludingAdminSearchEmployers = [
        ...employers,
        ...adminSearchData.employers,
      ];

      return {
        projectId: selectedProjectId,
        resources: resourcesIncludingAdminSearchResources,
        assignments: assignments,
        employers: employersIncludingAdminSearchEmployers,
        filePermissions: filePermissions,
        projects: projectAndChildProjects,
        records: recordsIncludingAdminSearchRecords,
      };
    } else {
      return;
    }
  }
);
