import Config from 'Config';
import aws4 from 'aws4';
import getCredentials from 'utils/getCredentials';

export default async function getProject(projectId, params) {
  const apiPath = `projects/${projectId}`;

  if (params === undefined) {
    const { accessKeyId, secretAccessKey, sessionToken } = await getCredentials();
    params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
  }

  const request = {
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const signer = new aws4.RequestSigner(request, {
    accessKeyId: params.accessKeyId,
    secretAccessKey: params.secretAccessKey,
    sessionToken: params.sessionToken,
  });
  const signedRequest = signer.sign();
  signedRequest.headers.Accept = '*/*';

  const response = await fetch(
    `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    signedRequest
  ).then((res) => res.json());
  return response;
}
