import { createAsyncThunk } from '@reduxjs/toolkit';
import patchApproveApprovalGroup from '../services/patchApproveApprovalGroup.service';

export const approveApprovalGroup = createAsyncThunk(
  'records/approveApprovalGroup',
  async ({ projectId, approvalCode }) => {
    const { approver, approvalGroup, records } =
      await patchApproveApprovalGroup(projectId, approvalCode);

    return { approver, approvalGroup, records };
  }
);
