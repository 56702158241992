import { createAsyncThunk } from '@reduxjs/toolkit';

export const getGeolocation = createAsyncThunk(
  'records/getGeolocation',
  async (_, { rejectWithValue }) => {
    const getNavigatorGeolocation = () => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(
            new Error({ code: 3, message: 'Geolocation fetching timed out' })
          );
        }, 10000);

        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              };
              resolve(coords);
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject(
            new Error({
              code: 4,
              message: 'Geolocation is not supported by your browser',
            })
          );
        }
      });
    };

    try {
      const coords = await getNavigatorGeolocation();
      return coords;
    } catch (error) {
      return rejectWithValue({ code: error.code, message: error.message });
    }
  }
);
