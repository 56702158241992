import { createAction } from '@reduxjs/toolkit';

export const modifyAssignment = createAction('projectEdit/modifyAssignment');

export const closeProjectAssignmentsView = createAction(
  'assignments/closeProjectAssignmentsView'
);

export const removeAssignmentAndItsChildAssignmentsToAdd = createAction(
  'assignments/removeAssignmentAndItsChildAssignmentsToAdd'
);

export const removeRootAssignmentToAdd = createAction(
  'assignments/removeRootAssignmentToAdd'
);

export const removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject =
  createAction(
    'assignments/removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject'
  );
