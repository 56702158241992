import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RECORD_TYPE } from 'scenes/WorkDemo/utils/report-generation/enums';

export default function WorkTypeSelect({ type, handleTypeSelect, disabled }) {
  const { t } = useTranslation('common');

  return (
    <FormControl>
      <RadioGroup
        row
        name="work-type-select"
        onChange={(e) => handleTypeSelect(e.target.value)}
        value={type}
      >
        <FormControlLabel
          value={RECORD_TYPE.PROJECTWORK}
          control={<Radio />}
          disabled={disabled}
          label={
            <Typography variant="body2">
              {t('record.type.projectWork')}
            </Typography>
          }
        />
        <FormControlLabel
          value={RECORD_TYPE.WORK}
          disabled={disabled}
          control={<Radio />}
          label={
            <Typography variant="body2">{t('record.type.work')}</Typography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
