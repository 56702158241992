import { createSlice } from '@reduxjs/toolkit';
import { addToBreadcrumbs } from './actions/addToBreadcrumbs';
import { clearBreadcrumbs } from './actions/clearBreadcrumbs';
import { deleteFromBreadcrumbs } from './actions/deleteFromBreadcrumbs';
import { setSelectedEmployer } from './actions/setSelectedEmployer';

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    path: [],
    selectedEmployer: '',
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addToBreadcrumbs, (state, action) => {
        state.path = [...state.path].includes(action.payload)
          ? [...state.path]
          : [...state.path, action.payload];
      })
      .addCase(deleteFromBreadcrumbs, (state, action) => {
        state.path = [...state.path].filter((key) => key !== action.payload);
      })
      .addCase(clearBreadcrumbs, (state, action) => {
        state.path = [];
      })
      .addCase(setSelectedEmployer, (state, action) => {
        state.selectedEmployer = action.payload;
      });
  },
});

export default breadcrumbsSlice.reducer;
