const resolvers = () => ({
  RecordCreate: recordResolvers(),
  RecordUpdate: recordResolvers(),
});

const recordResolvers = () => ({
  RecordList: ({ mutation, query }) => {
    return resolve({ mutation, query }, {}, includeRecord);
  },
  projectList: ({ mutation, query }) => {
    if (query.variables.last_projects) {
      return resolve(
        { mutation, query },
        { record: defaultItemFunction(mutation) },
        includeProject,
        projectFromRecord
      );
    } else {
      return query.result;
    }
  },
});

const includeProject = (qVars, item) => {
  const record = qVars.record;
  if (record.status === 'IN') {
    return false;
  }
  return true;
};

const projectFromRecord = (mutation) => {
  const record = defaultItemFunction(mutation);
  return record.project;
};

const includeRecord = (qVars, item) => {
  if (item?.deletedAt) return false;
  if (qVars.status && item?.status !== qVars.status) return false;
  if (qVars.personal && item?.resource && item?.resource?.personal === false)
    return false;
  if (qVars.project && qVars.project.length > 0) {
    if (!item?.project || !qVars.project.includes(item?.project?.id))
      return false;
  }
  if (qVars.projectKey && qVars.projectKey.length > 0) {
    if (
      !item.project.projectKey ||
      !qVars.projectKey.includes(item.project.projectKey)
    )
      return false;
  }
  return true;
};

const defaultItemFunction = (mutation) => {
  return mutation.result.data[Object.keys(mutation.result.data)[0]];
};

const resolve = (
  { mutation, query },
  variables,
  includeFunc,
  itemFunc = defaultItemFunction
) => {
  try {
    //let item = mutation.result.data[Object.keys(mutation.result.data)[0]];
    let item = itemFunc(mutation);
    const resultName = Object.keys(query.result)[0];
    const cache = query.result[resultName];

    const isIncluded = cache.items.some((x) => x && x.id === item.id);
    const shouldInclude = includeFunc(
      {
        ...query.variables,
        ...variables,
      },
      item
    );

    let updatedItems = null;
    if (isIncluded && !shouldInclude) {
      // if the result is found in the cached list and it should not be there after the mutation, remove it
      updatedItems = cache.items.filter((x) => !x || x.id !== item.id);
    } else if (!isIncluded && shouldInclude) {
      // if the result is not found in the cached list and it should be there after the mutation, add it
      updatedItems = [item, ...cache.items];
    }

    if (updatedItems) {
      return {
        ...query.result,
        [resultName]: {
          ...cache,
          items: updatedItems,
        },
      };
    }
  } catch (error) {
    console.error('MutationQueryResolver error', error);
  }
};

export default resolvers();
