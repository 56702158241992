import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setDeleteBulletin from 'scenes/BulletinBoard/utils/setDeleteBulletin';

export const deleteBulletin = createAsyncThunk(
  'bulletinBoard/deleteBulletin',
  async (bulletin, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
    const deletedBulletin = await setDeleteBulletin(bulletin, params);
    return { deletedBulletin };
  }
);
