import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { getInvoiceSortingRules } from '../services/getInvoiceSortingRules';

export const loadInvoiceSortingRules = createAsyncThunk(
  'invoices/invoiceSortingRules',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await getInvoiceSortingRules(mainProjectId);

    return response;
  }
);
