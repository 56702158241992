import { createAction } from "@reduxjs/toolkit";

export const setLoading = createAction(
    "projectForms/projectForms/setLoading"
);

export const resetProjectForms = createAction(
    "projectForms/projectForms/resetProjectForms"
);

export const resetResources = createAction(
    "projectForms/projectForms/resetResources"
);

export const setFormState = createAction(
    "projectForms/projectForms/setFormState"
);

export const removeFormState = createAction(
    "projectForms/projectForms/removeFormState"
);

export const setFormNotesFilePermissions = createAction(
    "projectForms/projectForms/setFormNotesFilePermissions"
);

export const setSnackBarState = createAction(
    "projectForms/projectForms/setSnackBarState"
);

export const setCompanyLogoState = createAction(
    "projectForms/projectForms/setCompanyLogoState"
);

export const setResourcesGroupedByEmployer = createAction(
    "projectForms/projectForms/setResourcesGroupedByEmployer"
);

export const setFormLabels = createAction(
    "projectForms/projectForms/setFormLabels"
);

export const setOpenedItemsList = createAction(
    "projectForms/projectForms/setOpenedItemsList"
);

export const setCurrentFormState = createAction(
    "projectForms/projectForms/setCurrentFormState"
);

export const setUnsavedChanges = createAction(
    "projectForms/projectForms/setUnsavedChanges"
);

export const setHasChanges = createAction(
    "projectForms/projectForms/setHasChanges"
);

export const setFormNotesForSave = createAction(
    "projectForms/projectForms/setFormNotesForSave"
);

export const setInitialTRFormNotesAsSet = createAction(
    "projectForms/projectForms/setInitialTRFormNotesAsSet"
);

export const setWorkers = createAction(
    "projectForms/projectForms/setWorkers"
);

export const resetWorkers = createAction(
    "projectForms/projectForms/resetWorkers"
);

export const setLatestTR = createAction(
    "projectForms/projectForms/setLatestTR"
);

export const resetLatestTR = createAction(
    "projectForms/projectForms/resetLatestTR"
);

export const setFormTemplateChanges = createAction(
    "projectForms/projectForms/setFormTemplateChanges"
);
export const resetFormTemplateChanges = createAction(
    "projectForms/projectForms/resetFormTemplateChanges"
);

export const setImageLoadingProgress = createAction(
    "projectForms/projectForms/setImageLoadingProgress"
);

export const setCurrentlyLoadingFormId = createAction(
    "projectForms/projectForms/setCurrentlyLoadingFormId"
);

export const setIsCancelled = createAction(
    "projectForms/projectForms/setIsCancelled"
);

export const setIsFormSaving = createAction(
    "projectForms/projectForms/setIsFormSaving"
);

export const setUploadingProgressValues = createAction(
    "projectForms/projectForms/setUploadingProgressValues"
);

export const setHasMissingOrientationForms = createAction(
    "projectForms/projectForms/setHasMissingOrientationForms"
);