import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminInitialWorkCalendarDataForProject } from 'scenes/WorkDemo/services/getAdminInitialWorkCalendarDataForProject.service';
import { loadUsersAndPersonalResources } from 'store/projectUsers/thunks/loadUsersAndPersonalResources';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadInitialCalendarDataForAdmin = createAsyncThunk(
  'workCalendar/loadInitialCalendarDataForAdmin',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { workSearchParams_demo } = state;

    const selectedProjectId = selectSelectedProjectId(state);

    const calendarDataPromise = getAdminInitialWorkCalendarDataForProject(
      selectedProjectId,
      workSearchParams_demo.fromDate,
      workSearchParams_demo.toDate
    );

    const [calendarData] = await Promise.all([calendarDataPromise]);

    if (calendarData.resources && calendarData.resources.length > 0) {
      dispatch(loadUsersAndPersonalResources(calendarData.resources));
    }

    const allData = {
      projectId: selectedProjectId,
      ...calendarData,
    };

    return allData;
  }
);
