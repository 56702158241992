import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectEditRecordDialogData,
  selectSelectedRecord,
  selectSelectedRecordBreakRecordsEditAttributesArray,
} from '../selectors/records.selectors';
import updateRecord from '../services/updateRecord';
export const editRecord = createAsyncThunk(
  'recordDialog/editRecord',
  async (_, { getState }) => {
    const selectedRecord = selectSelectedRecord(getState());
    let recordUpdateAttributes = selectEditRecordDialogData(getState());

    const originalRecordProjectId = selectedRecord.project;
    const updateAttributesProjectId = recordUpdateAttributes.project;

    const selectedRecordBreakRecordsEditAttributes =
      selectSelectedRecordBreakRecordsEditAttributesArray(getState());

    if (recordUpdateAttributes.status === 'IN') {
      recordUpdateAttributes = {
        ...recordUpdateAttributes,
        billedClockOutAt: undefined,
        clockOutAt: undefined,
      };
    }

    console.log('recordUpdateAttributes', recordUpdateAttributes);

    const records = await updateRecord({
      record: recordUpdateAttributes,
      projectIds: [originalRecordProjectId, updateAttributesProjectId],
      breakRecordsUpdateAttributes: selectedRecordBreakRecordsEditAttributes,
    });
    return records;
  }
);
