import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setOrientationFormTemplate from 'scenes/ProjectForms/utils/setOrientationFormTemplate';

export const createOrientationFormTemplate = createAsyncThunk(
  'projectForm/createOrientationFormTemplate',
  async (projectForm, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdFormTemplate = await setOrientationFormTemplate(projectForm, params);
    return { createdFormTemplate };
  }
);
