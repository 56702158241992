import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAdmin } from 'scenes/Permissions/services/deleteAdmin.service';

export const deleteAdminStatusOfUser = createAsyncThunk(
  'settings/deleteAdminStatusOfUser',
  async ({ userId, businessId }) => {
    const response = await deleteAdmin(userId, businessId);
    return response;
  }
);
