import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateTimelineService } from 'scenes/Timelines/services/updateTimeline.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const updateTimeline = createAsyncThunk(
  'timelines/updateTimeline',
  async ({ id, changes }, { getState, rejectWithValue }) => {
    const state = getState();
    const projectId = selectSelectedProjectId(state);
    try {
      const response = await updateTimelineService(projectId, id, changes);
      return response;
    } catch (error) {
      console.error('Failed to update timeline:', error);
      return rejectWithValue(error.response.data);
    }
  }
);
