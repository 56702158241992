import moment from 'moment';

export const calculateRecordsTotalTimesInSeconds = (records) => {
  let totalSeconds = 0;

  records.forEach((record) => {
    const startDate = moment.utc(record.clockInAt);
    const endDate = moment.utc(record.clockOutAt);

    const diff = endDate.diff(startDate, 'seconds');
    totalSeconds += diff;
  });

  return totalSeconds;
};
