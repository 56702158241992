import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';
export const getProjectBankImagesUploadSignedUrl = async ({
  projectId,
  fileName,
  contentType,
}) => {
  let apiPath = `files/upload-signed-url/project-bank/${projectId}/images`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileName: fileName, contentType: contentType }), // aws4 looks for body; axios for data
    data: { fileName: fileName, contentType: contentType },
  };

  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
};
