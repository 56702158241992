import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setCreateBulletin from 'scenes/BulletinBoard/utils/setCreateBulletin';

export const createBulletin = createAsyncThunk(
  'bulletinBoard/createBulletin',
  async (bulletin, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdBulletin = await setCreateBulletin(bulletin, params);

    return { createdBulletin };
  }
);
