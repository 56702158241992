import resourceItem from 'LixaniAPI/resourceListItem';
import projectItem from 'LixaniAPI/projectListItem';

const item = `
      __typename
      id
      createdAt
      updatedAt
      clockInAt
      clockOutAt
      billedClockInAt
      billedClockOutAt
      description
      title
      type
      status
      forcedClockOut
      value
      deletedAt
      latIn
      latOut
      longIn
      longOut
      project {
        ${projectItem}
      }
			
			resource {
				${resourceItem}
			}

			assignment {
				__typename
				id
				name
			}
      assignmentUnitAmount
			assignments {
				__typename
				id
				name
      }
      targetCountry
      changes {
        timeStamp
        user
        changes
      }
`;

export default item;
/*
      resource {
        __typename
        id
        user
        firstName
        middleName
        lastName
				taxNumber
        personal
        picture {
          bucket
          key
          region
        }
				idcard
      }
*/
