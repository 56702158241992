import { makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';

import { getRecordTimeLineItems } from './utils/getTimelineItems';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textItem: {
    padding: '0px',
    margin: '0px',
  },
}));

export function RecordsTimelineBreaks({ currentDate, breakRecords }) {
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return getRecordTimeLineItems(breakRecords, currentDate).map(
    (item, index) => (
      <React.Fragment key={index}>
        <line
          x1={`${item.start}%`}
          y1="55%"
          x2={`${item.end}%`}
          y2="55%"
          stroke={'#f23b0d'}
          strokeWidth={15}
          id={`break-${index}`}
          //  strokeOpacity={0.8}
        />
      </React.Fragment>
    )
  );
}
