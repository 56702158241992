import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getSavedProjectReports } from '../services/getSavedProjectReports.service';

export const loadProjectSavedReports = createAsyncThunk(
  'reports/loadProjectSavedReports',
  async (reportType, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const projectSavedReports = await getSavedProjectReports(
      selectedProjectId,
      reportType
    );

    return projectSavedReports;
  }
);
