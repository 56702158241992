import i18next from 'i18next';
import React from 'react';

import { MenuItem } from '@material-ui/core';
import TextInput from 'components/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import useStyles from './RecordDialogLayoutStyles';

export function TypeChange({
  handleChange,
  selectedType,
  disabled,
  recordType,
}) {
  const recordTypeList = {
    work: i18next.t('common:record.type.work'),
    projectWork: i18next.t('common:record.type.projectWork'),
    break: i18next.t('common:record.type.break'),
    coffeeBreak: i18next.t('common:record.type.coffee_break'),
    mealBreak: i18next.t('common:record.type.meal_break'),
    shift: i18next.t('common:record.type.shift'),
    absence: i18next.t('common:record.type.absence'),
    benefit: i18next.t('common:record.type.benefit'),
    ride: i18next.t('common:record.type.ride'),
    item: i18next.t('common:record.type.item'),
    negative: i18next.t('common:record.type.negative'),
    sick_leave: i18next.t('common:record.type.sick_leave'),
    absence_vacation_paid: i18next.t(
      'common:record.type.absence_vacation_paid'
    ),
  };

  const allTypeOptions = [
    { value: 'WORK', label: recordTypeList.work },
    { value: 'PROJECTWORK', label: recordTypeList.projectWork },
    { value: 'BREAK', label: recordTypeList.break },
    { value: 'BREAK_COFFEE', label: recordTypeList.coffeeBreak },
    { value: 'BREAK_MEAL', label: recordTypeList.mealBreak },
    { value: 'SHIFT', label: recordTypeList.shift },
    { value: 'ABSENCE', label: recordTypeList.absence },
    { value: 'BENEFIT', label: recordTypeList.benefit },
    { value: 'RIDE', label: recordTypeList.ride },
    { value: 'ITEM', label: recordTypeList.item },
    { value: 'NEGATIVE', label: recordTypeList.negative },
    { value: 'ABSENCE_SICK_LEAVE', label: recordTypeList.sick_leave },
    {
      value: 'ABSENCE_VACATION_PAID',
      label: recordTypeList.absence_vacation_paid,
    },
  ];

  const selectableTypeOptions = [
    'WORK',
    'PROJECTWORK',
    'ABSENCE_SICK_LEAVE',
    'ABSENCE_VACATION_PAID',
  ];

  const typeOptions =
    recordType === 'BREAK'
      ? allTypeOptions.filter((typeOption) => {
          return (
            typeOption.value.includes('BREAK') ||
            typeOption.value === selectedType
          );
        })
      : allTypeOptions;

  const { t } = useTranslation('common');
  const classes = useStyles();

  const typeSelected = allTypeOptions.find(
    (type) => type.value === selectedType
  );
  return (
    <TextInput
      displayEmpty
      fullWidth
      id="edit-record-type-select"
      label={t('calendar.type')}
      MenuProps={{
        PaperProps: {
          className: classes.menuPaper,
        },
      }}
      onChange={(e) => handleChange(e.target.value)}
      select
      shrink
      value={typeSelected.value}
      disabled={disabled}
    >
      {typeOptions
        .filter(
          (item) =>
            selectableTypeOptions.includes(item.value) ||
            typeSelected.value === item.value
        )
        .map((item) => (
          <MenuItem
            className={classes.menuItem}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
    </TextInput>
  );
}
