import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setEditForm from 'scenes/ProjectForms/utils/setEditForm';
import { useForm } from './hooks/useForm';

const { setTRFormReadyToBeSaved, setOrientationFormReadyToBeSaved } = useForm();

export const editForm = createAsyncThunk(
    'projectForm/editForm',
    async (projectForm, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        // If form is a TR-meter form, set the form ready to be sent to the database
        if (projectForm.measurement_targets) {
            projectForm = setTRFormReadyToBeSaved(projectForm);
        }
        if (projectForm.type === 'orientation_form') {
            projectForm = setOrientationFormReadyToBeSaved(projectForm);
        }
        const editedForm = await setEditForm(projectForm, params);
        return { editedForm };
    }
);