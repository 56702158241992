export const initSettings = (generationParams) => {
  const salaryReportSettings = {
    printHeader: true,
    reportName: 'salary_report',
    rowType: 'resources',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    processes: [{ amount: 0.5, threshold: 6, type: 'reduction' }],
    language: generationParams.language || 'en',
    sort: 'resource-lastName',
    dataSource: 'Record',
    filetype: generationParams.filetype || 'PDF',
    workHourType: generationParams.workHourType || 'ALL',
  };

  const hourSalaryReportSettings = {
    printHeader: true,
    filetype: generationParams.filetype || 'PDF',
    reportName: 'hour_salary_report',
    rowType: 'resources',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    language: generationParams.language || 'en',
    sort: 'resource-lastname',
    processes: [],
    dataSource: 'Record',
    workHourType: generationParams.workHourType || 'ALL',
  };

  const xlsReportSettings = {
    printHeader: true,
    filetype: generationParams.filetype || 'PDF',
    reportName: 'xls_report',
    rowType: 'resources2',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    language: generationParams.language || 'en',
    sort: 'resource-lastname',
    processes: [],
    dataSource: 'Record',
    workHourType: generationParams.workHourType || 'ALL',
  };

  const weekSalaryReportSettings = {
    printHeader: true,
    filetype: generationParams.filetype || 'PDF',
    reportName: 'week_salary_report',
    rowType: 'resources',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    language: generationParams.language || 'en',
    sort: 'resource-lastname',
    processes: [],
    dataSource: 'Record',
    workHourType: generationParams.workHourType || 'ALL',
  };

  const hourListingReportSettings = {
    printHeader: true,
    filetype: generationParams.filetype || 'PDF',
    reportName: 'hour_listing_report',
    rowType: 'resources',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    language: generationParams.language || 'en',
    sort: 'resource-lastname',
    processes: [],
    dataSource: 'Record',
    workHourType: generationParams.workHourType || 'ALL',
  };

  const employeeReportSettings = {
    printHeader: true,
    filetype: generationParams.filetype || 'PDF',
    reportName: 'employee_report',
    rowType: 'resources',
    pageOrientation: 'LANDSCAPE',
    lastNameFirst: true,
    language: generationParams.language || 'en',
    sort: 'resource-lastname',
    processes: [],
    dataSource: 'RecordResource',
    workHourType: generationParams.workHourType || 'ALL',
  };

  let settings;
  if (generationParams.selectedReportType === 'salary_report') {
    settings = salaryReportSettings;
  } else if (generationParams.selectedReportType === 'xls_report') {
    settings = xlsReportSettings;
  } else if (generationParams.selectedReportType === 'week_salary_report') {
    settings = weekSalaryReportSettings;
  } else if (generationParams.selectedReportType === 'hour_listing_report') {
    settings = hourListingReportSettings;
  } else if (generationParams.selectedReportType === 'employee_report') {
    settings = employeeReportSettings;
  } else {
    settings = hourSalaryReportSettings;
  }

  return settings;
};
