import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { patchUpdateAllProjectAssignmentsAsDeleted } from '../services/patchUpdateAllProjectAssignmentsAsDeleted.service';
import { selectAssignmentsArray } from '../selectors/assignment.selectors';
import { removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject } from '../actions/assignments.actions';

export const deleteAllProjectAssignments = createAsyncThunk(
  'assignments/deleteAllProjectAssignments',
  async (_, { getState, dispatch }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const assignments = selectAssignmentsArray(getState());

    const {
      childProjectIdsAssignmentsRemovedFrom,
      mainProjectUpdatedAssignments,
    } = await patchUpdateAllProjectAssignmentsAsDeleted(selectedProjectId);

    if (
      childProjectIdsAssignmentsRemovedFrom &&
      childProjectIdsAssignmentsRemovedFrom.length > 0
    ) {
      const affectedChildProjectIds = childProjectIdsAssignmentsRemovedFrom;

      const assignmentsOfAffectedChildProjects = assignments.filter(
        (assignment) => affectedChildProjectIds.includes(assignment.project)
      );

      if (
        assignmentsOfAffectedChildProjects &&
        assignmentsOfAffectedChildProjects.length > 0
      ) {
        const assignmentIdsToRemove = assignmentsOfAffectedChildProjects.map(
          (assignment) => assignment.id
        );

        dispatch(
          removeAssignmentsOfAffectedChildProjectsAfterDeletionInParentProject(
            assignmentIdsToRemove
          )
        );
      }
    }
    return mainProjectUpdatedAssignments;
  }
);
