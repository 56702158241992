import { createSelector } from '@reduxjs/toolkit';
import returnProjectIdAndItsChildProjectIds from 'scenes/WorkDemo/utils/returnProjectIdAndItsChildProjectIds';
import { uniqueResourcesByUserAndEmployer } from 'scenes/WorkDemo/utils/uniqueResourcesByUserAndEmployer';
import { selectAllResources } from 'store/resources/resourceSliceSelectors';
import { arrayToMap } from 'utils/arrayToMap';
import sort from 'utils/sort';
import { groupBy } from 'lodash-es';
const selectProjectsState = (state) => state.projects;

export const selectProjects = createSelector(
  [selectProjectsState],
  (state) => state.projects.entities
);

export const selectProjectsFolder = createSelector(
  [selectProjectsState],
  (state) => state.folder
);

export const selectSearchFilter = createSelector(
  [selectProjectsState],
  (state) => state.searchFilter
);
export const selectSearchFilterActive = createSelector(
  [selectProjectsState],
  (state) => (state.searchFilter ? true : false)
);

export const selectShowAll = createSelector(
  [selectProjectsState],
  (state) => state.showAll
);

export const selectMobileShowList = createSelector(
  [selectProjectsState],
  (state) => state.mobileShowList
);

export const selectProjectsIds = createSelector(
  [selectProjectsState],
  (state) => state.projects.ids
);

export const selectSelectedProjectId = createSelector(
  [selectProjectsState],
  (state) => state.selectedProjectId
);

export const selectIsProjectsLoading = createSelector(
  [selectProjectsState],
  (state) => state.loading
);

export const selectUserProjectsLoaded = createSelector(
  [selectProjectsState],
  (state) => state.userProjectsLoaded
);

export const selectIsAddingEmployer = createSelector(
  [selectProjectsState],
  (state) => state.isAddingEmployer
);

export const selectProjectIdsLatestClockedInTo = createSelector(
  [selectProjectsState],
  (state) => state.latestClockedInProjectIds
);
export const selectProjectRecordHours = createSelector(
  [selectProjectsState],
  (state) => state.projectRecordHours
);

export const selectIsProjectRecordHoursLoading = createSelector(
  [selectProjectsState],
  (state) => state.projectRecordHoursLoading
);

export const selectLatestUserActiveProjectIds = createSelector(
  [selectProjectsState],
  (state) => state.latestUserActiveProjectIds
);
export const selectSavingProcountorDimension = createSelector(
  [selectProjectsState],
  (state) => state.savingProcountorDimension
);

export const selectIsProjectCreateLoading = createSelector(
  [selectProjectsState],
  (state) => state.isProjectCreateLoading
);

export const selectProjectsArray = createSelector(
  [selectProjects],
  (projects) => Object.values(projects).filter((project) => !project.deletedAt)
);

export const selectProjectEmployeeEmployerProjects = createSelector(
  [selectProjectsState],
  (state) => state.projectEmployeeEmployerProjects.entities
);

export const selectProjectEmployeeEmployerProjectsArray = createSelector(
  [selectProjectEmployeeEmployerProjects],
  (employerProjects) => Object.values(employerProjects)
);

export const selectProjectsIndexedByParent = createSelector(
  [selectProjects],
  (projects) => groupBy(projects, 'parent')
);

export const selectChildProjectsOfSelectedProject = createSelector(
  [selectSelectedProjectId, selectProjectsIndexedByParent],
  (selectedProjectId, projectsIndexedByParent) =>
    projectsIndexedByParent[selectedProjectId]
      ? projectsIndexedByParent[selectedProjectId]
      : []
);

export const selectProjectsArrayWithJoinedData = createSelector(
  [selectProjectsArray, selectProjects],
  (projectsArray, projects) => {
    const joinedDataProjects = projectsArray.map((project) => ({
      ...project,
      __typename: 'Project',
      masterParent: projects[project.masterParent]
        ? projects[project.masterParent]
        : { id: project.masterParent },
      parent: projects[project.parent]
        ? projects[project.parent]
        : { id: project.parent },
      employer: projects[project.employer]
        ? projects[project.employer]
        : { id: project.employer },
    }));
    return joinedDataProjects;
  }
);

export const selectLatestUserActiveProjects = createSelector(
  [selectProjectsArrayWithJoinedData, selectLatestUserActiveProjectIds],
  (projects, latestActiveProjectIds) =>
    projects.filter((project) => latestActiveProjectIds.includes(project.id))
);

export const selectLatestUserActiveProjectsWithEmployers = createSelector(
  [selectLatestUserActiveProjects],
  (latestProjects) => {
    const latestProjectsEmployers = latestProjects
      .filter((project) => project.masterParent.id)
      .map((project) => project.masterParent);

    return [...latestProjects, ...latestProjectsEmployers];
  }
);

export const selectLatestUserActiveProjectsAndEmployerProjectsIds =
  createSelector(
    [selectLatestUserActiveProjectsWithEmployers],
    (latestProjects) => latestProjects.map((project) => project.id)
  );

export const selectLatestUserActiveProjectsAndEmployerProjectsIdsSet =
  createSelector(
    [selectLatestUserActiveProjectsWithEmployers],
    (latestProjects) => new Set(latestProjects.map((project) => project.id))
  );

export const selectProjectsEntitiesWithJoinedData = createSelector(
  [selectProjectsArrayWithJoinedData],
  (projects) => arrayToMap(projects)
);

export const selectSelectedProject = createSelector(
  [selectProjects, selectSelectedProjectId],
  (projects, selectedProjectId) =>
    projects[selectedProjectId] ? projects[selectedProjectId] : {}
);

export const selectIsSelectedProjectEmployerProject = createSelector(
  [selectSelectedProject],
  (project) => {
    const isEmployerProject =
      project.businessTypeEnum && project.businessTypeEnum === 'EMPLOYER';

    return isEmployerProject;
  }
);
export const selectSelectedProjectWithJoinedData = createSelector(
  [selectProjectsEntitiesWithJoinedData, selectSelectedProjectId],
  (projects, selectedProjectId) =>
    projects[selectedProjectId] ? projects[selectedProjectId] : {}
);

export const selectSelectedProjectParent = createSelector(
  [selectProjects, selectSelectedProject],
  (projects, selectedProject) =>
    projects[selectedProject.parent] ? projects[selectedProject.parent] : {}
);

export const selectSelectedProjectMasterParent = createSelector(
  [selectProjects, selectSelectedProject],
  (projects, selectedProject) =>
    projects[selectedProject.masterParent]
      ? projects[selectedProject.masterParent]
      : {}
);

export const selectSelectedProjectRecordHourTotals = createSelector(
  [selectProjectRecordHours, selectSelectedProjectId],
  (projectRecordHours, selectedProjectId) =>
    projectRecordHours[selectedProjectId]
      ? projectRecordHours[selectedProjectId]
      : {}
);

export const selectProcountorDimensions = createSelector(
  [selectProjectsState],
  (projectsState) => projectsState.procountorDimensions
);

export const selectProjectAndChildProjectResources = createSelector(
  [selectAllResources, selectProjects, (_, args) => args],
  (resources, projects, args) => {
    const projectId = args;

    const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
      [projectId],
      projects
    );

    {
      let resourceOptions = uniqueResourcesByUserAndEmployer(
        resources.filter((resource) =>
          projectAndChildProjectIds.includes(resource.project)
        )
      ).map((resource) => {
        if (projects[resource.employer]) {
          resource = {
            ...resource,
            employer: projects[resource.employer],
          };
        } else if (projects[resource.employer]) {
          resource = {
            ...resource,
            employer: projects[resource.employer],
          };
        }
        return resource;
      });

      resourceOptions.sort((a, b) => sort.sortByKey(a, b, 'lastName'));

      return resourceOptions;
    }
  }
);

export const selectProjectAndChildProjects = createSelector(
  [selectProjects, selectSelectedProjectId],
  (projects, projectId) => {
    const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
      [projectId],
      projects
    );

    return projects.filter((project) =>
      projectAndChildProjectIds.includes(project.id)
    );
  }
);

export const selectProjectMergeStatus = createSelector(
  [selectProjectsState],
  (state) => state.projectMergeStatus
);

export const selectProjectsMerging = createSelector(
  [selectProjectsState],
  (state) => state.projectsMerging
);

export const selectAddingProjectToMerge = createSelector(
  [selectProjectsState],
  (state) => state.addingProjectToMerge
);

export const selectProjectByKey = createSelector(
  [selectProjectsArray, (_, projectKey) => projectKey],
  (projects, projectKey) =>
    projects.find((project) => project.projectKey === projectKey)
);
