import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getAdminWorkCalendarDataFromExternalProject } from 'scenes/WorkDemo/services/getAdminWorkCalendarDataFromExternalProject.service';
import { loadRecordAllowancesOfProjectInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfProjectInTimeframe.thunk';

export const loadAdminWorkCalendarDataFromExternalProject = createAsyncThunk(
  'workCalendar/loadAdminWorkCalendarDataFromExternalProject',
  async (_, { getState, dispatch }) => {
    const { workSearchParams_demo } = getState();

    const selectedProjectId = selectSelectedProjectId(getState());

    if (selectedProjectId) {
      const fromDate = workSearchParams_demo.fromDate;
      const toDate = workSearchParams_demo.toDate;

      const adminData = await getAdminWorkCalendarDataFromExternalProject(
        selectedProjectId,
        fromDate,
        toDate
      );

      dispatch(loadRecordAllowancesOfProjectInTimeframe({ fromDate, toDate }));

      return adminData;
    } else {
      return;
    }
  }
);
