import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadRecordAllowancesOfEmployerInTimeframe } from './thunks/loadRecordAllowancesOfEmployerInTimeframe.thunk';
import { loadRecordAllowancesOfCurrentUserInTimeframe } from './thunks/loadRecordAllowancesOfCurrentUserInTimeframe.thunk';
import { loadRecordAllowancesOfProjectInTimeframe } from './thunks/loadRecordAllowancesOfProjectInTimeframe.thunk';
import { removeRecordAllowancesByRecordIds } from './actions/record-allowances.actions';
import { deleteRecordAllowanceThunk } from './thunks/deleteRecordAllowance.thunk';
import { enqueueSnackbar } from 'notistack';

const recordAllowancesAdapter = createEntityAdapter();

const recordAllowancesSlice = createSlice({
  name: 'recordAllowances',
  initialState: {
    recordAllowances: recordAllowancesAdapter.getInitialState(),
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadRecordAllowancesOfEmployerInTimeframe.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadRecordAllowancesOfEmployerInTimeframe.fulfilled,
        (state, action) => {
          recordAllowancesAdapter.setMany(
            state.recordAllowances,
            action.payload
          );
          state.loading = false;
        }
      )
      .addCase(
        loadRecordAllowancesOfEmployerInTimeframe.rejected,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        loadRecordAllowancesOfCurrentUserInTimeframe.fulfilled,
        (state, action) => {
          recordAllowancesAdapter.setMany(
            state.recordAllowances,
            action.payload
          );
        }
      )
      .addCase(
        loadRecordAllowancesOfProjectInTimeframe.fulfilled,
        (state, action) => {
          recordAllowancesAdapter.setMany(
            state.recordAllowances,
            action.payload
          );
        }
      )
      .addCase(removeRecordAllowancesByRecordIds, (state, action) => {
        recordAllowancesAdapter.removeMany(
          state.recordAllowances,
          action.payload
        );
      })
      .addCase(deleteRecordAllowanceThunk.fulfilled, (state, action) => {
        recordAllowancesAdapter.removeOne(
          state.recordAllowances,
          action.payload.id
        );
        enqueueSnackbar('Merkinnän lisä poistettu onnistuneesti', {
          variant: 'success',
        });
      });
  },
});

export default recordAllowancesSlice.reducer;
