import { createTheme } from '@material-ui/core/styles';

const pantonCommon = {
  color: 'initial',
  fontSize: '1rem',
  lineHeight: 1,
};

export const pantonFonts = {
  panton: "'Panton', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
  pantonLight:
    "'Panton  Light', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
  pantonExtraLight:
    "'Panton  ExtraLight', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
  pantonSemiBold:
    "'Panton  SemiBold', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
};

export const baseTheme = createTheme({
  typography: {
    fontFamily:
      "'Panton  Light', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif'",
    fontPanton: {
      ...pantonCommon,
      fontFamily: pantonFonts.panton,
    },
    fontPantonLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonLight,
    },
    fontPantonExtraLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonExtraLight,
    },
    fontPantonSemiBold: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonSemiBold,
    },
    h4: {
      fontFamily: pantonFonts.panton,
    },
    h5: {
      fontFamily: pantonFonts.panton,
    },
    body1: {
      fontFamily: pantonFonts.pantonLight,
    },
    body2: {
      fontFamily: pantonFonts.pantonLight,
      fontSize: '0.875rem',
    },
    caption: {
      fontFamily: pantonFonts.pantonLight,
    },
  },
});
