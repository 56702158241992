import dayjs from 'dayjs';

//-- dayjs extensions
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from './dayjsPlugins/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

//-- dayjs custom plugins
dayjs.extend(customParseFormat);

export default dayjs;
