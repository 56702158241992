import { createSlice } from '@reduxjs/toolkit';
import { setInitialProjectKeyToJoin } from '../actions/projectJoin.actions';
import { userJoinProject } from '../thunks/userJoinProject.thunk';

export const projectJoinSlice = createSlice({
  name: 'projectJoin',
  initialState: {
    projectKeyToJoin: '',
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setInitialProjectKeyToJoin, (state, action) => {
        state.projectKeyToJoin = action.payload;
      })
      .addCase(userJoinProject.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userJoinProject.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(userJoinProject.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
