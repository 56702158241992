import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getFilePermissionsForProject from 'scenes/BulletinBoard/utils/getFilePermissionsForProject';
import dayjs from 'dayjs';

export const loadImageSelectorData = createAsyncThunk(
  'bulletinBoard/loadImageSelectorData',
  async (projectId, { getState }) => {
    const { root, bulletinBoard } = getState();

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();
    const fromDate = dayjs().subtract(52, 'week').utcOffset(0).format();
    const toDate = dayjs().utcOffset(0).format();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
      fromDate: fromDate,
      toDate: toDate,
      type: 'image',
    };

    let getFilepermissionsPromises = [];

    getFilepermissionsPromises.push(
      getFilePermissionsForProject(projectId, params)
    );

    const getFilepermissionsPromisesSettled = await Promise.allSettled(
      getFilepermissionsPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const tempFilePermissions = [].concat(...getFilepermissionsPromisesSettled);
    const filePermissions = []
      .concat(tempFilePermissions)
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return { filePermissions };
  }
);
