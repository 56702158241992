// timelineService.js
import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const getTimelineTasksService = async (projectId, timelineId) => {
  const host = process.env.REACT_APP_WORK_CALENDAR_API_URL;
  const apiPath = `timelines/project/${projectId}/timeline/${timelineId}/get-timeline-tasks`;

  const request = {
    url: `https://${host}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: host,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };

  const signedRequest = await createSignedRequest(request);
  const response = await axios(signedRequest);

  return response.data;
};
