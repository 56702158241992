import fetchFilePermissions from '../services/fetchFilePermissions.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as imageSelectorActions from '../actions/imageSelectorActions';

/**
 * Used to get file permissions for current project, for ImageSelectorDialog
 * @param {object} params - The params object
 * @param {string} params.fromDate - The start date (optional), if not provided will get all file permissions
 * @param {string} params.toDate - The end date (optional), if not provided will get all file permissions
 * @returns - The file permissions
 */
export const getFilePermissions = createAsyncThunk(
    'imageSelector/getFilePermissions',
    async ({ fromDate, toDate }, { getState, dispatch }) => {
        const selectedProjectId = getState().projects.selectedProjectId;
        if (!selectedProjectId) {
            throw new Error('No selectedProjectId. Cannot fetch file permissions.');
        }
        let filePermissions = [];

        let response;
        let lastEvaluatedKey = null;

        try {
            do {
                response = await fetchFilePermissions(selectedProjectId, fromDate, toDate, lastEvaluatedKey);
                lastEvaluatedKey = response.LastEvaluatedKey;
                filePermissions.push(...response.Items);
                dispatch(imageSelectorActions.setFilePermissions(response.Items));
            } while (response.LastEvaluatedKey);
            dispatch(imageSelectorActions.setLoading(false));
        } catch (error) {
            console.error('Error fetching file permissions:', error);
            // Handle error if needed
            throw error; // Rethrow the error to propagate it
        }

        return { filePermissions };
    }
);