import { createAction } from '@reduxjs/toolkit';

export const openAddCardDialog = createAction('cards/openAddCardDialog');

export const openAddCardDialogForOtherUser = createAction(
  'cards/openAddCardDialogForOtherUser'
);

export const closeAddCardDialog = createAction('cards/closeAddCardDialog');

export const openDeleteCardDialog = createAction('cards/openDeleteCardDialog');

export const closeDeleteCardDialog = createAction(
  'cards/closeDeleteCardDialog'
);

export const setCardIdToDelete = createAction('cards/setCardIdToDelete');
