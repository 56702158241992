import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const deleteMediaFilePermission = async (
  filePermissionId,
  projectId
) => {
  const apiPath = `file-permissions/${filePermissionId}/delete-media-file-permission/${projectId}`;

  const body = {};
  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    //url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    data: body,
    body: JSON.stringify(body),
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);
  return response.data;
};
