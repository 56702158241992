import { useSelector } from 'react-redux';
import React from 'react';

import CreateRecordDialog from './CreateRecordDialog';
import EditRecordDialog from './EditRecordDialog';

import {
  selectClockOutRecordDialogOpen,
  selectCreateRecordDialogOpen,
  selectEditRecordDialogOpen,
  selectLocationMustDialog,
} from 'store/records/selectors/records.selectors';
import LocationMust from 'components/LocationMust/LocationMust';
import ClockOutRecordDialog from 'components/ClockOutDialog/ClockOutRecordDialog';

function RecordDialogContainer() {
  const editRecordDialogOpen = useSelector(selectEditRecordDialogOpen);

  const createRecordDialogOpen = useSelector(selectCreateRecordDialogOpen);
  const clockOutRecordDialogOpen = useSelector(selectClockOutRecordDialogOpen);

  const locationMustProject = useSelector(selectLocationMustDialog);

  const locationMustOpen = locationMustProject ? true : false;
  return (
    <>
      {editRecordDialogOpen ? (
        <EditRecordDialog open={editRecordDialogOpen} />
      ) : null}
      {createRecordDialogOpen ? (
        <CreateRecordDialog open={createRecordDialogOpen}></CreateRecordDialog>
      ) : null}
      {clockOutRecordDialogOpen ? (
        <ClockOutRecordDialog
          open={clockOutRecordDialogOpen}
        ></ClockOutRecordDialog>
      ) : null}

      {locationMustOpen ? (
        <LocationMust
          open={locationMustOpen}
          project={locationMustProject}
        ></LocationMust>
      ) : null}
    </>
  );
}

export default RecordDialogContainer;
