import { Snackbar, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotificationsSnackbar } from 'store/notificationSnackbar/actions/notificationSnackbar.actions';
import {
  selectIsNotificationSnackbarOpen,
  selectNotificationSnackbarMessage,
  selectNotificationSnackbarType,
} from 'store/notificationSnackbar/selectors/notificationSnackbar.selectors';

const NotificationSnackbar = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  const isSnackbarOpen = useSelector(selectIsNotificationSnackbarOpen);

  const notificationMessage = useSelector(selectNotificationSnackbarMessage);

  const notificationType = useSelector(selectNotificationSnackbarType);

  const useStyles = makeStyles((theme) => ({
    error: {
      color: 'white',
      backgroundColor: '#B00020',
    },
    success: {
      ...theme.typography.fontPantonLight,
      ...theme.component.snackBar,
    },
  }));

  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={5000}
      message={notificationMessage ? t(notificationMessage) : ''}
      onClose={() => dispatch(closeNotificationsSnackbar())}
      open={isSnackbarOpen}
      ContentProps={{
        className: classes[notificationType],
      }}
    ></Snackbar>
  );
};

export default NotificationSnackbar;
