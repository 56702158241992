import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSelectedProjectId } from '../actions/projectActions';
import { getProjectByProjectKey } from '../services/getProjectByProjectKey.service';

export const loadProjectByProjectKey = createAsyncThunk(
  'project/loadProjectByProjectKey',
  async (projectKey, { dispatch }) => {
    const project = await getProjectByProjectKey(projectKey);

    dispatch(setSelectedProjectId(project.id));

    return project;
  }
);
