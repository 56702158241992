import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateProjectBankFile } from 'scenes/ProjectBank/services/postCreateProjectBankFile.service';

export const createProjectBankFile = createAsyncThunk(
  'projectBank/createProjectBankFile',
  async (filePermission) => {
    const createdFilePermission = await postCreateProjectBankFile(
      filePermission
    );

    return createdFilePermission;
  }
);
