import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MapDialogLayout from './MapDialogLayout';
import { withApollo } from 'react-apollo';


/**
 * MapDialog opens a map with markers
 *
 * MapDialog takes arrays of records, projects, resources and addresses, and marks them on the map
 * To give just one record, props should be records={[someRecord]}
 * mapType can be "hybrid", "roadMap", "satellite", or null
 */
function MapDialog(props) {
  return (
    <MapDialogLayout
      onClose={props.onClose}
      records={props.records}
      projects={props.projects}
      resources={props.resources}
      addresses={props.addresses}
      mapType={props.mapType}
    />
  );
}

MapDialog.defaultProps = {};

MapDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  records: PropTypes.array,
  projects: PropTypes.array,
  resources: PropTypes.array,
  addresses: PropTypes.array,
  mapType: PropTypes.string,
};

export default withApollo(MapDialog);
