import React, { useState } from 'react';
import useStyles from './RecordDialogLayoutStyles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { selectSelectedRecordBreaksWithEditAttributes } from 'store/records/selectors/records.selectors';
import { BreaksTimePicker } from './BreakTimePicker';
import Button from 'components/Button/Button';
import AddBreakDialog from './AddBreakDialog';
import { createBreakRecord } from 'store/records/thunks/createBreakRecord.thunk';

export function BreaksComponent({
  disabled,
  parentClockIn,
  parentClockOut,
  addingBreaksDisabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const breakRecords = useSelector(
    selectSelectedRecordBreaksWithEditAttributes
  );

  const [AddBreakDialogOpen, setAddBreakDialogOpen] = useState(false);

  const closeAddBreakDialog = () => {
    setAddBreakDialogOpen(false);
  };
  const openAddBreakDialog = () => {
    setAddBreakDialogOpen(true);
  };

  const onCreateBreakRecord = (breakRecord) => {
    dispatch(createBreakRecord(breakRecord));
    setAddBreakDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <label className="mylabel">
          {`${t('calendar.breaks')} (${breakRecords.length})`}{' '}
        </label>
      </Grid>
      {breakRecords.map((breakRecord) => (
        <BreaksTimePicker
          key={breakRecord.id}
          breakRecord={breakRecord}
          disabled={disabled}
          parentClockIn={parentClockIn}
          parentClockOut={parentClockOut}
        ></BreaksTimePicker>
      ))}
      <Grid
        item
        xs={12}
        className={clsx(classes.actionBtn, classes.itemsFlexEnd)}
      >
        <Button
          disabled={addingBreaksDisabled}
          onClick={() => openAddBreakDialog()}
          color="primary"
        >
          {t('calendar.add_break')}
        </Button>
      </Grid>

      {AddBreakDialogOpen ? (
        <AddBreakDialog
          parentClockIn={parentClockIn}
          parentClockOut={parentClockOut}
          open={AddBreakDialogOpen}
          onClose={closeAddBreakDialog}
          onCreateBreakRecord={onCreateBreakRecord}
        ></AddBreakDialog>
      ) : null}
    </>
  );
}
