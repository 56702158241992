import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInteractionsByTarget } from '../services/get-interactions-by-target.service';

export const loadInteractionsByTarget = createAsyncThunk(
  'interactions/loadInteractionsByTarget',
  async (targetId) => {
    const interactions = await getInteractionsByTarget(targetId);
    return interactions;
  }
);
