import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPersonalRecordProjectsFromToday } from 'store/records/selectors/records.selectors';
import { RecordsTimelineWorkRecords } from './RecordsTimeLineWorkRecords';
import { RecordsTimelineBreaks } from './RecordTimelineBreaks';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import { selectPersonalWorkAndBreakRecordsFromToday } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';

const useStyles = makeStyles((theme) => ({}));

export function RecordsTimelineProjects({ currentDate }) {
  const theme = useTheme();
  const classes = useStyles();

  const projectsOfRecordsOfTheDay = useSelector(
    selectPersonalRecordProjectsFromToday
  );

  const recordsFromToday = useSelector(
    selectPersonalWorkAndBreakRecordsFromToday
  );

  const workRecords = recordsFromToday.filter(
    (record) =>
      record.type === RECORD_TYPE.WORK ||
      record.type === RECORD_TYPE.PROJECTWORK
  );

  const breakRecords = recordsFromToday.filter((record) =>
    [
      RECORD_TYPE.BREAK,
      RECORD_TYPE.BREAK_COFFEE,
      RECORD_TYPE.BREAK_MEAL,
    ].includes(record.type)
  );

  console.log('projectsOfRecordsOfTheDay', projectsOfRecordsOfTheDay);

  return projectsOfRecordsOfTheDay.map((project) => {
    const workRecordsByProject = workRecords.filter(
      (record) => record.project === project.id
    );
    const breakRecordsByProject = breakRecords.filter(
      (record) => record.project === project.id
    );

    return (
      <svg
        key={`timeline-project-${project.id}`}
        width={650}
        height="40px"
        className={classes.svg}
      >
        <RecordsTimelineWorkRecords
          projectName={project.name ? project.name : ''}
          currentDate={currentDate}
          workRecords={workRecordsByProject}
        ></RecordsTimelineWorkRecords>
        <RecordsTimelineBreaks
          breakRecords={breakRecordsByProject}
          currentDate={currentDate}
        ></RecordsTimelineBreaks>
      </svg>
    );
  });
}
