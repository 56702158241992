import { createAction } from '@reduxjs/toolkit';
export const openBusinessesLixaniUsageDataDialog = createAction(
  'settings/openBusinessesLixaniUsageDataDialog'
);

export const openAddEmployerDialog = createAction(
  'settings/openAddEmployerDialog'
);

export const closeAddEmployerDialog = createAction(
  'settings/closeAddEmployerDialog'
);
