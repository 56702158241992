import React from 'react';
import { Breadcrumb } from 'react-breadcrumbs';

import { useSelector } from 'react-redux';
import { selectProjectsCrumbRoute } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';

function ProjectCrumbRoute() {
  const selectedRoute = useSelector(selectProjectsCrumbRoute);

  const employers = selectedRoute.employers;

  const projects = selectedRoute.projectPath;
  const route = [...employers, ...projects];
  return route.map((item) => (
    <Breadcrumb
      key={item.key}
      data={{
        title: item.name,
        pathname: '/' + item.key,
      }}
    ></Breadcrumb>
  ));
}

export default ProjectCrumbRoute;
