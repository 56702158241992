import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import { getPermissionsOfProject } from '../../services/getPermissionsOfProject';

export const loadPermissionsOfProject = createAsyncThunk(
  'permissionsManagement/loadPermissionsOfProject',
  async (projectId) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const response = await getPermissionsOfProject(projectId, {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    return response;
  }
);
