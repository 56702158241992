import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectInRecord } from '../selectors/records.selectors';
import postClockInBreakRecord from '../services/clockInBreakRecord.service';
import uuidv4 from 'uuid/v4';
import { RECORD_STATUS } from 'LixaniAPI/enums';
import { setOptimisticRecord } from './setOptimisticRecord.thunk';

export const clockInBreakRecord = createAsyncThunk(
  'records/clockInBreakRecord',
  async (breakType, { getState, dispatch, rejectWithValue }) => {
    const inRecord = selectSelectedProjectInRecord(getState());

    const now = new Date().toISOString();

    console.log('parent record: ', inRecord);
    console.log('breakType: ', breakType);

    const projectId = inRecord.project.id;
    const parentId = inRecord.id;
    const resource =
      inRecord.resource && inRecord.resource.id
        ? inRecord.resource.id
        : undefined;

    const optimisticRecord = {
      id: uuidv4(),
      clockInAt: now,
      project: projectId,
      status: RECORD_STATUS.IN,
      type: breakType,
      mirrorUser: inRecord.mirrorUser,
      resource: resource,
      parentRecordId: parentId,
      updatedAt: now,
      createdAt: now,
    };

    await dispatch(setOptimisticRecord(optimisticRecord));

    console.log(optimisticRecord);

    try {
      const createdRecord = await postClockInBreakRecord(
        projectId,
        parentId,
        optimisticRecord
      );
      return createdRecord;
    } catch (e) {
      return rejectWithValue(optimisticRecord.id);
    }
  }
);
