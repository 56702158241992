import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import {
  selectBusinessNotificationsArray,
  selectNotificationsArray,
} from './notifications.selectors';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

const today = moment().format('YYYY-MM-DD');

export const selectLoggedInUserNotifications = createSelector(
  [selectNotificationsArray, selectLoggedInUserId],
  (notifications, userId) =>
    notifications.filter((notification) => notification.userId === userId)
);

export const selectActiveLoggedInUserNotifications = createSelector(
  [selectLoggedInUserNotifications],
  (userNotifications) => {
    const activeAlarms = userNotifications.filter(
      (notification) =>
        notification.cardsValidUntil >= today &&
        notification.applicationNotification &&
        !notification.isDismissed
    );

    return activeAlarms;
  }
);

export const selectLoggedInUserAdminBusinessNotifications = createSelector(
  [selectUserAdminBusinessIds, selectBusinessNotificationsArray],
  (userAdminBusinessIds, businessNotifications) =>
    businessNotifications.filter((notification) =>
      userAdminBusinessIds.includes(notification.businessId)
    )
);

export const selectActiveLoggedInUserAdminBusinessNotifications =
  createSelector(
    [selectLoggedInUserAdminBusinessNotifications],
    (notifications) =>
      notifications.filter(
        (notification) =>
          notification.cardsValidUntil >= today &&
          notification.applicationNotification &&
          !notification.isDismissed
      )
  );
