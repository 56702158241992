
import getFilePermission from "./getFilePermission";
import fetchFileAxios from "./fetchFileAxios";
import imageCompression from 'browser-image-compression';
import { getFileDownloadAsFileSignedUrlByFilePermission } from "../store/files/services/getFileDownloadAsFileSignedUrlByFilePermission.service";
import { getFileDownloadAsFileDataByKey } from "store/files/services/getFileDownloadAsFileDataByKey.service";
import { getFileSizeByFilePermissions } from "store/files/services/getFileSizeByFilePermission.service";
import { getFileSizeByKeys } from "store/files/services/getFileSizeByKey.service";

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isInt(value) {
  let x = parseFloat(value);
  return !isNaN(value) && (x | 0) === x;
}

export function getSizeInMB(sizeInBytes) {
  return sizeInBytes / (1024 * 1024);
}

/**
 * Used to get file sizes for files by file permission ids
 * @param {array} filepermissionIds - Array of filepermission ids
 * @returns - File sizes for files
 */
export const requestFileSizesByFilePermissions = async (filepermissionIds) => {
  const fileSizes = await getFileSizeByFilePermissions(filepermissionIds);
  return fileSizes;
};

/**
 * Used to get file sizes for files by file keys (S3 filepath)
 * @param {array} keys - Array of file keys
 * @returns - File sizes for files
 */
export const requestFileSizesByKeys = async (keys) => {
  const fileSizes = await getFileSizeByKeys(keys);
  return fileSizes;
};

/**
 * Used to download file from a key
 * @param {string} key - key of file in s3
 * @param {string} nameWithoutExtension - name of file (optional) if you wish to specify a different name, without extension
 * @returns - void - downloads file
 */
export async function handleDownloadByKey(key, nameWithoutExtension = null) {

  if (!key) {
    console.error('No key provided');
    return;
  }

  try {
    const response = await getFileDownloadAsFileDataByKey(key, nameWithoutExtension);
    console.log("🚀 ~ handleDownloadByKey ~ response:", response);

    if (!response) {
      console.error('No response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.url) {
      console.error('No url in response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.fileName) {
      console.error('No fileName in response from getFileDownloadAsFileDataByKey');
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = response.url;
    anchor.target = '_blank';
    anchor.download = response.fileName;

    document.body.appendChild(anchor);

    anchor.click();

    setTimeout(() => {
      document.body.removeChild(anchor);
    }, 100);

  } catch (error) {
    console.error('Error handling download:', error);
  }
};

/**
 * Used to download file by filepermission
 * @param {object} filePermission - filepermission object
 * @param {string} nameWithoutExtension - name of downloaded file (optional) if you wish to specify a different name, without extension
 * @returns - void - downloads file
 */
export async function handleDownloadByFilePermission(filePermission, nameWithoutExtension = null) {

  if (!filePermission) {
    console.error('No filePermission provided');
    return;
  }

  const fileName = nameWithoutExtension || filePermission.name;

  try {
    const response = await getFileDownloadAsFileSignedUrlByFilePermission(filePermission.id, fileName);
    console.log("🚀 ~ handleDownloadByFilePermission ~ response:", response);

    if (!response) {
      console.error('No response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.url) {
      console.error('No url in response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.fileName) {
      console.error('No fileName in response from getFileDownloadAsFileDataByKey');
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = response.url;
    anchor.target = '_blank';
    anchor.download = response.fileName;

    document.body.appendChild(anchor);

    anchor.click();

    setTimeout(() => {
      document.body.removeChild(anchor);
    }, 100);

  } catch (error) {
    console.error('Error handling download:', error);
  }
};

/**
 * Used to download file by filepermission id
 * @param {string} filePermissionId - filepermission id
 * @param {string} nameWithoutExtension - name of downloaded file (optional) if you wish to specify a different name, without extension
 * @returns - void - downloads file
 */
export async function handleDownloadByFilePermissionId(filePermissionId, nameWithoutExtension = null) {

  if (!filePermissionId) {
    console.error('No filePermissionId provided');
    return;
  }

  const filePermission = await getFilePermission(filePermissionId);

  const fileName = nameWithoutExtension || filePermission.name;

  try {
    const response = await getFileDownloadAsFileSignedUrlByFilePermission(filePermission.id, fileName);
    console.log("🚀 ~ handleDownloadByFilePermissionId ~ response:", response);

    if (!response) {
      console.error('No response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.url) {
      console.error('No url in response from getFileDownloadAsFileDataByKey');
      return;
    }

    if (!response.fileName) {
      console.error('No fileName in response from getFileDownloadAsFileDataByKey');
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = response.url;
    anchor.target = '_blank';
    anchor.download = response.fileName;

    document.body.appendChild(anchor);

    anchor.click();

    setTimeout(() => {
      document.body.removeChild(anchor);
    }, 100);

  } catch (error) {
    console.error('Error handling download:', error);
  }
};

/**
 * Used to get url (in S3) for image from file permission
 * @param {object} filePermissionId - file permission id
 * @returns - url to image
 */
export async function getImageUrlFromFilePermission(filePermissionId, cancelTokenSource) {
  if (!filePermissionId) return;
  const foundFilePermission = await getFilePermission(filePermissionId);
  if (!foundFilePermission) return;

  const fullSizeKey = foundFilePermission.key;
  const mediumKey = foundFilePermission.key.replace('project-bank', 'medium/prj-bank');
  let key = fullSizeKey;

  if (foundFilePermission.hasMediumSize) {
    key = mediumKey;
  }

  let imageUrl = await fetchFileAxios({ key, cancelTokenSource });

  // Handle error case where medium size file was not found even though it should exist
  if (!imageUrl) {
    const fullSizeUrl = await fetchFileAxios({ key: fullSizeKey, cancelTokenSource });
    if (fullSizeUrl) {
      const compressedUrl = await getCompressedImage(fullSizeUrl);
      imageUrl = compressedUrl;
    }
  }

  return imageUrl;
}

/**
     * Used to compress large images to smaller size
     * @param {file} imageFile 
     * @returns compressed image file
     */
export async function compressImage(imageFile) {
  const options = {
    maxSizeMB: 0.25,
    maxWidthOrHeight: 1920
  }
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Used to compress image from s3 url
 * @param {string} imageUrl - url to s3 image
 * @returns 
 */
export async function getCompressedImage(imageUrl) {
  const file = await fetch(imageUrl).then((r) => r.blob());
  const compressedFile = await compressImage(file);
  const newImageUrl = URL.createObjectURL(compressedFile);
  return newImageUrl;
}

export function dataURLToBlob(dataURL) {
  const parts = dataURL.split(',');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uint8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uint8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: contentType });
}

export function dataURLToBlobURL(dataURL) {
  // Convert Data URL to Blob
  const blob = dataURLToBlob(dataURL);

  // Create a Blob URL
  const blobURL = URL.createObjectURL(blob);

  return blobURL;
}

export async function getDataUrlFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    }
  });
}

/**
* Used to get base64 image from url
* @param {string} url 
* @returns promise with base64 image
*/
export async function getBase64FromURL(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

/**
* Create base64 url from a file
* @param {file} file 
*/
export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = error => reject(error);
  });
}

export const getHalfSizeDataUrlFromUrl = async (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      // set size proportional to image
      if (img.height <= 600 || img.width <= 600) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      } else {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.height = canvas.width * (img.height / img.width);

        // step 1 - resize to 50%
        var oc = document.createElement('canvas'),
          octx = oc.getContext('2d');

        oc.width = img.width * 0.5;
        oc.height = img.height * 0.5;
        octx.drawImage(img, 0, 0, oc.width, oc.height);

        // step 2
        octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

        // step 3, resize to final size
        ctx.drawImage(
          oc,
          0,
          0,
          oc.width * 0.5,
          oc.height * 0.5,
          0,
          0,
          canvas.width,
          canvas.height
        );
        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      }
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};

/**
   * browserDetect detects your browser and returns it as a String
   */
export function browserDetect() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Google Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else {
    browserName = '?';
  }

  return browserName;
}
