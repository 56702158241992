import { createSelector } from '@reduxjs/toolkit';

const selectNotificationSnackbarState = (state) => state.notificationSnackbar;

export const selectIsNotificationSnackbarOpen = createSelector(
  [selectNotificationSnackbarState],
  (state) => state.isOpen
);

export const selectNotificationSnackbarMessage = createSelector(
  [selectNotificationSnackbarState],
  (state) => state.message
);

export const selectNotificationSnackbarType = createSelector(
  [selectNotificationSnackbarState],
  (state) => state.type
);
