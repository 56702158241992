import Config from 'Config';
import aws4 from 'aws4';
import getCredentials from 'utils/getCredentials';

export default async function fetchFilePermissions(projectId, fromDate, toDate, lastEvaluatedKey = null) {
    const apiPath = `file-permissions/${projectId}`;
    const { accessKeyId, secretAccessKey, sessionToken } = await getCredentials();

    const request = {
        host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
        path: apiPath,
        method: 'POST',
        region: Config.AWS_REGION,
        service: 'execute-api',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            fromDate,
            toDate,
            lastEvaluatedKey,
        }),
    };
    const signer = new aws4.RequestSigner(request, {
        accessKeyId,
        secretAccessKey,
        sessionToken
    });
    const signedRequest = signer.sign();
    signedRequest.headers.Accept = '*/*';

    const response = await fetch(
        `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
        // `http://localhost:3001/${apiPath}`,
        signedRequest
    ).then((res) => res.json());
    console.log("🚀 ~ file: getFilePermissions.js ~ response", response)
    return response;
}