import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedRecordChangesInObjectFormatWithfilledData } from 'store/records/selectors/records.selectors';
import { loadRecordHistory } from 'store/records/thunks/loadRecordHistory';

export function HistoryList() {
  const changesWithFilledData = useSelector(
    selectSelectedRecordChangesInObjectFormatWithfilledData
  );

  return changesWithFilledData.map((changeObject, index) => {
    return (
      <Grid key={index} item xs={12}>
        <Typography>{`${changeObject.timeStamp} - ${changeObject.user}`}</Typography>
        {changeObject.changeList.map((listItem, index) => {
          return (
            <Typography
              key={index}
              paragraph={true}
              variant="caption"
            >{`${listItem.key}: ${listItem.oldValue} -> ${listItem.newValue}`}</Typography>
          );
        })}
      </Grid>
    );
  });
}
