import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAdmin } from 'scenes/Permissions/services/deleteAdmin.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const removeAdminStatusOfUser = createAsyncThunk(
  'permissionsManagement/removeAdminStatusOfUser',
  async (userId, { getState }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const businessId = selectedProject.business_id;

    if (!businessId) {
      throw new Error('no business id found :(');
    }

    const response = await deleteAdmin(userId, businessId);

    return response;
  }
);
