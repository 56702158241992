import i18next from 'i18next';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import useStyles from './RecordDialogLayoutStyles';
import Button from 'components/Button/Button';
import { selectUsersSelectedRecordSingleApproval } from 'store/approvals/selectors/approvals.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { approveRecord } from 'store/records/thunks/approveRecord.thunk';
import { disapproveRecord } from 'store/records/thunks/disapproveRecord.thunk';
import { checkRecord } from 'store/records/thunks/checkRecord.thunk';
import { uncheckRecord } from 'store/records/thunks/uncheckRecord.thunk';

export function ApprovalComponent({
  isPermissionToCheck,
  isPermissionToApprove,
  updatesPending,
  checkedOut,
  isRecordAttributeChanges,
  checked,
  sentToApproval,
}) {
  const { t } = useTranslation('common');

  const classes = useStyles();
  const dispatch = useDispatch();
  const singleApproval = useSelector(selectUsersSelectedRecordSingleApproval);
  const alreadyApprovedBySingleApproval = singleApproval ? true : false;

  const handleApprove = () => {
    dispatch(approveRecord());
  };

  const handleDisapprove = () => {
    dispatch(disapproveRecord(singleApproval.SK));
  };

  const handleCheck = () => {
    if (!checked) {
      dispatch(checkRecord());
    } else {
      dispatch(uncheckRecord());
    }
  };

  return (
    <>
      <Grid item xs={6} className={classes.centerItems}>
        <Button
          color="primary"
          disabled={
            !isPermissionToCheck ||
            updatesPending ||
            !checkedOut ||
            isRecordAttributeChanges
          }
          onClick={() => handleCheck()}
        >
          {!checked ? t('calendar.check_all') : t('calendar.uncheck_all')}
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.centerItems}>
        {!alreadyApprovedBySingleApproval ? (
          <Button
            color="primary"
            disabled={
              !isPermissionToApprove ||
              updatesPending ||
              !checkedOut ||
              isRecordAttributeChanges ||
              sentToApproval
            }
            onClick={() => handleApprove()}
          >
            {t('calendar.approve')}
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={
              !isPermissionToApprove ||
              updatesPending ||
              !checkedOut ||
              isRecordAttributeChanges
            }
            onClick={() => handleDisapprove()}
          >
            {t('Poista hyväksyntä')}
          </Button>
        )}
      </Grid>
      {isRecordAttributeChanges ? (
        <Grid item xs={12} className={classes.centerItems}>
          <FormHelperText>
            {`${t('calendar.unsaved_changes')} ${t(
              'calendar.save_changes_before_checking_or_approval'
            )}`}
          </FormHelperText>
        </Grid>
      ) : null}
    </>
  );
}
