
export const useForm = () => {

    /**
     * Used to set the TR-meter form ready to be saved to the database.
     * Remove measurement_target notes, notesToDatabase ( both saved seperately) & originalNotes (no need to save) from projectForm
     * @param {object} projectForm  to be saved to the database
     * @returns {object} projectForm ready to be sent to the database
     */
    function setTRFormReadyToBeSaved(projectForm) {
        let tempTargets = {};
        for (const [key, target] of Object.entries(projectForm.measurement_targets)) {
            const { notes, ...rest } = target;
            let noteIds = [];
            for (const note of notes) {
                noteIds.push(note.id);
            }
            tempTargets = { ...tempTargets, [key]: { ...rest, notes: noteIds } };
        }
        const { measurement_targets, notesToDatabase, originalNotes, loaded, ...rest } = projectForm;
        projectForm = { ...rest, measurement_targets: tempTargets };
        return projectForm;
    }

    /**
     * Used to set the Orientation form ready to be saved to the database.
     * Remove licenseRows images from projectForm
     * @param {object} projectForm  to be saved to the database
     * @returns {object} projectForm ready to be sent to the database
     */
    function setOrientationFormReadyToBeSaved(projectForm) {
        if (!projectForm.licenseRows || !projectForm.licenseRows.length) return projectForm;
        let tempLicenses = [...projectForm.licenseRows];
        const licensesWithoutImages = tempLicenses.map(license => {
            const { image, ...rest } = license;
            return rest;
        });
        projectForm = { ...projectForm, licenseRows: licensesWithoutImages };
        return projectForm;
    }

    return {
        setTRFormReadyToBeSaved,
        setOrientationFormReadyToBeSaved
    }
}