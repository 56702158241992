import clsx from 'clsx';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Dialog from 'components/DialogWrapper/DialogWrapper';

import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CalendarEditIcon from 'mdi-material-ui/CalendarEdit';
import CalendarRemoveIcon from 'mdi-material-ui/CalendarRemove';

import Button from 'components/Button/Button';
import ButtonSwitch from 'components/ButtonSwitch/ButtonSwitch';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import InTime from 'components/InTime/InTime';
import TextInput from 'components/TextInput/TextInput';
import AssignmentsSelect from './AssignmentsSelect';
import useStyles from './RecordDialogLayoutStyles';

import { RECORD_STATUS } from 'LixaniAPI/enums';

import MapDialog from 'components/MapDialog/MapDialog';
import ComboBox from 'scenes/ExpenseTracking/components/ComboBox';
import sortByName from 'utils/sortByName';

import { selectProjectAssignments } from 'store/assignments/selectors/assignment.selectors';
import {
  closeEditRecordDialog,
  setEditRecordDialogAttributes,
} from 'store/records/actions/records.actions';
import {
  isPermissionToApproveSelectedRecord,
  isPermissionToCheckSelectedRecordBilledTimes,
  isPermissionToCheckSelectedRecordClockedTimes,
  isPermissionsToEditSelectedRecord,
  selectEditDialogIsRecordAttributeChanges,
  selectEditRecordDialogData,
  selectIsAdminOfSelectedRecordProject,
  selectIsDialogResourcesLoading,
  selectIsRecordHistoryLoading,
  selectIsSelectedRecordPartOfApprovalGroup,
  selectRecordEditPending,
  selectRecordStatusChangePending,
  selectSelectedRecordApproveStatus,
  selectSelectedRecordCheckStatus,
  selectSelectedRecordCheckTimes,
} from 'store/records/selectors/records.selectors';
import { selectProjectResources } from 'store/resources/selectors/resources.selectors';
import { loadResourcesAndResourceEmployersOfProject } from 'store/resources/thunks/loadResourcesAndResourceEmployersOfProject';
import { TypeChange } from './typeChange';

import { CircularProgress, TextField } from '@material-ui/core';
import { deleteRecordThunk } from 'store/records/thunks/deleteRecordThunk';
import { editRecord } from 'store/records/thunks/editRecord';
import { HistoryList } from './historyList';

import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import { selectIsLoadApproversPending } from 'store/approvals/selectors/approvals.selectors';
import { loadActiveAssignmentsForProject } from 'store/assignments/thunks/loadActiveAssignmentsForProject';
import { selectEditDialogProjectOptions } from 'store/projects/selectors/recordProjectOptions.selectors';
import { handleBreakRecordsStatusChange } from 'store/records/thunks/handleBreakRecordsStatusChange';
import { loadRecordHistory } from 'store/records/thunks/loadRecordHistory';
import { BreaksComponent } from './BreaksComponent';
import { CheckSingleTimeCheckBox } from './CheckSingleTimeCheckBox';
import { ApprovalComponent } from './approvalComponent';
import { ApproverList } from './approverList';
import { NewHistoryList } from './newHistoryList';
import { RecordAllowances } from './RecordAllowances/RecordAllowances';

function EditRecordDialog({ open }) {
  const dispatch = useDispatch();
  const isPermissionsToEdit = useSelector(isPermissionsToEditSelectedRecord);
  const isPermissionToApprove = useSelector(
    isPermissionToApproveSelectedRecord
  );

  const isPermissionToCheckClockedTimes = useSelector(
    isPermissionToCheckSelectedRecordClockedTimes
  );

  const isPermissionToCheckBilledTimes = useSelector(
    isPermissionToCheckSelectedRecordBilledTimes
  );

  const dialogData = useSelector(selectEditRecordDialogData);
  const {
    id,
    project,
    clockInAt,
    clockOutAt,
    billedClockInAt,
    billedClockOutAt,
    status,
    type,
    description,
    resource,
    assignment,
    targetCountry,
    latIn,
    latOut,
    longIn,
    longOut,
    assignmentUnitAmount,
  } = dialogData;

  const classes = useStyles();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [removeRecordDialog, setRemoveRecordDialog] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const isAdminOfRecordProject = useSelector(
    selectIsAdminOfSelectedRecordProject
  );

  const { t } = useTranslation('common');

  const approved = useSelector(selectSelectedRecordApproveStatus);
  const sentToApproval = useSelector(selectIsSelectedRecordPartOfApprovalGroup);
  const checked = useSelector(selectSelectedRecordCheckStatus);
  const statusChangePending = useSelector(selectRecordStatusChangePending);
  const recordEditPending = useSelector(selectRecordEditPending);
  const historyLoading = useSelector(selectIsRecordHistoryLoading);
  const isLoadApproversPending = useSelector(selectIsLoadApproversPending);

  const updatesPending = statusChangePending || recordEditPending;

  const [showHistory, setShowHistory] = useState(false);
  const checkedOut = status === 'OUT' ? true : false;

  const projectOptions = useSelector(selectEditDialogProjectOptions);

  const isResourcesLoading = useSelector(selectIsDialogResourcesLoading);
  const isRecordAttributeChanges = useSelector(
    selectEditDialogIsRecordAttributeChanges
  );

  const {
    billedInAtChecked,
    billedOutAtChecked,
    clockedInAtChecked,
    clockedOutAtChecked,
  } = useSelector(selectSelectedRecordCheckTimes);

  const openHistory = () => {
    setShowHistory((prev) => {
      if (!prev) {
        dispatch(loadRecordHistory());
      }
      return !prev;
    });
  };

  const [unitAmountDialogOpen, setUnitAmountDialogOpen] = useState(false);

  const setAssignmentUnitAmount = (value) => {
    setAttributes({ assignmentUnitAmount: value });
  };
  const setAssignmentUnitAmountDialogOpen = (value) => {
    setUnitAmountDialogOpen(value);
  };

  const projectAssignments = useSelector((state) =>
    selectProjectAssignments(state, project)
  );

  const projectResources = useSelector((state) =>
    selectProjectResources(state, project)
  );

  const handleTypeChange = (value) => {
    setAttributes({ type: value });
  };

  const handleSave = () => {
    dispatch(editRecord(dialogData));
  };

  const closeDialog = () => {
    dispatch(closeEditRecordDialog());
  };

  const setAttributes = (attributeObject) => {
    dispatch(setEditRecordDialogAttributes(attributeObject));
  };

  const debounceDescriptionSearch = useCallback(
    debounce(setDescriptionSearchText, 500),
    []
  );

  function setDescriptionSearchText(value) {
    setAttributes({ description: value });
  }

  const handleClockInGreaterThanClockout = (clockIn, clockOut) => {
    if (clockIn > clockOut) {
      setAttributes({ clockInAt: clockIn, clockOutAt: clockIn });
    }
  };

  const handleBilledClockInGreaterThanClockout = (clockIn, clockOut) => {
    if (clockIn > clockOut) {
      setAttributes({ billedClockInAt: clockIn, billedClockOutAt: clockIn });
    }
  };

  const handleProjectChange = (project) => {
    setAttributes({
      project: project,
      resource: undefined,
      assignment: undefined,
    });
    dispatch(loadResourcesAndResourceEmployersOfProject(project));
    dispatch(loadActiveAssignmentsForProject(project));
  };

  const handleAssignmentChange = (assignmentId) => {
    // handling assignment deselect on click if assignment already selected
    if (assignmentId !== assignment) {
      setAttributes({
        assignment: assignmentId,
        assignmentUnitAmount: undefined,
      });
    } else {
      setAttributes({
        assignment: undefined,
        assignmentUnitAmount: undefined,
      });
    }
  };

  const handleRecordDelete = () => {
    dispatch(deleteRecordThunk({ projectId: project, recordId: id }));
    closeDialog();
  };

  const clockedInGreaterThanClockedOut = clockInAt > clockOutAt;

  const billedClockInGreaterThanClockout = billedClockInAt > billedClockOutAt;

  const dateFormat = 'YYYY-MM-DDTHH:mm';

  const formattedClockInAt = moment(clockInAt).format(dateFormat);

  const formattedClockOutAt = moment(clockOutAt).format(dateFormat);

  const formattedBilledClockInAt = moment(billedClockInAt).format(dateFormat);

  const formattedBilledClockOutAt = moment(billedClockOutAt).format(dateFormat);

  const formatDateOutput = (date) => {
    return moment(date).toISOString();
  };

  const handleCheckOutStatus = () => {
    const status = checkedOut ? 'IN' : 'OUT';

    const now = formatDateOutput(moment());
    setAttributes({
      status: status,
      billedClockOutAt: now,
      clockOutAt: now,
    });

    dispatch(handleBreakRecordsStatusChange({ status: status, now: now }));
  };

  const loadingSpinner = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
      }}
    >
      <CircularProgress color="primary" size={36} />
    </div>
  );

  return (
    <>
      <Dialog
        aria-labelledby="edit-record-dialog"
        classes={{ paper: classes.dialogPaper }}
        disableBackdropClick
        fullScreen={!upSm}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={closeDialog}
      >
        <DialogHeader
          icon={<CalendarEditIcon />}
          onClose={closeDialog}
          title={t('calendar.edit_record')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2} className={classes.gridContainer}>
            {!isLoadApproversPending ? (
              <>
                <Grid item xs={12}>
                  <ApproverList></ApproverList>
                </Grid>
                {isPermissionToCheckClockedTimes || isPermissionToApprove ? (
                  <ApprovalComponent
                    isPermissionToCheck={
                      isPermissionToCheckClockedTimes ||
                      isPermissionToCheckBilledTimes
                    }
                    isPermissionToApprove={isPermissionToApprove}
                    updatesPending={updatesPending}
                    checkedOut={checkedOut}
                    isRecordAttributeChanges={isRecordAttributeChanges}
                    checked={checked}
                    sentToApproval={sentToApproval}
                  ></ApprovalComponent>
                ) : null}
              </>
            ) : (
              <Grid item xs={12} className={classes.centerItems}>
                {loadingSpinner}
              </Grid>
            )}

            {showHistory ? (
              !historyLoading ? (
                <>
                  <HistoryList></HistoryList>
                  <NewHistoryList></NewHistoryList>
                </>
              ) : (
                <Grid item xs={12} className={classes.centerItems}>
                  {loadingSpinner}
                </Grid>
              )
            ) : null}
            <Grid item xs={12} className={classes.gridItem}>
              <Typography className={classes.inTime}>
                IN{' '}
                <InTime
                  clockInAt={formattedClockInAt}
                  clockOutAt={formattedClockOutAt}
                  status={checkedOut ? RECORD_STATUS.OUT : RECORD_STATUS.IN}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.clockedItem}>
              <ButtonSwitch
                checked={!checkedOut}
                fullWidth
                on={t('record.clocked_in')}
                off={t('record.clocked_out')}
                onChange={handleCheckOutStatus}
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  checked ||
                  sentToApproval ||
                  billedOutAtChecked ||
                  clockedOutAtChecked
                }
              />
            </Grid>
            {/*LEIMATTU AIKA*/}
            <Grid item xs={12}>
              <label className="mylabel">{t('calendar.clocked_time')}</label>
            </Grid>
            <Grid item xs={6}>
              <CheckSingleTimeCheckBox
                isPermissionToCheck={isPermissionToCheckClockedTimes}
                updatesPending={updatesPending}
                checkedOut={checkedOut}
                isRecordAttributeChanges={isRecordAttributeChanges}
                checked={clockedInAtChecked}
                attributeToEditName={'clockedInAtChecked'}
              ></CheckSingleTimeCheckBox>

              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: classes.textInput }}
                fullWidth
                label={t('calendar.start_date')}
                variant="outlined"
                size="small"
                type="datetime-local"
                value={formattedClockInAt}
                onChange={(event) =>
                  setAttributes({
                    clockInAt: formatDateOutput(event.target.value),
                  })
                }
                onBlur={(event) =>
                  handleClockInGreaterThanClockout(
                    formatDateOutput(event.target.value),
                    formatDateOutput(clockOutAt)
                  )
                }
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  checked ||
                  sentToApproval ||
                  clockedInAtChecked
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={clsx(classes.dateItem, checkedOut && classes.hidden)}
            >
              <Typography className={classes.dateItem}>
                {t('calendar.clocked_in')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={clsx(!checkedOut && classes.hidden)}>
              <CheckSingleTimeCheckBox
                isPermissionToCheck={isPermissionToCheckClockedTimes}
                updatesPending={updatesPending}
                checkedOut={checkedOut}
                isRecordAttributeChanges={isRecordAttributeChanges}
                checked={clockedOutAtChecked}
                attributeToEditName={'clockedOutAtChecked'}
              ></CheckSingleTimeCheckBox>
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{ className: classes.textInput }}
                fullWidth
                label={t('calendar.end_date')}
                variant="outlined"
                size="small"
                type="datetime-local"
                value={formattedClockOutAt}
                onChange={(event) =>
                  setAttributes({
                    clockOutAt: formatDateOutput(event.target.value),
                  })
                }
                onBlur={(event) =>
                  handleClockInGreaterThanClockout(
                    formatDateOutput(clockInAt),
                    formatDateOutput(event.target.value)
                  )
                }
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  checked ||
                  sentToApproval ||
                  clockedOutAtChecked
                }
              />
            </Grid>
            {/*LASKUTETTAVA AIKA*/}
            {isPermissionToApprove ? (
              <>
                <Grid item xs={12}>
                  <label className="mylabel">{t('calendar.billed_time')}</label>
                </Grid>
                <Grid item xs={6}>
                  <CheckSingleTimeCheckBox
                    isPermissionToCheck={isPermissionToCheckBilledTimes}
                    updatesPending={updatesPending}
                    checkedOut={checkedOut}
                    isRecordAttributeChanges={isRecordAttributeChanges}
                    checked={billedInAtChecked}
                    attributeToEditName={'billedInAtChecked'}
                  ></CheckSingleTimeCheckBox>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: classes.textInput }}
                    fullWidth
                    label={t('calendar.start_date')}
                    variant="outlined"
                    size="small"
                    type="datetime-local"
                    value={formattedBilledClockInAt}
                    onChange={(event) =>
                      setAttributes({
                        billedClockInAt: formatDateOutput(event.target.value),
                      })
                    }
                    onBlur={(event) =>
                      handleBilledClockInGreaterThanClockout(
                        formatDateOutput(event.target.value),
                        formatDateOutput(billedClockOutAt)
                      )
                    }
                    disabled={
                      (!isPermissionsToEdit && !isPermissionToApprove) ||
                      approved ||
                      updatesPending ||
                      sentToApproval ||
                      billedInAtChecked
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(
                    classes.dateItem,
                    checkedOut && classes.hidden
                  )}
                >
                  <Typography className={classes.clockedText}>
                    {t('calendar.clocked_in')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(!checkedOut && classes.hidden)}
                >
                  <CheckSingleTimeCheckBox
                    isPermissionToCheck={isPermissionToCheckBilledTimes}
                    updatesPending={updatesPending}
                    checkedOut={checkedOut}
                    isRecordAttributeChanges={isRecordAttributeChanges}
                    checked={billedOutAtChecked}
                    attributeToEditName={'billedOutAtChecked'}
                  ></CheckSingleTimeCheckBox>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: classes.textInput }}
                    fullWidth
                    label={t('calendar.end_date')}
                    variant="outlined"
                    size="small"
                    type="datetime-local"
                    value={formattedBilledClockOutAt}
                    onChange={(event) =>
                      setAttributes({
                        billedClockOutAt: formatDateOutput(event.target.value),
                      })
                    }
                    onBlur={(event) =>
                      handleBilledClockInGreaterThanClockout(
                        formatDateOutput(billedClockInAt),
                        formatDateOutput(event.target.value)
                      )
                    }
                    disabled={
                      (!isPermissionsToEdit && !isPermissionToApprove) ||
                      approved ||
                      updatesPending ||
                      sentToApproval ||
                      billedOutAtChecked
                    }
                  />
                </Grid>
              </>
            ) : null}
            <BreaksComponent
              disabled={!isPermissionsToEdit || approved || updatesPending}
              addingBreaksDisabled={
                sentToApproval || approved || updatesPending
              }
              parentClockIn={formattedClockInAt}
              parentClockOut={formattedClockOutAt}
            ></BreaksComponent>
            <Grid item xs={12} className={classes.gridItem}>
              <RecordAllowances />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <ComboBox
                items={projectOptions}
                selected={project}
                onChange={(e) => handleProjectChange(e)}
                label={t('project.project')}
                emptyOption={false}
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  sentToApproval
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <TypeChange
                handleChange={handleTypeChange}
                selectedType={type}
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  sentToApproval
                }
              ></TypeChange>
            </Grid>
            {type === 'RIDE' && (
              <Grid item xs={12} className={classes.gridItem}>
                <CountrySelect
                  changeCountry={(country) =>
                    setAttributes({ targetCountry: country })
                  }
                  label={t('record.country')}
                  propsCountry={targetCountry}
                  fullWidth
                  disabled={
                    !isPermissionsToEdit ||
                    approved ||
                    updatesPending ||
                    sentToApproval
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.gridItem}>
              <ComboBox
                items={projectResources
                  .sort((a, b) => sortByName(a, b, 'lastName'))
                  .map((item) => {
                    if (item.employer?.name) {
                      return {
                        ...item,
                        name: `${item.employer.name} / ${item.lastName} ${item.firstName}`,
                      };
                    } else {
                      return {
                        ...item,
                        name: `MyLixani (Ei työnantajaa) / ${item.lastName} ${item.firstName}`,
                      };
                    }
                  })}
                selected={resource}
                onChange={(value) => setAttributes({ resource: value })}
                label={t('resource.resource')}
                emptyOption={false}
                disabled={
                  isResourcesLoading ||
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  sentToApproval
                }
                loading={isResourcesLoading}
              />
            </Grid>
            {type === 'WORK' && (
              <>
                <Grid item xs={4} className={classes.gridItem}>
                  <TextInput
                    value={latIn ? 'Saatu' : 'Ei saatu'}
                    fullWidth
                    label={'Sijainti sisään'}
                    multiline
                    disabled={true}
                  />
                </Grid>
                {clockOutAt && (
                  <Grid item xs={4} className={classes.gridItem}>
                    <TextInput
                      value={latOut ? 'Saatu' : 'Ei saatu'}
                      fullWidth
                      label={'Sijainti ulos'}
                      multiline
                      disabled={true}
                    />
                  </Grid>
                )}
                <Grid item xs={4} className={classes.gridItem}>
                  <Button
                    className={classes.actionBtn}
                    onClick={() => setMapOpen(true)}
                    outline
                    color="warning"
                    label={'Paikkatieto'}
                    disabled={
                      (latIn && longIn) || (latOut && longOut) ? false : true
                    }
                  >
                    {'Avaa kartta'}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.gridItem}>
              <TextInput
                fullWidth
                id="description"
                onChange={(e) => debounceDescriptionSearch(e.target.value)}
                label={t('calendar.description')}
                multiline
                disabled={
                  !isPermissionsToEdit ||
                  approved ||
                  updatesPending ||
                  sentToApproval
                }
                defaultValue={description}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth>
                <AssignmentsSelect
                  a1={
                    projectAssignments && projectAssignments[0]
                      ? projectAssignments[0]
                      : undefined
                  }
                  disabled={
                    !isPermissionsToEdit ||
                    approved ||
                    updatesPending ||
                    sentToApproval
                  }
                  selectedAssignment={assignment}
                  assignments={projectAssignments}
                  update={handleAssignmentChange}
                  unitAmountDialogOpen={unitAmountDialogOpen}
                  setDialogOpen={setAssignmentUnitAmountDialogOpen}
                  setAssignmentUnitAmount={setAssignmentUnitAmount}
                  unitAmount={assignmentUnitAmount}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions className={classes.dialogActions}>
          {isPermissionsToEdit ? (
            <Button
              className={clsx(classes.actionBtn, classes.actionBtnDelete)}
              color="danger"
              onClick={() => setRemoveRecordDialog(true)}
              disabled={approved || updatesPending || sentToApproval}
            >
              {t('common.remove')}
            </Button>
          ) : null}
          <Button
            className={classes.actionBtn}
            onClick={openHistory}
            outline
            color="primary"
          >
            {t('record.history')}
          </Button>

          <Button
            className={classes.actionBtn}
            onClick={closeDialog}
            outline
            color="primary"
          >
            {t('common.close')}
          </Button>

          <Button
            className={classes.actionBtn}
            onClick={handleSave}
            color="primary"
            disabled={
              clockedInGreaterThanClockedOut ||
              billedClockInGreaterThanClockout ||
              !resource ||
              !isPermissionsToEdit ||
              approved ||
              updatesPending ||
              sentToApproval
            }
          >
            {!recordEditPending ? t('common.save') : <ButtonLoading />}
          </Button>
        </DialogActions>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={removeRecordDialog}
          onClose={() => setRemoveRecordDialog(false)}
          aria-labelledby="contact-delete-dialog"
          maxWidth="sm"
        >
          <DialogHeader
            icon={<CalendarRemoveIcon />}
            onClose={() => setRemoveRecordDialog(false)}
            title={t('calendar.remove_record')}
          />
          <DialogContent className={classes.removeRecordDialogContent}>
            <DialogContentText>
              {t('calendar.remove_record_confirmation')}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => setRemoveRecordDialog(false)}
              color="primary"
            >
              {t('common.cancel')}
            </Button>
            <Button onClick={handleRecordDelete} color="primary">
              {t('common.remove')}
            </Button>
          </DialogActions>
        </Dialog>
        {mapOpen && (
          <MapDialog
            onClose={() => setMapOpen(false)}
            records={[dialogData]}
            projects={[
              projectOptions.find(
                (projectOption) => projectOption.id === project
              ),
            ]}
          ></MapDialog>
        )}
      </Dialog>
    </>
  );
}

export default EditRecordDialog;
