import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApprovalGroupsByProject } from '../services/getApprovalGroupsByProject.service';

export const loadApprovalGroupsByProject = createAsyncThunk(
  'approvals/load-approval-groups-by-project',
  async (projectId) => {
    const response = await getApprovalGroupsByProject(projectId);
    return response;
  }
);
