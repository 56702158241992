import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { loadUsersAndPersonalResources } from './thunks/loadUsersAndPersonalResources';
import { loadUsersAndPersonalResourcesByUserIds } from './thunks/loadUsersAndPersonalResourcesByUserIds';
import { loadEmployeeConfigurationsInBusiness } from './thunks/loadEmployeeConfigurationsInBusiness.thunk';
import { groupBy } from 'lodash-es';
import {
  closeAddNewUserDialog,
  openAddNewUserDialog,
} from './actions/project-users.actions';
import { createOrUpdateUser } from 'store/user/thunks/createOrUpdateUser.thunk';

const usersAdapter = createEntityAdapter();
const userPersonalResourcesAdapter = createEntityAdapter();

const projectUsersSlice = createSlice({
  name: 'projectUsers',
  initialState: {
    users: usersAdapter.getInitialState(),
    userPersonalResources: userPersonalResourcesAdapter.getInitialState(),
    employeeBusinessConfigurationsByBusinessId: {},
    loading: false,
    isAddNewUserDialogOpen: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUsersAndPersonalResources.pending, (state) => {
        state.loading = true;
      })

      .addCase(loadUsersAndPersonalResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(
        loadEmployeeConfigurationsInBusiness.fulfilled,
        (state, action) => {
          state.employeeBusinessConfigurationsByBusinessId = groupBy(
            action.payload,
            'businessId'
          );
        }
      )
      .addCase(openAddNewUserDialog, (state, action) => {
        state.isAddNewUserDialogOpen = true;
      })
      .addCase(closeAddNewUserDialog, (state, action) => {
        state.isAddNewUserDialogOpen = false;
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        state.isAddNewUserDialogOpen = false;
      })
      .addMatcher(
        isAnyOf(
          loadUsersAndPersonalResources.fulfilled,
          loadUsersAndPersonalResourcesByUserIds.fulfilled
        ),
        (state, action) => {
          const users = action.payload.users;

          const userPersonalResources = action.payload.userPersonalResources;

          usersAdapter.setMany(state.users, users);
          usersAdapter.setMany(
            state.userPersonalResources,
            userPersonalResources
          );
          state.loading = false;
        }
      );
  },
});

export default projectUsersSlice.reducer;
