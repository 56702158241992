import { createAction } from '@reduxjs/toolkit';

export const showOriginalText = createAction(
  'translations/showOriginalText',
  (targetId) => ({
    payload: targetId,
  })
);

export const showTranslatedText = createAction(
  'translations/showTranslatedText',
  (targetId) => ({
    payload: targetId,
  })
);
