import { createAction } from '@reduxjs/toolkit';

export const updateFilepermission = createAction(
  'filepermissions/updateFilepermission'
);

export const setSelectedProjectBankFolder = createAction(
  'filePermissions/setSelectedProjectBankFolder'
);

export const setInitialProjectBankPath = createAction(
  'filePermissions/setInitialProjectBankPath'
);

export const setFolderTree = createAction('filePermissions/setFolderTree');
