import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { withApollo } from 'react-apollo';
import { GoogleMap, Marker, InfoWindow, Circle } from '@react-google-maps/api';

const Map = (props) => {
  const { center, zoom, markers, mapType, circles } = props;
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [mapCenter, setMapCenter] = useState({lat: 0, lng: 0});
  
  const centerOptions = useMemo(() => ({ lat: mapCenter?.lat, lng: mapCenter?.lng }), [mapCenter])

  const [mapZoom, setMapZoom] = useState(zoom);
  const [infoOpen, setInfoOpen] = useState(false);
  const [markerMap, setMarkerMap] = useState({});

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setMapCenter(marker.position);
    if (mapZoom < 13) {
      setMapZoom(13);
    }
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };

  // Called when infobox is closed, closes info and sets active markers as null
  const handleCloseInfo = () => {
    setActiveMarker(null);
    setInfoOpen(false);
  };

  // Centers and zooms the map so that all markers fit inside
  const fitBounds = async (map) => {
    if (!map) return;
    if (markers.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((place) => {
        bounds.extend(place.position);
        return place.id;
      });
      map.fitBounds(bounds);
      
    } else {
      setMapCenter(markers[0].position);
      setMapZoom(15);
    }
  };

  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const loadHandler = React.useCallback(
    (map) => {
      setMapRef(map);
      fitBounds(map);
    },
    [markers]
  );

  const handleCenterChanged = useCallback(() => {
    if (mapRef !== undefined && mapRef !== null) {
      const newCenter = mapRef.getCenter()
      
      if (newCenter !== undefined && setMapCenter !== undefined) {
        const tempCenter = { latitude: newCenter.lat(), longitude: newCenter.lng() }
        console.log("changing center", tempCenter)
        setMapCenter(tempCenter)
      }
    }
  }, [mapRef, setMapCenter])

  return (
    <GoogleMap
      onLoad={loadHandler}
      onCenterChanged={() => {
        if (mapRef !== null) {
          //handleCenterChanged()
        }
      }}
      onZoomChanged={() => setMapZoom(mapRef.getZoom())}
      zoom={mapZoom}
      center={centerOptions}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{
        height: '70vh',
        width: '100%',
      }}
      mapTypeId={mapType}
    >
      {markers &&
        markers.map((marker) => {
          return (
            <Marker
              key={marker.id}
              position={marker.position}
              icon={marker.icon}
              onClick={() => handleActiveMarker(marker)}
              onLoad={(eventMarker) => markerLoadHandler(eventMarker, marker)}
            ></Marker>
          );
        })}
      {infoOpen && activeMarker && (
        <InfoWindow
          anchor={markerMap[activeMarker.id]}
          onCloseClick={() => handleCloseInfo()}
        >
          <div style={{ color: 'black' }}>
            {activeMarker.desc}
            <br />
            {activeMarker.address}
          </div>
        </InfoWindow>
      )}
      {circles &&
        circles.map((circle) => {
          return (
            <Circle
              key={circle.id}
              center={circle.center}
              options={circle.options}
              onClick={() => console.log('Clicked circle')}
            ></Circle>
          );
        })}
    </GoogleMap>
  );
};
const memoMap = memo(withApollo(Map))
export default memoMap;


