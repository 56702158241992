import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setDeleteFormTemplate from 'scenes/ProjectForms/utils/setDeleteFormTemplate';

export const deleteFormTemplate = createAsyncThunk(
  'projectForm/deleteFormTemplate',
  async (templateData, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const deletedFormTemplate = await setDeleteFormTemplate(templateData, params);
    return { deletedFormTemplate };
  }
);
