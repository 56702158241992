function hoursToTime(hours, noMinutes = false) {
  const _hours = hours.toString().split('.')[0];
  const _minutes = (
    '0' + Math.abs(parseInt(hours * 60, 10) % 60).toString()
  ).slice(-2);
  if (noMinutes) {
    let intHours = parseInt(_hours);
    const intMinutes = parseInt(_minutes);
    if (intMinutes > 30) {
      intHours += 1;
    }
    return intHours.toString() + 'h';
  }
  return _hours + 'h ' + (_minutes ? `${_minutes}min` : '00min');
}

export default hoursToTime;
