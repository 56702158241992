import { RECORD_TYPE } from './enums';

const doReduction = (reportData, process) => {
  const threshold = process.threshold;
  const amount = process.amount;
  const days = reportData.days;
  const resources = reportData.resources;

  Object.keys(days).forEach((key) => {
    const dayResources = days[key];
    Object.keys(dayResources).forEach((resourcekey) => {
      const resource = dayResources[resourcekey];
      if (resource.hours >= threshold) {
        resource.reduction += amount;
        resources[resource.resource.user].totalReduction += amount;
        resources[resource.resource.user].reductions[RECORD_TYPE.WORK] +=
          amount;
        resources[resource.resource.user].reductions[RECORD_TYPE.PROJECTWORK] +=
          amount;
      }
    });
  });
  return reportData;
};

export const reduction = doReduction;
