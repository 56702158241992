import Config from 'Config';
export function createRequestGetInitialWorkCalendarDataForProject(
  projectId,
  fromDate,
  toDate
) {
  const apiPath = `workflow/init/${projectId}/${fromDate}/${toDate}`;
  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  return request;
}
