import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getBulletins from 'scenes/BulletinBoard/utils/getBulletins';
import getProject from 'scenes/BulletinBoard/utils/getProject';
import getFilePermission from 'scenes/BulletinBoard/utils/getFilePermission';
import getDefaultFilePermissionsForBulletins from 'scenes/BulletinBoard/utils/getDefaultFilePermissionsForBulletins';

export const loadInitialBulletinData = createAsyncThunk(
  'bulletinBoard/loadBulletinData',
  async (projectId, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    let getBulletinsPromises = [];
    let getProjectPromises = [];
    let getDefaultImagesPromises = [];

    getBulletinsPromises.push(getBulletins(projectId, params));
    getProjectPromises.push(getProject(projectId, params));
    getDefaultImagesPromises.push(
      getDefaultFilePermissionsForBulletins(params)
    );

    const getBulletinsPromisesSettled = await Promise.allSettled(
      getBulletinsPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const getProjectPromisesSettled = await Promise.allSettled(
      getProjectPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const getDefaultImagesPromisesSettled = await Promise.allSettled(
      getDefaultImagesPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const tempBulletins = [].concat(...getBulletinsPromisesSettled);
    const project = [].concat(...getProjectPromisesSettled);
    const defaultFilePermissions = [].concat(
      ...getDefaultImagesPromisesSettled
    );

    const bulletins = []
      .concat(tempBulletins)
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    // Handle logo fetching
    let employerProject = [];
    const getEmployerProjectPromises = [];
    if (project[0] && project[0].masterParent) {
      getEmployerProjectPromises.push(
        getProject(project[0].masterParent, params)
      );
      const getEmployerProjectPromisesSettled = await Promise.allSettled(
        getEmployerProjectPromises
      ).then((promises) => promises.map((promise) => promise.value));
      employerProject = [].concat(...getEmployerProjectPromisesSettled);
    }

    // Finally, fetch filepermissions for bulletins
    const getFilePermissionsPromises = [];

    bulletins.forEach((bulletin) => {
      getFilePermissionsPromises.push(
        getFilePermission(bulletin.image1, params)
      );
      getFilePermissionsPromises.push(
        getFilePermission(bulletin.image2, params)
      );
    });

    const getFilePermissionsPromisesSettled = await Promise.allSettled(
      getFilePermissionsPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const filePermissions = [].concat(
      ...getFilePermissionsPromisesSettled,
      ...getDefaultImagesPromisesSettled
    );

    return {
      bulletins,
      project,
      filePermissions,
      employerProject,
      defaultFilePermissions,
    };
  }
);
