import { createAsyncThunk } from '@reduxjs/toolkit';

import { clearModificationsAndNewTasks } from '../timelineSlice';
import {
  selectDeletedTasks,
  selectModifiedTasks,
  selectNewTasksArray,
  selectSelectedTimelineId,
} from '../selectors/timelineSelectors';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { createAndUpdateTimelineTasksService } from 'scenes/Timelines/services/createAndUpdateTimelineTasks.service';

export const saveTimelineTasks = createAsyncThunk(
  'timelines/saveTimelineTasks',
  async (_, { getState, dispatch }) => {
    const state = getState();

    const projectId = selectSelectedProjectId(state);
    const timelineId = selectSelectedTimelineId(state);

    const modifiedTasks = selectModifiedTasks(state);
    const newTasks = selectNewTasksArray(state);
    const deletedTasks = selectDeletedTasks(state);
    console.log('modifiedTasks', modifiedTasks);
    console.log('newTasks', newTasks);
    console.log('deletedTasks', deletedTasks);

    const response = await createAndUpdateTimelineTasksService(
      projectId,
      timelineId,
      {
        tasksToCreate: newTasks,
        tasksToUpdate: modifiedTasks,
        tasksToDelete: deletedTasks,
      }
    );

    console.log('Tasks saved successfully:', response);
    dispatch(clearModificationsAndNewTasks());
    return response;
  }
);
