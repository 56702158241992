import { createEntityAdapter } from '@reduxjs/toolkit';

export const filePermissionsAdapter = createEntityAdapter();
export const assignmentsAdapter = createEntityAdapter();
export const workDemoProjectsAdapter = createEntityAdapter();
export const workDemoEmployersAdapter = createEntityAdapter();
export const workDemoResourcesAdapter = createEntityAdapter();

export const {
  selectAll: selectAllFilePermissions,
  selectById: selectFilePermissionById,
  selectIds: selectFilePermissionIds,
} = filePermissionsAdapter.getSelectors(
  (state) => state.workCalendar.filePermissions_demo
);

export const {
  selectAll: selectAllAssignments,
  selectEntities: selectAssignmentEntities,
  selectById: selectAssignmentById,
  selectIds: selectAssignmentIds,
} = assignmentsAdapter.getSelectors(
  (state) => state.workCalendar.assignments_demo
);
