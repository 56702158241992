import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setSendBulletin from 'scenes/BulletinBoard/utils/setSendBulletin';

export const sendBulletin = createAsyncThunk(
  'bulletinBoard/sendBulletin',
  async (body, { getState }) => {

    const projectId = getState().projects.selectedProjectId;

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const response = await setSendBulletin(body, params, projectId);

    return { response };
  }
);
