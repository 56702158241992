import { createAsyncThunk } from '@reduxjs/toolkit';
import deleteResource from '../services/deleteResource.service';

export const leaveProject = createAsyncThunk(
  'Resources/leaveProject',
  async (resource, { rejectWithValue }) => {
    try {
      const response = await deleteResource(resource);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
