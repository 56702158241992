import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEmployerAllowancesByBusinessId } from '../services/getEmployerAllowancesByBusinessId.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const loadEmployerAllowancesByBusinessId = createAsyncThunk(
  'employerAllowances/load-by-business-id',
  async (businessId, { getState }) => {
    const selectedProject = selectSelectedProject(getState());

    const selectedProjectBusinessId = selectedProject.business_id;

    let businessIdToSearch = businessId
      ? businessId
      : selectedProjectBusinessId;

    const employerAllowances = await getEmployerAllowancesByBusinessId(
      businessIdToSearch
    );

    return employerAllowances;
  }
);
