module.exports.sortByDefault = (a, b, lng = 'en') => {
  return a.localeCompare(b, lng, { sensitivity: 'base' });
};

module.exports.sortByKey = (a, b, key, lng = 'en') => {
  try {
    const nameA = a[key] && a[key];
    const nameB = b[key] && b[key];
    if (nameA && nameB)
      return nameA.localeCompare(nameB, lng, { sensitivity: 'base' });
    return 0;
  } catch (err) {
    return 0;
  }
};
