import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectRoleChangeHistory } from 'store/changeHistory/services/getProjectRoleChangeHistory.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectRoleChangeHistory = createAsyncThunk(
  'permissions/loadProjectRoleChangeHistory',
  async (roleId, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const response = await getProjectRoleChangeHistory(projectId, roleId);

    return response;
  }
);
