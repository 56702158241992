import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeRecordAllowancesByRecordIds } from 'store/recordAllowances/actions/record-allowances.actions';
import { selectRecordAllowancesByRecordId } from 'store/recordAllowances/selectors/record-allowances.selectors';
import deleteRecord from '../services/deleteRecord';
export const deleteRecordThunk = createAsyncThunk(
  'recordDialog/deleteRecord',
  async ({ projectId, recordId }, { getState, dispatch }) => {
    console.log('delete thunk', projectId, recordId);

    const state = getState();

    const recordAllowances = selectRecordAllowancesByRecordId(state, recordId);

    const response = await deleteRecord(projectId, recordId);

    if (recordAllowances && recordAllowances.length > 0) {
      const recordAllowancesIds = recordAllowances.map(
        (recordAllowance) => recordAllowance.id
      );

      dispatch(removeRecordAllowancesByRecordIds(recordAllowancesIds));
    }

    return response;
  }
);
