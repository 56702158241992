import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserSettings } from '../services/getUserSettings.service';
import { setUserSetting } from 'store/actions/setUserSetting';
import { changeLanguage } from 'utils/changeLanguage';

export const loadUserSettings = createAsyncThunk(
  'user/loadUserSettings',
  async (_, { dispatch }) => {
    const userSetting = await getUserSettings();
    dispatch(setUserSetting(userSetting));
    changeLanguage(userSetting.locale);

    return userSetting;
  }
);
