import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from '../../../utils/createSignedRequest';

export default async function patchCheckRecordSingleTime(
  timeAttributeName,
  projectId,
  recordId
) {
  const apiPath = `records/check-single-time/${projectId}/${recordId}`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'PATCH',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ attributeName: timeAttributeName }),
    data: { attributeName: timeAttributeName },
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
