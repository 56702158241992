import moment from 'moment';

function momentDiffHours(a, b, status) {
  const mA = moment(a);
  let mB = moment();
  if (status === 'OUT' && b) {
    mB = moment(b);
  }
  const duration = moment.duration(mB.diff(mA));
  const hours = duration.asHours();
  return hours;
}

export default momentDiffHours;
