import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { RecordsTimeline } from './RecordsTimeLine';
import { RecordsTimelineDates } from './RecordsTimelineDates';
import { selectPersonalRecordsFromToday } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px',
  },
}));

export function RecordsTimelineContainer(currentDate) {
  const theme = useTheme();
  const classes = useStyles();

  const recordsFromToday = useSelector(selectPersonalRecordsFromToday);

  return recordsFromToday.length > 0 ? (
    <div className={classes.container}>
      <RecordsTimelineDates
        currentDate={currentDate}
        recordsFromToday={recordsFromToday}
      ></RecordsTimelineDates>
      <RecordsTimeline recordsFromToday={recordsFromToday}></RecordsTimeline>
    </div>
  ) : null;
}
