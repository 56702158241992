import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMediaFilePermission } from 'scenes/ProjectGallery/services/deleteMediaFilePermission.service';

export const deleteMediaFilePermissionThunk = createAsyncThunk(
  'file-permissions/delete-media-filepermission',
  async ({ filePermissionId, projectId }, { rejectWithValue }) => {
    try {
      const response = await deleteMediaFilePermission(
        filePermissionId,
        projectId
      );

      return response;
    } catch (err) {
      // add message from api if no permission etc.
      const rejectData = { message: '', filePermissionId };
      return rejectWithValue(rejectData);
    }
  }
);
