import React from 'react';
import PropTypes from 'prop-types';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  badge: {
    ...theme.component.sideBadge,
    color: 'white',
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}));

function SideBadge(props) {
  const { children, ...badgeProps } = props;
  const classes = useStyles();

  return (
    <Badge
      classes={{ badge: classes.badge }}
      {...badgeProps}
      overlap="rectangular"
    >
      {children}
    </Badge>
  );
}

SideBadge.propTypes = {
  children: PropTypes.node,
};

export default SideBadge;
