import { createSelector } from '@reduxjs/toolkit';
import { TAXNUMBER_STATUS } from 'LixaniAPI/enums';
import { orderBy } from 'lodash';
import { VIEW_SUBPROJECTS } from 'scenes/Permissions/constants/permissions';
import {
  selectSelectedFolderUserPermissions,
  selectSelectedProjectUserPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import {
  selectCurrentUserResidentResources,
  selectCurrentUserResourcesIndexedByProject,
  selectLoggedInUserResourcesArray,
} from 'store/resources/selectors/resources.selectors';
import { selectCurrentUser } from 'store/selectors/root.selectors';
import { getProjectsResourcesEmployers } from '../utils/getProjectsResourcesEmployers';
import { isSubProject } from '../utils/isSubProject';
import {
  selectChildProjectsOfSelectedProject,
  selectProjectsArray,
  selectProjectsArrayWithJoinedData,
  selectProjectsFolder,
  selectSearchFilter,
  selectSelectedProject,
} from './projectsSelectors';

export const selectProjectsThatUserHasResourceToOrIsCreatorOf = createSelector(
  [
    selectProjectsArrayWithJoinedData,
    selectCurrentUserResourcesIndexedByProject,
    selectCurrentUser,
  ],

  (projects, currentUsersResourcesIndexedByProject, user) => {
    const projectsThatHaveUserResourceOrIsCreatedBy = projects.filter(
      (project) =>
        currentUsersResourcesIndexedByProject[project.id] ||
        user.id === project.mirrorUser
    );

    return projectsThatHaveUserResourceOrIsCreatedBy
      ? projectsThatHaveUserResourceOrIsCreatedBy
      : [];
  }
);

export const selectProjectsWithSameEmployerAsSelectedProjectWithoutEmployerProject =
  createSelector(
    [selectProjectsArrayWithJoinedData, selectSelectedProject],

    (projects, selectedProject) => {
      const projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProject =
        projects.filter((project) => {
          const isEmployerProject = project.businessTypeEnum === 'EMPLOYER';
          //const isUserProjectCreator = user.id === project.mirrorUser;
          const isSelectedProjectEmployerProject =
            selectedProject.businessTypeEnum === 'EMPLOYER';
          const selectedProjectEmployer = isSelectedProjectEmployerProject
            ? selectedProject.id
            : selectedProject.masterParent;

          const isProjectFromSameEmployer =
            selectedProjectEmployer &&
            selectedProjectEmployer === project.masterParent.id;
          return isProjectFromSameEmployer && !isEmployerProject;
        });

      const projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted =
        orderBy(
          projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProject,
          [(project) => project.name.toLowerCase()],
          ['asc']
        );

      return projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted
        ? projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted
        : [];
    }
  );

export const selectTaxNumberMissing = createSelector(
  [selectProjectsThatUserHasResourceToOrIsCreatorOf, selectCurrentUser],
  (projects, user) => {
    if (Object.keys(projects).length > 0) {
      let ilve = false;
      for (let item in projects) {
        let project = projects[item];
        if (project.ilve === 'yes') {
          ilve = true;
        }
      }
      if (ilve && user && user.taxNumberStatus !== TAXNUMBER_STATUS.VALID) {
        return true;
      } else {
        return false;
      }
    }
  }
);

export const selectUserEmployers = createSelector(
  [
    selectProjectsArrayWithJoinedData,
    selectLoggedInUserResourcesArray,
    selectCurrentUser,
  ],
  (projects, resources, user) => {
    const userResourceProjectIds = resources
      .filter(
        (resource) =>
          resource.project &&
          // only resources that are employed by the employer project itself are considered as being the user's employer
          resource.project === resource.employer &&
          // admin resources currently filtered out, they are not shown as employers
          !resource.adminOfBusiness
      )
      .map((resource) => resource.project);

    const employerProjectsThatUserHasAddedAsEmployerOrIsCreatorOf =
      projects.filter(
        (project) =>
          project.businessTypeEnum === 'EMPLOYER' &&
          (userResourceProjectIds.includes(project.id) ||
            user.id === project.mirrorUser)
      );

    return employerProjectsThatUserHasAddedAsEmployerOrIsCreatorOf;
  }
);

export const selectProjectsThatUserHasResourceToOrIsCreatorOfWithoutJoinedData =
  createSelector(
    [
      selectProjectsArray,
      selectCurrentUserResourcesIndexedByProject,
      selectCurrentUser,
    ],
    (projects, currentUserResourcesIndexedByProject, user) => {
      const projectsThatHaveUserResourceOrIsCreatedBy = projects.filter(
        (project) =>
          currentUserResourcesIndexedByProject[project.id] ||
          user.id === project.mirrorUser
      );

      return projectsThatHaveUserResourceOrIsCreatedBy;
    }
  );

export const selectProjectsThatUserHasResidentResourceTo = createSelector(
  [selectProjectsArray, selectCurrentUserResidentResources],
  (projects, resources) => {
    const projectIdsOfResidentResources = resources.map(
      (resource) => resource.project
    );
    const projectsWithResidentResource = projects.filter((project) =>
      projectIdsOfResidentResources.includes(project.id)
    );

    return projectsWithResidentResource;
  }
);

export const selectProjectsIdsThatUserHasResourceToOrIsCreatorOf =
  createSelector(
    [selectProjectsThatUserHasResourceToOrIsCreatorOf],
    (projects) => projects.map((project) => project.id)
  );

export const selectProjectsWithEmployerOrWithoutParent = createSelector(
  [
    selectProjectsThatUserHasResourceToOrIsCreatorOfWithoutJoinedData,
    selectProjectsIdsThatUserHasResourceToOrIsCreatorOf,
  ],
  (projects, projectIds) =>
    projects.filter(
      (project) =>
        project.businessTypeEnum === 'EMPLOYER' ||
        !projectIds.includes(project.parent)
    )
);

export const selectChildProjects = createSelector(
  [
    selectProjectsThatUserHasResourceToOrIsCreatorOf,
    selectSelectedProject,
    selectProjectsIdsThatUserHasResourceToOrIsCreatorOf,
    selectLoggedInUserResourcesArray,
  ],
  (projects, selectedProject, projectIds, currentUserResources) =>
    projects.filter((project) => {
      if (project.businessTypeEnum !== 'EMPLOYER' && project.parent) {
        const isChildProject = project.parent.id === selectedProject.id;
        const hasNoParentButIsChildOfEmployerProject =
          project.masterParent.id === selectedProject.id && project.parent
            ? !projectIds.includes(project.parent.id)
            : false;
        const hasNoParentButIsChildOfEmployerProjectByResource =
          getProjectsResourcesEmployers(
            currentUserResources,
            project.id
          ).includes(selectedProject.id) &&
          !projectIds.includes(project.parent.id);

        const isNotSubProjectAndIsChildOfEmployerProjectByResource =
          getProjectsResourcesEmployers(
            currentUserResources,
            project.id
          ).includes(selectedProject.id) && typeof project.key === 'string'
            ? !isSubProject(project.key)
            : false;

        const hasNoParentButChildHasPartOfProjectKey =
          project.key &&
          typeof project.key === 'string' &&
          project.key.includes(selectedProject.key) &&
          project.key !== selectedProject.key &&
          !projectIds.includes(project.parent.id);

        return (
          isChildProject ||
          hasNoParentButIsChildOfEmployerProject ||
          hasNoParentButIsChildOfEmployerProjectByResource ||
          hasNoParentButChildHasPartOfProjectKey ||
          isNotSubProjectAndIsChildOfEmployerProjectByResource
        );
      }
    })
);
export const selectNotJoinedProjectsWithPermissionToViewInFolder =
  createSelector(
    [
      selectSelectedProject,
      selectProjectsFolder,
      selectSelectedFolderUserPermissions,
      selectProjectsArray,
      selectCurrentUserResourcesIndexedByProject,
    ],
    (
      selectedProject,
      selectedFolder,
      userPermissionsInSelectedFolder,
      projects,
      currentUserResourcesIndexedByProject
    ) => {
      if (!userPermissionsInSelectedFolder[VIEW_SUBPROJECTS]) {
        return [];
      } else {
        const notJoinedProjectsInFolder = projects.filter(
          (project) =>
            project.business_id === selectedFolder.projectBusinessId &&
            project.businessTypeEnum === 'NORMAL' &&
            !currentUserResourcesIndexedByProject[project.id]?.find(
              (resource) => resource.employer === selectedProject.id
            )
        );
        return notJoinedProjectsInFolder;
      }
    }
  );
export const selectNotJoinedChildProjectsOfSelectedProject = createSelector(
  [
    selectChildProjectsOfSelectedProject,
    selectChildProjects,
    selectSelectedProjectUserPermissions,
  ],

  (
    childProjectsOfSelectedProject,
    alreadyJoinedChildProjects,
    selectedProjectUserPermissions
  ) => {
    if (selectedProjectUserPermissions[VIEW_SUBPROJECTS]) {
      return childProjectsOfSelectedProject.filter(
        (project) =>
          !project.deletedAt &&
          !alreadyJoinedChildProjects.find(
            (alreadyJoinedChildProject) =>
              project.id === alreadyJoinedChildProject.id
          )
      );
    } else {
      return [];
    }
  }
);

export const selectCurrentProjectsChildProjectsWithSearchFilter =
  createSelector(
    [selectChildProjects, selectSearchFilter],
    (projects, searchFilter) =>
      projects.filter((project) => {
        return (
          (project.name ? project.name : '') +
          (project.projectKey ? project.projectKey : '') +
          (project.masterProjectKey ? project.masterProjectKey : '') +
          (project.masterParent.name ? project.masterParent.name : '') +
          (project.masterParent.business_id
            ? project.masterParent.business_id
            : '')
        )
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      })
  );

export const selectProjectsWithEmployerOrWithoutParentWithSearchFilter =
  createSelector(
    [selectProjectsWithEmployerOrWithoutParent, selectSearchFilter],
    (projects, searchFilter) =>
      projects.filter((project) => {
        return (
          (project.name ? project.name : '') +
          (project.projectKey ? project.projectKey : '') +
          (project.key ? project.key : '')
        )
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      })
  );

export const selectAllProjectsWithSearchFilter = createSelector(
  [selectProjectsThatUserHasResourceToOrIsCreatorOf, selectSearchFilter],
  (projects, searchFilter) =>
    projects.filter((project) => {
      return (
        (project.name ? project.name : '') +
        (project.projectKey ? project.projectKey : '') +
        (project.key ? project.key : '')
      )
        .toLowerCase()
        .includes(searchFilter.toLowerCase());
    })
);
