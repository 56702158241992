import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const setCreateSaleInvoice = async (
  projectId,
  invoice
) => {
  const apiPath = `invoice/${projectId}/send-sales-invoice-to-accounting`;

  const body = {
    invoice: invoice,
    masterParentId: invoice.masterParent || null
  };

  console.log('body', body);

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    //url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    data: body,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  console.log('request', request);
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);
  console.log('response', response)
  return response.data;
};
