import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequestWorkCalendarDataInTimeframeForProjects } from 'scenes/WorkDemo/utils/requests/createRequestGetWorkCalendarDataInTimeframeForProjects';
import returnProjectIdAndItsChildProjectIds from 'scenes/WorkDemo/utils/returnProjectIdAndItsChildProjectIds';
import axios from 'axios';
import { createSignedRequest } from '../../utils/createSignedRequest';

import getCredentials from '../../utils/getCredentials';
import {
  selectProjects,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { loadAdminWorkCalendarDataFromExternalProject } from './loadAdminWorkCalendarDataFromExternalProject.thunk';

export const loadCalendarDataInTimeframe = createAsyncThunk(
  'workCalendar/loadCalendarDataInTimeframe',
  async (_, { getState, dispatch }) => {
    const { workSearchParams_demo } = getState();

    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projects = selectProjects(state);

    const userAdminBusinessIds = selectUserAdminBusinessIds(getState());

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const selectedProject = projects[selectedProjectId];

    if (selectedProjectId && selectedProject?.businessTypeEnum === 'EMPLOYER') {
      const projectsToSearch = Object.values(projects)
        .filter(
          (project) => project.business_id === selectedProject.business_id
        )
        .map((project) => project.id);

      const requests = await Promise.all(
        projectsToSearch.map((projectId) => {
          const request = createRequestWorkCalendarDataInTimeframeForProjects(
            projectId,
            workSearchParams_demo.fromDate,
            workSearchParams_demo.toDate
          );
          return createSignedRequest(
            request,
            accessKeyId,
            secretAccessKey,
            sessionToken
          );
        })
      );

      const data = await Promise.all(
        requests.map((request) => axios(request).then((res) => res.data))
      );
      return data;
    } else if (selectedProjectId) {
      if (userAdminBusinessIds && userAdminBusinessIds.length > 0) {
        await dispatch(loadAdminWorkCalendarDataFromExternalProject());
      }
      const projectsToSearch = returnProjectIdAndItsChildProjectIds(
        [selectedProjectId],
        Object.values(projects)
      );
      const requests = await Promise.all(
        projectsToSearch.map((projectId) => {
          const request = createRequestWorkCalendarDataInTimeframeForProjects(
            projectId,
            workSearchParams_demo.fromDate,
            workSearchParams_demo.toDate
          );
          return createSignedRequest(
            request,
            accessKeyId,
            secretAccessKey,
            sessionToken
          );
        })
      );

      const data = await Promise.all(
        requests.map((request) => axios(request).then((res) => res.data))
      );

      return data;
    } else {
      return;
    }
  }
);
