import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectIsAdminInSelectedProject } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { loadAdminCalendarDataInTimeframe } from './loadAdminCalendarDataInTimeframe.thunk';
import { loadCalendarDataInTimeframe } from './loadCalendarDataInTimeframe.thunk';
import { setWorkSearchParams } from '../actions/demoActions';
import dayjs from 'dayjs';
import {
  selectIsActiveSearchFilters,
  selectIsPersonalWorkCalendarMode,
} from '../selectors/workSelectorsDemo';
import { loadAdminCalendarDataInTimeframeWithSearchParams } from './loadAdminCalendarDataInTimeframeWithSearchParams.thunk';
import { selectWorkSearchStateFromDateAndToDate } from '../selectors/workSearchParamsSelectors';
import { loadPersonalWorkCalendarData } from './my-lixani/loadPersonalWorkCalendarData.thunk';

export const searchWorkCalendar = createAsyncThunk(
  'project/searchWorkCalendar',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const isAdminInSelectedProject = selectIsAdminInSelectedProject(state);

    const componentStateDates = selectWorkSearchStateFromDateAndToDate(state);

    const isPersonalWorkCalendarMode = selectIsPersonalWorkCalendarMode(state);

    dispatch(
      setWorkSearchParams({
        fromDate: dayjs(componentStateDates.fromDate)
          .startOf('day')
          .utcOffset(0)
          .format(),
        toDate: dayjs(componentStateDates.toDate)
          .endOf('day')
          .utcOffset(0)
          .format(),
      })
    );

    const isActiveSearchFilters = selectIsActiveSearchFilters(state);

    if (isPersonalWorkCalendarMode) {
      await dispatch(loadPersonalWorkCalendarData());
    } else {
      if (isAdminInSelectedProject) {
        if (isActiveSearchFilters) {
          await dispatch(loadAdminCalendarDataInTimeframeWithSearchParams());
        } else {
          await dispatch(loadAdminCalendarDataInTimeframe());
        }
      } else {
        await dispatch(loadCalendarDataInTimeframe());
      }
    }
  }
);
