import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getResourcesByProjectByEmploymentType from 'scenes/BulletinBoard/utils/getResourcesByProjectByEmploymentType';
import getManualResourcesByProjectByEmploymentType from 'scenes/BulletinBoard/utils/getManualResourcesByProjectByEmploymentType';

export const loadBulletinSendData = createAsyncThunk(
  'bulletinBoard/loadBulletinSendData',
  async (projectId, { getState }) => {
    const { root, bulletinBoard } = getState();

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    let getResourcesPromises = [];
    let getManualResourcesPromises = [];

    getResourcesPromises.push(
      getResourcesByProjectByEmploymentType(projectId, 'resident', params)
    );

    getManualResourcesPromises.push(
      getManualResourcesByProjectByEmploymentType(projectId, 'resident', params)
    );

    const getResourcesPromisesSettled = await Promise.allSettled(
      getResourcesPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const getManualResourcesPromisesSettled = await Promise.allSettled(
      getManualResourcesPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const resources = [].concat(...getResourcesPromisesSettled);
    const manualResources = [].concat(...getManualResourcesPromisesSettled);

    return { resources, manualResources };
  }
);
