import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrientationQuestionSet,
} from '../services/getOrientationQuestionSet';

import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadOrientationQuestionSet = createAsyncThunk(
  'orientation/loadOrientationQuestionSet',
  async (params, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    console.log('selectedProjectId', selectedProjectId);

    const orientationQuestionSet = await getOrientationQuestionSet(
      selectedProjectId
    );

    console.log('orientationQuestionSet', orientationQuestionSet);

    return orientationQuestionSet;
  }
);
