export const sortByProject = (a, b, lng) => {
  return a.project.name.localeCompare(b.project.name, lng, {
    sensitivity: 'base',
  });
};

export const sortByEmployer = (a, b, lng) => {
  return a.employer.name.localeCompare(b.employer.name, lng, {
    sensitivity: 'base',
  });
};

export const sortByFirstName = (a, b, lng) => {
  try {
    return a.firstName.localeCompare(b.firstName, lng, {
      sensitivity: 'base',
    });
  } catch (err) {
    return 0;
  }
};

export const sortByLastName = (a, b, lng) => {
  try {
    return a.lastName.localeCompare(b.lastName, lng, {
      sensitivity: 'base',
    });
  } catch (err) {
    return 0;
  }
};

export const sortByRecordFirstName = (a, b, lng) => {
  return a.resource.firstName.localeCompare(b.resource.firstName, lng, {
    sensitivity: 'base',
  });
};

export const sortByRecordLastName = (a, b, lng) => {
  return a.resource.lastName.localeCompare(b.resource.lastName, lng, {
    sensitivity: 'base',
  });
};

export const sortByUpdatedAt = (a, b, lng, fallback = 'createdAt') => {
  try {
    if (a.updatedAt && b.updatedAt) {
      return b.updatedAt.localeCompare(a.updatedAt, lng, {
        sensitivity: 'base',
      });
    } else if (a[fallback] && b[fallback]) {
      return b[fallback].localeCompare(a[fallback], lng, {
        sensitivity: 'base',
      });
    } else {
      return 0;
    }
  } catch (err) {
    return 0;
  }
};

export const sortByClockInAt = (a, b, lng) => {
  return b.clockInAt.localeCompare(a.clockInAt, lng, { sensitivity: 'base' });
};
