import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectSelectedRecordsIds } from 'store/records/selectors/records.selectors';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import { selectWorkCalendarRecordsArray } from 'store/records/selectors/records-base.selectors';

export const openSendToApprovalDialog = createAsyncThunk(
  'approvals/OpenSendToApprovalDialog',
  async (_, { getState }) => {
    const selectedEmployerIdByNavigation = selectSelectedEmployer(getState());

    const selectedRecordsIds = selectSelectedRecordsIds(getState());

    const selectedRecords = selectWorkCalendarRecordsArray(getState()).filter(
      (record) => selectedRecordsIds.includes(record.id)
    );

    const selectedRecordsProjectId = selectedRecords[0].project;

    const initialDialogData = {
      employerId: selectedEmployerIdByNavigation,
      projectId: selectedRecordsProjectId,
      approvers: [],
    };

    return initialDialogData;
  }
);
