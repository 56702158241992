import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as demoActions from './store/actions/demoActions';
import dayjs from 'dayjs';

const workCalendarSelectedAssignmentsAdapter = createEntityAdapter();
const selectedUsersAdapter = createEntityAdapter();
const selectedProjectsAdapter = createEntityAdapter();

const initialState = {
  //stateDate: dayjs().format('YYYY-MM-DD'),
  fromDate: dayjs().startOf('isoWeek').utcOffset(0).format(),
  toDate: dayjs().endOf('isoWeek').utcOffset(0).format(),
  componentStateFromDate: dayjs().startOf('isoWeek').utcOffset(0).format(),
  componentStateToDate: dayjs().endOf('isoWeek').utcOffset(0).format(),
  //employees: [],
  //employers: [],
  //keyword: [],
  //listProject: [],
  //mainParent: null,
  selectedResourceIds: [],
  selectedUsers: selectedUsersAdapter.getInitialState(),
  selectedProjects: selectedProjectsAdapter.getInitialState(),
  selectedAssignment: workCalendarSelectedAssignmentsAdapter.getInitialState(),
  selectedEmployerBusinessIds: [],
  descriptionSearchText: undefined,
  visibleWeeks: [dayjs().startOf('isoWeek').format()],
  selectionMode: false,
  selectedApprovalGroupCodes: [],
};

const workSearchParamsSlice = createSlice({
  name: 'workSearchParams_demo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(demoActions.setWorkSearchParams, (state, action) => {
        let visibleWeeksArray = [];

        for (
          let i = dayjs(action.payload.fromDate);
          i.format('YYYY-WW') <= dayjs(action.payload.toDate).format('YYYY-WW');
          i = i.add(1, 'week')
        ) {
          visibleWeeksArray.push(i.startOf('isoWeek').format());
        }

        const newState = {
          ...state,
          visibleWeeks: visibleWeeksArray,
          ...action.payload,
        };

        return newState;
      })
      .addCase(
        demoActions.setWorkSearchParamsFromDateState,
        (state, action) => {
          state.componentStateFromDate = action.payload;
        }
      )
      .addCase(demoActions.setWorkSearchParamsToDateState, (state, action) => {
        state.componentStateToDate = action.payload;
      })
      .addCase(demoActions.resetWorkSearchParams, (state, action) => ({
        ...initialState,
      }))
      .addCase(
        demoActions.setWorkSearchParamsSelectedProjects,
        (state, action) => {
          selectedProjectsAdapter.setAll(
            state.selectedProjects,
            action.payload
          );
        }
      )
      .addCase(
        demoActions.setWorkSearchParamsSelectedResources,
        (state, action) => ({ ...state, selectedResourceIds: action.payload })
      )
      .addCase(
        demoActions.setWorkSearchParamsSelectedUsers,
        (state, action) => {
          selectedUsersAdapter.setAll(state.selectedUsers, action.payload);
        }
      )
      .addCase(
        demoActions.setWorkSearchParamsSelectedAssignment,
        (state, action) => {
          workCalendarSelectedAssignmentsAdapter.setOne(
            state.selectedAssignment,
            action.payload
          );
        }
      )
      .addCase(
        demoActions.removeWorkSearchParamsSelectedAssignment,
        (state, action) => {
          workCalendarSelectedAssignmentsAdapter.removeOne(
            state.selectedAssignment,
            action.payload.id
          );
        }
      )
      .addCase(
        demoActions.setWorkSearchParamsSelectedEmployers,
        (state, action) => ({
          ...state,
          selectedEmployerBusinessIds: action.payload,
        })
      )
      .addCase(
        demoActions.setWorkSearchParamsDescriptionSearchText,
        (state, action) => ({ ...state, descriptionSearchText: action.payload })
      )

      .addCase(demoActions.toggleVisibleWeek, (state, action) => {
        if (state.visibleWeeks.includes(action.payload)) {
          return {
            ...state,
            visibleWeeks: state.visibleWeeks.filter(
              (w) => w !== action.payload
            ),
          };
        } else {
          return {
            ...state,
            visibleWeeks: [...state.visibleWeeks, action.payload].sort(),
          };
        }
      })
      .addCase(demoActions.calendarOpenPreviousWeek, (state, action) => {
        const prevWeek = action.payload;

        return {
          ...state,
          fromDate: prevWeek < state.fromDate ? prevWeek : state.fromDate,
          componentStateFromDate:
            prevWeek < state.fromDate ? prevWeek : state.fromDate,
          visibleWeeks: [prevWeek],
        };
      })
      .addCase(demoActions.calendarOpenNextWeek, (state, action) => {
        const nextWeek = action.payload;

        return {
          ...state,
          toDate:
            nextWeek > state.toDate
              ? dayjs(nextWeek).endOf('isoWeek').format()
              : state.toDate,
          componentStateToDate:
            nextWeek > state.toDate
              ? dayjs(nextWeek).endOf('isoWeek').format()
              : state.toDate,
          visibleWeeks: [nextWeek],
        };
      })
      .addCase(demoActions.calendarOpenCurrentWeek, (state, action) => {
        const startOfCurrentWeek = dayjs().startOf('isoWeek').format();

        if (startOfCurrentWeek > state.toDate) {
          return {
            ...state,
            fromDate: startOfCurrentWeek,
            toDate: dayjs(startOfCurrentWeek).endOf('isoWeek').format(),
            visibleWeeks: [startOfCurrentWeek],
          };
        } else {
          return {
            ...state,
            visibleWeeks: [startOfCurrentWeek],
          };
        }
      })
      .addCase(demoActions.showMap, (state, action) =>
        Array.isArray(action.payload)
          ? {
              ...state,
              showMap: action.payload,
            }
          : { ...state, showMap: [action.payload] }
      )
      .addCase(demoActions.closeMap, (state, action) => {
        return {
          ...state,
          showMap: null,
        };
      })
      .addCase(demoActions.setSelectionMode, (state, action) => {
        return {
          ...state,
          selectionMode: action.payload,
        };
      })
      .addCase(
        demoActions.setWorkSearchParamsApprovalGroup,
        (state, action) => {
          state.selectedApprovalGroupCodes = [
            ...state.selectedApprovalGroupCodes,
            action.payload,
          ];
        }
      )
      .addCase(
        demoActions.clearWorkSearchParamsApprovalGroup,
        (state, action) => {
          state.selectedApprovalGroupCodes = [];
        }
      );
  },
});

export default workSearchParamsSlice.reducer;
