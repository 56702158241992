import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLatestActiveProjectsOfUser } from '../services/getLatestActiveProjectsOfUser.service';

export const loadLatestActiveProjectsOfUser = createAsyncThunk(
  'projects/loadLatestActiveProjectsOfUser',
  async () => {
    const projectIds = await getLatestActiveProjectsOfUser();

    return projectIds;
  }
);
