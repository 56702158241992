import { createAsyncThunk } from '@reduxjs/toolkit';
import { putAdmin } from 'scenes/Permissions/services/putAdmin.service';

export const putAdminStatusOfUser = createAsyncThunk(
  'settings/putAdminStatusOfUser',
  async ({ userId, businessId }) => {
    const response = await putAdmin(userId, businessId);
    return response;
  }
);
