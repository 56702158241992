import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadOrientationQuestionSet } from './thunks/loadOrientationQuestionSet';
import { loadOrientationRequirement } from './thunks/loadOrientationRequirement';

const orientationQuestionSetAdapter = createEntityAdapter();

export const orientationSlice = createSlice({
  name: 'orientation',
  initialState: {
    orientationQuestionSet: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadOrientationQuestionSet.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadOrientationQuestionSet.fulfilled, (state, action) => {
        state.orientationQuestionSet = action.payload;
        state.loading = false;
      })
      .addCase(loadOrientationQuestionSet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(loadOrientationRequirement.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadOrientationRequirement.fulfilled, (state, action) => {
        state.orientationQuestionSet = action.payload;
        state.loading = false;
      })
      .addCase(loadOrientationRequirement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
