import { createAsyncThunk } from '@reduxjs/toolkit';
import getUserResourcesByProject from '../services/getUserResourcesByProject';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadUserResourcesForProject = createAsyncThunk(
  'resources/loadUserResourcesForProject',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const response = await getUserResourcesByProject(selectedProjectId);
    return response;
  }
);
