import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectRoles } from '../../selectors/permissionsSelectors';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const assignDefaultEmployeeRole = createAsyncThunk(
  'permissionsManagement/assignDefaultEmployeeRole',
  async (roleId, { getState }) => {
    const projectRoles = selectSelectedProjectRoles(getState());

    const selectedProjectId = selectSelectedProjectId(getState());

    if (!roleId) {
      const notDefinedDefaultRoleItem = {
        PK: `PROJECT#${selectedProjectId}`,
        SK: 'DEFAULT_ROLE_EMPLOYEE',
        roleId: '',
        projectId: selectedProjectId,
      };

      return notDefinedDefaultRoleItem;
    } else {
      const assignedRole = projectRoles.find((role) => role.roleId === roleId);

      const assignedDefaultRoleItem = {
        PK: `PROJECT#${assignedRole.projectId}`,
        SK: 'DEFAULT_ROLE_EMPLOYEE',
        roleId,
        projectId: assignedRole.projectId,
      };

      return assignedDefaultRoleItem;
    }
  }
);
