import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getCompanyInvoiceTotalsByProject } from '../services/getCompanyInvoiceTotalsByProject';

export const loadCompanyInvoiceTotals = createAsyncThunk(
  'expenseTracking/loadCompanyInvoiceTotals',
  async (params, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    return await getCompanyInvoiceTotalsByProject(selectedProjectId);
  }
);
