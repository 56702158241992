import { makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import { getRecordsFirstAndLastClockedTimes } from './utils/getRecordsFirstAndLastClockedTimes';
import moment from 'moment';
import { getRecordTimeLineItems } from './utils/getTimelineItems';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textItem: {
    padding: '0px',
    margin: '0px',
  },
}));

export function RecordsTimelineWorkRecords({
  currentDate,
  workRecords,
  projectName,
}) {
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(() => {
      //  setRecordsTimeframe(getRecordsFirstAndLastClockedTimes(workRecords));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return getRecordTimeLineItems(workRecords, currentDate).map((item, index) => (
    <React.Fragment key={index}>
      {index === 0 ? (
        <text
          x={`${item.start}%`}
          y="25%"
          fontSize="12"
          textAnchor="start"
          stroke="white"
        >
          {projectName}
        </text>
      ) : null}

      <line
        key={index}
        x1={`${item.start}%`}
        y1="55%"
        x2={`${item.end}%`}
        y2="55%"
        stroke={item.isClockedIn ? '#05c705' : '#e6e6e6'}
        strokeWidth={15}
        id={`work-${index}`}

        // strokeOpacity={0.8}
      />
    </React.Fragment>
  ));
}
