import { RECORDS_APPROVE } from 'scenes/Permissions/constants/permissions';
import { getNearestUserProjectPermissions } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

export const checkPermissionsToApproveRecord = (
  record,
  userProjectPermissions,
  projects,
  resourceDefaultPermissions,
  IsAdminOfSelectedRecordProject
) => {
  const nearestUserProjectPermissionsForRecord =
    getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      record.project
    );

  const defaultPermissionsWithNearestProjectPermissions = {
    ...resourceDefaultPermissions,
    ...nearestUserProjectPermissionsForRecord,
  };

  const isPermissionToApprove =
    defaultPermissionsWithNearestProjectPermissions[RECORDS_APPROVE];

  return IsAdminOfSelectedRecordProject || isPermissionToApprove;
};
