import { createAsyncThunk } from '@reduxjs/toolkit';

import { postInteraction } from '../services/post-interaction.service';

export const createInteraction = createAsyncThunk(
  'interactions/post-interaction',
  async ({ projectId, targetId, content, contentType, targetType }) => {
    const newInteraction = await postInteraction(
      projectId,
      targetId,
      content,
      contentType,
      targetType
    );
    return newInteraction;
  }
);
