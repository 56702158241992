import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getRecordAllowancesOfProjectInTimeframe } from '../services/getRecordAllowancesOfProjectInTimeframe.service';

export const loadRecordAllowancesOfProjectInTimeframe = createAsyncThunk(
  'recordAllowances/loadRecordAllowancesOfProjectInTimeframe',
  async (timeframe, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await getRecordAllowancesOfProjectInTimeframe(
      selectedProjectId,
      timeframe
    );

    return response;
  }
);
