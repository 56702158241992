import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getResourcesByUser from 'scenes/ProjectForms/utils/getResourcesByUser';

export const loadResourcesByUser = createAsyncThunk(
    'projectForm/loadResourcesByUser',
    async (projectId, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getResourcesPromises = [];

        getResourcesPromises.push(getResourcesByUser(projectId, params));

        const getResourcesPromisesSettled = await Promise.allSettled(
            getResourcesPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const resources = [].concat(...getResourcesPromisesSettled);

        return {
            resources,
        };
    }
);