import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { capitalize } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  rootFullWidth: {
    display: 'flex',
  },
  button: {
    ...theme.typography.fontPanton,
  },
  buttonFullWidth: {
    width: '100%',
  },
  paper: {
    background: 'none',
    borderRadius: 4,
    color: theme.palette.text.caption,
    display: 'flex',
  },
  paperFullWidth: {
    width: '100%',
  },
  off: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    flexBasis: '50%',
    padding: theme.spacing(1.5) - 1,
    paddingRight: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
  offChecked: {
    color: theme.palette.textWhite.secondary,
  },
  on: {
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    flexBasis: '50%',
    padding: theme.spacing(1.5) - 1,
    paddingLeft: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
  onChecked: {
    color: theme.palette.textWhite.secondary,
  },
  colorInitial: {
    border: `1px solid ${theme.component.buttonSwitchColorInitial}`,
    '& $offChecked': {
      background: theme.component.buttonSwitchColorInitial,
    },
    '& $onChecked': {
      background: theme.component.buttonSwitchColorInitial,
    },
  },
  input: {
    display: 'none',
  },
}));

function ButtonSwitch(props) {
  const [isChecked, setChecked] = useState(props.checked);
  const inputEl = useRef(null);
  const classes = useStyles();
  const {
    ButtonProps,
    className,
    classes: classesProp,
    color,
    disabled,
    fullWidth,
    id,
    name,
    off,
    on,
    onChange,
  } = props;

  const rootClasses = clsx(classes.root, className && className, {
    [classes.rootFullWidth]: fullWidth,
  });

  const buttonClasses = clsx(
    classes.button,
    classesProp.root && classesProp.root,
    { [classes.buttonFullWidth]: fullWidth }
  );

  const paperClasses = clsx(
    classes.paper,
    color && classes[`color${capitalize(color)}`],
    classesProp.paper && classesProp.paper,
    { [classes.paperFullWidth]: fullWidth }
  );

  const offClasses = clsx(
    classes.off,
    !isChecked && classes.offChecked,
    classesProp.off && classesProp.off
  );

  const onClasses = clsx(
    classes.on,
    isChecked && classes.onChecked,
    classesProp.on && classesProp.on
  );

  const handleClick = () => {
    setChecked(!isChecked);
    inputEl.current.click();
  };

  return (
    <span className={rootClasses}>
      <ButtonBase
        className={buttonClasses}
        disabled={disabled}
        onClick={handleClick}
        {...ButtonProps}
      >
        <Paper className={paperClasses}>
          <span className={offClasses}>{off}</span>
          <span className={onClasses}>{on}</span>
        </Paper>
      </ButtonBase>
      <input
        checked={isChecked}
        className={classes.input}
        id={id}
        name={name}
        onChange={onChange}
        ref={inputEl}
        type="checkbox"
      />
    </span>
  );
}

ButtonSwitch.defaultProps = {
  checked: false,
  classes: {},
  color: 'initial',
  id: '',
  name: '',
  off: '',
  on: '',
  onChange: () => {},
};

ButtonSwitch.propTypes = {
  ButtonProps: PropTypes.object,
  checked: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  off: PropTypes.string,
  on: PropTypes.string,
  onChange: PropTypes.func,
};

export default ButtonSwitch;
