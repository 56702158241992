import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { getPersonalWorkCalendarData } from 'scenes/WorkDemo/services/getPersonalWorkCalendarData.service';
import { loadAssignmentsForProjects } from 'store/assignments/thunks/loadAssignmentsForProjects';
import { loadRecordAllowancesOfCurrentUserInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfCurrentUserInTimeframe.thunk';
import {
  selectWorkSearchParamsFromDate,
  selectWorkSearchParamsToDate,
} from '../../selectors/workSearchParamsSelectors';

export const loadPersonalWorkCalendarData = createAsyncThunk(
  'workCalendar/loadPersonalWorkCalendarData',
  async (_, { dispatch, getState }) => {
    const state = getState();

    const fromDate = selectWorkSearchParamsFromDate(state);

    const toDate = selectWorkSearchParamsToDate(state);

    const personalWorkCalendarData = await getPersonalWorkCalendarData(
      fromDate,
      toDate
    );

    dispatch(
      loadRecordAllowancesOfCurrentUserInTimeframe({ fromDate, toDate })
    );

    const personalRecords = personalWorkCalendarData.records;

    const projectIdsWithExistingRecords = uniq(
      personalRecords.map((record) => record.project)
    );
    dispatch(loadAssignmentsForProjects(projectIdsWithExistingRecords));

    return personalWorkCalendarData;
  }
);
