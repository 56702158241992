import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectCardsArrayWithoutDeleted } from './cards.selectors';

export const selectCurrentUserCards = createSelector(
  [selectCardsArrayWithoutDeleted, selectLoggedInUserId],
  (cards, currentUserId) =>
    cards.filter((card) => card.userId === currentUserId)
);

export const selectValidCurrentUserCards = createSelector(
  [selectCurrentUserCards],
  (cards) => {
    const currentDate = moment().format('YYYY-MM-DD');

    return cards.filter((card) => card.validUntil >= currentDate);
  }
);

export const selectExpiredCurrentUserCards = createSelector(
  [selectCurrentUserCards],
  (cards) => {
    const currentDate = moment().format('YYYY-MM-DD');

    return cards.filter((card) => card.validUntil < currentDate);
  }
);

export const selectCurrentUserCardsExpiringInSetPeriod = createSelector(
  [selectValidCurrentUserCards],
  (validCurrentUserCards) => {
    const defaultExpirationWarning = moment()
      .add(1, 'month')
      .format('YYYY-MM-DD');

    const expiringCards = validCurrentUserCards.filter(
      (card) => card.validUntil <= defaultExpirationWarning
    );

    return expiringCards;
  }
);

export const selectCurrentUserCardIdsExpiringInSetPeriod = createSelector(
  [selectCurrentUserCardsExpiringInSetPeriod],
  (cards) => cards.map((card) => card.id)
);

export const selectIsExpiringCurrentUserCards = createSelector(
  [selectCurrentUserCardsExpiringInSetPeriod],
  (expiringCards) => (expiringCards && expiringCards.length > 0 ? true : false)
);

export const selectValidCurrentUserCardsWithExpiringStatus = createSelector(
  [selectValidCurrentUserCards, selectCurrentUserCardIdsExpiringInSetPeriod],
  (validCards, expiringCardIds) =>
    validCards.map((card) => ({
      ...card,
      isExpiring: expiringCardIds.includes(card.id),
    }))
);
