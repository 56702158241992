import i18n from '../i18n';
import dayjs from 'dayjs';
import moment from 'moment';

export const changeLanguage = (locale) => {
  const currentLang = i18n.language;
  let language = locale && locale ? locale : i18n.language;

  if (currentLang !== locale) {
    i18n.changeLanguage(language);
  }

  try {
    import(`dayjs/locale/${language.toLowerCase()}.js`).then((lng) =>
      dayjs.locale(lng.name)
    );
    moment.locale(language);
    require('moment/locale/' + language.toLowerCase()); // eslint-disable-line no-undef
  } catch (error) {
    //console.log('dayjs error', error);
    dayjs.locale('en');
    moment.locale('en');
  }
};
