import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProcountorDimensions } from '../services/getProcountorDimensions.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const loadProcountorDimensions = createAsyncThunk(
  'project/loadProcountorDimensions',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const selectedProject = selectSelectedProject(state);
    if (!selectedProject.business_id) {
      return rejectWithValue('no business id');
    } else {
      const dimensions = await getProcountorDimensions(
        selectedProject.business_id
      );
      /* const dimensions = await getProcountorDimensions('123454'); */
      return { procountorDimensions: dimensions ? dimensions : [] };
    }
  }
);
