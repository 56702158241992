import imageCompression from 'browser-image-compression';

export const compressCardImage = async (cardImage) => {
  console.log(`originalFile size ${cardImage.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
  };

  const compressedFile = await imageCompression(cardImage, options);

  console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

  return compressedFile;
};
