import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCreateSaleInvoice } from '../services/setCreateSaleInvoice';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';


export const createSaleInvoice = createAsyncThunk(
  'saleInvoices/createSaleInvoice',
  async (params, { getState, dispatch }) => {
    console.log('params', params);
    const { saleInvoice } = params;

    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await setCreateSaleInvoice(selectedProjectId, saleInvoice);
    console.log('response', response);
    return response;
  }
);
