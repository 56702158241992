import { createAsyncThunk } from '@reduxjs/toolkit';
// import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getAttachments } from '../../utils/getAttachments';

export const loadAttachments = createAsyncThunk(
    'bulletins/loadAttachments',
    async (bulletinId, { getState }) => {

        const attachments = await getAttachments(
            bulletinId
        );

        return attachments;
    }
);