import assignmentItem from 'LixaniAPI/assignmentListItem';
import { itemWithBudget } from 'LixaniAPI/assignmentListItem';
const items = `
    items {
      ${assignmentItem}
    }
    nextToken
`;

const itemsWithBudget = `
items {
  ${itemWithBudget}
}
nextToken
`;

export { itemsWithBudget };
export default items;
