import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedEmployersBusinessIds } from 'scenes/WorkDemo/store/selectors/workSearchParamsSelectors';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import {
  selectProjectsArray,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import { closeReportSendDialog } from '../actions/reports.actions';
import {
  selectAdditionalUserSalaryRows,
  selectIsRecordBasedReductionsActive,
  selectReportBasicData,
  selectReportPreviewWeeklyPeriodTableData,
  selectReportUserRowTotals,
  selectRowComments,
  selectSalaryRowsRecipientEmails,
} from '../selectors/reports.selectors';
import { postProcountorSalaryRows } from '../services/postProcountorSalaryRows.service';
import { returnSentReportDataToSave } from '../utils/returnSentReportDataToSave';

export const sendProcountorSalaryData = createAsyncThunk(
  'reports/sendProcountorSalaryData',
  async (_, { getState, dispatch }) => {
    const reportBasicData = selectReportBasicData(getState());

    const workCalendarSelectedBusinessIds = selectSelectedEmployersBusinessIds(
      getState()
    );

    const activeEmployerId = selectSelectedEmployer(getState());

    const projects = selectProjectsArray(getState());

    const selectedProject = selectSelectedProject(getState());
    const recipientEmailAddresses = selectSalaryRowsRecipientEmails(getState());

    const rowComments = selectRowComments(getState());

    const navigationActiveEmployer = projects.find(
      (project) => project.id === activeEmployerId
    );

    const additionalUserSalaryRows = selectAdditionalUserSalaryRows(getState());

    const reportUserRowTotals = selectReportUserRowTotals(getState());

    const weeklyTableData = selectReportPreviewWeeklyPeriodTableData(
      getState()
    );

    const isRecordBasedReductions = selectIsRecordBasedReductionsActive(
      getState()
    );

    let businessId;

    if (workCalendarSelectedBusinessIds[0]) {
      businessId = workCalendarSelectedBusinessIds[0];
    } else if (navigationActiveEmployer) {
      businessId = navigationActiveEmployer.business_id;
    } else {
      businessId = selectedProject.business_id;
    }

    let userSalaryRows = [];

    Object.values(reportUserRowTotals).forEach((row) => {
      const { salaryType, name, unitCount, userId, procountorPersonNumber } =
        row;

      const rowComment = rowComments[userId];

      userSalaryRows.push({
        userId,
        salaryType,
        unitCount,
        projectName: '',
        assignmentName: '',
        earningPeriodStartDate: reportBasicData.fromDate,
        earningPeriodEndDate: reportBasicData.toDate,
        comment: rowComment,
        personName: name,
        procountorPersonNumber,
      });
    });

    Object.values(additionalUserSalaryRows).map((userRows) =>
      userRows.forEach((salaryRow) => {
        const rowComment =
          rowComments[`${salaryRow.userId}-${salaryRow.salaryType}`];

        const manuallyAddedUser =
          salaryRow.userId.includes('custom-added-user');

        const manuallyAddedMainSalaryRow = Object.values(
          Object.values(reportUserRowTotals)
        ).find((userRow) => userRow.userId === salaryRow.userId);

        userSalaryRows.push({
          userId: manuallyAddedUser ? undefined : salaryRow.userId,
          salaryType: salaryRow.salaryType,
          unitCount: salaryRow.unitCount,
          unitPrice: salaryRow.unitPrice,
          earningPeriodStartDate: reportBasicData.fromDate,
          earningPeriodEndDate: reportBasicData.toDate,
          comment: rowComment,
          personName: manuallyAddedUser
            ? manuallyAddedMainSalaryRow?.name
            : salaryRow.userName,
          procountorPersonNumber:
            manuallyAddedMainSalaryRow?.procountorPersonNumber,
        });
      })
    );

    const reportDataToSave = returnSentReportDataToSave({
      selectedProjectId: selectedProject.id,
      weeklyTableData,
      userAdditionalSalaryRows: additionalUserSalaryRows,
      reportBasicData,
      state: 'SENT',
      rowComments,
      reportUserRowTotals,
      isRecordBasedReductions,
    });

    const response = await postProcountorSalaryRows(
      {
        salaryRows: userSalaryRows,
        recipientEmailAddresses,
        reportDataToSave,
      },
      businessId
    );

    dispatch(closeReportSendDialog());

    return response;
  }
);
