import {
  BULLETIN_BOARD_ACCESS,
  BULLETIN_CREATE_EDIT_OWN,
  EMPLOYEE_FEATURES_ACCESS,
  FORMS_ACCESS,
  FORMS_CREATE_EDIT_OWN,
  GALLERY_ACCESS,
  PROJECT_BANK_ACCESS,
  PROJECT_GENERATE_REPORT,
  PROJECT_INVITE,
  PROJECT_TIMELINES_VIEW,
  UPLOAD_FILE,
  UPLOAD_PICTURE,
  VIEW_OTHERS_FILE_PERMISSIONS,
  WORK_CALENDAR_ACCESS,
} from './permissions';

export const EMPLOYEE_DEFAULT_ROLE_ITEM = {
  roleId: 'LIXANI_DEFAULT_EMPLOYEE',
  // projectId: 'LIXANI',
  // PK: 'PROJECT#LIXANI',
  // SK: 'ROLE#DEFAULT_EMPLOYEE',
  roleName: 'Työntekijä (Lixani oletus)',
  permissions: {
    [FORMS_ACCESS]: true,
    [FORMS_CREATE_EDIT_OWN]: true,
    [BULLETIN_BOARD_ACCESS]: true,
    [BULLETIN_CREATE_EDIT_OWN]: true,
    [GALLERY_ACCESS]: true,
    [PROJECT_BANK_ACCESS]: true,
    [UPLOAD_PICTURE]: true,
    [UPLOAD_FILE]: true,
    [PROJECT_GENERATE_REPORT]: true,
    [PROJECT_INVITE]: true,
    [WORK_CALENDAR_ACCESS]: true,
    [EMPLOYEE_FEATURES_ACCESS]: true,
    [VIEW_OTHERS_FILE_PERMISSIONS]: true,
    [PROJECT_TIMELINES_VIEW]: true,
  },
};

export const RESIDENT_DEFAULT_ROLE_ITEM = {
  roleId: 'LIXANI_DEFAULT_RESIDENT',
  // projectId: 'LIXANI',
  // PK: 'PROJECT#LIXANI',
  // SK: 'ROLE#DEFAULT_RESIDENT',
  roleName: 'Asukas (Lixani oletus)',
  permissions: {
    [BULLETIN_BOARD_ACCESS]: true,
  },
};
