import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { selectModifiedNotificationSettings } from '../selectors/notification-settings.selectors';
import { postUpdateNotificationSettingsOfBusiness } from '../services/postUpdateNotificationSettingsOfBusiness.service';

export const saveNotificationSettingsOfBusiness = createAsyncThunk(
  'notificationSettings/saveNotificationSettingsOfBusiness',
  async (_, { getState }) => {
    const selectedProject = selectSelectedProject(getState());

    const modifiedNotificationSettingsAttributes =
      selectModifiedNotificationSettings(getState());

    const response = await postUpdateNotificationSettingsOfBusiness(
      selectedProject.id,
      modifiedNotificationSettingsAttributes
    );

    return response;
  }
);
