import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { RECORD_STATUS } from 'LixaniAPI/enums';
import AssignmentAmountDialogLayout from './AssignmentAmountDialogLayout';
import recordUpdate from 'LixaniAPI/recordUpdate';
import { Record } from 'lixani-lib-graphql';

function AssignmentAmountDialog(props) {
  return (
    <AssignmentAmountDialogLayout
      onClose={props.onClose}
      record={props.record}
      assignment={props.assignment}
      setAssignmentUnitAmount={props.setAssignmentUnitAmount}
      assignmentUnitAmount={props.assignmentUnitAmount}
    />
  );
}


AssignmentAmountDialog.defaultProps = {
  classes: {},
  onClose: () => null,
};

AssignmentAmountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired
};

export default AssignmentAmountDialog;
