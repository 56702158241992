import classicTheme from './classicTheme';
import darkTheme from './darkTheme';
import lightTheme from './lightTheme';

function useTheme(theme) {
  if (theme === 'dark') return darkTheme;
  if (theme === 'light') return lightTheme;
  return classicTheme;
}

export { default } from './classicTheme';
export { useTheme };
export { default as ClassicTheme } from './classicTheme';
export { default as DarkTheme } from './darkTheme';
export { default as LightTheme } from './lightTheme';
