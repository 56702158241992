import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMediaFilePermissionsByUserAndProject } from '../../services/getMediaFilePermissionsByUserAndProject.service';
import { selectGalleryLastEvaluatedKey } from 'scenes/ProjectGallery/store/selectors/projectGallery.selectors';

export const loadMediaFilePermissionsByUserAndProject = createAsyncThunk(
  'file-permissions/get-media-file-permissions-by-user-and-project',
  async ({ projectId }, { dispatch }) => {
    let lastEvaluatedKey = undefined;
    let filePermissions = [];
    let records = [];

    do {
      const response = await getMediaFilePermissionsByUserAndProject(
        projectId,
        lastEvaluatedKey
      );

      lastEvaluatedKey = response.lastEvaluatedKey;
      filePermissions.push(response.filePermissions);
      records.push(response.records);
    } while (lastEvaluatedKey);

    return { filePermissions: filePermissions.flat(), records: records.flat() };
  }
);
