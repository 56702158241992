import React, { Component } from 'react';
import { Translation } from 'react-i18next';

import Button from 'components/Button/Button';

import ChevronRightIcon from 'mdi-material-ui/ChevronRight';
import ChevronDownIcon from 'mdi-material-ui/ChevronDown';
import { RecordAssignmentAmountDialog } from 'components/AssignmentAmountDialog/RecordAssignmentAmoutDialog';

class AssignmentsSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openTree: {},
      assignment: this.props.a1,
      selectedAssignment: this.props.selectedAssignment,
    };
  }

  componentDidUpdate = (prevStat, prevProp) => {
    if (
      this.state.assignment !== this.props.a1 ||
      this.state.selectedAssignment !== this.props.selectedAssignment
    ) {
      this.setState({
        assignment: this.props.a1,
        selectedAssignment: this.props.selectedAssignment,
      });
    }
  };

  render() {
    const assignments =
      (this.props.assignments &&
        this.props.assignments.reduce((resp, ob) => {
          let my = ob.project.mirrorUser;
          if (!(my in resp)) {
            resp[my] = [];
          }
          resp[my].push(ob);
          return resp;
        }, {})) ||
      {};

    const getChildren2 = (mirrorUser, parent, copyOrigin) =>
      assignments[mirrorUser].filter((ob) => {
        const pa = (ob.parent && ob.parent.id) || null;
        if (copyOrigin) {
          return pa === parent || pa === copyOrigin;
        } else {
          return pa === parent;
        }
      });

    /**
     * Goes through all assignments and returns a layer of the tree structure
     *
     */
    const getAssignments = (mirrorUser, parent, copyOrigin) => {
      let parentAssignments = assignments[mirrorUser].filter((ob) => {
        const pa = (ob.parent && ob.parent.id) || null;
        if (copyOrigin) {
          return pa === parent || pa === copyOrigin;
        } else {
          return pa === parent;
        }
      });
      return parentAssignments;
    };

    /**
     * getAssignmentNumber combines given assignments number with all its parents numbers
     */
    const getAssignmentNumber = (ass) => {
      let assignmentNumber = '';
      if (ass.number) {
        assignmentNumber += ass.number + '.';
        if (ass.parent) {
          assignmentNumber = ass.parent.number + '.' + assignmentNumber;
          if (ass.parent.parent) {
            assignmentNumber =
              ass.parent.parent.number + '.' + assignmentNumber;
            if (ass.parent.parent.parent) {
              assignmentNumber =
                ass.parent.parent.parent.number + '.' + assignmentNumber;
            }
          }
        }
      }
      return assignmentNumber;
    };

    /**
     * Constructs the tree of assignments
     */

    const assignmentHasUnitAmountToSet = (assignmentId, assingments) => {
      const assignmentToCheck = assingments.find(
        (assignment) => assignment.id === assignmentId
      );

      return assignmentToCheck && assignmentToCheck.unit ? true : false;
    };

    const selectedAssignmentObject = this.props.assignments.find(
      (assignment) => assignment.id === this.state.selectedAssignment
    );

    const showAssignments2 = (mirrorUser, parent, copyOrigin) => {
      let assignmentLayer = getAssignments(mirrorUser, parent, copyOrigin);

      return (
        <div>
          {assignmentLayer
            .sort((a, b) => a.number - b.number)
            .map((a, index) => {
              const isLast =
                getChildren2(mirrorUser, a.id, a.copyOrigin).length === 0;
              const color = this.state.selectedAssignment
                ? a.id === this.state.selectedAssignment
                  ? 'dark'
                  : 'light'
                : 'light';
              return (
                <div style={{ marginBottom: 2 }} key={a.id + ' ' + index}>
                  {!isLast && (
                    <div>
                      <Button
                        disabled={this.props.disabled}
                        fullWidth={true}
                        color="light"
                        onClick={() => {
                          this.setState({
                            openTree: {
                              ...this.state.openTree,
                              [a.id]: !this.state.openTree[a.id],
                            },
                          });
                        }}
                      >
                        <div
                          style={{
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              float: 'right',
                            }}
                          >
                            {this.state.openTree[a.id] ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </div>
                          {a.number != null && a.number !== ''
                            ? getAssignmentNumber(a)
                            : ''}{' '}
                          {a.name}
                        </div>
                      </Button>

                      {this.state.openTree[a.id] && (
                        <div style={{ margin: 10 }}>
                          {showAssignments2(mirrorUser, a.id, a.copyOrigin)}
                        </div>
                      )}
                    </div>
                  )}

                  {isLast && (
                    <div>
                      <Button
                        disabled={this.props.disabled}
                        fullWidth={true}
                        color={color}
                        onClick={() => {
                          let ass = this.state.selectedAssignment
                            ? this.state.selectedAssignment
                            : {};
                          this.props.update(
                            this.state.assignment === a.id ? null : a.id
                          );

                          const isUnitAmount = assignmentHasUnitAmountToSet(
                            a.id,
                            this.props.assignments
                          );

                          const isAlreadySelected =
                            a.id === this.state.selectedAssignment;
                          if (isUnitAmount && !isAlreadySelected) {
                            this.props.setDialogOpen(true);
                          }
                        }}
                      >
                        <div
                          style={{
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          {`${
                            a.number != null && a.number !== ''
                              ? getAssignmentNumber(a)
                              : ''
                          } ${a.name} ${
                            a.id === this.state.selectedAssignment &&
                            a.unit &&
                            this.props.unitAmount
                              ? `${this.props.unitAmount} ${a.unit}`
                              : ''
                          }`}
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          <RecordAssignmentAmountDialog
            assignment={selectedAssignmentObject}
            assignmentUnitAmount={
              selectedAssignmentObject
                ? selectedAssignmentObject.assignmentUnitAmount
                : undefined
            }
            setAssignmentUnitAmount={(amount) =>
              this.props.setAssignmentUnitAmount(amount)
            }
            open={this.props.unitAmountDialogOpen}
            setDialogOpen={this.props.setDialogOpen}
          ></RecordAssignmentAmountDialog>
        </div>
      );
    };
    return (
      <Translation ns={['common']}>
        {(t) => (
          <div>
            {Object.keys(assignments).map((key, index) => (
              <div key={key}>
                <label className="mylabel">{t('expense.assignment')}</label>

                {showAssignments2(key, null)}
              </div>
            ))}
          </div>
        )}
      </Translation>
    );
  }
}

export default AssignmentsSelect;
