import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getSubProjects from 'scenes/ProjectForms/utils/getSubProjects';

export const getParentSubProjects = createAsyncThunk(
    'projectForm/getSubProjects',
    async (business_id, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getSubProjectPromises = [];

        getSubProjectPromises.push(getSubProjects(business_id, params));

        const getSubProjectPromisesSettled = await Promise.allSettled(
            getSubProjectPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const subProjects = [].concat(...getSubProjectPromisesSettled);

        return {
            subProjects,
        };
    }
);