import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import patchCheckRecord from '../services/checkRecord.service';

export const checkRecord = createAsyncThunk(
  'records/checkRecord',
  async (_, { getState }) => {
    const selectedRecord = selectSelectedRecord(getState());

    const recordProjectId = selectedRecord.project;

    const checkedRecord = await patchCheckRecord(
      recordProjectId,
      selectedRecord.id
    );

    return checkedRecord;
  }
);
