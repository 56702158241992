import axios from 'axios';
import Config from 'Config';
import dayjs from 'dayjs';
import { createSignedRequest } from 'utils/createSignedRequest';

export const getProjectRecordsFromToday = async (projectId) => {
  const fromDate = dayjs().startOf('day').utcOffset(0).format();
  const toDate = dayjs().endOf('day').utcOffset(0).format();

  const apiPath = `projects/${projectId}/records/${fromDate}/${toDate}`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
};
