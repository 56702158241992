import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateFilepermissionReactions } from '../services/updateFilepermissionReactions';

export const reactToFilepermission = createAsyncThunk(
  'file-permissions/',
  async (params, { getState, dispatch }) => {
    console.log('params', params);
    const { filePermissionId, reaction } = params;

    const response = await updateFilepermissionReactions(
      filePermissionId,
      reaction
    );
    console.log('response', response);
    return response
  }
);
