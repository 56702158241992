import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import patchCheckRecordSingleTime from '../services/patchCheckRecordSingleTime.service';

export const checkRecordSingleTime = createAsyncThunk(
  'records/checkRecordSingleTime',
  async (timeAttributeName, { getState }) => {
    const selectedRecord = selectSelectedRecord(getState());

    const recordProjectId = selectedRecord.project;

    const checkedRecord = await patchCheckRecordSingleTime(
      timeAttributeName,
      recordProjectId,
      selectedRecord.id
    );

    return checkedRecord;
  }
);
