import dayjs from 'dayjs';

export default {
  stateDate: dayjs().format('YYYY-MM-DD'),
  fromDate: dayjs().startOf('isoWeek').utcOffset(0).format(),
  toDate: dayjs().endOf('isoWeek').utcOffset(0).format(),
  employees: [],
  employers: [],
  keyword: [],
  listProject: [],
  mainParent: null,
  selectedProjects: [],
  visibleWeeks: [dayjs().format('GGGG[W]WW')],
};
