import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { selectUserIdToAddCardFor } from '../selectors/cards.selectors';
import { getCardImageUploadSignedUrl } from '../services/getCardImageUploadSignedUrl.service';
import { postAddCardForUser } from '../services/postAddCardForUser.service';
import { compressCardImage } from '../utils/compressCardImage.util';

export const addCardForOtherUser = createAsyncThunk(
  'cards/addCardForOtherUser',
  async (cardData, { getState }) => {
    const userIdOfOtherUserToAddCardFor = selectUserIdToAddCardFor(getState());

    const { cardAttributes, cardImage } = cardData;

    const compressedImage = await compressCardImage(cardImage);

    const cardUserId = userIdOfOtherUserToAddCardFor;

    if (!cardUserId) {
      console.log('Error: User id not provided to add card for');
    }

    const cardToPost = { ...cardAttributes, userId: cardUserId };

    const fileType = compressedImage.type;

    const imageUploadSignedUrl = await getCardImageUploadSignedUrl(
      cardUserId,
      cardAttributes.id,
      fileType
    );

    if (imageUploadSignedUrl) {
      const options = {
        headers: {
          'Content-Type': fileType,
        },
      };

      await Axios.put(imageUploadSignedUrl, compressedImage, options);
    }

    const response = await postAddCardForUser(cardToPost);

    return response;
  }
);
