import { createAsyncThunk } from '@reduxjs/toolkit';
import { RECORD_STATUS, RECORD_TYPE } from 'LixaniAPI/enums';
import { selectSelectedEmployer } from 'store/breadcrumbs/selectors/breadcrumbs.selectors';
import { selectProjects } from 'store/projects/selectors/projectsSelectors';
import postClockInRecord from 'store/records/services/clockInRecord.service';
import { selectLoggedInUserResourcesArray } from 'store/resources/selectors/resources.selectors';
import {
  selectLoggedInUserId,
  selectUserCoordinates,
} from 'store/user/selectors.js/user.selectors';
import uuidv4 from 'uuid/v4';
import { openLocationMustDialog } from '../actions/records.actions';
import { getGeolocation } from './getGeolocation.thunk';
import { setOptimisticRecord } from './setOptimisticRecord.thunk';

export const clockInRecord = createAsyncThunk(
  'records/clockInRecord',
  async (
    { projectId, allowClockInWithOutLocation },
    { getState, dispatch, rejectWithValue }
  ) => {
    const now = new Date().toISOString();

    await dispatch(getGeolocation());

    const activeEmployerId = selectSelectedEmployer(getState());

    const projects = selectProjects(getState());

    const project = projects[projectId];

    const loggedInUserId = selectLoggedInUserId(getState());

    const currentUserResources = selectLoggedInUserResourcesArray(getState());

    const currentUserResourcesFromRecordProject = currentUserResources.filter(
      (resource) => resource.project === projectId
    );

    const recordProjectResourcesWithNavigationActiveEmployer =
      currentUserResourcesFromRecordProject.filter(
        (resource) => resource.employer === activeEmployerId
      );

    // resource that is not an admin resource is prioritized to be used when clocking in record.
    const normalEmployeeResource =
      recordProjectResourcesWithNavigationActiveEmployer.find(
        (resource) => !resource.adminOfBusiness
      );

    const resourceToMarkRecordFor = normalEmployeeResource
      ? normalEmployeeResource
      : recordProjectResourcesWithNavigationActiveEmployer[0];

    // if no resource can be deduced from front end, resource is sent as undefined to backend, where it is finally determined
    // also setting billedClockInAt default value same as clockInAt

    const coordinates = selectUserCoordinates(getState());

    if (project.locationMust && !coordinates && !allowClockInWithOutLocation) {
      dispatch(openLocationMustDialog(project));
      return rejectWithValue('locationMust');
    }

    const recordWithActiveEmployerResourceFilledAndBilledClockOut = {
      id: uuidv4(),
      clockInAt: now,
      project: project.id,
      status: RECORD_STATUS.IN,
      type: RECORD_TYPE.WORK,
      title: null,
      mirrorUser: loggedInUserId,

      resource: resourceToMarkRecordFor
        ? resourceToMarkRecordFor.id
        : undefined,
      billedClockInAt: now,
      latIn: coordinates ? coordinates.latitude : undefined,
      longIn: coordinates ? coordinates.longitude : undefined,
    };

    await dispatch(
      setOptimisticRecord(
        recordWithActiveEmployerResourceFilledAndBilledClockOut
      )
    );

    const response = await postClockInRecord(
      recordWithActiveEmployerResourceFilledAndBilledClockOut
    );

    return response;
  }
);
