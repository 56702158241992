import { createEntityAdapter } from '@reduxjs/toolkit';

export const resourcesAdapter = createEntityAdapter();
export const resourcesToMakeAdminOfAdapter = createEntityAdapter();
export const loggedInUserResourcesAdapter = createEntityAdapter();

export const {
  selectAll: selectAllResources,
  selectEntities: selectResourceEntities,
  selectById: selectResourceById,
  selectIds: selectResourceIds,
} = resourcesAdapter.getSelectors((state) => state.resources.resources);
export const {
  selectAll: selectAllResourcesToMakeAdminOf,
  selectEntities: selectResourcesToMakeAdminOfEntities,
  selectById: selectResourcesToMakeAdminOfById,
  selectIds: selectResourcesToMakeAdminOfIds,
} = resourcesToMakeAdminOfAdapter.getSelectors(
  (state) => state.resources.resourcesToMakeAdminOf
);
