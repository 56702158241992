import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import patchUncheckRecord from '../services/uncheckRecord.service';

export const uncheckRecord = createAsyncThunk(
  'records/uncheckRecord',
  async (_, { getState }) => {
    const selectedRecord = selectSelectedRecord(getState());

    const recordProjectId = selectedRecord.project;

    const unChecked = await patchUncheckRecord(
      recordProjectId,
      selectedRecord.id
    );

    return unChecked;
  }
);
