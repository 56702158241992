import { createSlice } from '@reduxjs/toolkit';
import { arrayToMap } from 'utils/arrayToMap';
import { permissionsArrayToMap } from 'utils/permissionsArrayToMap';
import { loadAllUserProjectsPermissions } from '../thunks/loadAllUserProjectsPermissions.thunk';
import { loadUserSingleProjectPermissions } from '../thunks/loadUserSingleProjectPermissions';

export const projectPermissionsSlice = createSlice({
  name: 'projectPermissions',
  initialState: {
    userProjectPermissionsByProjectId: {},
    userAdminPermissions: {},
    loading: false,
    isPersonalPermissionsLoaded: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUserSingleProjectPermissions.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loadUserSingleProjectPermissions.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        userProjectPermissionsByProjectId: {
          ...state.userProjectPermissionsByProjectId,
          [action.payload.projectId]: action.payload.permissions,
        },
      }))
      .addCase(loadUserSingleProjectPermissions.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(loadAllUserProjectsPermissions.pending, (state) => ({
        ...state,
        loading: true,
        isPersonalPermissionsLoaded: false,
      }))
      .addCase(loadAllUserProjectsPermissions.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        userProjectPermissionsByProjectId: permissionsArrayToMap(
          action.payload.projectPermissions,
          'projectId'
        ),
        userAdminPermissions: arrayToMap(
          action.payload.adminPermissions,
          'businessId'
        ),
        isPersonalPermissionsLoaded: true,
      }))
      .addCase(loadAllUserProjectsPermissions.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
        isPersonalPermissionsLoaded: true,
      }));
  },
});
