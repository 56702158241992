import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';
import axios from 'axios';

export default async function getResourcesAndResourceEmployersOfProject(
  projectId
) {
  const apiPath = `projects/${projectId}/resources-and-resource-employers`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,

    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
