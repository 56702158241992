import { createSignedRequest } from 'utils/createSignedRequest';
import axios from 'axios';
import Config from 'Config';

export async function getPermissionsOfProject(projectId) {
  const apiPath = `permission/${projectId}/permissions`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,

    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'GET',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };

  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest)
    .then((res) => res)
    .catch((e) => console.error(e));

  return response.data;
}
