import recordListItem from 'LixaniAPI/recordListItem'
import assignmentListItem from 'LixaniAPI/assignmentListItem';

const item = `
			__typename
			id
			createdAt
			updatedAt
			name
			description
			type
			key
			parentKey
			path
			parentPath
			businessId
			record {
				${recordListItem}
			}
			rotation
			assignment {
				${assignmentListItem}
			}
			project {
				id
				name
				projectKey
			}

			parent {
				__typename
				id
				createdAt
				updatedAt
				name
				description
				type
				key
				parentKey
				path
				parentPath
				businessId
				record {
					id
				}
				file {
					bucket
					key
					region
					mimeType
				}
			}

			file {
				bucket
				key
				region
				mimeType
			}

			user {
				id
				firstName
				lastName
			}
			reactions {
				id
				createdAt
				userId
				reaction
			}
			hasMediumSize
`;

export default item;
