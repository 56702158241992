import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeRoleFromUserService } from 'scenes/Permissions/services/roles/removeRoleFromUser.service';
import { closeRemoveRoleFromUserDialog } from '../../actions/permissons-dialog.actions';

export const removeRoleFromUserThunk = createAsyncThunk(
  'permissionsManagement/removeRoleFromUser',
  async (_, { getState, dispatch }) => {
    const {
      permissionsDialog: { removeRoleFromUserDialogData },
    } = getState();

    const userId = removeRoleFromUserDialogData.user;
    const projectId = removeRoleFromUserDialogData.project;

    const response = await removeRoleFromUserService(userId, projectId);

    dispatch(closeRemoveRoleFromUserDialog());

    return response;
  }
);
