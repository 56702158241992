import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchNotificationAsDismissed } from '../services/updateNotificationAsDismissed.service';

export const dismissNotification = createAsyncThunk(
  'notifications/dismissNotification',
  async (notificationId) => {
    const response = await patchNotificationAsDismissed(notificationId);

    return response;
  }
);
