import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';
import Config from 'Config';

export async function setDeleteAttachment(filePermissionId) {
    const apiPath = `file-permissions/${filePermissionId}/set-as-deleted`;

    const request = {
        url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
        // url: `http://localhost:3001/${apiPath}`,
        host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
        path: apiPath,
        method: 'PATCH',
        region: Config.AWS_REGION,
        service: 'execute-api',
        body: JSON.stringify({}),
        data: {},
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const signedRequest = await createSignedRequest(request);
    const response = await axios(signedRequest);

    return response.data;
}