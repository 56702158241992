import { createAsyncThunk } from '@reduxjs/toolkit';

import { setUpdateInvoice } from '../services/setUpdateInvoice';
import { setUpdateMultipleInvoices } from '../services/setUpdateMultipleInvoices';

export const patchUpdateMultipleInvoices = createAsyncThunk(
  'invoices/patchUpdateMultipleInvoices',
  async ({invoiceIds, updateData}, { getState, dispatch }) => {
    console.log('updateData', updateData);
    const projectId = getState().projects.selectedProjectId;

    const response = await setUpdateMultipleInvoices(invoiceIds, projectId, updateData);
    console.log('response', response);
    return response;
  }
);
