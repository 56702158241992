import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Refresher(props) {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = new Date().toLocaleTimeString();
      setTime(newTime);
    }, props.interval);
    return () => clearInterval(interval);
  }, [time]);

  return <React.Fragment>{props.render()}</React.Fragment>;
}

Refresher.propTypes = {
  render: PropTypes.func.isRequired,
  interval: PropTypes.number,
};

Refresher.defaultProps = {
  interval: 1000,
};

export default Refresher;
