import { createAsyncThunk } from '@reduxjs/toolkit';

import { setUpdateInvoiceSortingRule } from '../services/setUpdateInvoiceSortingRule';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const patchUpdateInvoiceSortingRule = createAsyncThunk(
  'invoices/patchUpdateInvoiceSortingRule',
  async ({ businessId, updateData }, { getState, dispatch }) => {
    console.log('PATCH updateData', updateData);
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await setUpdateInvoiceSortingRule(mainProjectId, businessId, updateData);
    console.log('response', response);
    return response;
  }
);
