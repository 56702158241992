import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPersonalClockedInRecords } from '../services/getPersonalClockedInRecords.service';

export const loadPersonalClockedInRecords = createAsyncThunk(
  'records/loadPersonalClockedInRecords',
  async () => {
    const clockedInRecords = await getPersonalClockedInRecords();

    return clockedInRecords;
  }
);
