import Config from 'Config';
import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';

export async function putCreateOrUpdateUser(
  userAttributes,
  projectKeyOfProjectToJoin,
  businessIdOfEmployerToJoinWith
) {
  const apiPath = 'user/create-or-update-user';

  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'PUT',
    body: JSON.stringify({
      userAttributes,
      projectKeyOfProjectToJoin,
      businessIdOfEmployerToJoinWith,
    }),
    data: {
      userAttributes,
      projectKeyOfProjectToJoin,
      businessIdOfEmployerToJoinWith,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);
  const response = await axios(signedRequest);

  return response.data;
}
