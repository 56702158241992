import { createAsyncThunk } from '@reduxjs/toolkit';
import patchClockOutBreakRecord from '../services/clockOutBreakRecord.service';

export const clockOutBreakRecord = createAsyncThunk(
  'records/clockOutBreakRecord',
  async (recordId) => {
    const updatedRecord = await patchClockOutBreakRecord(recordId);
    return updatedRecord;
  }
);
