import Config from 'Config';
import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';

/**
 * Updates a project with the given projectData.project values
 * @param {object} projectData - needs to be in form of - { project: {...updatable properties} }
 * @returns
 */
export default async function updateProjectService(projectId, projectData) {
  const apiPath = `projects/${projectId}/update-project`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(projectData), // aws4 looks for body; axios for data
    data: projectData,
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
