import { createSelector } from '@reduxjs/toolkit';

const selectProjectUsersState = (state) => state.projectUsers;

export const selectProjectUsers = createSelector(
  [selectProjectUsersState],
  (state) => state.users.entities
);

export const selectProjectUsersPersonalResoures = createSelector(
  [selectProjectUsersState],
  (state) => state.userPersonalResources.entities
);

export const selectProjectUsersArray = createSelector(
  [selectProjectUsers],
  (users) => Object.values(users)
);

export const selectEmployeeBusinessConfigurationsByBusinessId = createSelector(
  [selectProjectUsersState],
  (state) => state.employeeBusinessConfigurationsByBusinessId
);

export const selectEmployeeConfigurationsOfEmployerByBusinessId =
  createSelector(
    [
      selectEmployeeBusinessConfigurationsByBusinessId,
      (_, businessId) => businessId,
    ],
    (employeeConfigurationsByBusinessId, businessId) => {
      const configurations = employeeConfigurationsByBusinessId[businessId];

      return configurations;
    }
  );
export const selectIsAddNewUserDialogOpen = createSelector(
  [selectProjectUsersState],
  (state) => state.isAddNewUserDialogOpen
);
