import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserRecordsFromToday } from '../services/getUserRecordsFromToday.service';

export const loadUserRecordsFromToday = createAsyncThunk(
  'records/loadUserRecordsFromToday',
  async () => {
    const records = await getUserRecordsFromToday();
    return records;
  }
);
