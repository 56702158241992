import { createSlice } from '@reduxjs/toolkit';

import { loadInteractionsByProject } from './thunks/loadInteractionsByProject';
import { groupBy } from 'lodash-es';
import { createInteraction } from './thunks/createInteraction';
import { loadInteractionsByTarget } from './thunks/loadInteractionsByTarget';

const initialState = {
  targetGroupedInteractions: {},
};

const interactionsSlice = createSlice({
  name: 'interactions',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadInteractionsByProject.pending, (state, action) => {})
      .addCase(loadInteractionsByProject.fulfilled, (state, action) => {
        const interactions = action.payload.map((interaction) => {
          return { ...interaction, id: interaction.SK };
        });

        const groupedInteractions = groupBy(interactions, 'targetId');

        const newTargetGroupedInteractions = {
          ...state.targetGroupedInteractions,
          ...groupedInteractions,
        };

        state.targetGroupedInteractions = newTargetGroupedInteractions;
      })
      .addCase(loadInteractionsByProject.rejected, (state, action) => {})
      .addCase(loadInteractionsByTarget.fulfilled, (state, action) => {
        const interactions = action.payload.map((interaction) => {
          return { ...interaction, id: interaction.SK };
        });

        const groupedInteractions = groupBy(interactions, 'targetId');

        const newTargetGroupedInteractions = {
          ...state.targetGroupedInteractions,
          ...groupedInteractions,
        };

        state.targetGroupedInteractions = newTargetGroupedInteractions;
      })
      .addCase(createInteraction.fulfilled, (state, action) => {
        if (state.targetGroupedInteractions[action.payload.targetId]) {
          state.targetGroupedInteractions[action.payload.targetId].push(
            action.payload
          );
        } else {
          state.targetGroupedInteractions[action.payload.targetId] = [
            action.payload,
          ];
        }
      });
  },
});

export default interactionsSlice.reducer;
