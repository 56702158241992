import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setEditFormTemplateInAllEmployerProjects from 'scenes/ProjectForms/utils/setEditFormTemplateInAllEmployerProjects';

export const editFormTemplateInAllEmployerProjects = createAsyncThunk(
  'projectForm/editFormTemplateInAllEmployerProjects',
  async (templateData, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const editedFormTemplate = await setEditFormTemplateInAllEmployerProjects(templateData, params);
    return { editedFormTemplate };
  }
);
