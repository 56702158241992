export const returnParentAndChildAssignmentIds = (parentIds, assignments) => {
  {
    let children = assignments
      .filter((assignment) => parentIds.includes(assignment.parent))
      .map((assignment) => assignment.id);
    if (children.length > 0) {
      const nextChildren = returnParentAndChildAssignmentIds(
        children,
        assignments
      );
      return [...parentIds, ...nextChildren];
    }
    return [...parentIds, ...children];
  }
};
