import React from 'react';
import { Grid } from '@material-ui/core';

export const RecordAllowanceItem = ({ name, unitCount, unit }) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        {name}
      </Grid>
      <Grid item xs={6}>
        {`${unitCount} ${unit}`}
      </Grid>
    </Grid>
  );
};
