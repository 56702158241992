const Config = {
  AWS_ACCOUNT_ID: process.env.REACT_APP_AWS_ACCOUNT_ID,
  AWS_AUTH_TYPE: process.env.REACT_APP_AWS_AUTH_TYPE,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_SMS_NUMBER: process.env.REACT_APP_AWS_SMS_NUMBER,
  BUILD_TS: process.env.REACT_APP_BUILD_TS,
  CHECKTAXNUMBER_APIKEY: process.env.REACT_APP_CHECKTAXNUMBER_APIKEY,
  CHECKTAXNUMBER_URL: process.env.REACT_APP_CHECKTAXNUMBER_URL,
  FILES_DOMAIN: process.env.REACT_APP_FILES_DOMAIN,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  ILVE_LOGO: process.env.REACT_APP_ILVE_LOGO,
  ILVE_WATERMARK: process.env.REACT_APP_ILVE_WATERMARK,
  LINK_LIXANI_URL: process.env.REACT_APP_LINK_LIXANI_URL,
  LIXANI_ADMIN_URL: process.env.REACT_APP_LIXANI_ADMIN_URL,
  LIXANI_WORK_URL: process.env.REACT_APP_LIXANI_WORK_URL,
  MY_LIXANI_URL: process.env.REACT_APP_MY_LIXANI_URL,
  SSO_TOKEN_URL: process.env.REACT_APP_SSO_TOKEN_URL,
  SSO_LOGIN_URL: process.env.REACT_APP_SSO_LOGIN_URL,
  SSO_LOGOUT_URL: process.env.REACT_APP_SSO_LOGOUT_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  S3_REGION: process.env.REACT_APP_S3_REGION,
  S3_PROFILE_IMAGES_DIR: process.env.REACT_APP_S3_PROFILE_IMAGES_DIR,
  S3_LOGOS_DIR: process.env.REACT_APP_S3_LOGOS_DIR,
  S3_PROJECT_BANK_DIR: process.env.REACT_APP_S3_PROJECT_BANK_DIR,
  S3_SIGNATURES_DIR: process.env.REACT_APP_S3_SIGNATURES_DIR,
  UNKNOWN_PROFILE_URL: process.env.REACT_APP_UNKNOWN_PROFILE_URL,
  UNKNOWN_PROFILE_URL_2: process.env.REACT_APP_UNKNOWN_PROFILE_URL_2,
};

export default Config;
