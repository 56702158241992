import axios from 'axios';

let creds = {};

async function getCredentials() {
  if (
    !creds.keyExpiration ||
    Math.floor(Date.now() / 1000) > creds.keyExpiration
  ) {
    return axios({
      method: 'get',
      url: process.env.REACT_APP_SSO_TOKEN_URL,
      withCredentials: true,
    }).then((response) => {
      if (response.data.errorMessage) throw new Error('SSO server error');
      if (response.data) {
        creds = response.data;
      }
      return {
        accessKeyId: response.data.accessKeyId,
        secretAccessKey: response.data.secretAccessKey,
        sessionToken: response.data.sessionToken,
      };
    });
  } else {
    return creds;
  }
}

export default getCredentials;
