import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadAllUserProjectsPermissions } from 'scenes/Permissions/store/thunks/loadAllUserProjectsPermissions.thunk';
import { setSelectedProjectId } from 'scenes/Project/store/actions/projectActions';
import { setProject } from 'store/actions/setProject';
import { loadMediaFilePermissionsByUserAndProject } from 'scenes/ProjectGallery/store/thunks/loadMediaFilePermissionsByUserAndProject';
import { loadResourcesOfProject } from 'store/resources/thunks/loadResourcesOfProject';
import {
  selectIsAdminInSelectedProject,
  selectSelectedProjectUserPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { VIEW_OTHER_RESOURCES } from 'scenes/Permissions/constants/permissions';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadInteractionsByProject } from 'store/interactions/thunks/loadInteractionsByProject';

export const initProjectGallery = createAsyncThunk(
  'projectGallery/initProjectGallery',
  async (projectId, { dispatch, getState }) => {
    dispatch(setProject({ id: projectId }));
    dispatch(setSelectedProjectId(projectId));

    await dispatch(loadAllUserProjectsPermissions());

    const state = getState();

    const permissions = selectSelectedProjectUserPermissions(state);
    const isAdmin = selectIsAdminInSelectedProject(state);
    await dispatch(loadAssignmentsForProject(projectId));

    if (permissions[VIEW_OTHER_RESOURCES] || isAdmin) {
      await dispatch(loadResourcesOfProject(projectId));
    }

    await dispatch(loadMediaFilePermissionsByUserAndProject({ projectId }));

    await dispatch(loadInteractionsByProject(projectId));

    // await dispatch(loadGalleryImages());
  }
);
