import { createAction } from '@reduxjs/toolkit';

export const setWorkSearchParams = createAction('demo/setWorkSearchParams');
export const resetWorkSearchParams = createAction('demo/resetWorkSearchParams');
export const resetWorkCalendar = createAction('demo/resetWorkCalendar');

export const toggleVisibleWeek = createAction('demo/toggleVisibleWeek');
export const setWorkSearchParamsSelectedProjects = createAction(
  'demo/setWorkSearchParamsSelectedProjects'
);
export const setWorkSearchParamsSelectedResources = createAction(
  'demo/setWorkSearchParamsSelectedResources'
);
export const setWorkSearchParamsSelectedUsers = createAction(
  'demo/setWorkSearchParamsSelectedUsers'
);
export const setWorkSearchParamsSelectedAssignment = createAction(
  'demo/setWorkSearchParamsSelectedAssignment'
);
export const removeWorkSearchParamsSelectedAssignment = createAction(
  'demo/removeWorkSearchParamsSelectedAssignment'
);
export const setWorkSearchParamsSelectedEmployers = createAction(
  'demo/setWorkSearchParamsSelectedEmployers'
);
export const setWorkSearchParamsFromDate = createAction(
  'demo/setWorkSearchParamsFromDate'
);
export const setWorkSearchParamsToDate = createAction(
  'demo/setWorkSearchParamsToDate'
);
export const setWorkSearchParamsDescriptionSearchText = createAction(
  'demo/setWorkSearchParamsDescriptionSearchText'
);
export const calendarOpenPreviousWeek = createAction(
  'demo/calendarOpenPreviousWeek'
);
export const calendarOpenNextWeek = createAction('demo/calendarOpenNextWeek');
export const calendarOpenCurrentWeek = createAction(
  'demo/calendarOpenCurrentWeek'
);

export const setFetchedSearchParams = createAction(
  'workCalendar/setFetchedSearchParams'
);

export const setWorkSearchParamsToDateState = createAction(
  'workCalendar/setWorkSearchParamsToDateState'
);

export const setWorkSearchParamsFromDateState = createAction(
  'workCalendar/setWorkSearchParamsFromDateState'
);

export const showMap = createAction('demo/showMap');

export const closeMap = createAction('demo/closeMap');

export const setSelectionMode = createAction('demo/setSelectionMode');

export const updateRecord = createAction('demo/updateRecord');
export const createRecord = createAction('demo/createRecord');
export const deleteRecord = createAction('demo/deleteRecord');

export const setPersonalWorkCalendarMode = createAction(
  'workCalendar/setWorkCalendarMode'
);
export const setWorkSearchParamsApprovalGroup = createAction(
  'workCalendar/setWorkSearchParamsApprovalGroups'
);

export const clearWorkSearchParamsApprovalGroup = createAction(
  'workCalendar/clearWorkSearchParamsApprovalGroups'
);
