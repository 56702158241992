import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { getCardImageUploadSignedUrl } from '../services/getCardImageUploadSignedUrl.service';
import { postAddCardForUser } from '../services/postAddCardForUser.service';
import { compressCardImage } from '../utils/compressCardImage.util';

export const addCardForCurrentUser = createAsyncThunk(
  'cards/addCardForCurrentUser',
  async (cardData, { getState }) => {
    const currentUserId = selectLoggedInUserId(getState());

    const { cardAttributes, cardImage } = cardData;

    const compressedImage = await compressCardImage(cardImage);

    const cardToPost = { ...cardAttributes, userId: currentUserId };

    const fileType = compressedImage.type;

    const imageUploadSignedUrl = await getCardImageUploadSignedUrl(
      currentUserId,
      cardAttributes.id,
      fileType
    );

    if (imageUploadSignedUrl) {
      const options = {
        headers: {
          'Content-Type': fileType,
        },
      };

      await Axios.put(imageUploadSignedUrl, compressedImage, options);
    }

    const response = await postAddCardForUser(cardToPost);

    return response;
  }
);
