import { createAction } from '@reduxjs/toolkit';

export const setSelectedProjectId = createAction(
  'project/setSelectedProjectId'
);

export const setMobileShowList = createAction('project/setMobileShowList');

export const setSelectedFolder = createAction('project/setSelectedFolder');

export const toggleShowAll = createAction('project/toggleShowAll');
