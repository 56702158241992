import aws4 from 'aws4';
import Config from 'Config';
import getCredentials from 'utils/getCredentials';
import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';

/**
 * Fetches a file from the files domain using axios
 * @param {object} params - the parameters object
 * @param {string} params.key - the key of the file to fetch (required)
 * @param {file} params.file - not used in the function (some legacy code), some places in the code pass it to the function
 * @param {boolean} params.blobUrl - if true, returns a blob url (optional) default is false
 * @param {boolean} params.blob - if true, returns a blob object (optional) default is false
 * @param {object} params.credentials - credentials object to use for the request (optional)
 * @param {object} params.cancelTokenSource - cancel token source to use for the request (optional)
 * @returns
 */
const fetchFileAxios = async ({ key, file, blobUrl = false, blob = false, credentials, cancelTokenSource }) => {
  if (!cancelTokenSource) cancelTokenSource = axios.CancelToken.source();
  if (!credentials) credentials = await getCredentials();
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const filesDomain = process.env.REACT_APP_FILES_DOMAIN;

  let fixedKey;
  const folder1 = key.split('/')[0];
  const path = key.substr(key.indexOf('/') + 1).replace(/\//g, '_');
  fixedKey = folder1 + '/' + path;

  const payload = {
    getSignedUrl: true,
    key: fixedKey,
  };
  const request = {
    host: filesDomain,
    path: fixedKey,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload), // aws4 looks for body; axios for data
    data: payload,
  };

  const signedRequest = await createSignedRequest(request, credentials);

  // cancelTokenSource.current = axios.CancelToken.source();

  try {
    // console.log('Signed Request:', signedRequest);
    const response = await axios(`https://${filesDomain}/${fixedKey}`, {
      ...signedRequest,
      cancelToken: cancelTokenSource.token, // Pass the cancellation token
    });
    // console.log("🚀 ~ file: fetchFileAxios.js:50 ~ fetchFileAxios ~ response:", response)

    if (response.status === 404) {
      console.log('File not found for:', fixedKey);
      return null; // Return null for non-existent files
    }

    const json = response.data;

    try {
      if (blobUrl) {
        const blobResponse = await axios.get(json.signedUrl, {
          responseType: 'blob',
          cancelToken: cancelTokenSource.token, // Set the cancellation token
        });

        const blob = new Blob([blobResponse.data]);
        const url = URL.createObjectURL(blob);
        return url;
      }

      if (blob) {
        const blobResponse = await axios.get(json.signedUrl, {
          responseType: 'blob',
          cancelToken: cancelTokenSource.token, // Set the cancellation token
        });

        return new Blob([blobResponse.data]);
      }

      const fetchResponse = await axios.get(json.signedUrl, {
        cancelToken: cancelTokenSource.token, // Set the cancellation token
      });

      if (fetchResponse.status === 200) {
        return json.signedUrl;
      } else {
        return null;
      }
    } catch (error) {
      console.log('error fetching file:', error);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      // Handle cancellation here.
      console.log('Request canceled:', error.message);
    } else {
      // Handle error here.
      console.log('Error in fetchFile:', error);
    }
    return null; // Return null when an error occurs
  }
};

export default fetchFileAxios;
