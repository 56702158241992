import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMediaFilePermission } from '../../services/updateMediaFilePermission.service';

export const updateMediaFilePermissionThunk = createAsyncThunk(
  'file-permissions/update-media-filepermission',
  async (
    { filePermissionId, projectId, updateParams },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateMediaFilePermission(
        filePermissionId,
        projectId,
        updateParams
      );

      return response;
    } catch (err) {
      // add message from api if no permission etc.
      const rejectData = { message: '', filePermissionId };
      return rejectWithValue(rejectData);
    }
  }
);
