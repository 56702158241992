import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectSelectedRecordBreakRecordsEditAttributesArray,
  selectSelectedRecordBreaksWithEditAttributes,
} from '../selectors/records.selectors';

export const handleBreakRecordsStatusChange = createAsyncThunk(
  'records/handleBreakRecordsStatusChange',
  async ({ status, now }, { getState }) => {
    const selectedRecordBreaksEditAttributes =
      selectSelectedRecordBreakRecordsEditAttributesArray(getState());

    if (status === 'OUT') {
      const breakRecords = selectSelectedRecordBreaksWithEditAttributes(
        getState()
      );
      return breakRecords
        .filter((breakRecord) => !breakRecord.clockOutAt)
        .map((breakRecord) => {
          return {
            id: breakRecord.id,
            clockInAt: breakRecord.clockInAt,
            status: status,
            clockOutAt: now,
          };
        });
    } else if (status === 'IN') {
      return selectedRecordBreaksEditAttributes
        .filter((breakEditAttributes) => 'clockOutAt' in breakEditAttributes)
        .map((breakEditAttributes) => {
          let attributes = { ...breakEditAttributes, status: 'IN' };

          delete attributes.clockOutAt;
          return attributes;
        });
    }
  }
);
