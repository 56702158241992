import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAdmin } from 'scenes/Permissions/services/deleteAdmin.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const removeAdminStatusOfUsers = createAsyncThunk(
  'permissionsManagement/removeAdminStatusOfUsers',
  async (_, { getState }) => {
    const {
      permissions: { selectedUsersToUpdatePermissions },
    } = getState();

    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const businessId = selectedProject.business_id;

    if (!businessId) {
      throw new Error('no business id found :(');
    }

    const removedAdmins = await Promise.all(
      selectedUsersToUpdatePermissions.map((user) =>
        deleteAdmin(user, businessId)
      )
    );
    return removedAdmins;
  }
);
