import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import getTextWidth from 'utils/getTextWidth';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    ...theme.typography.fontPanton,
    color: theme.palette.text.secondary,
  },
  inputLabelShrink: {
    transform: 'translate(12px, -4px) scale(0.75) !important',
  },
  togglePasswordButton: {
    fontSize: '0.75rem',
    padding: theme.spacing(1),
  },
  phoneInput: {
    color: theme.palette.textInvert.secondary,
    '& .form-control': {
      ...theme.typography.fontPanton,
      color: theme.palette.text.secondary,
      background: 'none',
      border: 'none',
      paddingBottom: theme.spacing(1.33),
      paddingTop: theme.spacing(1.33),
      '&:focus': {
        border: 'none',
        boxShadow: 'none',
        background: 'none',
      },
    },
    '& .special-label': {
      ...theme.typography.fontPantonLight,
      background: 'none',
      fontSize: '0.8rem',
      left: theme.spacing(1.25),
      top: -theme.spacing(1.5),
    },
    '& + div.flag-dropdown': {
      background: 'none',
      border: 'none',
      '&:hover': {
        background: 'none',
        border: 'none',
      },
      '&:focus': {
        background: 'none',
        border: 'none',
      },
      '& .selected-flag': {
        background: 'none',
        '&:hover': {
          background: 'none',
        },
      },
    },
    '& + div.selected-flag.open': {
      background: 'none !important',
    },
    '& + div.selected-flag:hover': {
      background: 'none !important',
    },
    '& + div.flag-dropdown.open': {
      background: 'none !important',
      '& .selected-flag': {
        background: 'none !important',
      },
      '& .country-list': {
        position: 'fixed',
      },
    },
  },
  phoneInputFullWidth: {
    width: '100%',
    '& .form-control': {
      width: '100%',
    },
  },
  phoneInputContainer: {
    borderRadius: theme.spacing(0.5),
    position: 'relative',
  },
  phoneInputFieldset: {
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 'inherit',
    borderStyle: 'solid',
    borderWidth: 1,
    bottom: 0,
    left: 0,
    margin: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: -5,
  },
  phoneInputFieldsetActive: {
    borderColor: theme.palette.primary.light,
    borderWidth: 2,
  },
  phoneInputLabel: {
    ...theme.typography.fontPantonLight,
    fontSize: '0.8rem',
  },
  phoneInputLabelActive: {
    color: theme.palette.primary.light,
  },
  phoneInputMarginDense: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  phoneInputMarginNormal: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  phoneDropdown: {
    background: 'none',
    backgroundColor: 'red',
  },
  '& + div.country-list': {
    backgroundColor: 'red',
  },
  inputCharCounter: {
    fontSize: '10px',
    position: 'absolute',
    paddingLeft: theme.spacing(0.2),
    paddingRight: theme.spacing(0.2),
    borderRadius: '4px',
    bottom: -5,
    right: 15,
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, maxValue, minValue, ...other } = props;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if ((!maxValue && maxValue !== 0) || value <= maxValue) {
      if ((!minValue && minValue !== 0) || value >= minValue) {
        return inputObj;
      }
    }
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueLimit}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandsGroupStyle={'thousand'}
      thousandSeparator={' '}
      decimalSeparator={','}
    />
  );
}

function NumericFormatCustom(props) {
  const { inputRef, onChange, maxValue, minValue, ...other } = props;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if ((!maxValue && maxValue !== 0) || value <= maxValue) {
      if ((!minValue && minValue !== 0) || value >= minValue) {
        return inputObj;
      }
    }
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isAllowed={withValueLimit}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={','}
    />
  );
}

const TextInput = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [phoneInputFocused, setPhoneInputFocused] = useState(false);
  const { t } = useTranslation('common');
  const {
    children,
    className,
    disabled,
    error,
    focused,
    fullWidth,
    helperText,
    id,
    label,
    labelId,
    InputLabelProps,
    inputProps,
    InputProps,
    margin,
    required,
    select,
    shrink,
    SelectProps,
    type,
    number,
    disableNumberFormat,
    showCharCounter,
    charCounterStyle,
    ...restProps
  } = props;

  const labelWidthValue = getTextWidth(label) * 1.12;

  const inputUuid = uuidv4();
  const labelUuid = uuidv4();

  if (type === 'phone') {
    return (
      <div
        className={clsx(
          classes.phoneInputContainer,
          margin === 'dense' && classes.phoneInputMarginDense,
          margin === 'normal' && classes.phoneInputMarginNormal,
          fullWidth && classes.phoneInputFullWidth
        )}
      >
        <PhoneInput
          autoFormat={true}
          buttonClass={classes.phoneDropdown}
          containerClass={clsx(
            classes.phoneInput,
            fullWidth && classes.phoneInputFullWidth
          )}
          country={'fi'}
          countryCodeEditable={false}
          inputClass={clsx(classes.phoneInput, className)}
          onFocus={() => setPhoneInputFocused(true)}
          onBlur={() => setPhoneInputFocused(false)}
          inputProps={inputProps}
          placeholder={!phoneInputFocused ? 'Phone' : ''}
          preferredCountries={['fi', 'gb', 'se', 'ee', 'pl', 'ro', 'ru']}
          specialLabel={label}
          {...InputProps}
          {...restProps}
        />
        <fieldset
          className={clsx(
            classes.phoneInputFieldset,
            phoneInputFocused && classes.phoneInputFieldsetActive
          )}
        >
          <legend
            className={clsx(
              classes.phoneInputLabel,
              phoneInputFocused && classes.phoneInputLabelActive
            )}
          >
            {label}
          </legend>
        </fieldset>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>

      // <PhoneInput
      //   autoFormat={true}
      //   country={'fi'}
      //   regions={'europe'}
      //   countryCodeEditable={false}
      //   dropdownStyle={{
      //     minHeight: 200,
      //     color: 'black',
      //     position: 'absolute',
      //   }}
      //   //buttonClass={classes.phoneDropdown}
      // />
    );
  }

  return (
    <FormControl
      className={className}
      disabled={disabled}
      error={error}
      focused={focused}
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      size="small"
      variant="outlined"
      style={showCharCounter && inputProps.maxLength ? { position: 'relative' } : props.style}
    >
      <InputLabel
        classes={{
          root: classes.inputLabel,
          shrink: classes.inputLabelShrink,
        }}
        htmlFor={id ? id : inputUuid}
        id={labelId ? labelId : labelUuid}
        shrink={shrink}
        {...InputLabelProps}
      >
        {label}
      </InputLabel>
      {select ? (
        <Select
          id={id ? id : inputUuid}
          labelId={labelId ? labelId : labelUuid}
          labelWidth={labelWidthValue}
          notched={shrink}
          ref={ref}
          inputProps={{
            onBlur: props.onBlur,
            onClick: props.onClick,
            onFocus: props.onFocus,
            ...inputProps,
          }}
          {...SelectProps}
          {...restProps}
        >
          {children}
        </Select>
      ) : (
        <OutlinedInput
          id={id ? id : inputUuid}
          type={
            type === 'password' && !showPassword
              ? 'password'
              : type !== 'password'
                ? type
                : 'text'
          }
          endAdornment={
            type === 'password' ? (
              <InputAdornment position="end">
                <ButtonBase
                  className={classes.togglePasswordButton}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  edge="end"
                >
                  {showPassword ? t('input.hide') : t('input.show')}
                </ButtonBase>
              </InputAdornment>
            ) : props.adornment ? (
              props.adornment
            ) : (
              ''
            )
          }
          ref={ref}
          labelWidth={labelWidthValue}
          inputComponent={number ? disableNumberFormat ? NumericFormatCustom : NumberFormatCustom : 'input'}
          inputProps={{
            onBlur: props.onBlur,
            onClick: props.onClick,
            onFocus: props.onFocus,
            ...inputProps,
          }}
          notched={shrink}
          {...InputProps}
          {...restProps}
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {/* Character counter */}
      {showCharCounter && inputProps.maxLength ?
        <div className={classes.inputCharCounter} style={{ ...charCounterStyle }}>
          {props.value?.length || 0}/{inputProps.maxLength}
        </div>
        : null}
    </FormControl>
  );
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  label: '',
};

TextInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  focused: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  labelId: PropTypes.string,
  margin: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  shrink: PropTypes.bool,
  type: PropTypes.string,
};

export default TextInput;
