import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { getExpiringCardsInfoOfEmployeesInBusiness } from '../services/getExpiringCardsInfoOfEmployeesInBusiness.service';

export const loadExpiringCardInfoOfEmployeesOfEmployer = createAsyncThunk(
  'cards/loadExpiringCardInfoOfEmployeesInBusiness',
  async (_, { getState }) => {
    const userAdminBusinessIds = selectUserAdminBusinessIds(getState());

    const cardsInfoOfEmployersResponses = await Promise.all(
      userAdminBusinessIds.map((businessId) =>
        getExpiringCardsInfoOfEmployeesInBusiness(businessId)
      )
    );

    const cardsData = cardsInfoOfEmployersResponses.flat();

    return cardsData;
  }
);
