import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkTimes } from './refreshComponents/WorkTimes';
import Allowances from './Allowances';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.component.dialogPaper,
    padding: '8px',
    borderRadius: '2px',
    opacity: '0.9',
    gap: '8px',
  },
}));

export default function WorkTimesContainer() {
  const { t } = useTranslation('common');

  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <WorkTimes></WorkTimes>
      <Allowances></Allowances>
    </Grid>
  );
}
