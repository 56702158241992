import { PROJECT_TOGGLABLE_PERMISSIONS } from '../constants/project-togglable-permissions';

export const returnTogglableDefaultPermissions = () => {
  let defaults = {};

  PROJECT_TOGGLABLE_PERMISSIONS.forEach((permission) => {
    defaults[permission] = false;
  });

  return defaults;
};
