import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLatestProjectsClockedIn } from '../services/getLatestProjectsClockedIn.service';

export const loadLatestProjectsClockedInTo = createAsyncThunk(
  'projects/loadLatestProjectsClockedInTo',
  async () => {
    const projectIds = await getLatestProjectsClockedIn();

    return projectIds;
  }
);
