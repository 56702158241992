import { createSelector } from '@reduxjs/toolkit';
import { selectProjectsArray } from './projectsSelectors';
import { selectSelectedRecord } from 'store/records/selectors/records.selectors';
import returnProjectIdAndItsChildProjectIds from 'scenes/WorkDemo/utils/returnProjectIdAndItsChildProjectIds';

const selectCurrentProject = (state) => state.root.project;

export const selectEditDialogProjectOptions = createSelector(
  selectCurrentProject,
  selectProjectsArray,
  selectSelectedRecord,
  (currentProject, projects, selectedRecord) => {
    let projectsToSearch = returnProjectIdAndItsChildProjectIds(
      [currentProject.id],
      projects
    );
    // records project is not always selected projects child. for example if record is in subcontractor project.
    // adding records selected project to options.
    let options = projects.filter(
      (project) =>
        projectsToSearch.includes(project.id) ||
        project.id === selectedRecord.project
    );

    return options;
  }
);

export const selectCreateRecordProjectOptions = createSelector(
  selectCurrentProject,
  selectProjectsArray,
  (currentProject, projects) => {
    let projectsToSearch = returnProjectIdAndItsChildProjectIds(
      [currentProject.id],
      projects
    );

    let options = projects.filter((project) =>
      projectsToSearch.includes(project.id)
    );

    return options;
  }
);
