import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  closeNotificationsSnackbar,
  openNotificationsSnackbar,
} from './actions/notificationSnackbar.actions';
import { clockInRecord } from 'store/records/thunks/clockInRecord.thunk';
import { loadProcountorDimensions } from 'scenes/Project/store/thunks/loadProcountorDimensions.thunk';
import { saveProcountorDimension } from 'scenes/Project/store/thunks/saveProcountorDimension.thunk';
import { addEmployerForUser } from 'store/projects/thunks/addEmployerForUser.thunk';
import { userJoinProject } from 'store/projects/thunks/userJoinProject.thunk';
import { addProjectToMergeFrom } from 'store/projects/thunks/addProjectToMergeFrom.thunk';
import { putProjectToMergeInto } from 'store/projects/thunks/putProjectToMergeInto.thunk';
import { startProjectMergeFrom } from 'store/projects/thunks/startProjectMergeFrom.thunk';
import { startProjectMergeInto } from 'store/projects/thunks/startProjectMergeInto.thunk';
import { createProjectBankFolder } from 'scenes/ProjectBank/store/thunks/createProjectBankFolder.thunk';
import { createProjectBankFile } from 'scenes/ProjectBank/store/thunks/createProjectBankFile.thunk';
import { createProject } from 'store/projects/thunks/createProject.thunk';
import { setUserBusinessConfigurations } from 'scenes/UserProfile/store/thunks/setUserBusinessConfigurations.thunk';
import { sendProcountorSalaryData } from 'store/reports/thunks/sendProcountorSalaryData.thunk';
import { saveProjectAssignments } from 'store/assignments/thunks/saveProjectAssignments.thunk';
import { deleteAssignment } from 'store/assignments/thunks/deleteAssignment.thunk';
import { saveReport } from 'store/reports/thunks/saveReport.thunk';
import { createOrUpdateUser } from 'store/user/thunks/createOrUpdateUser.thunk';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18n';

export const notificationSnackbarSlice = createSlice({
  name: 'notificationSnackbar',
  initialState: {
    isOpen: false,
    type: '',
    message: '',
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(openNotificationsSnackbar, (state, action) => {
        state.isOpen = true;
        state.type = action.payload.type;
        state.message = action.payload.message;
      })
      .addCase(closeNotificationsSnackbar, (state, action) => {
        state.isOpen = false;
        state.type = '';
        state.message = '';
      })
      .addCase(clockInRecord.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'notifications.errors.clock-in-rejected';
      })
      .addCase(saveProcountorDimension.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = action.error.message;
      })
      .addCase(saveProcountorDimension.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'Dimensio tallennettu onnistuneesti';
      })
      .addCase(putProjectToMergeInto.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = action.payload.errorMessage
          ? action.payload.errorMessage
          : 'Projektien lisääminen yhdistettäväksi epäonnistui';
      })
      .addCase(addProjectToMergeFrom.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = action.payload.errorMessage
          ? action.payload.errorMessage
          : 'Projektin lisääminen yhdistettäväksi epäonnistui';
      })
      .addCase(addEmployerForUser.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'notifications.errors.add-employer-rejected';
      })
      .addCase(userJoinProject.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'notifications.errors.join-project-rejected';
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'notifications.errors.create-project-rejected';
      })
      .addCase(createProjectBankFolder.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'bank.add_folder_success';
      })
      .addCase(createProjectBankFile.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'bank.add_file_success';
      })
      .addCase(setUserBusinessConfigurations.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'Työnantajakohtaiset asetukset päivitetty';
      })
      .addCase(sendProcountorSalaryData.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'report.billing-data-send-success';
      })
      .addCase(sendProcountorSalaryData.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'report.billing-data-send-fail';
      })
      .addCase(saveProjectAssignments.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'Projektin litterat päivitetty onnistuneesti';
      })
      .addCase(saveProjectAssignments.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'Projektin litteroiden päivitys epäonnistui';
      })
      .addCase(deleteAssignment.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'Littera poistettu onnistuneesti';
      })
      .addCase(saveReport.fulfilled, (state, action) => {
        state.isOpen = true;
        state.type = 'success';
        state.message = 'Raportti tallennettu onnistuneesti';
      })
      .addCase(saveReport.rejected, (state, action) => {
        state.isOpen = true;
        state.type = 'error';
        state.message = 'Raportin tallennus epäonnistui';
      })
      .addCase(createOrUpdateUser.rejected, (_, action) => {
        const message = [
          'project-not-exists',
          'not-admin-of-project',
          'employer-not-exists',
        ].includes(action.payload)
          ? `add-user-dialog.errors.${action.payload}`
          : 'add-user-dialog.errors.create-user-fail';
        enqueueSnackbar(i18n.t(message), {
          variant: 'error',
        });
      })
      .addMatcher(
        isAnyOf(startProjectMergeFrom.rejected, startProjectMergeInto.rejected),
        (state, action) => {
          state.isOpen = true;
          state.type = 'error';
          state.message = 'Projektien yhdistäminen epäonnistui';
        }
      )
      .addMatcher(
        isAnyOf(
          startProjectMergeFrom.fulfilled,
          startProjectMergeInto.fulfilled
        ),
        (state, action) => {
          state.isOpen = true;
          state.type = 'success';
          state.message = 'Projektien yhdistämien onnistui';
        }
      );
  },
});
