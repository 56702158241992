import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import { createAbsenceRecordsByTimeframe } from './createAbsenceRecordsByTimeframe.thunk';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import { selectAllowancesCreateParams } from '../selectors/records-base.selectors';

export const distributeAllowanceOperationsByAllowancesCreateParams =
  createAsyncThunk(
    'records/handleClockOutAllowances',
    async (_, { getState, dispatch }) => {
      const clockOutAllowances = selectAllowancesCreateParams(getState());
      const mirrorUser = selectLoggedInUserId(getState());
      const selectedRecord = selectSelectedRecord(getState());

      const AbsenceRecordAllowancesParamsTypes = [
        RECORD_TYPE.ABSENCE_SICK_LEAVE,
        RECORD_TYPE.ABSENCE_VACATION_PAID,
      ];
      const RecordUpdateAllowancesParamsTypes = ['']; // not yet defined

      const AbsenceRecordAllowancesParams = clockOutAllowances.filter(
        (params) => {
          return AbsenceRecordAllowancesParamsTypes.includes(params.type);
        }
      );

      const RecordUpdateAllowancesParams = clockOutAllowances.filter(
        (params) => {
          return RecordUpdateAllowancesParamsTypes.includes(params.type);
        }
      );

      AbsenceRecordAllowancesParams.forEach((params) => {
        dispatch(
          createAbsenceRecordsByTimeframe({
            type: params.type,
            startDateUtc: params.startDate,
            endDateUtc: params.endDate,
            projectId: selectedRecord.project,
            resourceId: selectedRecord.resource,
            mirrorUser: mirrorUser,
          })
        );
      });

      RecordUpdateAllowancesParams.forEach((param) => {
        // dispatch setClockOutRecordUpdateParams({})
      });
    }
  );
