import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { getUserManualContacts } from '../services/getUserManualContacts.service';

export const loadUserManualContacts = createAsyncThunk(
  'manualContacts/loadUserManualContacts',
  async (_, { getState }) => {
    const userId = selectLoggedInUserId(getState());

    const response = await getUserManualContacts(userId);
    return response;
  }
);
