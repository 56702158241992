import { createAsyncThunk } from '@reduxjs/toolkit';

import { setUpdateInvoice } from '../services/setUpdateInvoice';


export const patchUpdateInvoice = createAsyncThunk(
  'invoices/patchUpdateInvoice',
  async ({ invoiceId, updateData}, { getState, dispatch }) => {
    console.log('PATCH updateData', updateData);
    const projectId = getState().projects.selectedProjectId;
    
    const response = await setUpdateInvoice(
      invoiceId,
      projectId,
      updateData
    );
    console.log('response', response);
    return response
  }
);
