import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import useStyles from './RecordDialogLayoutStyles';
import { useDispatch } from 'react-redux';
import { checkRecordSingleTime } from 'store/records/thunks/checkRecordSingleTime';
import { uncheckRecordSingleTime } from 'store/records/thunks/uncheckRecordSingleTime';
import { useTranslation } from 'react-i18next';

export function CheckSingleTimeCheckBox({
  isPermissionToCheck,
  updatesPending,
  checkedOut,
  isRecordAttributeChanges,
  checked,
  attributeToEditName,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const handleCheck = () => {
    if (!checked) {
      dispatch(checkRecordSingleTime(attributeToEditName));
    } else {
      dispatch(uncheckRecordSingleTime(attributeToEditName));
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          disabled={
            !isPermissionToCheck ||
            updatesPending ||
            !checkedOut ||
            isRecordAttributeChanges
          }
          checked={checked}
          onChange={() => handleCheck()}
        />
      }
      label={<label className="mylabel">{t('calendar.checked')}</label>}
      labelPlacement="start"
    ></FormControlLabel>
  );
}
