import { Grid, TextField, makeStyles } from '@material-ui/core';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

export default function AllowanceDaysSelect({ timeFrame, setTimeFrame }) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleTimeFrameChange = (object) => {
    setTimeFrame((prev) => {
      return { ...prev, ...object };
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className={classes.gridItem}>
        <TextField
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: classes.textInput }}
          fullWidth
          label={t('calendar.start_date')}
          variant="outlined"
          size="small"
          type="date"
          value={timeFrame.startDate}
          onChange={(event) =>
            handleTimeFrameChange({
              startDate: event.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        <TextField
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: classes.textInput }}
          fullWidth
          label={t('calendar.end_date')}
          variant="outlined"
          size="small"
          type="date"
          value={timeFrame.endDate}
          onChange={(event) =>
            handleTimeFrameChange({
              endDate: event.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  );
}
