import { createAsyncThunk } from '@reduxjs/toolkit';
import { RECORD_STATUS } from 'LixaniAPI/enums';
import { selectPersonalRecords } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';
import { loadAssignmentsForProject } from 'store/assignments/thunks/loadAssignmentsForProject';
import { loadEmployerAllowancesByBusinessId } from 'store/employerAllowances/thunks/loadEmployerAllowancesByBusinessId.thunk';
import {
  selectCurrentUserEmployerProjects,
  selectLoggedInUserResourcesEntities,
} from 'store/resources/selectors/resources.selectors';
import { getGeolocation } from './getGeolocation.thunk';

export const openClockOutRecordDialog = createAsyncThunk(
  'demo/openClockOutRecordDialog',
  async (projectId, { getState, dispatch }) => {
    dispatch(getGeolocation());
    const personalRecords = selectPersonalRecords(getState());

    const userResources = selectLoggedInUserResourcesEntities(getState());

    const clockedInRecordByProjectId = personalRecords.find(
      (record) =>
        record.project === projectId && record.status === RECORD_STATUS.IN
    );

    if (!clockedInRecordByProjectId) {
      console.log('CLOCKED IN RECORD NOT FOUND BY PROJECT ID');
    }

    const recordResource = userResources[clockedInRecordByProjectId.resource];

    if (recordResource.employerBusinessId) {
      dispatch(
        loadEmployerAllowancesByBusinessId(recordResource.employerBusinessId)
      );
    } else {
      const userEmployerProjects = selectCurrentUserEmployerProjects(
        getState()
      );

      const resourceEmployerProject = userEmployerProjects.find(
        (project) => project.id === recordResource.employer
      );

      if (resourceEmployerProject) {
        dispatch(
          loadEmployerAllowancesByBusinessId(
            resourceEmployerProject.business_id
          )
        );
      } else {
        console.log('EMPLOYER FOR CLOCK OUT RECORD RESOURCE NOT FOUND');
      }
    }

    dispatch(loadAssignmentsForProject(projectId));

    return clockedInRecordByProjectId.id;
  }
);
