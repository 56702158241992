function hoursToTimeFull(hours) {
  const _hours = hours.toString().split('.')[0];
  const _minutes = (
    '0' + Math.abs(parseInt(hours * 60, 10) % 60).toString()
  ).slice(-2);
  const _seconds = (
    '0' + Math.abs(parseInt(hours * 3600, 10) % 60).toString()
  ).slice(-2);
  return `${_hours}:${_minutes ? `${_minutes}` : '00'}:${
    _seconds ? `${_seconds}` : '00'
  }`;
}

export default hoursToTimeFull;
