import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { arrayToMap } from 'utils/arrayToMap';
import { loadGalleryImages } from '../thunks/loadGalleryData.thunk';
import { loadMediaFilePermissionsByUserAndProject } from 'scenes/ProjectGallery/store/thunks/loadMediaFilePermissionsByUserAndProject';
import { initProjectGallery } from '../thunks/initProjectGallery.thunk';
import {
  clearGalleryData,
  setSelectedFilePermissionId,
} from '../actions/projectGallery.actions';
import { updateMediaFilePermissionThunk } from 'scenes/ProjectGallery/store/thunks/updateMediaFilePermission.thunk';
import { deleteMediaFilePermissionThunk } from '../thunks/deleteMediaFilePermission.thunk';
import { reactToFilepermission } from 'store/filepermissions/thunks/reactToFilepermission';

const mediaFilePermissionsAdapter = createEntityAdapter();

const initialState = {
  projectImages: {},
  loading: false,
  mediaFilePermissions: mediaFilePermissionsAdapter.getInitialState(),
  selectedFilePermissionId: '',
  isMoreToFetch: true,
  lastEvaluatedKey: undefined,
  loadingFilePermissions: false,
  updatePending: false,
  updatingFilePermissions: [],
};

export const projectGallerySlice = createSlice({
  name: 'projectGallery',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadGalleryImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadGalleryImages.fulfilled, (state, action) => {
        state.loading = false;
        state.projectImages = arrayToMap(action.payload);
      })
      .addCase(loadGalleryImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(clearGalleryData, (state, action) => {
        // reseting to initial.
        return initialState;
      })

      .addCase(initProjectGallery.pending, (state, action) => {
        // reseting to initial state on gallery init.
        return { ...initialState, loading: true };
      })
      .addCase(initProjectGallery.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(initProjectGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(deleteMediaFilePermissionThunk.pending, (state, action) => {
        const filePermissionId = action.meta.arg.filePermissionId;
        if (!state.updatingFilePermissions.includes(filePermissionId)) {
          state.updatingFilePermissions.push(filePermissionId);
        }
      })
      .addCase(deleteMediaFilePermissionThunk.fulfilled, (state, action) => {
        const filePermissionId = action.payload.filePermissionId;
        if (state.updatingFilePermissions.includes(filePermissionId)) {
          state.updatingFilePermissions = state.updatingFilePermissions.filter(
            (fpId) => fpId !== filePermissionId
          );
        }

        mediaFilePermissionsAdapter.removeOne(
          state.mediaFilePermissions,
          action.payload.id
        );
      })
      .addCase(deleteMediaFilePermissionThunk.rejected, (state, action) => {
        const filePermissionId = action.payload.filePermissionId;
        if (state.updatingFilePermissions.includes(filePermissionId)) {
          state.updatingFilePermissions = state.updatingFilePermissions.filter(
            (fpId) => fpId !== filePermissionId
          );
        }

        state.updatePending = false;
      })
      .addCase(
        loadMediaFilePermissionsByUserAndProject.fulfilled,
        (state, action) => {
          mediaFilePermissionsAdapter.setMany(
            state.mediaFilePermissions,
            action.payload.filePermissions
          );

          if (action.payload.lastEvaluatedKey) {
            state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
          } else {
            state.isMoreToFetch = false;
          }

          state.loadingFilePermissions = false;
        }
      )
      .addCase(updateMediaFilePermissionThunk.pending, (state, action) => {
        const filePermissionId = action.meta.arg.filePermissionId;
        if (!state.updatingFilePermissions.includes(filePermissionId)) {
          state.updatingFilePermissions.push(filePermissionId);
        }
        state.updatePending = true;
      })
      .addCase(updateMediaFilePermissionThunk.rejected, (state, action) => {
        const filePermissionId = action.payload.filePermissionId;
        if (state.updatingFilePermissions.includes(filePermissionId)) {
          state.updatingFilePermissions = state.updatingFilePermissions.filter(
            (fpId) => fpId !== filePermissionId
          );
        }

        state.updatePending = false;
      })
      .addCase(updateMediaFilePermissionThunk.fulfilled, (state, action) => {
        const filePermission = action.payload;

        mediaFilePermissionsAdapter.setOne(
          state.mediaFilePermissions,
          filePermission
        );

        if (state.updatingFilePermissions.includes(filePermission.id)) {
          state.updatingFilePermissions = state.updatingFilePermissions.filter(
            (fpId) => fpId !== filePermission.id
          );
        }

        state.updatePending = false;
      })
      .addCase(
        loadMediaFilePermissionsByUserAndProject.pending,
        (state, action) => {
          state.loadingFilePermissions = true;
        }
      )
      .addCase(reactToFilepermission.fulfilled, (state, action) => {
        mediaFilePermissionsAdapter.setOne(
          state.mediaFilePermissions,
          action.payload
        );
      })

      .addCase(setSelectedFilePermissionId, (state, action) => {
        state.selectedFilePermissionId = action.payload;
      });
  },
});
