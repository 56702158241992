import { createAsyncThunk } from '@reduxjs/toolkit';
import uuidv4 from 'uuid/v4';

export const createEmployerAllowanceDraft = createAsyncThunk(
  'employerAllowances/createDraft',
  async ({ employerAllowanceAttributes }) => {
    const draftId = uuidv4();

    const employerAllowanceDraft = {
      id: draftId,
      integrationPlatform: 'PROCOUNTOR',
    };

    const draft = employerAllowanceAttributes
      ? employerAllowanceAttributes
      : employerAllowanceDraft;
    return draft;
  }
);
