import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { GALLERY_EDIT_OTHER_USERS_IMAGES } from 'scenes/Permissions/constants/permissions';
import {
  selectIsBusinessAdminOrOwnerOfSelectedProject,
  selectSelectedProjectUserPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { selectAssignments } from 'store/assignments/selectors/assignment.selectors';
import {
  selectProjects,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectRecords } from 'store/records/selectors/records-base.selectors';
import { selectAllResources } from 'store/resources/resourceSliceSelectors';
import { selectUser } from 'store/user/selectors.js/user.selectors';

const selectProjectGalleryState = (state) => state.projectGallery;

export const selectIsGalleryLoading = createSelector(
  [selectProjectGalleryState],
  (state) => state.loading
);

export const selectIsUpdatePending = createSelector(
  [selectProjectGalleryState],
  (state) => state.updatePending
);

export const selectIsLoadingFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.loadingFilePermissions
);

export const selectProjectImages = createSelector(
  [selectProjectGalleryState],
  (state) => state.projectImages
);

export const selectProjectImagesArray = createSelector(
  [selectProjectImages],
  (images) => Object.values(images)
);

export const selectSelectedProjectImages = createSelector(
  [selectProjectImagesArray, selectSelectedProjectId],
  (images, selectedProjectId) =>
    images.filter((image) => image.project === selectedProjectId)
);

export const selectMediaFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.mediaFilePermissions.entities
);

export const selectMediaFilePermissionsArray = createSelector(
  [selectMediaFilePermissions],
  (filePermissions) => Object.values(filePermissions)
);

export const selectSelectedFilePermissionId = createSelector(
  [selectProjectGalleryState],
  (state) => state.selectedFilePermissionId
);

export const selectGalleryLastEvaluatedKey = createSelector(
  [selectProjectGalleryState],
  (state) => state.lastEvaluatedKey
);

export const selectIsMoreToFetch = createSelector(
  [selectProjectGalleryState],
  (state) => state.isMoreToFetch
);
export const selectUpdatingFilePermissions = createSelector(
  [selectProjectGalleryState],
  (state) => state.updatingFilePermissions
);

export const selectFilePermissionIdsUserHasPermissionToEdit = createSelector(
  [
    selectMediaFilePermissionsArray,
    selectUser,
    selectIsBusinessAdminOrOwnerOfSelectedProject,
    selectSelectedProjectUserPermissions,
  ],
  (filePermissionArray, user, isAdminOrOwner, permissions) => {
    return filePermissionArray
      .filter((filePermission) => {
        const permissionToEditOtherUsersImages = permissions[
          GALLERY_EDIT_OTHER_USERS_IMAGES
        ]
          ? permissions[GALLERY_EDIT_OTHER_USERS_IMAGES]
          : false;

        const isCreatorOfFilePermission = filePermission.mirrorUser === user.id;

        return (
          isAdminOrOwner ||
          permissionToEditOtherUsersImages ||
          isCreatorOfFilePermission
        );
      })
      .map((fp) => fp.id);
  }
);

export const selectMediaFilePermissionsWithJoinedData = createSelector(
  [
    selectMediaFilePermissionsArray,
    selectAllResources,
    selectProjects,
    selectRecords,
    selectAssignments,
    selectSelectedProjectId,
  ],
  (
    filepermissionsArray,
    resources,
    projectsEntities,
    records,
    assigments,
    selectedProjectId
  ) => {
    return filepermissionsArray
      .filter((fp) => fp.project === selectedProjectId)
      .map((filepermission) => {
        const user = resources.find(
          (resource) => resource.user === filepermission.user
        );
        const project = projectsEntities[filepermission.project];
        let filePermissionWithJoinedData = filepermission;
        if (user) {
          filePermissionWithJoinedData = {
            ...filePermissionWithJoinedData,
            user: user,
          };
        }
        if (project) {
          filePermissionWithJoinedData = {
            ...filePermissionWithJoinedData,
            project: project,
          };
        }

        if (filepermission.record) {
          const record = records[filepermission.record];
          if (record) {
            filePermissionWithJoinedData = {
              ...filePermissionWithJoinedData,
              record: record,
            };
          }
        }

        if (filepermission.assignment) {
          const assignment = assigments[filepermission.assignment];
          if (assignment) {
            filePermissionWithJoinedData = {
              ...filePermissionWithJoinedData,
              assignment: assignment,
            };
          }
        }

        return filePermissionWithJoinedData;
      });
  }
);

export const selectSelectedFilePermission = createSelector(
  [selectSelectedFilePermissionId, selectMediaFilePermissionsWithJoinedData],
  (selectedFilePermissionId, filePermissions) =>
    filePermissions.find((fp) => fp.id === selectedFilePermissionId)
);

export const selectFilePermissionsByYearAndWeeks = createSelector(
  [selectMediaFilePermissionsWithJoinedData],
  (filePermissions) => {
    const weekSortedFilePermissions = [];

    filePermissions.forEach((filePermission) => {
      const week = dayjs(filePermission.createdAt).format('W');
      const year = dayjs(filePermission.createdAt).format('YYYY');

      if (typeof weekSortedFilePermissions[year] === 'undefined') {
        weekSortedFilePermissions[year] = {};
      }
      if (typeof weekSortedFilePermissions[year][week] === 'undefined') {
        weekSortedFilePermissions[year][week] = [];
      }

      weekSortedFilePermissions[year][week].push(filePermission);
    });

    return weekSortedFilePermissions;
  }
);
