import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getUsersForOrientation from 'scenes/ProjectForms/utils/getUsersForOrientation';

export const loadUsersForOrientation = createAsyncThunk(
    'projectForm/loadUsersForOrientation',
    async (projectId, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        const orientationUsers = await getUsersForOrientation(projectId, params);
        return {
            orientationUsers,
        };
    }
);