import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import {
  selectAdditionalUserSalaryRows,
  selectIsRecordBasedReductionsActive,
  selectReportBasicData,
  selectReportPreviewWeeklyPeriodTableData,
  selectReportUserRowTotals,
  selectRowComments,
} from '../selectors/reports.selectors';
import { postSavedProjectReport } from '../services/postSavedProjectReport.service';

export const saveReport = createAsyncThunk(
  'reports/saveReport',
  async (reportState, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const reportBasicData = selectReportBasicData(getState());

    const { reportType, fromDate, toDate } = reportBasicData;

    const userAdditionalSalaryRows = selectAdditionalUserSalaryRows(getState());

    const rowComments = selectRowComments(getState());

    const reportUserRowTotals = selectReportUserRowTotals(getState());

    const weeklyTableData = selectReportPreviewWeeklyPeriodTableData(
      getState()
    );

    const isRecordBasedReductions = selectIsRecordBasedReductionsActive(
      getState()
    );

    const totalRows = [];

    Object.values(reportUserRowTotals).forEach((row) => {
      totalRows.push({
        name: row.name,
        userId: row.userId,
        salaryType: row.salaryType,
        unitCount: row.unitCount,
        unitCountWithoutReductions: row.unitCountWithoutReductions,
        procountorPersonNumber: row.procountorPersonNumber,
      });
    });

    const reportData = {
      projectId: selectedProjectId,
      tableDataByWeekPeriod: weeklyTableData,
      userAdditionalSalaryRows: userAdditionalSalaryRows,
      reportType,
      fromDate,
      toDate,
      state: reportState,
      rowComments,
      totals: totalRows,
      isRecordBasedReductions,
    };

    const report = await postSavedProjectReport(reportData, selectedProjectId);

    return report;
  }
);
