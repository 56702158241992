import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CloseCircleIcon from 'mdi-material-ui/CloseCircleOutline';
import PencilIcon from 'mdi-material-ui/PencilOutline';
import StopCircleIcon from 'mdi-material-ui/StopCircleOutline';
import ProjectIcon from 'components/LightIcons/Project2Light';

import Refresher from 'components/Refresher/Refresher';
import momentDiffTime from 'utils/momentDiffTime';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dashboardButtonDefault,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
    border: theme.component.border,
  },
  infoContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    padding: theme.spacing(1.5),
    width: '100%',
  },
  info: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  parentText: {
    ...theme.typography.fontPantonLight,
    color: theme.palette.text.caption,
    fontSize: '0.7rem',
    lineHeight: '1.5',
  },
  projectText: {
    ...theme.typography.fontPanton,
    color: theme.palette.text.secondary,
    fontSize: '0.85rem',
    lineHeight: '1.5',
  },
  subtitleText: {
    ...theme.typography.fontPantonLight,
    color: theme.palette.text.tertiary,
    fontSize: '0.7rem',
  },
  timerText: {
    ...theme.typography.fontPanton,
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
  },
  icon: {
    fontSize: 'inherit',
  },
  clockOutGrid: {
    alignItems: 'center',
    display: 'flex',
  },
  clockOutButton: {
    borderRadius: '100%',
    color: theme.palette.text.secondary,
    fontSize: '2rem',
    padding: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  actionButtons: {
    borderTop: theme.component.border,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionButton: {
    color: theme.palette.text.secondary,
    fontSize: '1.5rem',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function RecordItem(props) {
  const classes = useStyles();
  const { onClickClockOut, onClickRecordDialog, record, onClose } = props;

  const checkClockIn = (t) => {
    let clockIn = moment(record.clockInAt).format('L HH:mm');
    if (moment(record.clockInAt).format('L') === moment().format('L')) {
      clockIn = `${t('datetime.today')} ${moment(record.clockInAt).format(
        'HH:mm'
      )}`;
    } else if (
      moment(record.clockInAt).format('L') ===
      moment().subtract(1, 'days').format('L')
    ) {
      clockIn = `${t('datetime.yesterday')} ${moment(record.clockInAt).format(
        'HH:mm'
      )}`;
    }
    return clockIn;
  };

  let projectName = null;
  let projectKey = null;

  if (record.project) {
    projectName = record.project.name;
    projectKey = record.project.projectKey;
  }

  return (
    <Translation ns={['common']}>
      {(t) => (
        <Paper className={classes.root} elevation={1}>
          <Grid container>
            <Grid item xs={true}>
              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  {record.project.parent && (
                    <Typography
                      variant="inherit"
                      className={classes.parentText}
                    >
                      {record.project.parent.name}
                    </Typography>
                  )}
                  <Typography
                    variant="inherit"
                    className={classes.projectText}
                    onClick={() => onClose()}
                    component={Link}
                    to={`/project/${record.project.projectKey}`}
                  >
                    {projectName}
                  </Typography>
                  <Typography
                    variant="inherit"
                    className={classes.subtitleText}
                  >
                    {`${projectKey} - ${checkClockIn(t)}`}
                  </Typography>
                </div>
                <Typography variant="inherit" className={classes.timerText}>
                  <Refresher
                    render={() =>
                      momentDiffTime(
                        record.clockInAt,
                        record.clockOutAt,
                        record.status
                      )
                    }
                  />
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.clockOutGrid}>
              <ButtonBase
                className={classes.clockOutButton}
                onClick={() => onClickClockOut(record)}
              >
                <StopCircleIcon className={classes.icon} />
              </ButtonBase>
            </Grid>
          </Grid>
          <div className={classes.actionButtons}>
            <ButtonBase
              className={classes.actionButton}
              onClick={() => onClose()}
              component={Link}
              to={`/project/${record.project.projectKey}`}
            >
              <ProjectIcon className={classes.icon} />
            </ButtonBase>
            <ButtonBase
              className={classes.actionButton}
              onClick={() => onClickRecordDialog(record)}
            >
              <PencilIcon className={classes.icon} />
            </ButtonBase>
          </div>
        </Paper>
      )}
    </Translation>
  );
}

RecordItem.defaultProps = {
  classes: {},
  onClickClockOut: () => null,
  onClickRecordDialog: () => null,
  record: {},
};

RecordItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickClockOut: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickRecordDialog: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default RecordItem;
