import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getRecordAllowancesOfEmployerInTimeframe } from '../services/getRecordAllowancesOfEmployerInTimeframe.service';

export const loadRecordAllowancesOfEmployerInTimeframe = createAsyncThunk(
  'recordAllowances/loadRecordAllowancesOfEmployerInTimeframe',
  async (timeframe, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await getRecordAllowancesOfEmployerInTimeframe(
      selectedProjectId,
      timeframe
    );
    return response;
  }
);
