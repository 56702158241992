import { createAsyncThunk } from '@reduxjs/toolkit';

import { patchUpdateAssignmentAsDeleted } from '../services/patchUpdateAssignmentAsDeleted.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { returnParentAndChildAssignmentIds } from '../utils/returnParentAndChildAssignmentIds.util';
import { removeAssignmentAndItsChildAssignmentsToAdd } from '../actions/assignments.actions';
import { selectChildAssignmentsToAddArray } from '../selectors/assignment.selectors';

export const deleteAssignment = createAsyncThunk(
  'assignments/deleteAssignment',
  async (assignmentId, { getState, dispatch }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const childAssignmentsToAdd = selectChildAssignmentsToAddArray(getState());

    const assignmentsSetAsDeleted = await patchUpdateAssignmentAsDeleted(
      selectedProjectId,
      assignmentId
    );

    if (childAssignmentsToAdd.length > 0) {
      const assignmentAndItsChildAssignmentIds =
        returnParentAndChildAssignmentIds(
          [assignmentId],
          childAssignmentsToAdd
        );

      dispatch(
        removeAssignmentAndItsChildAssignmentsToAdd(
          assignmentAndItsChildAssignmentIds
        )
      );
    }

    return assignmentsSetAsDeleted;
  }
);
