import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadNotificationSettingsOfBusiness } from './thunks/loadNotificationSettingsOfBusiness.thunk';
import { modifyNotificationSetting } from './actions/notification-settings.actions';
import { saveNotificationSettingsOfBusiness } from './thunks/saveNotificationSettingsOfBusiness.thunk';
import { enqueueSnackbar } from 'notistack';
import { loadLoggedInUserNotifications } from './thunks/loadLoggedInUserNotifications.thunk';
import { dismissNotification } from './thunks/dismissNotification.thunk';

const businessNotificationSettingsAdapter = createEntityAdapter();
const notificationsAdapter = createEntityAdapter();
// const userNotificationSettingsAdapter = createEntityAdapter();

const notificationsSlice = createSlice({
  name: 'projectUsers',
  initialState: {
    businessNotificationSettings:
      businessNotificationSettingsAdapter.getInitialState(),
    // userNotificationSettingsAdapter: userNotificationSettingsAdapter.getInitialState(),
    notifications: notificationsAdapter.getInitialState(),
    isLoadingNotificationSettings: false,
    modifiedNotificationSettings: {},
    loading: false,
    isAddNewUserDialogOpen: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadNotificationSettingsOfBusiness.pending, (state) => {
        state.isLoadingNotificationSettings = true;
      })
      .addCase(
        loadNotificationSettingsOfBusiness.fulfilled,
        (state, action) => {
          state.isLoadingNotificationSettings = false;
          businessNotificationSettingsAdapter.setOne(
            state.businessNotificationSettings,
            action.payload
          );
        }
      )
      .addCase(loadNotificationSettingsOfBusiness.rejected, (state) => {
        state.isLoadingNotificationSettings = false;
      })
      .addCase(modifyNotificationSetting, (state, action) => {
        state.modifiedNotificationSettings[action.payload.attribute] =
          action.payload.value;
      })
      .addCase(
        saveNotificationSettingsOfBusiness.pending,
        (state, action) => {}
      )
      .addCase(
        saveNotificationSettingsOfBusiness.fulfilled,
        (state, action) => {
          enqueueSnackbar('Hälytysasetukset tallennettu', {
            variant: 'success',
          });
          businessNotificationSettingsAdapter.setOne(
            state.businessNotificationSettings,
            action.payload
          );
          state.modifiedNotificationSettings = {};
        }
      )
      .addCase(saveNotificationSettingsOfBusiness.rejected, (state, action) => {
        enqueueSnackbar('Hälytysasetusten tallennus epäonnistui', {
          variant: 'error',
        });
      })
      .addCase(loadLoggedInUserNotifications.fulfilled, (state, action) => {
        notificationsAdapter.setMany(state.notifications, action.payload);
      })
      .addCase(dismissNotification.fulfilled, (state, action) => {
        notificationsAdapter.setOne(state.notifications, action.payload);
      });
  },
});

export default notificationsSlice.reducer;
