import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';
import Config from 'Config';

export default async function updateRecord({
  record,
  projectIds,
  breakRecordsUpdateAttributes,
}) {
  const apiPath = `records/edit`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    //url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    body: JSON.stringify({ record, projectIds, breakRecordsUpdateAttributes }),
    data: { record, projectIds, breakRecordsUpdateAttributes },
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
