import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchDeleteProjectRole } from 'scenes/Permissions/services/roles/patchDeleteProjectRole.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const deleteProjectRole = createAsyncThunk(
  'permissions/deleteProjectRole',
  async (roleId, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const response = await patchDeleteProjectRole(projectId, roleId);

    return response;
  }
);
