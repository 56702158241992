import { createAction } from '@reduxjs/toolkit';

export const closeSendToApprovalDialog = createAction(
  'approvals/closeSendToApprovalDialog'
);

export const setSendToApprovalDialogAttributes = createAction(
  'approvals/setSendToApprovalDialogAttributes'
);

export const openApprovalGroupsDialog = createAction(
  'approvals/openApprovalsGroupDialog'
);
export const closeApprovalGroupsDialog = createAction(
  'approvals/closeApprovalsGroupDialog'
);

export const addApprover = createAction('approvals/addApprover');
export const removeApprover = createAction('approvals/removeApprover');
export const setSelectedApprovalGroup = createAction(
  'approvals/setSelectedApprovalGroup'
);
export const clearSelectedApprovalGroup = createAction(
  'approvals/clearSelectedApprovalGroup'
);
export const setApprovalGroupDeleteDialogOpen = createAction(
  'approvals/setApprovalGroupDeleteDialogOpen'
);
