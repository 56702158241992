import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { updateRecords } from '../services/updateRecords.service';
import {
  selectSelectedRecordsEditAttributes,
  selectSelectedRecordsIds,
  selectSelectedWorkCalendarsRecordsProjectIds,
} from '../selectors/selectedRecords.selectors';
export const editRecords = createAsyncThunk(
  'records/editRecords',
  async (_, { rejectWithValue, getState }) => {
    const state = getState();

    const attributesToEdit = selectSelectedRecordsEditAttributes(state);
    const selectedRecordsProjectIds =
      selectSelectedWorkCalendarsRecordsProjectIds(state);

    const projectIds =
      attributesToEdit && attributesToEdit.project
        ? [...selectedRecordsProjectIds, attributesToEdit.project]
        : selectedRecordsProjectIds;
    const selectedRecordsIds = selectSelectedRecordsIds(state);

    const uniqProjectIds = uniq(projectIds);

    try {
      const response = await updateRecords(
        uniqProjectIds,
        selectedRecordsIds,
        attributesToEdit
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
