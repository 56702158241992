import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from '../../../utils/createSignedRequest';

export default async function postClockInBreakRecord(
  projectId,
  parentRecordId,
  optimisticRecord
) {
  const apiPath = `${projectId}/records/clock-in-break-record/${parentRecordId}`;

  const request = {
    // url: `https://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(optimisticRecord), // aws4 looks for body; axios for data
    data: optimisticRecord,
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
