import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDeleteInvoiceSortRule } from '../services/setDeleteInvoiceSortRule';

export const deleteInvoiceSortRule = createAsyncThunk(
  'invoices/deleteInvoiceSortRule',
  async ({ invoiceSortRuleId }, { getState, dispatch }) => {
    const response = await setDeleteInvoiceSortRule(invoiceSortRuleId);
    console.log('response', response);
    return response;
  }
);
