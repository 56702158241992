import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  closeAddCardDialog,
  closeDeleteCardDialog,
  openAddCardDialog,
  openAddCardDialogForOtherUser,
  openDeleteCardDialog,
} from './actions/cards.actions';
import { addCardForCurrentUser } from './thunks/addCardForCurrentUserThunk';
import { addCardForOtherUser } from './thunks/addCardForOtherUser.thunk';
import { deleteCard } from './thunks/deleteCard.thunk';
import { loadCurrentUserCards } from './thunks/loadCurrentUserCards.thunk';
import { loadExpiringCardInfoOfEmployeesOfEmployer } from './thunks/loadExpiringCardInfoOfEmployeesInBusiness.thunk';
import { loadExpiringCardsOfOwnBusinessEmployees } from './thunks/loadExpiringCardsOfOwnBusinessEmployees.thunk';
import { loadValidCardsOfUser } from './thunks/loadValidCardsOfUser.thunk';
import i18n from 'i18next';
const cardsAdapter = createEntityAdapter();

const expiringEmployeeCardsAdapter = createEntityAdapter();

const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    cards: cardsAdapter.getInitialState(),
    loading: false,
    validCardsLoaded: false,
    isAddCardDialogOpen: false,
    isAddCardForOtherUserDialogOpen: false,
    isAddingCard: false,
    userIdToAddCardFor: '',
    expiringEmployeeCards: expiringEmployeeCardsAdapter.getInitialState(),
    isDeleteCardDialogOpen: false,
    cardIdToDelete: '',
    isDeletingCard: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadCurrentUserCards.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadCurrentUserCards.fulfilled, (state, action) => {
        cardsAdapter.setMany(state.cards, action.payload);
        state.loading = false;
      })
      .addCase(loadCurrentUserCards.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loadValidCardsOfUser.fulfilled, (state, action) => {
        cardsAdapter.setMany(state.cards, action.payload);
        state.validCardsLoaded = true;
      })
      .addCase(addCardForCurrentUser.pending, (state, action) => {
        state.isAddingCard = true;
      })
      .addCase(addCardForCurrentUser.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isAddingCard = false;
        state.isAddCardDialogOpen = false;
        enqueueSnackbar('Kortti lisätty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(addCardForCurrentUser.rejected, (state, action) => {
        state.isAddingCard = false;
        enqueueSnackbar('Kortin lisäys epäonnistui', {
          variant: 'error',
        });
      })
      .addCase(addCardForOtherUser.pending, (state, action) => {
        state.isAddingCard = true;
      })
      .addCase(addCardForOtherUser.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isAddingCard = false;
        state.isAddCardForOtherUserDialogOpen = false;
        state.userIdToAddCardFor = '';
        enqueueSnackbar('Kortti lisätty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(addCardForOtherUser.rejected, (state, action) => {
        state.isAddingCard = false;
        enqueueSnackbar('Kortin lisäys epäonnistui', {
          variant: 'error',
        });
      })
      .addCase(openAddCardDialog, (state, action) => {
        state.isAddCardDialogOpen = true;
      })
      .addCase(openAddCardDialogForOtherUser, (state, action) => {
        state.isAddCardForOtherUserDialogOpen = true;
        state.userIdToAddCardFor = action.payload;
      })
      .addCase(closeAddCardDialog, (state, action) => {
        state.isAddCardDialogOpen = false;
        state.isAddCardForOtherUserDialogOpen = false;
        state.userIdToAddCardFor = '';
      })
      .addCase(
        loadExpiringCardInfoOfEmployeesOfEmployer.fulfilled,
        (state, action) => {
          expiringEmployeeCardsAdapter.setMany(
            state.expiringEmployeeCards,
            action.payload
          );
        }
      )
      .addCase(
        loadExpiringCardsOfOwnBusinessEmployees.fulfilled,
        (state, action) => {
          expiringEmployeeCardsAdapter.setMany(
            state.expiringEmployeeCards,
            action.payload
          );
        }
      )
      .addCase(openDeleteCardDialog, (state, action) => {
        state.isDeleteCardDialogOpen = true;

        state.cardIdToDelete = action.payload;
      })
      .addCase(closeDeleteCardDialog, (state, action) => {
        state.isDeleteCardDialogOpen = false;
        state.cardIdToDelete = '';
      })
      .addCase(deleteCard.pending, (state, action) => {
        state.isDeletingCard = true;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isDeleteCardDialogOpen = false;
        state.cardIdToDelete = '';
        state.isDeletingCard = false;
        enqueueSnackbar(i18n.t('notifications.success.delete-card-success'), {
          variant: 'success',
        });
      })
      .addCase(deleteCard.rejected, (state, action) => {
        state.isDeletingCard = false;
        enqueueSnackbar(i18n.t('notifications.errors.delete-card-rejected'), {
          variant: 'error',
        });
      });
  },
});

export default cardsSlice.reducer;
