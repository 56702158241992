import moment from 'moment';
import { sortBy } from 'lodash';

export const getRecordsFirstAndLastClockedTimes = (records) => {
  const recordsSortedByClockInAt = sortBy(records, 'clockInAt');

  const recordsFilledWithClockOut = records.map((record) => {
    if (record.clockOutAt) return record;
    return { ...record, clockOutAt: moment().toISOString() };
  });
  const recordsSortedByClockOutAt = sortBy(
    recordsFilledWithClockOut,
    'clockOutAt'
  );
  const firstRecord = recordsSortedByClockInAt[0];

  const lastRecord =
    recordsSortedByClockOutAt[recordsSortedByClockOutAt.length - 1];

  const clockIn = moment(
    firstRecord ? firstRecord.clockInAt : undefined
  ).toISOString();
  const clockOut = moment(
    lastRecord ? lastRecord.clockOutAt : undefined
  ).toISOString();

  return { clockIn, clockOut };
};
