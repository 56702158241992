import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchProjectIdCard } from '../services/patchProjectIdCard.service';
import { selectSelectedProjectId } from '../selectors/projectsSelectors';

export const updateProjectIdCard = createAsyncThunk(
  'projects/updateProjectIdCard',
  async (idCard, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const updatedProject = await patchProjectIdCard(selectedProjectId, idCard);

    return updatedProject;
  }
);
