import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimelinesService } from 'scenes/Timelines/services/getTimelines.service';

export const getTimelines = createAsyncThunk(
  'timelines/getTimelines',
  async (projectId, { rejectWithValue }) => {
    const timelines = await getTimelinesService(projectId);
    return timelines.timelines;
  }
);
