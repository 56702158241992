import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Button, Divider } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import getCroppedImg from './CropImage';
import { styles } from './Styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LixaniFiles } from 'lixani-lib-graphql';

const CropperComponent = ({
  classes,
  onSaveCroppedImage,
  source,
  s3Object,
  requestFile,
  setProfileImageModified
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!s3Object) {
      if (typeof source !== 'string') {
        setImageSrc(URL.createObjectURL(source));
      } else {
        setImageSrc(source);
      }
    } else {
      let user = '';
      if (source && source.key) {
        requestFile(source.key, user).then((imageUrl) => {
          setImageSrc(imageUrl);
        });
      }
    }
  }, [source]);

  function saveCropped(blob) {
    onSaveCroppedImage(blob);
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      saveCropped(croppedImage);
      setProfileImageModified(true)
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <>
      <div className={classes.cropContainer}>
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          //aspect={2 / 3}
          aspect={4 / 5}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            {t('profile.zoom')}
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
            style={{ color: 'rgba(255,255,255,1.0)' }}
          />
        </div>
        <Divider />
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            {t('profile.rotate')}
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
            style={{ color: 'rgba(255,255,255,1.0)' }}
          />
        </div>
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          classes={{ root: classes.cropButton }}
        >
          {t('profile.save_crop')}
        </Button>
      </div>
    </>
  );
};

CropperComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onSaveCroppedImage: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  s3Object: PropTypes.bool,
  requestFile: PropTypes.func,
  user: PropTypes.object,
};

const styledCropper = withStyles(styles)(CropperComponent);

export default LixaniFiles(styledCropper);
