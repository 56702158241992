import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Button/Button';

import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import DialogHeader from 'components/DialogHeader/DialogHeader';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    ...theme.component.dialogPaper,
    border: theme.component.border,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 24,
      marginRight: 24,
    },
  },
  dialogContent: {
    paddingTop: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  progressValues: { textAlign: 'center' },
}));

function UploadProgressDialog({
  open,
  close,
  progress,
  uploadSizes,
  filesCount,
  filesUploadingCount,
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  console.log('uploadProgress', progress);

  return (
    <Dialog
      aria-labelledby="upload-progress-dialog"
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogHeader
        title={`${t(
          'common.downloading'
        )} ${filesUploadingCount}/${filesCount}`}
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LinearProgress
              variant="determinate"
              value={progress}
            ></LinearProgress>
          </Grid>
          <Grid item xs={12} className={classes.progressValues}>
            <Typography variant="body2">{`${uploadSizes.loaded} / ${uploadSizes.total} MB`}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button onClick={close}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadProgressDialog;
