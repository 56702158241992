import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as imageSelectorActions from './actions/imageSelectorActions';
import { getFilePermissions } from './thunks/getFilePermissions';

const filePermissionsAdapter = createEntityAdapter();

const resetState = {
    imageSelectorFilePermissions: createEntityAdapter().getInitialState(),
    loadingFilePermissions: false,
    initialFilePermissionsLoaded: false,
}

export const imageSelectorSlice = createSlice({
    name: 'imageSelector',
    initialState: {
        imageSelectorFilePermissions: filePermissionsAdapter.getInitialState(),
        loading: false,
        loadingFilePermissions: false,
        initialFilePermissionsLoaded: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFilePermissions.pending, (state) => {
                state.loadingFilePermissions = true;
            })
            .addCase(getFilePermissions.fulfilled, (state, action) => {
                state.loadingFilePermissions = false;
                filePermissionsAdapter.addMany(state.imageSelectorFilePermissions, action.payload.filePermissions);
            })
            .addCase(getFilePermissions.rejected, (state) => {
                state.loadingFilePermissions = false;
            })
            .addCase(imageSelectorActions.setFilePermissions, (state, action) => {
                state.initialFilePermissionsLoaded = true;
                filePermissionsAdapter.addMany(state.imageSelectorFilePermissions, action.payload);
            })
            .addCase(imageSelectorActions.resetState, () => resetState)
            .addCase(imageSelectorActions.setLoading, (state, action) => {
                state.loading = action.payload;
            });
    },
});
