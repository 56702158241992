import { createAsyncThunk } from '@reduxjs/toolkit';
import deleteApprovalGroup from '../services/deleteApprovalGroup.service';
import { selectSelectedApprovalGroup } from '../selectors/approvals.selectors';

export const removeApprovalGroup = createAsyncThunk(
  'approvals/remove-approval-group',
  async (_, { getState }) => {
    const selectedApprovalGroup = selectSelectedApprovalGroup(getState());

    const { records, approvalGroupSK, approversSKs } =
      await deleteApprovalGroup(
        selectedApprovalGroup.projectId,
        selectedApprovalGroup.groupCode
      );

    return { records, approvalGroupSK, approversSKs };
  }
);
