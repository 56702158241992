import {
  EMPLOYEE_DEFAULT_ROLE_ITEM,
  RESIDENT_DEFAULT_ROLE_ITEM,
} from '../constants/lixani-default-project-roles';

export const returnDefaultPermissionsByEmploymentType = (projectResources) => {
  const employeeResource = projectResources.filter(
    (resource) =>
      !resource.employmentType || resource.employmentType === 'EMPLOYEE'
  )[0];

  const resourceDefaultPermissions = employeeResource
    ? EMPLOYEE_DEFAULT_ROLE_ITEM.permissions
    : RESIDENT_DEFAULT_ROLE_ITEM.permissions;

  return resourceDefaultPermissions;
};
