import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import Refresher from 'components/Refresher/Refresher';

import hoursToTime from 'utils/hoursToTime';
import hoursToTimeFull from 'utils/hoursToTimeFull';
import hoursToTimeSimple from 'utils/hoursToTimeSimple';
import momentDiffHours from 'utils/momentDiffHours';
import { connect } from 'react-redux';
import { selectPersonalTodayClockedInRecords } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';

const mapStateToProps = (state) => ({
  records: selectPersonalTodayClockedInRecords(state),
});
class InTime extends Component {
  render = () => {
    const { records, clockInAt, clockOutAt, status, simple, full } = this.props;

    const total = () => {
      let totalHours = 0;
      if (clockInAt) {
        totalHours = momentDiffHours(clockInAt, clockOutAt, status);
      } else {
        [].concat(records || []).forEach((item) => {
          const now = DateTime.local();
          if (item?.clockInAt != null) {
            totalHours =
              totalHours +
              momentDiffHours(
                item.clockInAt,
                item.clockOutAt ? item.clockOutAt : now.toISO(),
                item.status
              );
          }
        });
      }
      if (simple) {
        return hoursToTimeSimple(totalHours);
      } else if (full) {
        return hoursToTimeFull(totalHours);
      }
      return hoursToTime(totalHours);
    };

    return <Refresher render={total} />;
  };
}

InTime.defaultProps = {
  full: false,
  project: {},
  records: [],
  simple: false,
};

InTime.propTypes = {
  clockInAt: PropTypes.string,
  clockOutAt: PropTypes.string,
  full: PropTypes.bool,
  project: PropTypes.object,
  records: PropTypes.array.isRequired,
  simple: PropTypes.bool,
  status: PropTypes.string,
};

export default connect(mapStateToProps)(InTime);
