import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBreakRecordsByParentRecordId } from '../services/getBreakRecordsByParentRecordId.service';

export const getRecordsBreakRecords = createAsyncThunk(
  'records/getRecordsBreakRecords',
  async ({ projectId, parentRecordId }, { getState }) => {
    console.log('projectId', projectId);
    const records = await getBreakRecordsByParentRecordId(
      projectId,
      parentRecordId
    );

    return records;
  }
);
