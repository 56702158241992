import {
  EDIT_OTHER_USERS_RECORDS,
  RECORDS_EDIT_OWN_RECORDS,
} from 'scenes/Permissions/constants/permissions';
import { getNearestUserProjectPermissions } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';

export const checkPermissionsToEditRecord = (
  record,
  userProjectPermissions,
  projects,
  resourceDefaultPermissions,
  currentUser,
  isOwnerOfRecordProject,
  isBusinessAdminOfResource,
  IsAdminOfSelectedRecordProject
) => {
  const nearestUserProjectPermissionsForRecord =
    getNearestUserProjectPermissions(
      projects,
      userProjectPermissions,
      record.project
    );

  const defaultPermissionsWithNearestProjectPermissions = {
    ...resourceDefaultPermissions,
    ...nearestUserProjectPermissionsForRecord,
  };

  const isOwnRecord = record.mirrorUser === currentUser.id;
  // console.log('isOwnRecord', isOwnRecord);
  // console.log(
  //   'defaultPermissionsWithNearestProjectPermissions',
  //   defaultPermissionsWithNearestProjectPermissions
  // );

  // puuttuu RECORDS_EDIT_OWN_RECORDS, EDIT_OTHER_USERS_RECORDS
  const isPermissionToEdit = isOwnRecord
    ? defaultPermissionsWithNearestProjectPermissions[RECORDS_EDIT_OWN_RECORDS]
    : defaultPermissionsWithNearestProjectPermissions[EDIT_OTHER_USERS_RECORDS];

  const isRecordCurrentlyClockedIn = record.status === 'IN';

  // console.log('isOwnerOfRecordProject', isOwnerOfRecordProject);
  // console.log('isBusinessAdminOfResource', isBusinessAdminOfResource);
  // console.log('isPermissionToEdit', isPermissionToEdit);
  // console.log('isRecordCurrentlyClockedIn', isRecordCurrentlyClockedIn);
  // console.log('IsAdminOfSelectedRecordProject', IsAdminOfSelectedRecordProject);

  return (
    isOwnerOfRecordProject ||
    isBusinessAdminOfResource ||
    isPermissionToEdit ||
    isRecordCurrentlyClockedIn ||
    IsAdminOfSelectedRecordProject
  );
};
