import moment from 'moment';

function momentDiffTime(
  a,
  b,
  status,
  format = 'HHMMSS',
  ignoreSeconds = false
) {
  let mA = moment(a);

  let mB = moment();
  if (status === 'OUT') {
    mB = moment(b);
  }
  if (ignoreSeconds) {
    mA = mA.seconds(0).milliseconds(0);
    mB = mB.seconds(0).milliseconds(0);
  }
  const duration = moment.duration(mB.diff(mA));
  const hours = parseInt(duration.asHours(), 10);
  const minutes = ('0' + (parseInt(duration.asMinutes(), 10) % 60)).slice(-2);
  const seconds = ('0' + (parseInt(duration.asSeconds(), 10) % 60)).slice(-2);
  if (format === 'HH') {
    return hours;
  } else if (format === 'HHMM') {
    return `${hours}:${minutes}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
}

export default momentDiffTime;
