import { createAsyncThunk } from '@reduxjs/toolkit';
import postBreakRecord from '../services/postBreakRecord.service';
import { selectSelectedRecord } from '../selectors/records.selectors';
export const createBreakRecord = createAsyncThunk(
  'records/createBreakRecord',
  async (breakRecord, { getState }) => {
    const parentRecord = selectSelectedRecord(getState());

    const response = await postBreakRecord({
      projectId: parentRecord.project,
      parentRecordId: parentRecord.id,
      breakRecord: breakRecord,
    });
    return response;
  }
);
