// Usage:
// .sort((a,b) => sortByName(a,b,value))

function sortByName(a, b, variable) {
  const nameA = a[variable] && a[variable].toLowerCase();
  const nameB = b[variable] && b[variable].toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

export default sortByName;
