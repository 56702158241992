import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectFolders } from 'scenes/ProjectBank/services/getProjectFolders.service';
import { loadUsersAndPersonalResourcesByUserIds } from 'store/projectUsers/thunks/loadUsersAndPersonalResourcesByUserIds';
import { uniq } from 'lodash';

export const loadProjectFolders = createAsyncThunk(
  'projectBank/loadProjectFolders',
  async (_, { getState, dispatch }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const projectFolders = await getProjectFolders(selectedProjectId);

    if (projectFolders && projectFolders.length > 0) {
      const uniqueUserIds = uniq(projectFolders.map((folder) => folder.user));

      dispatch(loadUsersAndPersonalResourcesByUserIds(uniqueUserIds));
    }

    return projectFolders;
  }
);
