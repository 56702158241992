import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { getInvoiceSortingRules } from '../services/getInvoiceSortingRules';
import { getInvoiceSortingRule } from '../services/getInvoiceSortingRule';

export const loadInvoiceSortingRule = createAsyncThunk(
  'invoices/invoiceSortingRule',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const businessId = params.businessId;
    if (!businessId) {
      throw new Error('Business ID is required');
    }

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await getInvoiceSortingRule(mainProjectId, businessId);

    return response;
  }
);
