import { createSlice } from '@reduxjs/toolkit';
import { loadUser } from './thunks/loadUser.thunk';
import { loadUserSettings } from './thunks/loadUserSettings.thunk';
import { updateUserSettings } from './thunks/updateUserSettings.thunk';
import { getGeolocation } from 'store/records/thunks/getGeolocation.thunk';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    settings: {},
    loading: true,
    userLoaded: false,
    settingsLoaded: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.user = action.payload;

        state.loading = false;
        state.userLoaded = true;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar(i18n.t('notifications.errors.get-user-rejected'), {
          variant: 'error',
        });
      })
      .addCase(loadUserSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadUserSettings.fulfilled, (state, action) => {
        state.settings = action.payload;

        state.loading = false;
        state.settingsLoaded = true;
      })
      .addCase(loadUserSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        enqueueSnackbar(
          i18n.t('notifications.errors.get-user-settings-rejected'),
          {
            variant: 'error',
          }
        );
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
      })
      .addCase(getGeolocation.fulfilled, (state, action) => {
        console.log('getGeolocation fulfilled', action.payload);
        state.coordinates = action.payload;
      })
      .addCase(getGeolocation.pending, (state, action) => {
        console.log('location fetch started');
        state.coordinates = undefined;
        state.geolocationError = undefined;
      })
      .addCase(getGeolocation.rejected, (state, action) => {
        state.coordinates = undefined;
        console.log('rejected geolocation:', action.payload);
        state.geolocationError = action.payload;
      });
  },
});

export default userSlice.reducer;
