import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { RECORD_TYPE } from 'LixaniAPI/enums';
import Button from 'components/Button/Button';
import DialogHeader from 'components/DialogHeader/DialogHeader';
import TextInput from 'components/TextInput/TextInput';
import CalendarPlusIcon from 'mdi-material-ui/CalendarPlus';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAllowancesCreateParams } from 'store/records/actions/records.actions';
import uuidv4 from 'uuid/v4';
import { AllowancesUnitCount } from './AllowancesUnitCount/AllowancesUnitCount';
import AllowanceDaysSelect from './Components/AllowanceDaysSelect';
import { selectSelectedClockOutRecordResourceEmployerAllowances } from 'store/employerAllowances/selectors/record-employer-allowances.selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.component.dialogPaper,
    padding: '8px',
  },
  dialogPaper: {
    ...theme.component.dialogPaper,
  },
}));

function AllowanceDialog({ open, setDialogOpen }) {
  const theme = useTheme();

  const classes = useStyles();

  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const [selectedAllowanceType, setAllowanceType] = useState('');

  const [unitCount, setUnitCount] = useState('');

  const isSelectedAllowanceType = selectedAllowanceType ? true : false;

  const employerAllowances = useSelector(
    selectSelectedClockOutRecordResourceEmployerAllowances
  );

  const [timeFrame, setTimeFrame] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const invalidTimeframe = timeFrame.endDate < timeFrame.startDate;

  const allowanceTypeOptions = [
    {
      salaryName: t('record.type.sick_leave'),
      salaryType: RECORD_TYPE.ABSENCE_SICK_LEAVE,
    },
    {
      salaryName: t('record.type.absence_vacation_paid'),
      salaryType: RECORD_TYPE.ABSENCE_VACATION_PAID,
    },
  ];

  const allowanceTypeOptionsWithEmployerAllowances = [
    ...allowanceTypeOptions,
    ...employerAllowances,
  ];

  const selectedAllowance = allowanceTypeOptionsWithEmployerAllowances.find(
    (allowance) => allowance.salaryType === selectedAllowanceType
  );

  const handleAllowanceTypeChange = (value) => {
    setAllowanceType(value);
    setUnitCount('');
    setTimeFrame({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    });
  };

  const selectAllowanceComponent = (allowanceType) => {
    switch (allowanceType) {
      case 'ABSENCE_SICK_LEAVE':
      case 'ABSENCE_VACATION_PAID':
        return (
          <AllowanceDaysSelect
            allowanceType={allowanceType}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
          ></AllowanceDaysSelect>
        );
      default:
        return selectedAllowanceType ? (
          <AllowancesUnitCount
            t={t}
            unit={selectedAllowance.unit}
            unitCount={unitCount}
            setUnitCount={setUnitCount}
          ></AllowancesUnitCount>
        ) : null;
    }
  };

  const setAbsenceRecordsParams = () => {
    const startDate = new Date(timeFrame.startDate).toISOString();
    const endDate = new Date(timeFrame.endDate).toISOString();
    const paramsId = uuidv4();

    const absenceRecordsParams = {
      id: paramsId,
      type: selectedAllowanceType,
      startDate,
      endDate,
      allowanceType: 'RECORD',
    };
    dispatch(setAllowancesCreateParams(absenceRecordsParams));
  };

  const setRecordAllowancesToAdd = () => {
    const absenceRecordsParams = {
      id: uuidv4(),
      salaryType: selectedAllowanceType,
      unitCount,
      allowanceType: 'RECORD_ALLOWANCE',
    };
    dispatch(setAllowancesCreateParams(absenceRecordsParams));
  };

  const addAllowance = (allowanceType) => {
    const isAbsenceRecordAllowance =
      allowanceType === RECORD_TYPE.ABSENCE_SICK_LEAVE ||
      allowanceType === RECORD_TYPE.ABSENCE_VACATION_PAID;

    if (isAbsenceRecordAllowance) {
      setAbsenceRecordsParams();
    } else {
      setRecordAllowancesToAdd();
    }

    setDialogOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="allowance-dialog"
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      maxWidth="xs"
      open={open}
    >
      <DialogHeader
        icon={<CalendarPlusIcon />}
        title={t('record.allowances')}
      />
      <DialogContent>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <TextInput
              displayEmpty
              fullWidth
              id="allowance-type-select"
              label={t('calendar.type')}
              MenuProps={{
                PaperProps: {
                  className: classes.menuPaper,
                },
              }}
              onChange={(e) => handleAllowanceTypeChange(e.target.value)}
              select
              shrink
              value={selectedAllowanceType}
            >
              {allowanceTypeOptionsWithEmployerAllowances.map((item) => (
                <MenuItem
                  className={classes.menuItem}
                  key={item.salaryType}
                  value={item.salaryType}
                >
                  {item.salaryName}
                </MenuItem>
              ))}
            </TextInput>
          </Grid>
          <Grid item xs={12}>
            {selectAllowanceComponent(selectedAllowanceType)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => setDialogOpen(false)} color="primary">
          {t('common.cancel')}
        </Button>

        <Button
          onClick={() => addAllowance(selectedAllowanceType)}
          color="primary"
          disabled={!isSelectedAllowanceType || invalidTimeframe}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AllowanceDialog;
