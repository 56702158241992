import { Grid } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import Button from 'components/Button/Button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteRecordAllowanceThunk } from 'store/recordAllowances/thunks/deleteRecordAllowance.thunk';

export const RecordAllowancesList = ({ recordAllowances }) => {
  const dispatch = useDispatch();
  return (
    <>
      {recordAllowances.map((recordAllowance) => (
        <Grid
          key={recordAllowance.id}
          container
          style={{ display: 'flex', width: '100%' }}
        >
          <Grid item xs={8} style={{ display: 'flex' }}>
            <p style={{ width: '120px' }}>{recordAllowance.name} </p>
            <p>
              {recordAllowance.unitCount} {recordAllowance.unit}
            </p>
          </Grid>
          <Grid item xs={4}>
            <Button
              elevated
              color="danger"
              style={{ padding: '8px', marginLeft: '8px' }}
              onClick={() =>
                dispatch(deleteRecordAllowanceThunk(recordAllowance.id))
              }
            >
              <Icon path={mdiDelete} size={1}></Icon>
            </Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
