export const overrideRolePermissionsWithUserPermissions = (
  appDefaultPermissions,
  resourceDefaultPermissions,
  rolePermissions,
  permissionExceptions
) => ({
  ...appDefaultPermissions,
  ...resourceDefaultPermissions,
  ...rolePermissions,
  ...permissionExceptions,
});
