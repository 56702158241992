import { createAction } from '@reduxjs/toolkit';

export const updateInvoicesFromGraphqlMutate = createAction(
  'invoices/updateInvoicesFromGraphqlMutate'
);

export const setInvoiceLoadedAmount = createAction(
  'invoices/setInvoiceLoadedAmount'
);

export const setSaleInvoiceLoadedAmount = createAction(
  'invoices/setSaleInvoiceLoadedAmount'
);

export const clearInvoiceData = createAction('invoices/clearInvoiceData');

export const removeLastInvoiceError = createAction('invoices/removeLastInvoiceError');