import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectCurrentUserEmployerProjects } from 'store/resources/selectors/resources.selectors';
import { loadEmployerAllowancesByBusinessId } from './loadEmployerAllowancesByBusinessId.thunk';

export const loadAllowancesOfCurrentUserEmployers = createAsyncThunk(
  'employerAllowances/loadAllowancesOfCurrentUserEmployers',
  async (_, { getState, dispatch }) => {
    const currentUserEmployerProjects = selectCurrentUserEmployerProjects(
      getState()
    );

    const currentUserEmployerBusinessIds = currentUserEmployerProjects.map(
      (employerProject) => employerProject.business_id
    );

    currentUserEmployerBusinessIds.map((businessId) =>
      dispatch(loadEmployerAllowancesByBusinessId(businessId))
    );
  }
);
