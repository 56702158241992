import { uniqWith } from 'lodash';

export const uniqueResourcesByUserAndEmployer = (resources) => {
  const resourcesWithEmployer = resources.filter(
    (resource) => resource.employer
  );

  const resourcesWithoutEmployer = resources.filter(
    (resource) => !resource.employer
  );

  const uniqueResourcesWithEmployer = uniqWith(
    resourcesWithEmployer,
    (a, b) => a.user === b.user && a.employer === b.employer
  );

  const uniqueResourcesRecordResourcesWithoutEmployer = uniqWith(
    resourcesWithoutEmployer,
    (a, b) => a.user === b.user
  );

  const uniqueResources = [
    ...uniqueResourcesWithEmployer,
    ...uniqueResourcesRecordResourcesWithoutEmployer,
  ];

  return uniqueResources;
};
