import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { getInvoiceSortingRules } from '../services/getInvoiceSortingRules';
import { getInvoiceSortRules } from '../services/getInvoiceSortRules';

export const loadInvoiceSortRules = createAsyncThunk(
  'invoices/invoiceSortRules',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await getInvoiceSortRules(mainProjectId);

    return response;
  }
);
