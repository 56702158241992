import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectAndSubProjectBulletins } from 'scenes/BulletinBoard/services/getProjectAndSubProjectBulletins';

export const loadProjectAndSubProjectBulletins = createAsyncThunk(
  'bulletins/loadProjectAndSubProjectBulletins',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projectAssignments = await getProjectAndSubProjectBulletins(
      selectedProjectId
    );

    return projectAssignments;
  }
);
