import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProjectAssignments } from '../services/getProjectAssignments';

export const loadAssignmentsForProjects = createAsyncThunk(
  'assignments/loadAssignmentsForProjects',
  async (projectIds) => {
    const projectAssignments = await Promise.all(
      projectIds.map((id) => getProjectAssignments(id))
    );

    return projectAssignments.flat();
  }
);
