const RECORD_TYPE = Object.freeze({
  ABSENCE: 'ABSENCE',
  ABSENCE_VACATION_PAID: 'ABSENCE_VACATION_PAID',
  ABSENCE_SICK_LEAVE: 'ABSENCE_SICK_LEAVE',
  BENEFIT: 'BENEFIT',
  BREAK: 'BREAK',
  BREAK_COFFEE: 'BREAK_COFFEE',
  BREAK_MEAL: 'BREAK_MEAL',
  ITEM: 'ITEM',
  RIDE: 'RIDE',
  SHIFT: 'SHIFT',
  WORK: 'WORK',
  PROJECTWORK: 'PROJECTWORK',
  NEGATIVE: 'NEGATIVE',
});

export { RECORD_TYPE };
