import { createAsyncThunk } from '@reduxjs/toolkit';
import { putAdmin } from 'scenes/Permissions/services/putAdmin.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const putAdminStatusOfUsers = createAsyncThunk(
  'permissionsManagement/putAdminStatusOfUsers',
  async (_, { getState }) => {
    const {
      permissions: { selectedUsersToUpdatePermissions },
    } = getState();

    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const businessId = selectedProject.business_id;

    if (!businessId) {
      throw new Error('no business id found :(');
    }

    const admins = await Promise.all(
      selectedUsersToUpdatePermissions.map((user) => putAdmin(user, businessId))
    );

    return admins;
  }
);
