import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectCardIdToDelete } from '../selectors/cards.selectors';
import { patchDeleteCard } from '../services/patchDeleteCard.service';

export const deleteCard = createAsyncThunk(
  'cards/deleteCard',
  async (_, { getState }) => {
    const selectedCardIdToDelete = selectCardIdToDelete(getState());

    const response = await patchDeleteCard(selectedCardIdToDelete);

    return response;
  }
);
