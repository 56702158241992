import { Grid, Typography, makeStyles } from '@material-ui/core';
import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLocationPending } from 'store/records/selectors/records.selectors';
import {
  selectGeolocationError,
  selectUserCoordinates,
} from 'store/user/selectors.js/user.selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.component.dialogPaper,
    padding: '8px',
    borderRadius: '2px',
    opacity: '0.9',
  },
}));

export default function LocationInfo({ record }) {
  const { t } = useTranslation('common');

  const classes = useStyles();

  const currentCoordinates = useSelector(selectUserCoordinates);

  const latIn = record.latIn;
  const longIn = record.longIn;

  const latOut = currentCoordinates ? currentCoordinates.latitude : undefined;
  const longOut = currentCoordinates ? currentCoordinates.longitude : undefined;

  const isClockInLocation = latIn && longIn;
  const isClockOutLocation = latOut && longOut;

  const locationPending = useSelector(selectIsLocationPending);
  const geolocationError = useSelector(selectGeolocationError);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight="bold">
          {t('common.location')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('location.location_on_clockin')}:`}
          </Grid>
          <Grid item xs={4}>
            {isClockInLocation
              ? t('location.location_received')
              : t('location.no_location_on_clockin')}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            {`${t('location.location_on_clockout')}:`}
          </Grid>
          <Grid item xs={4}>
            {!locationPending ? (
              isClockOutLocation ? (
                t('location.location_received')
              ) : geolocationError && geolocationError.code === 1 ? (
                t('location.location_user_denied')
              ) : (
                t('location.location_other_denied')
              )
            ) : (
              <ButtonLoading />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
