import gql from 'graphql-tag';

import recordItem from 'LixaniAPI/recordListItem';

export default gql`
mutation RecordUpdate($id: ID!, $project: ID,
                      $clockOutAt: String, $clockInAt: String,
                      $billedClockOutAt: String, $billedClockInAt: String,
                      $status: RecordStatus, $description: String,
											$value: String,
											$assignment: ID,
                      $assignmentUnitAmount: String,
											$assignments: [ID],
											$delete: Boolean,
                      $type: RecordType,
                      $targetCountry: String,
                      $latIn: String,
                      $latOut: String,
                      $longIn: String,
                      $longOut: String) {
    recordUpdate(input: {
        id: $id
        project: $project
        clockOutAt: $clockOutAt
        clockInAt: $clockInAt
        billedClockOutAt: $billedClockOutAt
        billedClockInAt: $billedClockInAt
        value: $value
        status: $status
        description: $description
        delete: $delete
				assignment: $assignment
        assignmentUnitAmount: $assignmentUnitAmount
				assignments: $assignments
        type: $type
        targetCountry: $targetCountry
        latIn: $latIn
        latOut: $latOut
        longIn: $longIn
        longOut: $longOut
    }) {
			${recordItem}
    }
}`;
