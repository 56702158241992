import { createSlice } from '@reduxjs/toolkit';
import { loadTranslation } from './thunks/loadTranslation.thunk';
import {
  showOriginalText,
  showTranslatedText,
} from './actions/translations.actions';

export const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    translationsByLanguageCode: {},
    targetsWithTranslationEnabled: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadTranslation.fulfilled, (state, action) => {
        if (action.payload) {
          if (
            !state.translationsByLanguageCode[action.payload.targetLanguageCode]
          ) {
            state.translationsByLanguageCode[
              action.payload.targetLanguageCode
            ] = {};
          }

          state.translationsByLanguageCode[action.payload.targetLanguageCode][
            action.payload.targetId
          ] = action.payload.translatedText;
          state.targetsWithTranslationEnabled.push(action.payload.targetId);
        }
      })
      .addCase(showOriginalText, (state, action) => {
        const newTargetsWithTranslationEnabled =
          state.targetsWithTranslationEnabled.filter(
            (targetId) => targetId !== action.payload
          );
        state.targetsWithTranslationEnabled = newTargetsWithTranslationEnabled;
      })
      .addCase(showTranslatedText, (state, action) => {
        state.targetsWithTranslationEnabled.push(action.payload);
      });
  },
});
