import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRecordHistory } from '../services/getRecordHistory';
import { selectSelectedRecordId } from '../selectors/records-base.selectors';

export const loadRecordHistory = createAsyncThunk(
  'records/loadHistory',
  async (_, { getState }) => {
    const selectedRecordId = selectSelectedRecordId(getState());
    const response = await getRecordHistory(selectedRecordId);

    return response;
  }
);
