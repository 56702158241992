import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedRecord } from '../selectors/records.selectors';
import patchApproveRecord from '../services/approveRecord.service';

export const approveRecord = createAsyncThunk(
  'records/approveRecord',
  async (_, { getState }) => {
    console.log('approveRecord thunk');
    const selectedRecord = selectSelectedRecord(getState());

    const recordProjectId = selectedRecord.project;

    const { updatedRecord, approval } = await patchApproveRecord(
      recordProjectId,
      selectedRecord.id
    );

    return { updatedRecord, approval };
  }
);
