import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setEditFormTemplate from 'scenes/ProjectForms/utils/setEditFormTemplate';

export const editFormTemplate = createAsyncThunk(
    'projectForm/editFormTemplate',
    async (data, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        const editedFormTemplate = await setEditFormTemplate(data, params);
        return { editedFormTemplate };
    }
);