import { createAsyncThunk } from '@reduxjs/toolkit';


import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { checkOrientationRequirement } from '../services/checkOrientationRequirement';

export const loadOrientationRequirement = createAsyncThunk(
  'orientation/loadOrientationRequirement',
  async (params, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    console.log('selectedProjectId', selectedProjectId);

    const requirement = await checkOrientationRequirement(
      selectedProjectId
    );

    console.log('orientationQuestionSet', requirement);

    return requirement;
  }
);
