import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminPermissionsOfUser } from 'scenes/Permissions/services/getAdminPermissionsOfUser.service';
import { getAllUserProjectsPermissions } from 'scenes/Permissions/services/getProjectPermissions.service';

export const loadAllUserProjectsPermissions = createAsyncThunk(
  'permissions/loadAllUserProjectsPermissions',
  async (_, { getState }) => {
    const {
      root: { user },
    } = await getState();

    const userId = user.id;

    const projectPermissions = await getAllUserProjectsPermissions(userId);

    const adminPermissions = await getAdminPermissionsOfUser();

    return { projectPermissions, adminPermissions };
  }
);
