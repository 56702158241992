import projectItem from './projectListItem';

const item = `
      __typename
      id
      createdAt
      updatedAt
      project {
				${projectItem}
			}
      firstName
			lastName
      middleName
      lastRecord
      personal
			user
			mirrorUser

      dateOfBirth
      taxNumber
      email
      phone
      co
      street1
      street2
      latitude
      longitude
      postcode
      city
      homeRegion
      homeCountry        
      description
      idcard
      employmentType
      employer {
        ${projectItem}
      }

      picture {
        bucket
        key
        region
      }

      nestedpicture {
        bucket
        key
        region
      }

			parentMirror {
				resource {
          id
          createdAt
          updatedAt

          firstName
          lastName
          middleName
          personal
          user
          mirrorUser
    
          dateOfBirth
          taxNumber
          email
          phone
          co
          street1
          street2
          postcode
          city
          homeRegion
          homeCountry        
          description
          idcard
          employmentType
          
          employer {
            ${projectItem}
          }
    
          picture {
            bucket
            key
            region
          }
    
          nestedpicture {
            bucket
            key
            region
          }
          
          project {
            ${projectItem}
          }
				}
        
			}
    wage
`;

export default item;
