import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { selectApprovalGroups } from 'store/approvals/selectors/approvals.selectors';
import {
  setWorkSearchParamsApprovalGroup,
  setWorkSearchParamsFromDateState,
  setWorkSearchParamsToDateState,
} from '../actions/demoActions';
import { searchWorkCalendar } from './searchWorkCalendar.thunk';

export const showSelectedApprovalGroupRecords = createAsyncThunk(
  'workCalendar/showSelectedApprovalGroupRecords',
  async (SK, { getState, dispatch }) => {
    const approvalGroups = selectApprovalGroups(getState());
    const approvalGroup = approvalGroups[SK];

    const fromDate = approvalGroup.firstBilledInAt;
    const toDate = approvalGroup.lastBilledOutAt;

    dispatch(
      setWorkSearchParamsFromDateState(
        dayjs(fromDate).startOf('isoWeek').format('YYYY-MM-DD')
      )
    );
    dispatch(
      setWorkSearchParamsToDateState(
        dayjs(toDate).endOf('isoWeek').format('YYYY-MM-DD')
      )
    );

    dispatch(setWorkSearchParamsApprovalGroup(approvalGroup.groupCode));

    dispatch(searchWorkCalendar());
  }
);
