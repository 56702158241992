import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  addApprover,
  clearSelectedApprovalGroup,
  closeApprovalGroupsDialog,
  closeSendToApprovalDialog,
  openApprovalGroupsDialog,
  removeApprover,
  setApprovalGroupDeleteDialogOpen,
  setSelectedApprovalGroup,
  setSendToApprovalDialogAttributes,
} from './actions/approvals.actions';
import { openSendToApprovalDialog } from './thunks/openSendToApprovalDialog';
import { createRecordsApprovalGroup } from './thunks/createRecordsApprovalGroup';
import { loadApprovalGroupsByProject } from './thunks/loadApprovalGroupsByProject';
import { loadApproversByGroupCode } from './thunks/loadApproversByGroupCode';
import { removeApprovalGroup } from './thunks/removeApprovalGroup';
import { showSelectedApprovalGroupRecords } from 'scenes/WorkDemo/store/thunks/showApprovalGroupRecords';
import { loadApproversByRecord } from './thunks/loadApproversByRecord';
import { approveRecord } from 'store/records/thunks/approveRecord.thunk';
import { disapproveRecord } from 'store/records/thunks/disapproveRecord.thunk';
import { approveApprovalGroup } from './thunks/approveApprovalGroup.thunk';

const approvalGroupsAdapter = createEntityAdapter();
const approversAdapter = createEntityAdapter();

const initialState = {
  approvalGroups: approvalGroupsAdapter.getInitialState(),
  approvers: approversAdapter.getInitialState(),
  createRecordsApprovalGroupPending: false,
  loadApproversPending: false,
  approvalGroupsDialogOpen: false,
  sendToApprovalDialogOpen: false,
  sendToApprovalDialogData: {
    projectId: undefined,
    employerId: undefined,
    approvers: [],
  },
  selectedApprovalGroupSK: '',
  approvalGroupDeletePending: false,
  approvalGroupDeleteDialogOpen: false,
  approvePending: false,
};

export const approvalsSlice = createSlice({
  name: 'approvals',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(openSendToApprovalDialog.fulfilled, (state, action) => {
        state.sendToApprovalDialogData = action.payload;
        state.sendToApprovalDialogOpen = true;
      })
      .addCase(closeSendToApprovalDialog, (state, action) => {
        state.sendToApprovalDialogOpen = false;
        state.sendToApprovalDialogData = initialState.sendToApprovalDialogData;
      })
      .addCase(setSendToApprovalDialogAttributes, (state, action) => {
        state.sendToApprovalDialogData = {
          ...state.sendToApprovalDialogData,
          ...action.payload,
        };
      })
      .addCase(openApprovalGroupsDialog, (state, action) => {
        state.approvalGroupsDialogOpen = true;
      })
      .addCase(closeApprovalGroupsDialog, (state, action) => {
        state.approvalGroupsDialogOpen = false;
      })
      .addCase(addApprover, (state, action) => {
        state.sendToApprovalDialogData.approvers.push(action.payload);
      })
      .addCase(removeApprover, (state, action) => {
        const index = action.payload;
        state.sendToApprovalDialogData.approvers.splice(index, 1);
      })
      .addCase(createRecordsApprovalGroup.fulfilled, (state, action) => {
        const approvers = action.payload.approvers.map((approver) => {
          return { ...approver, id: approver.SK };
        });
        const approvalGroup = {
          ...action.payload.approvalGroup,
          id: action.payload.approvalGroup.SK,
        };

        approversAdapter.setMany(state.approvers, approvers);
        approvalGroupsAdapter.setOne(state.approvalGroups, approvalGroup);

        state.createRecordsApprovalGroupPending = false;
        state.sendToApprovalDialogOpen = false;
        state.sendToApprovalDialogData = initialState.sendToApprovalDialogData;
      })
      .addCase(createRecordsApprovalGroup.pending, (state, action) => {
        state.createRecordsApprovalGroupPending = true;
      })
      .addCase(createRecordsApprovalGroup.rejected, (state, action) => {
        state.createRecordsApprovalGroupPending = false;
      })
      .addCase(loadApprovalGroupsByProject.fulfilled, (state, action) => {
        const approvalGroups = action.payload.map((approvalGroup) => {
          return { ...approvalGroup, id: approvalGroup.SK };
        });
        approvalGroupsAdapter.setMany(state.approvalGroups, approvalGroups);
      })

      .addCase(loadApproversByGroupCode.fulfilled, (state, action) => {
        const approvers = action.payload.map((approver) => {
          return { ...approver, id: approver.SK };
        });
        approversAdapter.setMany(state.approvers, approvers);
        state.loadApproversPending = false;
      })
      .addCase(loadApproversByGroupCode.pending, (state, action) => {
        state.loadApproversPending = true;
      })
      .addCase(loadApproversByRecord.pending, (state, action) => {
        state.loadApproversPending = true;
      })
      .addCase(loadApproversByRecord.fulfilled, (state, action) => {
        const approvers = action.payload.map((approver) => {
          return { ...approver, id: approver.SK };
        });
        approversAdapter.setMany(state.approvers, approvers);
        state.loadApproversPending = false;
      })

      .addCase(removeApprovalGroup.pending, (state, action) => {
        state.approvalGroupDeletePending = true;
      })
      .addCase(removeApprovalGroup.rejected, (state, action) => {
        state.approvalGroupDeletePending = false;
      })
      .addCase(removeApprovalGroup.fulfilled, (state, action) => {
        const approversSKs = action.payload.approversSKs;
        const approvalGroupSK = action.payload.approvalGroupSK;

        approvalGroupsAdapter.removeOne(state.approvalGroups, approvalGroupSK);
        approversAdapter.removeMany(state.approvers, approversSKs);

        state.approvalGroupDeletePending = false;
        state.approvalGroupDeleteDialogOpen = false;
        state.selectedApprovalGroupSK = '';
      })
      .addCase(setSelectedApprovalGroup, (state, action) => {
        state.selectedApprovalGroupSK = action.payload;
      })
      .addCase(clearSelectedApprovalGroup, (state, action) => {
        state.selectedApprovalGroupSK = '';
      })
      .addCase(setApprovalGroupDeleteDialogOpen, (state, action) => {
        state.approvalGroupDeleteDialogOpen = action.payload;
      })
      .addCase(showSelectedApprovalGroupRecords.fulfilled, (state, action) => {
        state.approvalGroupsDialogOpen = false;
      })
      .addCase(approveRecord.fulfilled, (state, action) => {
        const approval = {
          ...action.payload.approval,
          id: action.payload.approval.SK,
        };
        approversAdapter.setOne(state.approvers, approval);
      })
      .addCase(disapproveRecord.fulfilled, (state, action) => {
        const approvaSKtoDelete = action.payload.approvalSK;
        approversAdapter.removeOne(state.approvers, approvaSKtoDelete);
      })
      .addCase(approveApprovalGroup.fulfilled, (state, action) => {
        const approver = {
          ...action.payload.approver,
          id: action.payload.approver.SK,
        };
        const approvalGroup = {
          ...action.payload.approvalGroup,
          id: action.payload.approvalGroup.SK,
        };

        approversAdapter.setOne(state.approvers, approver);
        approvalGroupsAdapter.setOne(state.approvalGroups, approvalGroup);
        state.approvePending = false;
      })
      .addCase(approveApprovalGroup.rejected, (state, action) => {
        state.approvePending = false;
      })
      .addCase(approveApprovalGroup.pending, (state, action) => {
        state.approvePending = true;
      });
  },
});
