import { createAsyncThunk } from '@reduxjs/toolkit';
import { putAdmin } from 'scenes/Permissions/services/putAdmin.service';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const assignAdminPermissionsForUser = createAsyncThunk(
  'permissionsManagement/assignAdminPermissionsForUser',
  async (userId, { getState }) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    console.log('selectedProject', selectedProject);

    const businessId = selectedProject.business_id;

    if (!businessId) {
      throw new Error('no business id found :(');
    }

    const response = await putAdmin(userId, businessId);

    return response;
  }
);
