import React, { useEffect } from 'react';

import Dialog from 'components/DialogWrapper/DialogWrapper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';

import CalendarEditIcon from 'mdi-material-ui/CalendarEdit';

import Button from 'components/Button/Button';

import DialogHeader from 'components/DialogHeader/DialogHeader';

import useStyles from './RecordDialogLayoutStyles';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { TypeChange } from './typeChange';
import moment from 'moment';
import { useState } from 'react';
import { selectRecordEditPending } from 'store/records/selectors/records.selectors';
import { useSelector } from 'react-redux';
import ButtonLoading from 'components/ButtonLoading/ButtonLoading';

function AddBreakDialog({
  open,
  parentClockIn,
  parentClockOut,
  onClose,
  onCreateBreakRecord,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [breakRecordAttributes, setBreakRecordAttributes] = useState({
    type: 'BREAK_COFFEE',
    clockInAt: undefined,
    clockOutAt: undefined,
  });
  const dateFormat = 'YYYY-MM-DDTHH:mm';

  const calculateBreakRecordDefaultTimes = (parentClockIn, parentClockOut) => {
    const parentClockInDate = moment(parentClockIn);
    const parentClockOutDate = moment(parentClockOut);

    const parentDiff = parentClockOutDate.diff(parentClockInDate, 'minutes');

    let breakLengthMinutes = 15;

    if (parentDiff < breakLengthMinutes) {
      breakLengthMinutes = parentDiff;
    }

    const defaultClockIn = parentClockInDate.format(dateFormat);
    const defaultClockOut = parentClockInDate
      .add(breakLengthMinutes, 'minutes')
      .format(dateFormat);

    setBreakRecordAttributes((prev) => {
      return {
        ...prev,
        clockInAt: defaultClockIn,
        clockOutAt: defaultClockOut,
      };
    });
  };

  useEffect(() => {
    calculateBreakRecordDefaultTimes(parentClockIn, parentClockOut);
  }, [parentClockIn, parentClockOut]);

  const setClockedTimes = (attributeName, value) => {
    const valueFormated = moment(value).format(dateFormat);
    setBreakRecordAttributes((prev) => {
      return { ...prev, [attributeName]: valueFormated };
    });
  };

  const timeDiff = moment(breakRecordAttributes.clockOutAt).diff(
    moment(breakRecordAttributes.clockInAt),
    'minutes'
  );

  const handleTypeChange = (value) => {
    setBreakRecordAttributes((prev) => {
      return { ...prev, type: value };
    });
  };

  const handleClockInGreaterThanClockout = (clockInAt, clockOutAt) => {
    const clockIn = moment(clockInAt).format(dateFormat);
    const clockOut = moment(clockOutAt).format(dateFormat);

    if (clockIn > clockOut) {
      setBreakRecordAttributes((prev) => {
        return { ...prev, clockInAt: clockIn, clockOutAt: clockIn };
      });
    }
  };

  const onSave = () => {
    const breakRecord = {
      clockInAt: moment(breakRecordAttributes.clockInAt).toISOString(),
      clockOutAt: moment(breakRecordAttributes.clockOutAt).toISOString(),
      type: breakRecordAttributes.type,
    };
    onCreateBreakRecord(breakRecord);
  };

  const recordEditPending = useSelector(selectRecordEditPending);

  const breakClockInBeforeParentClockIn =
    breakRecordAttributes.clockInAt < parentClockIn;
  const breakClockOutAfterParentClockOut =
    breakRecordAttributes.clockOutAt > parentClockOut;

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={() => onClose()}
      aria-labelledby="add-break-dialog"
      maxWidth="sm"
    >
      <DialogHeader
        icon={<CalendarEditIcon />}
        onClose={() => onClose()}
        title={t('calendar.add_break')}
      />
      <DialogContent>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={5}>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: classes.textInput }}
              fullWidth
              label={t('calendar.start_date')}
              variant="outlined"
              size="small"
              type="datetime-local"
              value={breakRecordAttributes.clockInAt}
              onChange={(event) =>
                setClockedTimes('clockInAt', event.target.value)
              }
              onBlur={(event) =>
                handleClockInGreaterThanClockout(
                  event.target.value,
                  breakRecordAttributes.clockOutAt
                )
              }
            />
          </Grid>

          <Grid item xs={5} className={clsx(classes.dateItem)}>
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: classes.textInput }}
              fullWidth
              label={t('calendar.end_date')}
              variant="outlined"
              size="small"
              type="datetime-local"
              value={breakRecordAttributes.clockOutAt}
              onChange={(event) =>
                setClockedTimes('clockOutAt', event.target.value)
              }
              onBlur={(event) =>
                handleClockInGreaterThanClockout(
                  breakRecordAttributes.clockInAt,
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={2}>
            <p className={classes.breakTimeDiff}>{`${timeDiff} min`}</p>
          </Grid>
          {breakClockInBeforeParentClockIn ||
          breakClockOutAfterParentClockOut ? (
            <Grid item xs={12} className={classes.centerItems}>
              <FormHelperText className={classes.warningText}>
                {t('calendar.break_is_beyond_record_timeframe')}
              </FormHelperText>
            </Grid>
          ) : null}
          <Grid item xs={12} className={classes.gridItem}>
            <TypeChange
              handleChange={handleTypeChange}
              selectedType={breakRecordAttributes.type}
              recordType={'BREAK'}
            ></TypeChange>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => onClose()} color="primary">
          {t('common.cancel')}
        </Button>
        <Button disabled={recordEditPending} onClick={onSave} color="primary">
          {!recordEditPending ? t('common.save') : <ButtonLoading />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddBreakDialog;
