import Config from 'Config';

export const createRequestGetAdminWorkCalendarDataForProjectInTimeFrame = (
  projectId,
  fromDate,
  toDate
) => {
  const apiPath = `workflow/admin-request/${projectId}/${fromDate}/${toDate}`;

  const request = {
    //   url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    region: Config.AWS_REGION,
    method: 'GET',
    service: 'execute-api',
  };

  return request;
};
