import { createSelector } from '@reduxjs/toolkit';
import { selectProjects } from 'store/projects/selectors/projectsSelectors';
import { orderBy } from 'lodash';
import {
  selectSelectedRecord,
  selectSelectedRecordsIds,
} from 'store/records/selectors/records.selectors';
import {
  selectSelectedProjectAndChildProjects,
  selectWorkCalendarProjectsArrayWithJoinedData,
} from 'scenes/WorkDemo/store/selectors/workSelectorsDemo';
import {
  selectResourceDefaultPermissions,
  selectUserAdminBusinessIds,
  selectUserProjectPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { uniq } from 'lodash';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import { checkUserPermissionInProject } from 'store/projects/utils/checkUserPermissionInProject';
import {
  PROJECT_APPROVALS_MANAGE,
  RECORDS_SEND_TO_APPROVAL,
} from 'scenes/Permissions/constants/permissions';
import { selectWorkCalendarRecordsArray } from 'store/records/selectors/records-base.selectors';

const selectApprovalsState = (state) => state.approvals;

export const selectIsSendToApprovalDialogOpen = createSelector(
  [selectApprovalsState],
  (state) => state.sendToApprovalDialogOpen
);

export const selectSendToApprovalDialogData = createSelector(
  [selectApprovalsState],
  (state) => state.sendToApprovalDialogData
);
export const selectApprovalGroupsDialogOpen = createSelector(
  [selectApprovalsState],
  (state) => state.approvalGroupsDialogOpen
);
export const selectIsLoadApproversPending = createSelector(
  [selectApprovalsState],
  (state) => state.loadApproversPending
);

export const selectSendToApprovalDialogDataProjectId = createSelector(
  [selectSendToApprovalDialogData],
  (dialogData) => dialogData.projectId
);

export const selectSendToApprovalDialogDataProject = createSelector(
  [selectProjects, selectSendToApprovalDialogDataProjectId],
  (projects, projectId) => projects[projectId]
);

export const selectCreateRecordsApprovalGroupPending = createSelector(
  [selectApprovalsState],
  (state) => state.createRecordsApprovalGroupPending
);

export const selectApprovers = createSelector(
  [selectApprovalsState],
  (state) => state.approvers.entities
);

export const selectApproversArray = createSelector(
  [selectApprovers],
  (approvers) => {
    const sortedApprovers = orderBy(
      Object.values(approvers),
      [(approver) => approver.approvedAt],
      ['asc']
    );
    return sortedApprovers ? sortedApprovers : [];
  }
);

export const selectApprovalGroups = createSelector(
  [selectApprovalsState],
  (state) => state.approvalGroups.entities
);

export const selectApprovalGroupsArray = createSelector(
  [selectApprovalGroups],
  (approvalGroups) =>
    orderBy(
      Object.values(approvalGroups),
      [(group) => group.approvedAt],
      ['asc']
    )
);

export const selectApprovalGroupsArrayBySelectedProject = createSelector(
  [selectApprovalGroupsArray, selectSelectedProjectAndChildProjects],
  (approvalGroups, selectedProjectAndChildProjects) => {
    const selectedProjectAndChildProjectsIds =
      selectedProjectAndChildProjects.map((project) => project.id);

    return approvalGroups.filter((group) =>
      selectedProjectAndChildProjectsIds.includes(group.projectId)
    );
  }
);

export const selectApprovalGroupsSKsWhichUserHasPermissionToManage =
  createSelector(
    [
      selectApprovalGroupsArrayBySelectedProject,
      selectLoggedInUserId,
      selectUserProjectPermissions,
      selectProjects,
      selectResourceDefaultPermissions,
      selectUserAdminBusinessIds,
    ],
    (
      approvalGroups,
      userId,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      userAdminBusinessIds
    ) => {
      const approvalGroupsWithPermissionToManage = approvalGroups
        .filter((approvalGroup) => {
          const userIsCreatorOfApprovalGroup =
            approvalGroup.creatorUserId === userId;

          const permissionToManageApprovalGroupInApprovalGroupProject =
            checkUserPermissionInProject(
              approvalGroup.projectId,
              userId,
              userProjectPermissions,
              projects,
              resourceDefaultPermissions,
              userAdminBusinessIds,
              PROJECT_APPROVALS_MANAGE
            );

          return (
            userIsCreatorOfApprovalGroup ||
            permissionToManageApprovalGroupInApprovalGroupProject
          );
        })
        .map((approvalGroup) => approvalGroup.SK);
      return approvalGroupsWithPermissionToManage.length > 0
        ? approvalGroupsWithPermissionToManage
        : [];
    }
  );

export const selectApprovalGroupsArrayBySelectedProjectFilteredByUserPermissions =
  createSelector(
    [
      selectApprovalGroupsArrayBySelectedProject,
      selectApprovalGroupsSKsWhichUserHasPermissionToManage,
    ],
    (approvalGroups, permissionToManageApprovalGroupsSKs) => {
      return approvalGroups.filter((approvalGroup) =>
        permissionToManageApprovalGroupsSKs.includes(approvalGroup.SK)
      );
    }
  );

export const selectProjectIdsOfApprovalGroupsBySelectedProject = createSelector(
  [selectApprovalGroupsArrayBySelectedProjectFilteredByUserPermissions],
  (approvalGroups) => uniq(approvalGroups.map((group) => group.projectId))
);

export const selectProjectsOfApprovalGroupsBySelectedProject = createSelector(
  [
    selectProjectIdsOfApprovalGroupsBySelectedProject,
    selectWorkCalendarProjectsArrayWithJoinedData,
  ],
  (projectIds, projects) => {
    return projects.filter((project) => projectIds.includes(project.id));
  }
);

export const selectSelectedProjectApprovalGroupsWithFilledDataArray =
  createSelector(
    [
      selectApprovalGroupsArrayBySelectedProjectFilteredByUserPermissions,
      selectApproversArray,
    ],
    (approvalGroups, approvers) => {
      return approvalGroups.map((approvalGroup) => {
        const groupsApprovers = approvers.filter(
          (approver) => approvalGroup.groupCode === approver.groupCode
        );

        return {
          ...approvalGroup,
          approvers: groupsApprovers,
        };
      });
    }
  );
export const selectSelectedProjectApprovalGroupsGroupedByProjects =
  createSelector(
    [
      selectProjectsOfApprovalGroupsBySelectedProject,
      selectSelectedProjectApprovalGroupsWithFilledDataArray,
    ],
    (projects, approvalGroups) => {
      return orderBy(
        projects.map((project) => {
          return {
            project: project,
            approvalGroups: approvalGroups.filter(
              (group) => group.projectId === project.id
            ),
          };
        }),
        [(object) => object.project.name.toLowerCase()],
        ['asc']
      );
    }
  );

export const selectSelectedRecordApprovers = createSelector(
  [selectApproversArray, selectSelectedRecord],
  (approvers, selectedRecord) => {
    const recordGroupCode = selectedRecord.groupCode;

    const approversByGroupCodeOrRecordId = approvers.filter(
      (approver) =>
        (recordGroupCode && approver.groupCode === recordGroupCode) ||
        (approver.recordId && approver.recordId === selectedRecord.id)
    );

    return orderBy(
      approversByGroupCodeOrRecordId,
      [(approver) => approver.createdAt],
      ['asc']
    );
  }
);

export const selectIsPermissionToSendSelectedRecordsToApproval = createSelector(
  [
    selectSelectedRecordsIds,
    selectLoggedInUserId,
    selectWorkCalendarRecordsArray,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectUserAdminBusinessIds,
  ],
  (
    selectedRecordsIds,
    userId,
    records,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    userAdminBusinessIds
  ) => {
    const selectedRecords = records.filter((record) =>
      selectedRecordsIds.includes(record.id)
    );
    const selectedRecordsProjectId = selectedRecords[0].project;

    const permissionToSendRecordsToApproval = checkUserPermissionInProject(
      selectedRecordsProjectId,
      userId,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      userAdminBusinessIds,
      RECORDS_SEND_TO_APPROVAL,
      true
    );
    return permissionToSendRecordsToApproval;
  }
);

export const selectSelectedApprovalGroupSK = createSelector(
  [selectApprovalsState],
  (state) => state.selectedApprovalGroupSK
);

export const selectSelectedApprovalGroup = createSelector(
  [selectSelectedApprovalGroupSK, selectApprovalGroups],
  (SK, approvalGroups) => approvalGroups[SK]
);

export const selectIsApprovalGroupDeletePending = createSelector(
  [selectApprovalsState],
  (state) => state.approvalGroupDeletePending
);
export const selectIsApprovalGroupApprovePending = createSelector(
  [selectApprovalsState],
  (state) => state.approvePending
);
export const selectIsApprovalGroupDeleteDialogOpen = createSelector(
  [selectApprovalsState],
  (state) => state.approvalGroupDeleteDialogOpen
);

export const selectUsersSelectedRecordSingleApproval = createSelector(
  [selectSelectedRecordApprovers, selectLoggedInUserId],
  (approvers, userId) => {
    return approvers.find(
      (approver) =>
        approver.approvalUserId === userId &&
        approver.SK.includes('SINGLEAPPROVAL#') &&
        approver.status === 'approved'
    );
  }
);
