import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { loadEmployeeUsersOfEmployer } from 'scenes/ProjectResources/store/thunks/loadEmployeeResourcesOfEmployer';
import {
  closeProjectResourcesView,
  setProjectResourcesEmployeeSearchString,
  setSelectedProjectResourceUserId,
} from './actions/project-resources.actions';

const projectEmployeeUsersAdapter = createEntityAdapter();

export const projectResourcesSlice = createSlice({
  name: 'projectResources',
  initialState: {
    projectEmployeeUsers: projectEmployeeUsersAdapter.getInitialState(),
    selectedProjectResourcesUserId: '',
    projectResourcesEmployeeSearchString: '',
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadEmployeeUsersOfEmployer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadEmployeeUsersOfEmployer.fulfilled, (state, action) => {
        projectEmployeeUsersAdapter.setMany(
          state.projectEmployeeUsers,
          action.payload
        );
        state.loading = false;
      })
      .addCase(loadEmployeeUsersOfEmployer.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(closeProjectResourcesView, (state, action) => {
        state.selectedProjectResourcesUserId = '';
        state.projectResourcesEmployeeSearchString = '';
        state.projectEmployeeUsers =
          projectEmployeeUsersAdapter.getInitialState();
      })
      .addCase(setSelectedProjectResourceUserId, (state, action) => {
        state.selectedProjectResourcesUserId = action.payload;
      })
      .addCase(setProjectResourcesEmployeeSearchString, (state, action) => {
        state.projectResourcesEmployeeSearchString = action.payload;
      });
  },
});
