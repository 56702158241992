import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { loadProjectFolders } from 'scenes/ProjectBank/store/thunks/loadProjectFolders.thunk';
import { loadProjectBankFolderContents } from '../thunks/loadProjectBankFolderContents.thunk';
import {
  setFolderTree,
  setInitialProjectBankPath,
  setSelectedProjectBankFolder,
} from 'store/filepermissions/actions/filepermissions.actions';
import { closeProjectBank, setSortBy } from '../actions/project-bank.actions';
import { setAsDeletedProjectBankFilePermission } from '../thunks/setAsDeletedProjectBankFilePermission.thunk';
import { createProjectBankFile } from '../thunks/createProjectBankFile.thunk';
import { createProjectBankFolder } from '../thunks/createProjectBankFolder.thunk';

const filesFilePermissionsAdapter = createEntityAdapter();
const projectFolderFilePermissionsAdapter = createEntityAdapter();

const initialState = {
  projectTreeLoading: false,
  isFolderFilesLoading: false,
  filesFilePermissions: filesFilePermissionsAdapter.getInitialState(),
  projectFolderFilePermissions:
    projectFolderFilePermissionsAdapter.getInitialState(),
  selectedFolderPath: '',
  selectedFolderId: '',
  initialProjectBankPath: '',
  folderTree: {},
  sortBy: '',
};

export const projectBankSlice = createSlice({
  name: 'projectBank',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadProjectFolders.pending, (state, action) => {
        state.projectTreeLoading = true;
      })
      .addCase(loadProjectFolders.fulfilled, (state, action) => {
        projectFolderFilePermissionsAdapter.setMany(
          state.projectFolderFilePermissions,
          action.payload
        );
        state.projectTreeLoading = false;
      })
      .addCase(loadProjectFolders.rejected, (state, action) => {
        state.projectTreeLoading = false;
      })

      .addCase(loadProjectBankFolderContents.pending, (state, action) => {
        state.isFolderFilesLoading = true;
      })
      .addCase(loadProjectBankFolderContents.fulfilled, (state, action) => {
        filesFilePermissionsAdapter.setMany(
          state.filesFilePermissions,
          action.payload
        );
        state.isFolderFilesLoading = false;
      })
      .addCase(loadProjectBankFolderContents.rejected, (state, action) => {
        state.isFolderFilesLoading = false;
      })

      .addCase(setSelectedProjectBankFolder, (state, action) => {
        state.selectedFolderId = action.payload.folderId;
        state.selectedFolderPath = action.payload.path;
      })
      .addCase(setSortBy, (state, action) => {
        state.sortBy = action.payload;
      })
      .addCase(
        setAsDeletedProjectBankFilePermission.fulfilled,
        (state, action) => {
          if (action.payload.type === 'FOLDER') {
            projectFolderFilePermissionsAdapter.upsertOne(
              state.projectFolderFilePermissions,
              action.payload
            );
          } else {
            filesFilePermissionsAdapter.upsertOne(
              state.filesFilePermissions,
              action.payload
            );
          }
        }
      )
      .addCase(createProjectBankFolder.fulfilled, (state, action) => {
        projectFolderFilePermissionsAdapter.setOne(
          state.projectFolderFilePermissions,
          action.payload
        );
      })
      .addCase(createProjectBankFile.fulfilled, (state, action) => {
        filesFilePermissionsAdapter.setOne(
          state.filesFilePermissions,
          action.payload
        );
      })
      .addCase(setInitialProjectBankPath, (state, action) => {
        state.initialProjectBankPath = action.payload;
      })
      .addCase(setFolderTree, (state, action) => {
        state.folderTree = action.payload;
      })
      .addCase(closeProjectBank, () => ({
        ...initialState,
      }));
  },
});
