import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setMobileShowList,
  setSelectedProjectId,
} from '../actions/projectActions';
import { getProjectByProjectKey } from '../services/getProjectByProjectKey.service';

export const loadProjectByProjectKeyAfterJoining = createAsyncThunk(
  'project/loadProjectByProjectKeyAfterJoining',
  async (projectKey, { dispatch }) => {
    const project = await getProjectByProjectKey(projectKey);

    dispatch(setSelectedProjectId(project.id));

    dispatch(setMobileShowList(false));

    return project;
  }
);
