import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  selectAssignmentModifications,
  selectChildAssignmentsToAddWithModifications,
  selectRootAssignmentsToAddArrayWithModifications,
} from '../selectors/assignment.selectors';
import { postCreateAndUpdateProjectAssignments } from '../services/postCreateAndUpdateProjectAssignments.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const saveProjectAssignments = createAsyncThunk(
  'assignments/saveProjectAssignments',
  async (_, { getState }) => {
    // only update/create modified assignments

    const newProjectRootAssignments =
      selectRootAssignmentsToAddArrayWithModifications(getState());

    const newChildAssignments = selectChildAssignmentsToAddWithModifications(
      getState()
    );

    const selectedProjectId = selectSelectedProjectId(getState());

    const allNewAssignmentsToCreate = [
      ...newProjectRootAssignments,
      ...newChildAssignments,
    ];

    const newAssignmentIdsToCreate = allNewAssignmentsToCreate.map(
      (assignment) => assignment.id
    );

    const modifiedAssignments = selectAssignmentModifications(getState());

    const existingAssignmentsToUpdate = Object.values(
      modifiedAssignments
    ).filter((assignment) => !newAssignmentIdsToCreate.includes(assignment.id));

    const createdAndModifiedAssignments = postCreateAndUpdateProjectAssignments(
      selectedProjectId,
      allNewAssignmentsToCreate,
      existingAssignmentsToUpdate
    );

    return createdAndModifiedAssignments;
  }
);
