import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { setCreateInvoiceSortRule } from '../services/setCreateInvoiceSortRule';

export const patchCreateInvoiceSortRule = createAsyncThunk(
  'invoices/patchCreateInvoiceSortRule',
  async ({ rule }, { getState, dispatch }) => {
    console.log('PATCH rule', rule);
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const ruleWithMainProject = {
      ...rule,
      mainProject: mainProjectId,
    };

    const response = await setCreateInvoiceSortRule(
      mainProjectId,
      ruleWithMainProject
    );
    console.log('response', response);
    return response;
  }
);
