import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { postGetUsersAndUserResources } from '../services/getUsersAndUserResources.service';

export const loadUsersAndPersonalResources = createAsyncThunk(
  'users/loadUsersAndPersonalResources',
  async (resources) => {
    const uniqueUserIds = uniq(resources.map((resource) => resource.user));

    const response = await postGetUsersAndUserResources(uniqueUserIds);
    return response;
  }
);
