import { createAsyncThunk } from '@reduxjs/toolkit';
import { RECORD_STATUS } from 'LixaniAPI/enums';
import moment from 'moment';
import 'moment-timezone';
import postRecord from '../services/postRecord';

export const createAbsenceRecordsByTimeframe = createAsyncThunk(
  'recordDialog/createAbsenceRecordsByTimeframe',
  async ({
    type,
    startDateUtc,
    endDateUtc,
    projectId,
    resourceId,
    mirrorUser,
  }) => {
    const recordsToCreate = [];

    // timezone defined to set records always on the same time-range. regardless of the local timezone.
    const startDate = moment.tz(startDateUtc, 'Europe/Helsinki');
    const endDate = moment.tz(endDateUtc, 'Europe/Helsinki');

    let currentDate = startDate;

    while (currentDate.isSameOrBefore(endDate)) {
      const clockIn = currentDate.clone();

      clockIn.set('hour', 7);

      const clockOut = clockIn.clone();
      clockOut.add(8.5, 'hours');

      const clockInUtc = clockIn.toISOString();
      const clockOutUtc = clockOut.toISOString();

      const recordToCreate = {
        clockInAt: clockInUtc,
        clockOutAt: clockOutUtc,
        billedClockInAt: clockInUtc,
        billedClockOutAt: clockOutUtc,
        type: type,
        project: projectId,
        resource: resourceId,
        mirrorUser: mirrorUser,
        status: RECORD_STATUS.OUT,
      };

      recordsToCreate.push(recordToCreate);

      currentDate.add(1, 'day');
    }

    const createdRecords = await Promise.all(
      recordsToCreate.map((recordToCreate) => postRecord(recordToCreate))
    );

    return createdRecords;
  }
);
