import { createAction } from '@reduxjs/toolkit';

export const openRemoveRoleFromUserDialog = createAction(
  'permissions/openRemoveRoleFromUserDialog'
);

export const closeRemoveRoleFromUserDialog = createAction(
  'permissions/closeRemoveRoleFromUserDialog'
);

export const openCreateNewRoleDialog = createAction(
  'permissions/openCreateNewRoleDialog'
);

export const closeCreateNewRoleDialog = createAction(
  'permissions/closeCreateNewRoleDialog'
);

export const openProjectRoleChangeHistoryDialog = createAction(
  'permissions/openProjectRoleChangeHistoryDialog'
);

export const closeProjectRoleChangeHistoryDialog = createAction(
  'permissions/closeProjectRoleChangeHistoryDialog'
);

export const setProjectRoleIdToDelete = createAction(
  'permissions/setProjectRoleIdToDelete'
);

export const closeDeleteProjectRoleConfirmationDialog = createAction(
  'permissions/closeDeleteProjectRoleConfirmationDialog'
);

export const openEditSingleUserPermissionsDialog = createAction(
  'permissions/openEditSingleUserPermissionsDialog'
);

export const closeEditSingleUserPermissionsDialog = createAction(
  'permissions/closeEditSingleUserPermissionsDialog'
);

export const openConfirmAdminModifyDialog = createAction(
  'permissions/openConfirmAdminModifyDialog'
);

export const closeConfirmAdminModifyDialog = createAction(
  'permissions/closeConfirmAdminModifyDialog'
);

export const openManageBusinessAdminsDialog = createAction(
  'permissions/openManageBusinessAdminsDialog'
);

export const closeManageBusinessAdminsDialog = createAction(
  'permissions/closeManageBusinessAdminsDialog'
);
