import { createAsyncThunk } from '@reduxjs/toolkit';
import Config from 'Config';
import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';

export const removeProjectToMergeFrom = createAsyncThunk(
  'projects/removeProjectToMergeFrom',
  async (projectKeyToRemove, { getState }) => {
    const state = getState();

    const selectedProjectId = state?.root?.project?.id;

    if (!selectedProjectId) {
      throw new Error('No project selected');
    }

    const apiPath = `projects/${selectedProjectId}/remove-project-to-merge-from`;

    const request = {
      //url: `http://localhost:3001/${apiPath}`,
      url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
      host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
      path: apiPath,
      method: 'PATCH',
      region: Config.AWS_REGION,
      service: 'execute-api',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectKeyToRemove }),
      data: { projectKeyToRemove },
    };
    const signedRequest = await createSignedRequest(request);

    const response = await axios(signedRequest);

    return response.data;
  }
);
