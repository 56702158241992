import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveProjectAssignments } from '../services/getActiveProjectAssignments';

// return only assignments not marked as deleted
export const loadActiveAssignmentsForProject = createAsyncThunk(
  'assignments/loadActiveAssignmentsForProject',
  async (projectId) => {
    const projectAssignments = await getActiveProjectAssignments(projectId);
    return projectAssignments;
  }
);
