import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DateTime from 'react-datetime';
import TextInput from 'components/TextInput/TextInput';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import './react-datetime_changes.css';

const useStyles = makeStyles((theme) => ({
  textInput: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
}));

function DateTimePicker(props) {
  const { value, inputProps, onChange, onOpen, disabled = false, onBlur, ...other } = props;
  const classes = useStyles();
  const { i18n } = useTranslation();

  let formatDate = () => {
    if (props.type === 'date') {
      return 'YYYY-MM-DD';
    } else if (props.type === 'time') {
      return 'HH:mm';
    } else {
      return 'YYYY-MM-DDTHH:mm';
    }
  };

  let dateFormat = formatDate();

  /**
   * Used to change value of the date picker
   * @param {event} e - event
   * @returns - returns the value to the parent component via onChange prop function if it exists
   */
  function handleOnChange(e) {
    const { value } = e.target;
    if (value) {
      if (onChange) {
        if (props.type === 'time') {
          onChange(moment(moment(value, 'HH:mm'), "YYYY-MM-DDTHH:mm"))
        } else {
          onChange(moment(value))
        }
      }
    }
  };

  /**
   * Used to save date picker value on blur
   * @param {event} e - event
   * @returns - returns the value to the parent component via onBlur prop function if it exists
   */
  function handleOnBlur(e) {
    const { value } = e.target;
    if (onBlur) {
      if (props.type === 'time') {
        onBlur(moment(moment(value, 'HH:mm'), "YYYY-MM-DDTHH:mm"))
      } else {
        onBlur(moment(value))
      }
    }
  };

  return (
    <>
      <TextInput
        value={value ? value.format(dateFormat) : null}
        onChange={handleOnChange}
        label={inputProps.label}
        helperText={inputProps.helperText}
        InputLabelProps={{ shrink: true }}
        InputProps={{ className: classes.textInput }}
        fullWidth
        shrink={true}
        type={props.type}
        required={!!props.required ? props.required : false}
        disabled={disabled}
        onBlur={handleOnBlur}
        adornment={inputProps.adornment}
      />
    </>
  );
}

DateTimePicker.defaultProps = {
  onOpen: () => { },
};

DateTimePicker.propTypes = {
  inputProps: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  value: PropTypes.object,
  type: PropTypes.string,
};

export default DateTimePicker;
