import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProjectInvoices } from '../services/getProjectInvoices';
import { getProjectInvoicesByDate } from '../services/getProjectInvoicesByDate';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import * as actions from '../actions/invoices.actions';

export const loadProjectInvoices = createAsyncThunk(
  'invoices/loadProjectInvoices',
  async (params, { getState, dispatch }) => {
    const state = getState();

    const { fromDate, toDate } = params;

    const selectedProjectId = selectSelectedProjectId(state);

    let projectInvoices = [];

    let response;

    do {
      if (fromDate && toDate) {
        response = await getProjectInvoicesByDate(
          selectedProjectId,
          fromDate,
          toDate,
          response?.LastEvaluatedKey
        );
        projectInvoices.push(...response.Items);
      } else {
        response = await getProjectInvoices(
          selectedProjectId,
          response?.LastEvaluatedKey
        );
        projectInvoices.push(...response.Items);
      }

      dispatch(actions.setInvoiceLoadedAmount(projectInvoices.length));
    } while (response.LastEvaluatedKey);

    return projectInvoices;
  }
);
