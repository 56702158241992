import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/TextInput/TextInput';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function CountrySelect(props) {
  const { t } = useTranslation('countries');

  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  const countries = [
    {
      code: 'AD',
      label: t('countries:countries.AD'),
      phone: '376',
      value: 'testi',
    },
    { code: 'AE', label: t('countries:countries.AE'), phone: '971' },
    { code: 'AF', label: t('countries:countries.AF'), phone: '93' },
    { code: 'AG', label: t('countries:countries.AG'), phone: '1-268' },
    { code: 'AI', label: t('countries:countries.AI'), phone: '1-264' },
    { code: 'AL', label: t('countries:countries.AL'), phone: '355' },
    { code: 'AM', label: t('countries:countries.AM'), phone: '374' },
    { code: 'AO', label: t('countries:countries.AO'), phone: '244' },
    { code: 'AQ', label: t('countries:countries.AQ'), phone: '672' },
    { code: 'AR', label: t('countries:countries.AR'), phone: '54' },
    { code: 'AS', label: t('countries:countries.AS'), phone: '1-684' },
    { code: 'AT', label: t('countries:countries.AT'), phone: '43' },
    {
      code: 'AU',
      label: t('countries:countries.AU'),
      phone: '61',
      suggested: true,
    },
    { code: 'AW', label: t('countries:countries.AW'), phone: '297' },
    { code: 'AX', label: t('countries:countries.AX'), phone: '358' },
    { code: 'AZ', label: t('countries:countries.AZ'), phone: '994' },
    { code: 'BA', label: t('countries:countries.BA'), phone: '387' },
    { code: 'BB', label: t('countries:countries.BB'), phone: '1-246' },
    { code: 'BD', label: t('countries:countries.BD'), phone: '880' },
    { code: 'BE', label: t('countries:countries.BE'), phone: '32' },
    { code: 'BF', label: t('countries:countries.BF'), phone: '226' },
    { code: 'BG', label: t('countries:countries.BG'), phone: '359' },
    { code: 'BH', label: t('countries:countries.BH'), phone: '973' },
    { code: 'BI', label: t('countries:countries.BI'), phone: '257' },
    { code: 'BJ', label: t('countries:countries.BJ'), phone: '229' },
    { code: 'BL', label: t('countries:countries.BL'), phone: '590' },
    { code: 'BM', label: t('countries:countries.BM'), phone: '1-441' },
    { code: 'BN', label: t('countries:countries.BN'), phone: '673' },
    { code: 'BO', label: t('countries:countries.BO'), phone: '591' },
    { code: 'BR', label: t('countries:countries.BR'), phone: '55' },
    { code: 'BS', label: t('countries:countries.BS'), phone: '1-242' },
    { code: 'BT', label: t('countries:countries.BT'), phone: '975' },
    { code: 'BV', label: t('countries:countries.BV'), phone: '47' },
    { code: 'BW', label: t('countries:countries.BW'), phone: '267' },
    { code: 'BY', label: t('countries:countries.BY'), phone: '375' },
    { code: 'BZ', label: t('countries:countries.BZ'), phone: '501' },
    {
      code: 'CA',
      label: t('countries:countries.CA'),
      phone: '1',
      suggested: true,
    },
    { code: 'CC', label: t('countries:countries.CC'), phone: '61' },
    { code: 'CD', label: t('countries:countries.CD'), phone: '243' },
    { code: 'CF', label: t('countries:countries.CF'), phone: '236' },
    { code: 'CG', label: t('countries:countries.CG'), phone: '242' },
    { code: 'CH', label: t('countries:countries.CH'), phone: '41' },
    { code: 'CI', label: t('countries:countries.CI'), phone: '225' },
    { code: 'CK', label: t('countries:countries.CK'), phone: '682' },
    { code: 'CL', label: t('countries:countries.CL'), phone: '56' },
    { code: 'CM', label: t('countries:countries.CM'), phone: '237' },
    { code: 'CN', label: t('countries:countries.CN'), phone: '86' },
    { code: 'CO', label: t('countries:countries.CO'), phone: '57' },
    { code: 'CR', label: t('countries:countries.CR'), phone: '506' },
    { code: 'CU', label: t('countries:countries.CU'), phone: '53' },
    { code: 'CV', label: t('countries:countries.CV'), phone: '238' },
    { code: 'CW', label: t('countries:countries.CW'), phone: '599' },
    { code: 'CX', label: t('countries:countries.CX'), phone: '61' },
    { code: 'CY', label: t('countries:countries.CY'), phone: '357' },
    { code: 'CZ', label: t('countries:countries.CZ'), phone: '420' },
    {
      code: 'DE',
      label: t('countries:countries.DE'),
      phone: '49',
      suggested: true,
    },
    { code: 'DJ', label: t('countries:countries.DJ'), phone: '253' },
    { code: 'DK', label: t('countries:countries.DK'), phone: '45' },
    { code: 'DM', label: t('countries:countries.DM'), phone: '1-767' },
    { code: 'DO', label: t('countries:countries.DO'), phone: '1-809' },
    { code: 'DZ', label: t('countries:countries.DZ'), phone: '213' },
    { code: 'EC', label: t('countries:countries.EC'), phone: '593' },
    { code: 'EE', label: t('countries:countries.EE'), phone: '372' },
    { code: 'EG', label: t('countries:countries.EG'), phone: '20' },
    { code: 'EH', label: t('countries:countries.EH'), phone: '212' },
    { code: 'ER', label: t('countries:countries.ER'), phone: '291' },
    { code: 'ES', label: t('countries:countries.ES'), phone: '34' },
    { code: 'ET', label: t('countries:countries.ET'), phone: '251' },
    { code: 'FI', label: t('countries:countries.FI'), phone: '358' },
    { code: 'FJ', label: t('countries:countries.FJ'), phone: '679' },
    { code: 'FK', label: t('countries:countries.FK'), phone: '500' },
    { code: 'FM', label: t('countries:countries.FM'), phone: '691' },
    { code: 'FO', label: t('countries:countries.FO'), phone: '298' },
    {
      code: 'FR',
      label: t('countries:countries.FR'),
      phone: '33',
      suggested: true,
    },
    { code: 'GA', label: t('countries:countries.GA'), phone: '241' },
    { code: 'GB', label: t('countries:countries.GB'), phone: '44' },
    { code: 'GD', label: t('countries:countries.GD'), phone: '1-473' },
    { code: 'GE', label: t('countries:countries.GE'), phone: '995' },
    { code: 'GF', label: t('countries:countries.GF'), phone: '594' },
    { code: 'GG', label: t('countries:countries.GG'), phone: '44' },
    { code: 'GH', label: t('countries:countries.GH'), phone: '233' },
    { code: 'GI', label: t('countries:countries.GI'), phone: '350' },
    { code: 'GL', label: t('countries:countries.GL'), phone: '299' },
    { code: 'GM', label: t('countries:countries.GM'), phone: '220' },
    { code: 'GN', label: t('countries:countries.GN'), phone: '224' },
    { code: 'GP', label: t('countries:countries.GP'), phone: '590' },
    { code: 'GQ', label: t('countries:countries.GQ'), phone: '240' },
    { code: 'GR', label: t('countries:countries.GR'), phone: '30' },
    { code: 'GS', label: t('countries:countries.GS'), phone: '500' },
    { code: 'GT', label: t('countries:countries.GT'), phone: '502' },
    { code: 'GU', label: t('countries:countries.GU'), phone: '1-671' },
    { code: 'GW', label: t('countries:countries.GW'), phone: '245' },
    { code: 'GY', label: t('countries:countries.GY'), phone: '592' },
    { code: 'HK', label: t('countries:countries.HK'), phone: '852' },
    { code: 'HM', label: t('countries:countries.HM'), phone: '672' },
    { code: 'HN', label: t('countries:countries.HN'), phone: '504' },
    { code: 'HR', label: t('countries:countries.HR'), phone: '385' },
    { code: 'HT', label: t('countries:countries.HT'), phone: '509' },
    { code: 'HU', label: t('countries:countries.HU'), phone: '36' },
    { code: 'ID', label: t('countries:countries.ID'), phone: '62' },
    { code: 'IE', label: t('countries:countries.IE'), phone: '353' },
    { code: 'IL', label: t('countries:countries.IL'), phone: '972' },
    { code: 'IM', label: t('countries:countries.IM'), phone: '44' },
    { code: 'IN', label: t('countries:countries.IN'), phone: '91' },
    { code: 'IO', label: t('countries:countries.IO'), phone: '246' },
    { code: 'IQ', label: t('countries:countries.IQ'), phone: '964' },
    { code: 'IR', label: t('countries:countries.IR'), phone: '98' },
    { code: 'IS', label: t('countries:countries.IS'), phone: '354' },
    { code: 'IT', label: t('countries:countries.IT'), phone: '39' },
    { code: 'JE', label: t('countries:countries.JE'), phone: '44' },
    { code: 'JM', label: t('countries:countries.JM'), phone: '1-876' },
    { code: 'JO', label: t('countries:countries.JO'), phone: '962' },
    {
      code: 'JP',
      label: t('countries:countries.JP'),
      phone: '81',
      suggested: true,
    },
    { code: 'KE', label: t('countries:countries.KE'), phone: '254' },
    { code: 'KG', label: t('countries:countries.KG'), phone: '996' },
    { code: 'KH', label: t('countries:countries.KH'), phone: '855' },
    { code: 'KI', label: t('countries:countries.KI'), phone: '686' },
    { code: 'KM', label: t('countries:countries.KM'), phone: '269' },
    { code: 'KN', label: t('countries:countries.KN'), phone: '1-869' },
    { code: 'KP', label: t('countries:countries.KP'), phone: '850' },
    { code: 'KR', label: t('countries:countries.KR'), phone: '82' },
    { code: 'KW', label: t('countries:countries.KW'), phone: '965' },
    { code: 'KY', label: t('countries:countries.KY'), phone: '1-345' },
    { code: 'KZ', label: t('countries:countries.KZ'), phone: '7' },
    { code: 'LA', label: t('countries:countries.LA'), phone: '856' },
    { code: 'LB', label: t('countries:countries.LB'), phone: '961' },
    { code: 'LC', label: t('countries:countries.LC'), phone: '1-758' },
    { code: 'LI', label: t('countries:countries.LI'), phone: '423' },
    { code: 'LK', label: t('countries:countries.LK'), phone: '94' },
    { code: 'LR', label: t('countries:countries.LR'), phone: '231' },
    { code: 'LS', label: t('countries:countries.LS'), phone: '266' },
    { code: 'LT', label: t('countries:countries.LT'), phone: '370' },
    { code: 'LU', label: t('countries:countries.LU'), phone: '352' },
    { code: 'LV', label: t('countries:countries.LV'), phone: '371' },
    { code: 'LY', label: t('countries:countries.LY'), phone: '218' },
    { code: 'MA', label: t('countries:countries.MA'), phone: '212' },
    { code: 'MC', label: t('countries:countries.MC'), phone: '377' },
    { code: 'MD', label: t('countries:countries.MD'), phone: '373' },
    { code: 'ME', label: t('countries:countries.ME'), phone: '382' },
    { code: 'MF', label: t('countries:countries.MF'), phone: '590' },
    { code: 'MG', label: t('countries:countries.MG'), phone: '261' },
    { code: 'MH', label: t('countries:countries.MH'), phone: '692' },
    { code: 'MK', label: t('countries:countries.MK'), phone: '389' },
    { code: 'ML', label: t('countries:countries.ML'), phone: '223' },
    { code: 'MM', label: t('countries:countries.MM'), phone: '95' },
    { code: 'MN', label: t('countries:countries.MN'), phone: '976' },
    { code: 'MO', label: t('countries:countries.MO'), phone: '853' },
    { code: 'MP', label: t('countries:countries.MP'), phone: '1-670' },
    { code: 'MQ', label: t('countries:countries.MQ'), phone: '596' },
    { code: 'MR', label: t('countries:countries.MR'), phone: '222' },
    { code: 'MS', label: t('countries:countries.MS'), phone: '1-664' },
    { code: 'MT', label: t('countries:countries.MT'), phone: '356' },
    { code: 'MU', label: t('countries:countries.MU'), phone: '230' },
    { code: 'MV', label: t('countries:countries.MV'), phone: '960' },
    { code: 'MW', label: t('countries:countries.MW'), phone: '265' },
    { code: 'MX', label: t('countries:countries.MX'), phone: '52' },
    { code: 'MY', label: t('countries:countries.MY'), phone: '60' },
    { code: 'MZ', label: t('countries:countries.MZ'), phone: '258' },
    { code: 'NA', label: t('countries:countries.NA'), phone: '264' },
    { code: 'NC', label: t('countries:countries.NC'), phone: '687' },
    { code: 'NE', label: t('countries:countries.NE'), phone: '227' },
    { code: 'NF', label: t('countries:countries.NF'), phone: '672' },
    { code: 'NG', label: t('countries:countries.NG'), phone: '234' },
    { code: 'NI', label: t('countries:countries.NI'), phone: '505' },
    { code: 'NL', label: t('countries:countries.NL'), phone: '31' },
    { code: 'NO', label: t('countries:countries.NO'), phone: '47' },
    { code: 'NP', label: t('countries:countries.NP'), phone: '977' },
    { code: 'NR', label: t('countries:countries.NR'), phone: '674' },
    { code: 'NU', label: t('countries:countries.NU'), phone: '683' },
    { code: 'NZ', label: t('countries:countries.NZ'), phone: '64' },
    { code: 'OM', label: t('countries:countries.OM'), phone: '968' },
    { code: 'PA', label: t('countries:countries.PA'), phone: '507' },
    { code: 'PE', label: t('countries:countries.PE'), phone: '51' },
    { code: 'PF', label: t('countries:countries.PF'), phone: '689' },
    { code: 'PG', label: t('countries:countries.PG'), phone: '675' },
    { code: 'PH', label: t('countries:countries.PH'), phone: '63' },
    { code: 'PK', label: t('countries:countries.PK'), phone: '92' },
    { code: 'PL', label: t('countries:countries.PL'), phone: '48' },
    { code: 'PM', label: t('countries:countries.PM'), phone: '508' },
    { code: 'PN', label: t('countries:countries.PN'), phone: '870' },
    { code: 'PR', label: t('countries:countries.PR'), phone: '1' },
    { code: 'PS', label: t('countries:countries.PS'), phone: '970' },
    { code: 'PT', label: t('countries:countries.PT'), phone: '351' },
    { code: 'PW', label: t('countries:countries.PW'), phone: '680' },
    { code: 'PY', label: t('countries:countries.PY'), phone: '595' },
    { code: 'QA', label: t('countries:countries.QA'), phone: '974' },
    { code: 'RE', label: t('countries:countries.RE'), phone: '262' },
    { code: 'RO', label: t('countries:countries.RO'), phone: '40' },
    { code: 'RS', label: t('countries:countries.RS'), phone: '381' },
    { code: 'RU', label: t('countries:countries.RU'), phone: '7' },
    { code: 'RW', label: t('countries:countries.RW'), phone: '250' },
    { code: 'SA', label: t('countries:countries.SA'), phone: '966' },
    { code: 'SB', label: t('countries:countries.SB'), phone: '677' },
    { code: 'SC', label: t('countries:countries.SC'), phone: '248' },
    { code: 'SD', label: t('countries:countries.SD'), phone: '249' },
    { code: 'SE', label: t('countries:countries.SE'), phone: '46' },
    { code: 'SG', label: t('countries:countries.SG'), phone: '65' },
    { code: 'SH', label: t('countries:countries.SH'), phone: '290' },
    { code: 'SI', label: t('countries:countries.SI'), phone: '386' },
    { code: 'SJ', label: t('countries:countries.SJ'), phone: '47' },
    { code: 'SK', label: t('countries:countries.SK'), phone: '421' },
    { code: 'SL', label: t('countries:countries.SL'), phone: '232' },
    { code: 'SM', label: t('countries:countries.SM'), phone: '378' },
    { code: 'SN', label: t('countries:countries.SN'), phone: '221' },
    { code: 'SO', label: t('countries:countries.SO'), phone: '252' },
    { code: 'SR', label: t('countries:countries.SR'), phone: '597' },
    { code: 'SS', label: t('countries:countries.SS'), phone: '211' },
    { code: 'ST', label: t('countries:countries.ST'), phone: '239' },
    { code: 'SV', label: t('countries:countries.SV'), phone: '503' },
    { code: 'SX', label: t('countries:countries.SX'), phone: '1-721' },
    { code: 'SY', label: t('countries:countries.SY'), phone: '963' },
    { code: 'SZ', label: t('countries:countries.SZ'), phone: '268' },
    { code: 'TC', label: t('countries:countries.TC'), phone: '1-649' },
    { code: 'TD', label: t('countries:countries.TD'), phone: '235' },
    { code: 'TF', label: t('countries:countries.TF'), phone: '262' },
    { code: 'TG', label: t('countries:countries.TG'), phone: '228' },
    { code: 'TH', label: t('countries:countries.TH'), phone: '66' },
    { code: 'TJ', label: t('countries:countries.TJ'), phone: '992' },
    { code: 'TK', label: t('countries:countries.TK'), phone: '690' },
    { code: 'TL', label: t('countries:countries.TL'), phone: '670' },
    { code: 'TM', label: t('countries:countries.TM'), phone: '993' },
    { code: 'TN', label: t('countries:countries.TN'), phone: '216' },
    { code: 'TO', label: t('countries:countries.TO'), phone: '676' },
    { code: 'TR', label: t('countries:countries.TR'), phone: '90' },
    { code: 'TT', label: t('countries:countries.TT'), phone: '1-868' },
    { code: 'TV', label: t('countries:countries.TV'), phone: '688' },
    { code: 'TW', label: t('countries:countries.TW'), phone: '886' },
    { code: 'TZ', label: t('countries:countries.TZ'), phone: '255' },
    { code: 'UA', label: t('countries:countries.UA'), phone: '380' },
    { code: 'UG', label: t('countries:countries.UG'), phone: '256' },
    {
      code: 'US',
      label: t('countries:countries.US'),
      phone: '1',
      suggested: true,
    },
    { code: 'UY', label: t('countries:countries.UY'), phone: '598' },
    { code: 'UZ', label: t('countries:countries.UZ'), phone: '998' },
    { code: 'VA', label: t('countries:countries.VA'), phone: '379' },
    { code: 'VC', label: t('countries:countries.VC'), phone: '1-784' },
    { code: 'VE', label: t('countries:countries.VE'), phone: '58' },
    { code: 'VG', label: t('countries:countries.VG'), phone: '1-284' },
    { code: 'VI', label: t('countries:countries.VI'), phone: '1-340' },
    { code: 'VN', label: t('countries:countries.VN'), phone: '84' },
    { code: 'VU', label: t('countries:countries.VU'), phone: '678' },
    { code: 'WF', label: t('countries:countries.WF'), phone: '681' },
    { code: 'WS', label: t('countries:countries.WS'), phone: '685' },
    { code: 'XK', label: t('countries:countries.XK'), phone: '383' },
    { code: 'YE', label: t('countries:countries.YE'), phone: '967' },
    { code: 'YT', label: t('countries:countries.YT'), phone: '262' },
    { code: 'ZA', label: t('countries:countries.ZA'), phone: '27' },
    { code: 'ZM', label: t('countries:countries.ZM'), phone: '260' },
    { code: 'ZW', label: t('countries:countries.ZW'), phone: '263' },
  ];

  const { changeCountry, propsCountry, label, ...restProps } = props;
  let valueObject = propsCountry
    ? countries.find((item) => item.code === propsCountry)
    : countries.find((item) => item.code === 'FI');
  const [country, setCountry] = useState(valueObject);
  const classes = useStyles();

  const handleCountryChange = (event, newValue) => {
    changeCountry(newValue ? newValue.code : null);
    setCountry(newValue);
  };

  useEffect(() => {
    setCountry(countries.find((item) => item.code === propsCountry))
  }, [propsCountry])

  return (
    <Autocomplete
      id="country-select-demo"
      options={countries}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => t('countries.' + option.code)}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {t('countries.' + option.code)} ({option.code})
        </React.Fragment>
      )}
      value={country}
      onChange={(event, newValue) => handleCountryChange(event, newValue)}
      renderInput={(params) => (
        <TextInput
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      {...restProps}
    />
  );
}

CountrySelect.propTypes = {
  changeCountry: PropTypes.func.isRequired,
  propsCountry: PropTypes.string,
  label: PropTypes.string,
};
