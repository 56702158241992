import { mdiAlertCircle, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Button from 'components/Button/Button';
import { COMPETENCY_CARD_TYPES } from 'constants/competency-card-types';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { dismissNotification } from 'store/notifications/thunks/dismissNotification.thunk';

export const ExpiringCardsNotification = ({
  expiringCards,
  cardsValidUntil,
  notificationId,
}) => {
  const [showNotification, setShowNotification] = useState(true);

  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  return showNotification ? (
    <div
      style={{
        backgroundColor: '#ff9800',
        opacity: '0.9',
        borderRadius: '2px',
        padding: '10px 5px 5px 5px',
        position: 'relative',
      }}
    >
      <Button
        onClick={() => setShowNotification(false)}
        style={{ position: 'absolute', top: 0, right: '8px', padding: 0 }}
      >
        <Icon path={mdiClose} size={1}></Icon>
      </Button>
      <div style={{ display: 'flex' }}>
        <Icon path={mdiAlertCircle} size={1}></Icon>
        <h3 style={{ margin: 0, opacity: 1 }}>
          {t('cards-and-competencies.notify_expiring_cards')}{' '}
          {moment(cardsValidUntil).format('l')}:
        </h3>
      </div>
      <div style={{ paddingLeft: '40px' }}>
        {expiringCards.map((card) => (
          <div key={card.id} style={{ opacity: 1 }}>
            {t(
              `cards-and-competencies.${COMPETENCY_CARD_TYPES[card.type].name}`
            )}
          </div>
        ))}
      </div>
      <div style={{ width: '100%', textAlign: 'right' }}>
        <Button
          elevated
          onClick={() => dispatch(dismissNotification(notificationId))}
        >
          {t('cards-and-competencies.clear_notification')}
        </Button>
      </div>
    </div>
  ) : null;
};
