import { createAsyncThunk } from '@reduxjs/toolkit';
import postUserJoinProject from '../services/postUserJoinProject.service';

export const userJoinProject = createAsyncThunk(
  'projects/userJoinProject',
  async (joinData) => {
    const projectJoinData = await postUserJoinProject(joinData);

    return projectJoinData;
  }
);
