import React, { useEffect } from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { LixaniFiles } from 'lixani-lib-graphql/lib/LixaniFiles';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

import { setProfileImageSrc } from 'store/actions/setProfileImageSrc';

const useStyles = makeStyles(() => ({
  root: {},
}));

function NavItemsProfileImage(props) {
  const { fileObject } = props;
  const profileImageSrc = useSelector((state) => state.root.profileImageSrc);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    async function requestFileEffect() {
      const { requestFile, user } = props;
      if (fileObject && fileObject.key) {
        const imageUrl = await requestFile(fileObject.key, user);
        if (imageUrl) dispatch(setProfileImageSrc(imageUrl));
      }
    }
    if (!profileImageSrc || profileImageSrc === Config.UNKNOWN_PROFILE_URL) {
      requestFileEffect();
    }

    return () => {};
  }, [fileObject]);

  return (
    <Avatar
      alt={`${props.resource.firstName} ${props.resource.lastName}`}
      className={classes.root}
      src={profileImageSrc}
    />
  );
}

NavItemsProfileImage.propTypes = {
  fileObject: PropTypes.object,
  requestFile: PropTypes.func,
  resource: PropTypes.object,
  user: PropTypes.object,
};

export default LixaniFiles(NavItemsProfileImage);
