import Button from 'components/Button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openAddNewUserDialog } from 'store/projectUsers/actions/project-users.actions';

const OpenAddUserDialogButton = () => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => dispatch(openAddNewUserDialog())}
    >
      {t('add-user-dialog.add-new-user')}
    </Button>
  );
};

export default OpenAddUserDialogButton;
