const getFileExtension = (filename) => {
  let extension = '';
  try {
    const nameParts = filename.split('.');
    extension = nameParts.pop();
  } catch {
    extension = '';
  }
  return extension;
};

export default getFileExtension;
