import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setEditBulletin from 'scenes/BulletinBoard/utils/setEditBulletin';

export const editBulletin = createAsyncThunk(
  'bulletinBoard/editBulletin',
  async (bulletin, { getState }) => {
    console.log("🚀 ~ editBulletin bulletin:", bulletin)
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const editedBulletin = await setEditBulletin(bulletin, params);
    return { editedBulletin };
  }
);
