import { createAction } from '@reduxjs/toolkit';

export const updateEmployerAllowanceDraft = createAction(
  'employerAllowances/updateDraft'
);
export const removeEmployerAllowanceDraft = createAction(
  'employerAllowances/removeDraft'
);
export const clearAllEmployerAllowanceDrafts = createAction(
  'employerAllowances/clearAllEmployerAllowanceDrafts'
);
