import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectRoles } from '../../selectors/permissionsSelectors';

export const assignDefaultExternalRole = createAsyncThunk(
  'permissionsManagement/assignDefaultExternalRole',
  async (roleId, { getState }) => {
    const projectRoles = selectSelectedProjectRoles(getState());

    const assignedRole = projectRoles.find((role) => role.roleId === roleId);

    const assignedDefaultRoleItem = {
      PK: `PROJECT#${assignedRole.projectId}`,
      SK: 'DEFAULT_ROLE_EXTERNAL',
      roleId,
      projectId: assignedRole.projectId,
    };

    return assignedDefaultRoleItem;
  }
);
