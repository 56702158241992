import { createTheme } from '@material-ui/core/styles';
import { baseTheme, pantonFonts } from './common';
import { BackgroundDarkJpg } from 'lixani-lib-data';

const defaultTextColor = 'rgba(255,255,255,1)';
const defaultBackgroundColor = '#147ba4';
const pantonCommon = {
  color: defaultTextColor,
  fontSize: '1rem',
  lineHeight: 1,
};
const textColors = {
  primary: 'rgba(255,255,255,1)',
  secondary: 'rgba(255,255,255,0.87)',
  tertiary: 'rgba(255,255,255,0.75)',
  caption: 'rgba(255,255,255,0.54)',
  disabled: 'rgba(255,255,255,0.38)',
  hint: 'rgba(255,255,255,0.38)',
};
const textWhiteColors = {
  primary: 'rgba(255,255,255,1)',
  secondary: 'rgba(255,255,255,0.87)',
  tertiary: 'rgba(255,255,255,0.75)',
  caption: 'rgba(255,255,255,0.54)',
  disabled: 'rgba(255,255,255,0.38)',
  hint: 'rgba(255,255,255,0.38)',
};
const textBlackColors = {
  primary: 'rgba(0,0,0,1)',
  secondary: 'rgba(0,0,0,0.87)',
  tertiary: 'rgba(0,0,0,0.75)',
  caption: 'rgba(0,0,0,0.54)',
  disabled: 'rgba(0,0,0,0.38)',
  hint: 'rgba(0,0,0,0.38)',
};
const scheme = {
  main: '#147ba4',
  darker: '#0b7197',
  dark: '#005a7f',
  lighter: '#1687af',
  light: '#1687af',
  lightest: '#21a8e0',
};

const classicTheme = createTheme({
  name: 'classic',
  typography: {
    fontFamily: pantonFonts.panton,
    fontPanton: {
      ...pantonCommon,
      fontFamily: pantonFonts.panton,
    },
    fontPantonLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonLight,
    },
    fontPantonExtraLight: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonExtraLight,
    },
    fontPantonSemiBold: {
      ...pantonCommon,
      fontFamily: pantonFonts.pantonSemiBold,
    },
    h4: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    h5: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    body1: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
    },
    body2: {
      color: textColors.secondary,
      fontFamily: pantonFonts.panton,
      fontSize: '0.875rem',
    },
    tertiary: {
      color: textColors.tertiary,
      fontFamily: pantonFonts.pantonLight,
    },
    caption: {
      color: textColors.caption,
      fontFamily: pantonFonts.pantonLight,
    },
  },
  palette: {
    borderColor: scheme.light,
    type: 'dark',
    text: {
      ...textColors,
    },
    textBlack: {
      ...textBlackColors,
    },
    textWhite: {
      ...textWhiteColors,
    },
    textInvert: {
      ...textBlackColors,
    },
    loading: {
      primary: scheme.main,
      secondary: scheme.lightest,
    },

    primary: {
      main: scheme.lighter,
      light: scheme.lightest,
      dark: scheme.dark,
      alt_main: scheme.main,
      gradient: 'linear-gradient(45deg, #1687af, #5bb7e1)',
    },
    danger: {
      main: '#b0340a',
      light: '#e96538',
      dark: '#7a0000',
    },
    trash: {
      main: 'rgba(176, 52, 10,0.5)',
      light: '#e96538',
      dark: '#7a0000',
    },
    buttonPrimary: scheme.lighter,
    buttonSecondary: scheme.lightest,
    buttonMain: scheme.main,
    buttonDark: scheme.dark,
    buttonDarker: scheme.darker,
    buttonLighter: scheme.lighter,
    buttonLight: scheme.light,
    buttonLightest: scheme.lightest,
    buttonHoverText: textWhiteColors.main,
  },
  component: {
    appbarToolbarRoot: {
      backgroundColor: scheme.lighter,
    },
    border: 'none',
    borderMust: `1px solid ${scheme.darker}`,
    borderDotted: `1px dotted ${scheme.dark}`,
    breadcrumbs: {
      background: scheme.darker,
      color: textColors.secondary,
    },
    buttonSwitchColorInitial: scheme.light,
    dashboardButtonColorDefault: {
      backgroundColor: scheme.lighter,
    },
    dashboardButtonColorSecondary: {
      backgroundColor: scheme.lightest,
    },
    dashboardButtonBackdrop: {
      borderRadius: 0,
    },
    dashboardButtonPaper: {
      alignContent: 'center',
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: baseTheme.spacing(1.5),
      minHeight: 100,
      width: '100%',
    },
    dashboardButtonIcon: {
      color: 'rgba(255,255,255,0.7)',
      fontSize: '2.5rem',
      lineHeight: '2.5rem',
      textAlign: 'center',
    },
    dashboardButtonPrimary: {
      fontFamily: pantonFonts.pantonLight,
    },
    dashboardButtonSecondary: {
      color: textColors.secondary,
      fontFamily: pantonFonts.pantonLight,
      minHeight: 22,
    },
    dashboardClockInListPaper: {
      backgroundColor: scheme.lighter,
      border: 'none',
      borderRadius: 0,
    },
    dashboardClockInCollapsed: {
      backgroundColor: scheme.main,
      borderRadius: 0,
    },
    dashboardPaper: {
      backgroundColor: scheme.lighter,
      border: 'none',
      //boxSizing: 'border-box',
      borderRadius: 0,
    },
    dashboardStatusTable: {
      color: textColors.primary,
    },
    dialogPaper: {
      background: defaultBackgroundColor,
      position: 'initial',
      overflow: 'initial',
    },
    dialogPaperDark: {
      background: scheme.dark,
      position: 'initial',
      overflow: 'initial',
    },
    dialogPaperFullscreen: {
      background: scheme.main,
    },
    dialogHeader: {
      background: 'linear-gradient(45deg, #1687af, #5bb7e1)',
    },
    dialogHeaderCloseButton: {
      borderLeft: `1px solid ${scheme.lighter}`,
      padding: baseTheme.spacing(1),
    },
    drawerPaper: {
      background: scheme.main,
    },
    drawerPaperMobile: {
      background: scheme.main,
    },
    drawerButton: {
      background: scheme.light,
      color: textColors.secondary,
    },
    imagePlaceholder: '/images/image_placeholder_white.png',
    videoPlaceholder: '/images/video_placeholder_white.png',
    infoContent: {
      background: 'rgba(20,123,164,0.5)',
    },
    navIcon: {
      color: textColors.tertiary,
    },
    navTab: {
      backgroundColor: scheme.dark,
    },
    navTabTitle: {},
    navTabActive: {
      backgroundColor: scheme.main,
    },
    projectClockButtonPaper: {
      backgroundColor: scheme.lightest,
      borderRadius: 0,
    },
    lightPaper: {
      backgroundColor: scheme.lightest,
    },
    commentMain: {
      background: '#1793bf',
    },
    commentSecondary: {
      backgroundColor: scheme.lightest,
    },
    projectsListButton: {
      backgroundColor: scheme.main,
      color: textColors.primary,
      fontFamily: pantonFonts.pantonLight,
    },
    projectsListButtonFirst: {
      color: textColors.tertiary,
      fontFamily: pantonFonts.panton,
    },
    projectsListButtonMiddle: {
      marginLeft: baseTheme.spacing(0.25),
    },
    projectsListButtonLast: {
      marginLeft: baseTheme.spacing(0.25),
    },
    projectsListButtonTitle: {
      fontFamily: pantonFonts.panton,
    },
    record: {
      color: textBlackColors.secondary,
    },
    recordBackface: {
      backgroundColor: 'rgb(0,0,0)',
    },
    recordWork: {
      background: 'rgba(0,0,0,0.1)',
    },
    recordProjectwork: {
      background: 'rgba(80,170,165,0.5)',
    },
    recordWorkActive: {
      background: 'hsla(120, 75%, 70%,0.5)',
    },
    recordBreak: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordBreak_coffee: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordBreak_meal: {
      background: 'hsla(0, 50%, 70%,0.5)',
    },
    recordShift: {
      background: 'hsla(30, 50%, 70%,0.5)',
    },
    recordAbsence: {
      background: 'hsla(210, 50%, 70%,0.5)',
    },
    recordBenefit: {
      background: 'hsla(90, 50%, 70%,0.5)',
    },
    recordRide: {
      background: 'hsla(180, 50%, 70%,0.5)',
    },
    recordNegative: {
      background: 'hsla(240, 50%, 70%,0.5)',
    },
    recordItem: {
      background: 'hsla(60, 50%, 70%,0.5)',
    },
    recordApproved: {
      background: 'hsla(137.4,99%,38.8%, 0.7)',
    },
    recordAbsence_sick_leave: {
      background: 'hsla(0,77.3%,91.4%, 0.5)',
    },
    recordAbsence_vacation_paid: {
      background: 'hsla(210, 50%, 70%,0.5)',
    },
    recordApprovePending: {
      background: 'hsla(60, 100%, 55%, 0.7)',
    },
    recordCheckMarkIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%', // Make the width 100%
      height: '100%', // Optionally, set the height to 100% to maintain aspect ratio
      color: '#FFFFFF',
      opacity: '0.4',
    },
    snackBar: {
      background: scheme.darker,
    },
    sideBadge: {
      border: `2px solid ${scheme.lighter}`,
    },
    workCalendarWeekPaper: {
      backgroundColor: '#FFFFFF',
    },
    workflowActionsViewtypeBar: {
      backgroundColor: scheme.dark,
    },
    workflowActionsViewTypeButton: {
      fontFamily: pantonFonts.panton,
      padding: baseTheme.spacing(2),
      backgroundColor: scheme.dark,
    },
    workflowActionsViewTypeButtonActive: {
      fontFamily: pantonFonts.panton,
      padding: baseTheme.spacing(2),
      backgroundColor: scheme.lighter,
    },
    workflowButton: {
      padding: baseTheme.spacing(1.5),
      marginRight: baseTheme.spacing(1),
      [baseTheme.breakpoints.down('xs')]: {
        padding: baseTheme.spacing(1),
        marginRight: 0,
      },
    },
    invoicePrimary: {
      backgroundColor: scheme.main,
    },
    invoiceSecondary: {
      backgroundColor: scheme.darker,
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#ccc',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#ccc',
        },
        '&$disabled': {
          color: 'darkgray',
          opacity: 0.7,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#fff',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          // backgroundColor: "#fff"
          backgroundColor: '#00ae2c',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          background: `url("data:image/jpg;base64,${BackgroundDarkJpg}") no-repeat center center fixed`,
          backgroundSize: 'cover',
          color: defaultTextColor,
          fontSmoothing: 'initial',
        },
        body: {
          background: 'none',
          backgroundColor: 'initial',
          color: defaultTextColor,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: scheme.lighter,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0',
      },
    },
    MuiButtonBase: {
      root: {
        ...pantonCommon,
        fontFamily: pantonFonts.panton,
      },
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#85aede',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#85aede',
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#85aede',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.87rem',
        minHeight: baseTheme.spacing(4),
      },
    },
  },
});

export default classicTheme;
