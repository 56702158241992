import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { postGenerateReportFromSelectedRecords } from 'scenes/WorkDemo/services/postGenerateReportFromSelectedRecords.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectSelectedRecordsIds } from 'store/records/selectors/selectedRecords.selectors';
import { selectReportGeneratedFromMode } from 'store/reports/selectors/reports.selectors';
import { selectWorkCalendarFilteredRecords } from '../../selectors/workSelectorsDemo';

export const generateReportFromSelectedRecords = createAsyncThunk(
  'workCalendar/generateReportFromSelectedRecords',
  async (dialogSettings, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const selectedRecordIds = selectSelectedRecordsIds(state);

    const reportGeneratedFromMode = selectReportGeneratedFromMode(state);

    const allWorkCalendarFilteredRecords =
      selectWorkCalendarFilteredRecords(state);

    const allWorkCalendarFilteredRecordsIds =
      allWorkCalendarFilteredRecords.map((record) => record.id);

    const recordIdsToGenerateReportFrom =
      reportGeneratedFromMode === 'SELECTED'
        ? selectedRecordIds
        : allWorkCalendarFilteredRecordsIds;

    const utcOffset = moment.parseZone(moment().format()).format('Z');

    const {
      selectedReportType,
      workStartTime,
      workEndTime,
      fromDate,
      toDate,
      language,
      filetype,
      workHourType,
    } = dialogSettings;

    const generationParams = {
      fromDate,
      toDate,
      selectedReportType,
      workStartTime,
      workEndTime,
      language,
      filetype,
      utcOffset,
      workHourType,
    };

    const report = await postGenerateReportFromSelectedRecords(
      selectedProjectId,
      recordIdsToGenerateReportFrom,
      generationParams
    );

    return report;
  }
);
