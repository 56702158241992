import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotificationsOfLoggedInUser } from '../services/getNotificationsOfLoggedInUser.service';

export const loadLoggedInUserNotifications = createAsyncThunk(
  'notifications/loadLoggedInUserNotifications',
  async () => {
    const response = await getNotificationsOfLoggedInUser();

    return response;
  }
);
