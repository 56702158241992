import { createSelector } from '@reduxjs/toolkit';

const selectUserProfileState = (state) => state.userProfile;

export const selectUserProfileUser = createSelector(
  [selectUserProfileState],
  (state) => state.user
);

export const selectUserProfileUserPersonalResource = createSelector(
  [selectUserProfileState],
  (state) => state.userPersonalResource
);

export const selectIsUserProfileLoading = createSelector(
  [selectUserProfileState],
  (state) => state.loading
);

export const selectUserProfileEmployerBusinessIds = createSelector(
  [selectUserProfileState],
  (state) => state.userEmployerBusinessIds
);

export const selectUserProfileEmployeeConfigurations = createSelector(
  [selectUserProfileState],
  (state) => Object.values(state.userEmployeeConfigurations)
);

export const selectUserEmployeeConfigurationOfEmployer = createSelector(
  [selectUserProfileEmployeeConfigurations, (_, businessId) => businessId],
  (configurations, businessId) => {
    const foundConfiguration = configurations.find(
      (configuration) => configuration.businessId === businessId
    );

    return foundConfiguration ? foundConfiguration : {};
  }
);
