import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    ...theme.component.dialogPaper,
  },
  btnCloseDialog: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    color: theme.palette.textBlack.primary,
    height: 24,
    width: 24,
    position: 'absolute',
    marginTop: '-30px',
    marginLeft: '28px',
    '&:active, &:hover': {
      backgroundColor: 'white',
    },
  },
  iconCloseDialog: {
    height: 16,
    width: 16,
  },
  dialogContent: {
    paddingTop: 8,
    paddingBottom: theme.spacing(3),
    //[theme.breakpoints.down('xs')]: {
    //	padding: '0 8px 8px 8px',
    //}
  },
  dialogActions: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  actionBtnDelete: {
    marginRight: 'auto',
  },
  gridItem: {
    //padding: '0 8px',
  },
  removeRecordDialogContent: {
    paddingTop: 20,
  },
  appBar: {
    boxShadow: 'none',
    marginBottom: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
  },
  altCloseBtn: {
    color: 'inherit',
    padding: 0,
  },
  menuItem: {
    //color: theme.palette.textBlack.primary,
    //padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    //fontSize: '0.87rem',
    //minHeight: theme.spacing(4)
  },
  datesContainer: {
    //padding: '0 8px',
  },
  inTime: {
    marginRight: '2%',
    float: 'right',
  },
  clockedIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5em',
    float: 'right',
  },
  dateItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .rdt': {
      flex: 1,
    },
  },
  clockedText: {
    //marginLeft: '10%',
    //marginTop: '1.5em',
  },
  hidden: {
    display: 'none',
  },
  menuPaper: {
    maxHeight: 200,
  },
  notJoined: {
    color: 'red',
  },
  leafletContainer: {
    height: '800px',
    width: '100%',
  },
}));

export default useStyles;
