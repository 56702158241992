import React from 'react';
import { useSelector } from 'react-redux';
import { selectRecordAllowancesOfSelectedRecord } from 'store/recordAllowances/selectors/record-and-employer-allowances.selectors';
import { RecordAllowancesList } from './RecordAllowancesList/RecordAllowancesList';
import { Grid } from '@material-ui/core';

export const RecordAllowances = () => {
  const selectedRecordAllowances = useSelector(
    selectRecordAllowancesOfSelectedRecord
  );

  return (
    <>
      <Grid item xs={12}>
        <label className="mylabel">
          {`${'Merkinnän lisät'} (${selectedRecordAllowances?.length || 0})`}{' '}
        </label>
      </Grid>
      {selectedRecordAllowances && selectedRecordAllowances.length > 0 ? (
        <RecordAllowancesList
          recordAllowances={selectedRecordAllowances}
        ></RecordAllowancesList>
      ) : null}
    </>
  );
};
