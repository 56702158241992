import { createAsyncThunk } from '@reduxjs/toolkit';

import { setCreateInvoiceSortingRule } from '../services/setCreateInvoiceSortingRule';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';

export const patchCreateInvoiceSortingRule = createAsyncThunk(
  'invoices/patchCreateInvoiceSortingRule',
  async ({ businessId, createData }, { getState, dispatch }) => {
    console.log('PATCH createData', createData);
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await setCreateInvoiceSortingRule(
      mainProjectId,
      businessId,
      createData
    );
    console.log('response', response);
    return response;
  }
);
