import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setFormNotes from 'scenes/ProjectForms/utils/setFormNotes';

export const setNotes = createAsyncThunk(
    'projectForm/setNotes',
    async (notes, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };
        const notesSet = await setFormNotes(notes, params);
        return { notesSet };
    }
);