import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserData } from 'scenes/UserProfile/services/getUserData.service';

export const loadUserData = createAsyncThunk(
  'users/loadUserData',
  async (userId) => {
    const userData = await getUserData(userId);

    return userData;
  }
);
