import { createSelector } from '@reduxjs/toolkit';
import { selectWorkCalendarRecordsArray } from 'store/records/selectors/records-base.selectors';
import { selectRecordAllowancesGroupedByRecordId } from './record-and-employer-allowances.selectors';
import { selectPersonalRecords } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';

export const selectWorkCalendarRecordsWithAllowanceIds = createSelector(
  [selectWorkCalendarRecordsArray, selectRecordAllowancesGroupedByRecordId],
  (records, recordAllowancesByRecordId) => {
    return records.map((record) => {
      const foundRecordAllowances = recordAllowancesByRecordId[record.id];

      if (foundRecordAllowances) {
        return { ...record, allowances: foundRecordAllowances };
      }
      return record;
    });
  }
);

export const selectPersonalRecordsWithAllowanceIds = createSelector(
  [selectPersonalRecords, selectRecordAllowancesGroupedByRecordId],
  (records, recordAllowancesByRecordId) => {
    return records.map((record) => {
      const foundRecordAllowances = recordAllowancesByRecordId[record.id];

      if (foundRecordAllowances) {
        return { ...record, allowances: foundRecordAllowances };
      }
      return record;
    });
  }
);

export const selectRecordAllowancesByRecordId = createSelector(
  [selectRecordAllowancesGroupedByRecordId, (_, args) => args],
  (recordAllowancesByRecordId, args) => recordAllowancesByRecordId[args]
);
