import { createAsyncThunk } from '@reduxjs/toolkit';
import { getValidCardsOfUser } from '../services/getValidCardsOfUser.service';

export const loadValidCardsOfUser = createAsyncThunk(
  'cards/loadValidCardsOfUser',
  async (userId) => {
    const response = await getValidCardsOfUser(userId);

    return response;
  }
);
