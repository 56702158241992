import aws4 from 'aws4';
import getCredentials from 'utils/getCredentials';

export const createSignedRequest = async (request, credentials) => {
  if (!credentials) credentials = await getCredentials();
  const { accessKeyId, secretAccessKey, sessionToken } = credentials;

  const signer = new aws4.RequestSigner(request, {
    accessKeyId,
    secretAccessKey,
    sessionToken,
  });
  const signedRequest = signer.sign();
  signedRequest.headers.Accept = '*/*';

  delete signedRequest.headers['Host'];
  delete signedRequest.headers['Content-Length'];

  return signedRequest;
};
