import { createSelector } from '@reduxjs/toolkit';

const selectUserState = (state) => state.user;

export const selectUser = createSelector(
  [selectUserState],
  (state) => state.user
);

export const selectUserLoaded = createSelector(
  [selectUserState],
  (state) => state.userLoaded
);

export const selectUserSettings = createSelector(
  [selectUserState],
  (state) => state.settings
);

export const selectUserSettingsLoaded = createSelector(
  [selectUserState],
  (state) => state.settingsLoaded
);
export const selectLoggedInUserId = createSelector(
  [selectUserState],
  (state) => state.user?.id
);

export const selectUserCoordinates = createSelector(
  [selectUserState],
  (state) => state.coordinates
);
export const selectGeolocationError = createSelector(
  [selectUserState],
  (state) => state.geolocationError
);
