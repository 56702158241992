import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRecordAllowance } from '../services/deleteRecordAllowance.service';

export const deleteRecordAllowanceThunk = createAsyncThunk(
  'recordAllowances/deleteRecordAllowance',
  async (recordAllowanceId) => {
    const response = await deleteRecordAllowance(recordAllowanceId);
    return response;
  }
);
