import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { DEFAULT_ADDITIONAL_SALARY_TYPES, RECORD_TYPE } from 'LixaniAPI/enums';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AllowanceDialog from '../AllowanceDialog/AllowanceDialog';
import AllowanceTimeframeItem from './AllowanceTimeframeItem';
import { RecordAllowanceItem } from './RecordAllowanceItem';
import { selectSelectedClockOutRecordResourceEmployerAllowances } from 'store/employerAllowances/selectors/record-employer-allowances.selectors';
import { selectAllowancesCreateParams } from 'store/records/selectors/records-base.selectors';

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: theme.palette.buttonPrimary,
    borderRadius: 4,
    boxSizing: 'border-box',
    textTransform: 'none',
    boxShadow: theme.shadows[1],
    marginTop: '8px',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}));

export default function Allowances({ record }) {
  const { t } = useTranslation('common');

  const classes = useStyles();

  const [allowanceDialogOpen, setAllowanceDialogOpen] = useState(false);

  const allowancesCreateParams = useSelector(selectAllowancesCreateParams);

  const allowanceTypeTranslations = {
    ABSENCE_SICK_LEAVE: t('common:record.type.sick_leave'),
    ABSENCE_VACATION_PAID: t('common:record.type.absence_vacation_paid'),
  };

  const employerAllowances = useSelector(
    selectSelectedClockOutRecordResourceEmployerAllowances
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t('record.allowances')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {allowancesCreateParams.map((createParams, index) => {
            const isTimeframedAllowance =
              createParams.type === RECORD_TYPE.ABSENCE_SICK_LEAVE ||
              createParams.type === RECORD_TYPE.ABSENCE_VACATION_PAID;

            if (isTimeframedAllowance) {
              return (
                <AllowanceTimeframeItem
                  name={allowanceTypeTranslations[createParams.type]}
                  startDate={createParams.startDate}
                  endDate={createParams.endDate}
                  key={index}
                ></AllowanceTimeframeItem>
              );
            } else {
              return (
                <RecordAllowanceItem
                  name={t(
                    DEFAULT_ADDITIONAL_SALARY_TYPES[createParams.salaryType]
                      .salaryName
                  )}
                  unit={
                    employerAllowances.find(
                      (allowance) =>
                        allowance.salaryType === createParams.salaryType
                    ).unit
                  }
                  unitCount={createParams.unitCount}
                  key={index}
                ></RecordAllowanceItem>
              );
            }
          })}
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.addButton}
            endIcon={<Icon path={mdiPlus} size={1}></Icon>}
            onClick={() => setAllowanceDialogOpen(true)}
          >
            {t('common.add')}
          </Button>
        </Grid>
      </Grid>

      {allowanceDialogOpen ? (
        <AllowanceDialog
          open={allowanceDialogOpen}
          setDialogOpen={(value) => setAllowanceDialogOpen(value)}
        ></AllowanceDialog>
      ) : null}
    </>
  );
}
