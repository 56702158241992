import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setDeleteForm from 'scenes/ProjectForms/utils/setDeleteForm';

export const deleteForm = createAsyncThunk(
    'projectForm/deleteForm',
    async (form, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };
        const deletedForm = await setDeleteForm(form, params);
        return { deletedForm };
    }
);