import { Grid } from '@material-ui/core';
import moment from 'moment';

import React from 'react';

export default function AllowanceTimeframeItem({ name, startDate, endDate }) {
  const startDateFormated = moment(startDate).format('DD.MM');
  const endDateFormated = moment(endDate).format('DD.MM.YYYY');

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          {name}
        </Grid>
        <Grid item xs={6}>
          {`${startDateFormated} - ${endDateFormated}`}
        </Grid>
      </Grid>
    </>
  );
}
