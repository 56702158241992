import { createAsyncThunk } from '@reduxjs/toolkit';
// import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { setDeleteAttachment } from '../../utils/setDeleteAttachment';

export const deleteAttachment = createAsyncThunk(
    'bulletins/deleteAttachment',
    async (filePermissionId, { getState }) => {
        // const state = getState();

        // const selectedProjectId = selectSelectedProjectId(state);

        const deletedAttachment = await setDeleteAttachment(filePermissionId);

        return { deletedAttachment };
    }
);