import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateTimelineSettingsService } from 'scenes/Timelines/services/updateTimelineSettings.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const updateTimelineSettings = createAsyncThunk(
  'timelines/updateTimelineSettings',
  async ({ id, timelineSettings }, { getState, rejectWithValue }) => {
    const state = getState();
    const projectId = selectSelectedProjectId(state);

    const response = await updateTimelineSettingsService(
      projectId,
      id,
      timelineSettings
    );
    return response;
  }
);
