const item = `
			__typename
			id
			projectKey
			key
			name
			createdAt
			updatedAt
			startAt
			endAt
			hasOwnAssignments
			defaultPermission {
				id
				sourceShow
				sourceUpdate
				targetShow
				targetUpdate
			}
			mirrorUser
			parent {
				id
				name
				street1
				street2
				latitude
				longitude
				co
				postcode
				city
				country
				explanation
				contact_first_name
				contact_last_name
				contact_street1
				contact_street2
				contact_co
				contact_postcode
				contact_city
				contact_country
				contact_email
				contact_phone
				masterProjectKey
				masterProject
				business_id
				business_type
				businessTypeEnum
				business_employment_type
				assignmentRequiredRule
				mirrorUser
				wageMultiplier
				projectKey
			}
			masterParent {
				id
				name
				street1
				street2
				latitude
				longitude
				co
				postcode
				city
				country
				explanation
				contact_first_name
				contact_last_name
				contact_street1
				contact_street2
				contact_co
				contact_postcode
				contact_city
				contact_country
				contact_email
				contact_phone
				masterProjectKey
				masterProject
				business_id
				business_type
				businessTypeEnum
				business_employment_type
				assignmentRequiredRule
				mirrorUser
				projectKey
			}
			street1
			street2
			co
			postcode
			city
			country
			latitude
			longitude
			explanation
			contact_first_name
			contact_last_name
			contact_street1
			contact_street2
			contact_co
			contact_postcode
			contact_city
			contact_country
			contact_email
			contact_phone
			masterProject
			masterProjectKey
			business_id
			business_type
			businessTypeEnum
			business_employment_type
			picture {
				bucket
				key
				region
			}
			pictureMust
			locationMust
			assignmentRequiredRule
			costpoolNumber
			ilve
			idcard
			nestedidcard
			filingsInfo
			logoUploader
			employer {
				id
				name
				business_id
				projectKey
			}
			employers {
				id
				name
				business_id
				projectKey
			}
			invoiceKeywords
			wageMultiplier
			fennoaProjectDimensionId
			iban
			bic
			budget
			budgetDivider
			budgetDividerValue
			mainColor
			orientationRequired
`;

export default item;
