import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getLastRecordForEveryResourceByUser from 'scenes/ProjectForms/utils/getLastRecordForEveryResourceByUser';

export const loadRecordsForResources = createAsyncThunk(
    'projectForm/loadRecordsForResources',
    async (projectId, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getRecordsPromises = [];

        getRecordsPromises.push(getLastRecordForEveryResourceByUser(projectId, params));

        const getRecordsPromisesSettled = await Promise.allSettled(
            getRecordsPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const resources = getRecordsPromisesSettled[0];

        return {
            resources,
        };
    }
);