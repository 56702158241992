import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from '../../../utils/createSignedRequest';

export default async function postClockOutRecord(
  record,
  allowancesSet,
  recordAllowancesToCreate
) {
  const apiPath = `records/${record.id}/clock-out-record`;

  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      record: record,
      allowancesSet: allowancesSet,
      recordAllowancesToCreate,
    }), // aws4 looks for body; axios for data
    data: {
      record: record,
      allowancesSet: allowancesSet,
      recordAllowancesToCreate,
    },
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
}
