import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotificationSettingsOfBusiness } from '../services/getNotificationSettingsOfBusiness.service';

export const loadNotificationSettingsOfBusiness = createAsyncThunk(
  'notificationSettings/loadNotificationSettingsOfBusiness',
  async (businessId) => {
    const response = await getNotificationSettingsOfBusiness(businessId);

    return response;
  }
);
