function getNestedAssignmentNumber(assignment, assignments) {
  let number = assignment.number;
  if (assignment.parent) {
    // handling assignments with and without filled parent data
    let parent = assignments.find((p) =>
      assignment.parent && assignment.parent.id
        ? p.id === assignment.parent.id
        : p.id === assignment.parent
    );
    if (parent) {
      number = getNestedAssignmentNumber(parent, assignments) + '.' + number;
    }
  }
  return number;
}

export default getNestedAssignmentNumber;
