export const returnResourcesFromSubProjects = (
  projects,
  selectedProjectId,
  resources
) => {
  let subProjectIds = [];

  projects.forEach((project) =>
    project.masterParent === selectedProjectId
      ? subProjectIds.push(project.id)
      : undefined
  );

  const projectResources = resources.filter((resource) =>
    subProjectIds.includes(resource.project)
  );

  return projectResources;
};
