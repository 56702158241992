import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import setSendForm from 'scenes/ProjectForms/utils/setSendForm';

export const sendForm = createAsyncThunk(
    'projectForm/sendForm',
    async (body, { getState }) => {
        const projectId = getState().projects.selectedProjectId;

        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        const sentForm = await setSendForm(body, params, projectId);

        return { sentForm };
    }
);