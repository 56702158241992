import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchSaveUserRoleModificationsInProject } from 'scenes/Permissions/services/roles/patchSaveUserRoleModificationsInProject.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectUserRoleModifications } from '../../selectors/permissionsSelectors';

export const saveUserRoleModifications = createAsyncThunk(
  'permissions/saveUserRoleModifications',
  async (_, { getState }) => {
    const projectId = selectSelectedProjectId(getState());

    const userRoleModifications = selectUserRoleModifications(getState());

    console.log('userRoleModifications', userRoleModifications);

    const response = await patchSaveUserRoleModificationsInProject(
      projectId,
      Object.values(userRoleModifications)
    );

    return response;
  }
);
