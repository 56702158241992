import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getManualResourcesByProjectByEmploymentType from 'scenes/ProjectForms/utils/getManualResourcesByProjectByEmploymentType';

export const loadFormSendData = createAsyncThunk(
  'projectForm/loadFormSendData',
  async (projectId, { getState }) => {

    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
    let getManualResourcesPromises = [];


    // Get manual resources of the project
    getManualResourcesPromises.push(
      getManualResourcesByProjectByEmploymentType(projectId, 'employee', params)
    );

    const getManualResourcesPromisesSettled = await Promise.allSettled(
      getManualResourcesPromises
    ).then((promises) => promises.map((promise) => promise.value));

    const manualResources = [].concat(...getManualResourcesPromisesSettled);

    return { manualResources };
  }
);
