import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const getProjectSaleInvoicesByDate = async (projectId, fromDate, toDate, lastEvaluatedKey) => {
  const apiPath = `invoice/${projectId}/sale-invoices/${fromDate}/${toDate}`;

  const body = {
    lastEvaluatedKey: lastEvaluatedKey,
    limit: 1000,
  };

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    data: body,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
};
