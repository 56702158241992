import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getProjectRecordTotalHours } from '../services/getProjectRecordTotalHours.service';

export const loadProjectRecordTotalHours = createAsyncThunk(
  'records/loadProjectRecordTotalHours',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const recordTotals = await getProjectRecordTotalHours(selectedProjectId);

    return recordTotals;
  }
);
