import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { getEmployerAndSubProjectsAssignments } from '../services/getEmployerAndSubProjectsAssigments';

export const loadProjectAndSubProjectAssignments = createAsyncThunk(
  'assignments/loadProjectAndSubProjectAssignments',
  async (_, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const projectAssignments = await getEmployerAndSubProjectsAssignments(
      selectedProjectId
    );
    return projectAssignments;
  }
);
