import React from 'react';
import PropTypes from 'prop-types';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from 'mdi-material-ui/Close';
import Icon from '@mdi/react';
import { mdiArrowCollapse, mdiClose } from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.component.dialogHeader,
    //borderBottom: `1px solid ${theme.palette.scheme.lighter}`,
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 'inherit',
  },
  titleText: {
    ...theme.typography.fontPanton,
    alignItems: 'center',
    color: theme.palette.textWhite.primary,
    display: 'flex',
    flex: 1,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    ...theme.component.dialogHeaderCloseButton,
    color: theme.palette.textWhite.primary,
  },
}));

function DialogHeader(props) {
  const classes = useStyles();
  const { icon, title, onClose, collapse } = props;

  return (
    <div className={classes.root}>
      <Typography variant="inherit" className={classes.titleText} noWrap={true}>
        {!icon ? null : React.cloneElement(icon, { className: classes.icon })}
        <span style={{ paddingTop: 4 }}>{title}</span>
      </Typography>
      {onClose && (
        <ButtonBase className={classes.closeButton} onClick={onClose}>
          <Icon path={collapse ? mdiArrowCollapse : mdiClose} size={1} />
        </ButtonBase>
      )}
    </div>
  );
}

DialogHeader.defaultProps = {
  icon: null,
  onClose: null,
  title: '',
};

DialogHeader.propTypes = {
  icon: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default DialogHeader;
