import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../utils/getCredentials';
import getProjectFormsByBusinessId from 'scenes/ProjectForms/utils/getProjectFormsByBusinessId';

export const getFormsByBusinessId = createAsyncThunk(
    'projectForm/getFormsByBusinessId',
    async (business_id, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let getProjectFormsPromises = [];

        getProjectFormsPromises.push(getProjectFormsByBusinessId(business_id, params));

        const getProjectFormsPromisesSettled = await Promise.allSettled(
            getProjectFormsPromises
        ).then((promises) => promises.map((promise) => promise.value));

        const subProjectForms = [].concat(...getProjectFormsPromisesSettled);

        return {
            subProjectForms,
        };
    }
);