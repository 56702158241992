import { createAsyncThunk } from '@reduxjs/toolkit';
import { getExpiringCardsOfOwnBusinessesEmployees } from '../services/getExpiringCardsOfOwnBusinessesEmployees.service';

export const loadExpiringCardsOfOwnBusinessEmployees = createAsyncThunk(
  'cards/loadExpiringCardsOfOwnBusinessEmployees',
  async () => {
    const ownEmployeeExpiringCards =
      await getExpiringCardsOfOwnBusinessesEmployees();

    return ownEmployeeExpiringCards;
  }
);
